import { FC, useMemo } from 'react';
import { useAppDispatch } from 'hooks/store';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import { orderBy } from 'lodash';
import cn from 'classnames';
import { MenuActionWithFloat, TableHeaderSort } from 'shared';
import { deleteUserThunk, updateUserThunk } from '../reducer';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { DotsIcon } from 'assets/icons';
import { colors } from 'variables';

const roleConfig: any = {
  admin: {
    label: 'Admin',
    bg: 'bg-green-gl',
  },
  user: {
    label: 'User',
    bg: 'bg-gray-g1',
  },
  manager: {
    label: 'Manager',
    bg: 'bg-pink-pl',
  },
  campaign_manager: {
    label: 'Campaign Manager',
    bg: 'bg-yellow-yl',
  },
  commenter: {
    label: 'Commenter',
    bg: 'bg-gray-g1',
  },
  influencer_manager: {
    label: 'Influencer Manager',
    bg: 'bg-blue-bl',
  },
  viewer: {
    label: 'Viewer',
    bg: 'bg-green-gl2',
  },
  test: {
    label: 'Test Account',
    bg: 'bg-pink-pl',
  },
};

interface IPeopleManagementTableProps {
  users: any[];
}

export const PeopleManagementTable: FC<IPeopleManagementTableProps> = ({ users }) => {
  const dispatch = useAppDispatch();

  const columns = useMemo(
    () => [
      {
        Header: 'User name',
        accessor: 'full_name',
        Cell: ({ value }: any) => (
          <p className="truncate" title={value}>
            {value}
          </p>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }: any) => <RoleView role={value} />,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }: any) => (
          <p className="truncate" title={value}>
            {value}
          </p>
        ),
      },
      {
        Header: '',
        accessor: 'action',
        width: 45,
        disableSortBy: true,
        className: 'sticky right-0 pl-0 py-0 !flex-none bg-gradient-to-l from-white via-white to-transparent opacity-0 group-hover:opacity-100',
        headerClassName: '!flex-none',
        Cell: ({ row }: any) => (
          <div
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MenuActionWithFloat
              className="flex"
              optionClass="!px-2.5"
              items={[
                {
                  label: 'Edit',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ['user', 'test'].includes(row.original.role) ? ModalTypes.AdminAddNewUser : ModalTypes.AdminAddNewManager,
                        params: {
                          user: row.original,
                          handleSave: async (data: any) =>
                            await dispatch(
                              updateUserThunk({
                                user_id: row.original.user_id,
                                data,
                              })
                            ),
                        },
                      })
                    ),
                },
                {
                  label: 'Delete',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.DeleteConfirmation,
                        params: {
                          name: row.original.full_name,
                          handleDelete: async () => await dispatch(deleteUserThunk(row.original.user_id)),
                        },
                      })
                    ),
                },
              ]}
            >
              {({ open }) => (
                <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                  <DotsIcon aria-hidden="true" />
                </span>
              )}
            </MenuActionWithFloat>
          </div>
        ),
      },
    ],
    []
  );

  const usersSorted = useMemo(() => orderBy(users, [(d) => d.full_name, (d) => d.user_id], ['asc', 'asc']), [users]);

  return (
    <div className="mt-3 flex flex-col overflow-hidden">
      <div className="flex flex-col min-w-full align-middle overflow-hidden">
        <div className="overflow-auto">
          <Table columns={columns} data={usersSorted} />
        </div>
      </div>
    </div>
  );
};

function Table({ columns, data }: any) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useFlexLayout
  );

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="min-w-full w-full rounded-[4px]">
      <thead className="sticky top-0 z-30 bg-gray-g1">
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="py-1.5 pl-6">
            {headerGroup.headers.map((column: any, hidx: number) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={cn(
                  'text-left text-xs font-normal text-black-b1',
                  typeof column.render('Header') === 'string' ? 'truncate' : '',
                  hidx === 0 ? 'pl-0 pr-2' : 'px-2',
                  column?.headerClassName || ''
                )}
              >
                <div
                  title={typeof column.Header === 'string' ? column.Header : ''}
                  className={cn('h-[18px] flex items-center space-x-3', column?.disableSortBy ? '' : 'cursor-pointer')}
                >
                  <p>{column.render('Header')}</p>
                  <TableHeaderSort column={column} activeFill={colors.black.b1} />
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="space-y-3">
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="relative group pl-6 rounded-[4px] bg-white hover:shadow-s1">
              {row.cells.map((cell: any, cidx: number) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={cn(
                      'relative flex items-center h-[58px] whitespace-nowrap text-sm leading-6 font-normal text-black-b1',
                      cidx === 0 ? 'pl-0 pr-2' : 'px-2',
                      cell.column?.className || ''
                    )}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export const RoleView: FC<{ role: string }> = ({ role }) => {
  return <div className={cn('flex items-center h-6 px-2.5 rounded-[4px] text-sm text-black-b1', roleConfig[role].bg)}>{roleConfig[role].label}</div>;
};
