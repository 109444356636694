import { FC } from 'react';
import { ClipLoader } from 'shared';
import { useGlobalLoader } from 'hooks/useGlobalLoader';

export const HeaderLoader: FC = () => {
  const loading = useGlobalLoader();

  return (
    <div className="absolute top-1/2 left-1/2 flex transform -translate-x-1/2 -translate-y-1/2">
      <ClipLoader loading={loading} styles={{ borderWidth: '4px' }} />
    </div>
  );
};
