import { FC, Fragment, ReactNode } from 'react';
import cn from 'classnames';
import { Menu as HMenu, Transition } from '@headlessui/react';

export type MenuItemsType = {
  label: string;
  onClick: (event: any) => void;
  iconComponent?: any;
  disabled?: boolean;
};

interface MenuProps {
  children: ReactNode | ((props: any) => ReactNode);
  items: MenuItemsType[];
  className?: string;
  itemsClass?: string;
  optionClass?: string;
}

export const Menu: FC<MenuProps> = ({ children, items, className, itemsClass = '', optionClass = '' }) => {
  return (
    <HMenu
      as="div"
      className={cn('relative inline-block text-left', className)}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {({ open }) => (
        <>
          <div>
            <HMenu.Button as="div" className="flex items-center focus:outline-none focus:ring-0">
              <span className="sr-only">Open menu</span>
              {typeof children === 'function' ? children({ open }) : children}
            </HMenu.Button>
          </div>

          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <HMenu.Items
              static
              className={cn(
                'origin-top-left absolute z-50 -left-2 mt-1 rounded-sm border border-gray-g1 shadow-s1 bg-white ring-0 focus:outline-none',
                itemsClass
              )}
            >
              <div className="py-1">
                {items.map((item: MenuItemsType) => (
                  <HMenu.Item key={item.label} disabled={item.disabled}>
                    <div
                      onClick={(e: any) => item.onClick(e)}
                      className={cn(
                        'whitespace-nowrap px-1.5 py-1.5 space-x-2 flex items-center cursor-pointer text-black-b1 hover:bg-gray-g1',
                        item.disabled ? 'disabled' : '',
                        optionClass
                      )}
                    >
                      {item.iconComponent ? <item.iconComponent className="w-4 h-4" aria-hidden="true" /> : null}
                      <p className="text-sm">{item.label}</p>
                    </div>
                  </HMenu.Item>
                ))}
              </div>
            </HMenu.Items>
          </Transition>
        </>
      )}
    </HMenu>
  );
};
