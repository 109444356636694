import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { isArray } from 'lodash';
import { fetchClientsThunk } from 'pages/Admin/reducer';
import {
  createCampaignApi,
  updateCampaignApi,
  duplicateCampaignApi,
  deleteCampaignApi,
  getCampaignsApi,
  ICreateCampaign,
  IGetCampaigns,
  IUpdateCampaign,
  IDuplicateCampaign,
  ICreateClient,
  createClientApi,
  updateClientApi,
  deleteClientApi,
} from 'services';
import { toast } from 'shared';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

interface ISettingsProps {
  tableFilters: any;
}

interface ICampaigns {
  campaigns: any;
  settings: ISettingsProps;
  loading: Loading;
}

const initialState: ICampaigns = {
  campaigns: {},
  settings: { tableFilters: {} },
  loading: defaultLoading,
};

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setSettings: (state, { payload }: PayloadAction<Partial<ISettingsProps>>) => {
      state.settings = { ...state.settings, ...payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignsThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchCampaignsThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CAMPAIGNS', payload);

        state.campaigns = payload;
        state.loading.is = false;
      })
      .addCase(fetchCampaignsThunk.rejected, (state) => {
        console.log('REJECTED FETCH CAMPAIGNS');

        state.loading.is = false;
      })

      .addCase(updateCampaignThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE CAMPAIGN', payload);

        if (state.campaigns && state.campaigns.campaigns) {
          const idx = state.campaigns.campaigns.findIndex((d: any) => d.campaign_id === payload[0].campaign_id);
          if (idx !== -1)
            state.campaigns.campaigns.splice(idx, 1, {
              ...state.campaigns.campaigns[idx],
              ...payload[0], // Not have some fields so we need use ...campaigns[idx]
            });
        }
      });
  },
});

export const campaignsActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchCampaignsThunk = createAsyncThunk('fetch_campaigns_thunk', async (args: IGetCampaigns) => {
  const response: any = await getCampaignsApi(args);

  if (typeof response === 'string') throw new Error();

  return response;
});

export const fetchCampaignsAllThunk = createAsyncThunk('fetch_campaigns_all_thunk', async (args: IGetCampaigns) => {
  const response: any = await getCampaignsApi(args);

  if (typeof response === 'string') throw new Error();

  return response;
});

export const createCampaignThunk = createAsyncThunk('create_campaign_thunk', async (data: ICreateCampaign, { dispatch, getState }: any) => {
  const { campaigns } = getState().campaigns;

  const response: any = await createCampaignApi(data);
  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to create new campaign', {
      type: 'error',
    });
    throw new Error();
  }
  // Need for load campaigns by page(pagination)
  const respCampaigns: any = await dispatch(
    fetchCampaignsThunk({
      page: campaigns.page || 1,
      order_by: campaigns.order_by || '',
      order_dir: campaigns.order_dir || '',
    })
  );
  if (fetchCampaignsThunk.fulfilled.match(respCampaigns))
    toast('Campaign created successfully', {
      type: 'success',
    });

  return null;
});

export const updateCampaignThunk = createAsyncThunk('update_campaign_thunk', async ({ campaign_id, data }: IUpdateCampaign) => {
  const response: any = await updateCampaignApi({ campaign_id, data });
  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to update campaign', {
      type: 'error',
    });
    throw new Error();
  }
  return response;
});

export const duplicateCampaignThunk = createAsyncThunk(
  'duplicate_campaign_thunk',

  async ({ campaign_id, data }: IDuplicateCampaign, { dispatch, getState }: any) => {
    const { campaigns } = getState().campaigns;
    const response: any = await duplicateCampaignApi({ campaign_id, data });

    if (!isArray(response) || (isArray(response) && !response.length)) {
      toast('Failed to update campaign', { type: 'error' });
      throw new Error();
    }

    const respCampaigns: any = await dispatch(
      fetchCampaignsThunk({
        page: campaigns.page || 1,
        order_by: campaigns.order_by || '',
        order_dir: campaigns.order_dir || '',
      })
    );

    if (fetchCampaignsThunk.fulfilled.match(respCampaigns)) {
      toast('Campaign duplicated successfully', { type: 'success' });
    }

    return null;
  }
);

export const deleteCampaignThunk = createAsyncThunk('delete_campaign_thunk', async (campaign_id: string, { dispatch, getState }: any) => {
  const { campaigns } = getState().campaigns;

  const response = await deleteCampaignApi(campaign_id);
  if (typeof response === 'string' && response === 'Success!') {
    const respCampaigns: any = await dispatch(
      fetchCampaignsThunk({
        page: campaigns.page || 1,
        order_by: campaigns.order_by || '',
        order_dir: campaigns.order_dir || '',
      })
    );
    if (fetchCampaignsThunk.fulfilled.match(respCampaigns))
      toast('Campaign deleted successfully', {
        type: 'success',
      });
  } else {
    toast('Failed to delete campaign', {
      type: 'error',
    });
    throw new Error();
  }

  return null;
});

export const createClientThunk = createAsyncThunk('create_client_thunk', async (data: ICreateClient, { dispatch, getState }: any) => {
  const response = await createClientApi(data);

  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to create new client', { type: 'error' });
    throw new Error();
  }

  const newClients = await dispatch(fetchClientsThunk());

  if (fetchClientsThunk.fulfilled.match(newClients)) {
    toast('Client created successfully', { type: 'success' });
  }

  return response;
});

interface IUpdateClientThunk {
  client_id: number;
  data: ICreateClient;
}

export const updateClientThunk = createAsyncThunk('update_client_thunk', async (args: IUpdateClientThunk, { dispatch, getState }: any) => {
  const response = await updateClientApi(args.client_id, args.data);

  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to update campaign', { type: 'error' });
    throw new Error();
  }

  const newClients = await dispatch(fetchClientsThunk());

  if (fetchClientsThunk.fulfilled.match(newClients)) {
    toast('Client updated successfully', { type: 'success' });
  }

  return response;
});

export const deleteClientThunk = createAsyncThunk('delete_client_thunk', async (client_id: number, { dispatch, getState }: any) => {
  const response = await deleteClientApi(client_id);

  if (typeof response === 'string' && response === 'Success!') {
    const newClients = await dispatch(fetchClientsThunk());

    if (fetchClientsThunk.fulfilled.match(newClients)) {
      toast('Client deleted successfully', { type: 'success' });
    }
  } else {
    toast('Failed to delete client', { type: 'error' });
    throw new Error();
  }
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.campaigns;

export const selectCampaignsData = createSelector(selectState, (state) => state.campaigns);
export const selectCampaignsSettings = createSelector(selectState, (state) => state.settings);

const campaigns: any[] = [];

export const selectCampaigns = createSelector(selectCampaignsData, (state) => state?.campaigns || campaigns);

export const selectCampaignsPagesCount = createSelector(selectCampaignsData, (state) => state?.pages_count || 1);

export const selectCampaignsCount = createSelector(selectCampaignsData, (state) => state?.campaigns_count || 0);

export const selectCampaignsOrderBy = createSelector(selectCampaignsData, (state) => state?.order_by || '');

export const selectCampaignsOrderDir = createSelector(selectCampaignsData, (state) => state?.order_dir || '');

export const selectCampaignsLoading = createSelector(selectState, (state) => state.loading);

export const selectCampaignsLoadingToHeader = createSelector(selectCampaignsLoading, (loading) => Boolean(loading.is && !loading.id));

export const selectCampaignsSettingsTableFilter = createSelector(
  selectCampaignsSettings,
  (settings) => settings?.tableFilters || {}
);

export const selectCampaignsSettingsTableFilterResizing = createSelector(
  selectCampaignsSettings,
  (settings) => settings?.tableFilters?.resizing || {}
);
