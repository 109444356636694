import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import cn from 'classnames';
import { approvalManagementStatuses, assetsStatuses, campaignStatuses, contentFormatStatuses } from 'utils/common';
import { Form, Input, Label, RSelect, ClipLoader, DatePicker } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { getStatus } from 'pages/Campaign/tabs/Selected';
import { CustomColoredSelectOption, CustomColoredSelectSingleValue, customColoredSelectStyles } from '../selected/EditSelectedChannel';

const EST_TIMEZONE = 'America/New_York';

interface IManagementEditProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const ManagementEdit: FC<IManagementEditProps> = ({ params, hideModal }) => {
  const defaultValues: any = useMemo(
    () => ({
      channel_name: params?.influencer?.channel_name || '',
      campaign_status: params?.influencer?.campaign_status || getStatus('', campaignStatuses).value,
      theme: params?.influencer?.theme || '',
      giveaway_code: params?.influencer?.giveaway_code || '',
      content_format: params?.influencer?.content_format || getStatus('', contentFormatStatuses).value,
      assets_status: params?.influencer?.assets_status || getStatus('', assetsStatuses).value,
      approval_management: params?.influencer?.approval_management || getStatus('', approvalManagementStatuses).value,
      draft_due_date: params?.influencer?.draft_due_date || null,
      publication_date: params?.influencer?.publication_date ? utcToZonedTime(params.influencer.publication_date, EST_TIMEZONE) : null,
      tracking_link: params?.influencer?.tracking_link || '',
      video_draft: params?.influencer?.video_draft || '',
      video_final: params?.influencer?.video_final || '',
    }),
    [params?.influencer]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title="Update management influencer" close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        // validationType={ValidationTypes.AdminAddInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values, moment(zonedTimeToUtc(values.publication_date, EST_TIMEZONE)).toISOString());
          const copyValues = Object.assign({}, values);
          delete copyValues.channel_name;
          return params
            .handleSave({
              ...copyValues,
              sm_platform: params?.influencer?.sm_platform,
              draft_due_date: values.draft_due_date ? moment(values.draft_due_date).toISOString() : null,
              publication_date: values.publication_date ? moment(zonedTimeToUtc(values.publication_date, EST_TIMEZONE)).toISOString() : null,
            })
            .then(() => hideModal());
        }}
      >
        {({ register, control, formState, setFocus }) => {
          const { errors, isSubmitting } = formState;
          return (
            <>
              <div className="pointer-events-none">
                <Label htmlFor="channel_name" label="Name" error={errors.channel_name} className="text-gray-gm" />
                <div className="mt-0.5">
                  <Input id="channel_name" type="text" placeholder="Name" error={errors.channel_name} disabled {...register('channel_name')} />
                </div>
              </div>
              <div>
                <Label htmlFor="campaign_status" label="Status" error={errors.campaign_status} />
                <div className="mt-0.5">
                  <Controller
                    name="campaign_status"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={campaignStatuses}
                          error={errors.campaign_status}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={campaignStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="content_format" label="Content format" error={errors.content_format} />
                <div className="mt-0.5">
                  <Controller
                    name="content_format"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={contentFormatStatuses}
                          error={errors.content_format}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={contentFormatStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="theme" label="Theme" error={errors.theme} />
                <div className="relative mt-0.5">
                  <Input id="theme" type="text" placeholder="Theme" error={errors.theme} {...register('theme')} />
                </div>
              </div>
              <div>
                <Label htmlFor="giveaway_code" label="Giveaway code" error={errors.giveaway_code} />
                <div className="relative mt-0.5">
                  <Input id="giveaway_code" type="text" placeholder="Giveaway code" error={errors.giveaway_code} {...register('giveaway_code')} />
                </div>
              </div>
              <div>
                <Label htmlFor="assets_status" label="Assets Status" error={errors.assets_status} />
                <div className="mt-0.5">
                  <Controller
                    name="assets_status"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={assetsStatuses}
                          error={errors.assets_status}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={assetsStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="approval_management" label="Approval" error={errors.approval_management} />
                <div className="mt-0.5">
                  <Controller
                    name="approval_management"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={approvalManagementStatuses}
                          error={errors.approval_management}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={approvalManagementStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="draft_due_date" label="Draft due date" error={errors.draft_due_date} />
                <div className="relative mt-0.5">
                  <Controller
                    name="draft_due_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        placeholder="Draft due date"
                        error={errors.draft_due_date}
                        selected={field.value ? new Date(field.value) : null}
                        onChange={(value: Date | null) => {
                          field.onChange(value);
                          setFocus('draft_due_date');
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <Label
                  htmlFor="publication_date"
                  label={
                    <div className="flex justify-between">
                      <p>Publication date</p>
                      <p className="font-medium text-gray-gm">Eastern Standard Time (EST)</p>
                    </div>
                  }
                  error={errors.publication_date}
                />
                <div className="relative mt-0.5">
                  <Controller
                    name="publication_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect={true}
                        dateFormat={'MM/dd/yyyy hh:mm aa'}
                        placeholder="Publication date"
                        error={errors.publication_date}
                        selected={field.value ? field.value : null}
                        onChange={(value: Date | null) => {
                          field.onChange(value);
                          setFocus('publication_date');
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="tracking_link" label="Tracking link" error={errors.tracking_link} />
                <div className="relative mt-0.5">
                  <Input
                    id="tracking_link"
                    type="text"
                    placeholder="Tracking link"
                    error={errors.tracking_link}
                    className="!text-blue-b1"
                    {...register('tracking_link')}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="video_draft" label="Video draft" error={errors.video_draft} />
                <div className="relative mt-0.5">
                  <Input
                    id="video_draft"
                    type="text"
                    placeholder="Video draft"
                    error={errors.video_draft}
                    className="!text-blue-b1"
                    {...register('video_draft')}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="video_final" label="Video final" error={errors.video_final} />
                <div className="relative mt-0.5">
                  <Input
                    id="video_final"
                    type="text"
                    placeholder="Video final"
                    error={errors.video_final}
                    className={params?.influencer?.sm_platform === 'youtube' ? '!text-blue-b1' : ''}
                    {...register('video_final')}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <button
                  type="button"
                  className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  <p>Save</p>
                  <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                </button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
