import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmptyIcon } from 'assets/icons';

export const SelectedEmpty: FC = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <EmptyIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">No selected influencers yet</h1>
      <p className="mt-2 text-center text-sm leading-6 text-black-b1">
        Go to the Influencers page and select the most
        <br /> suitable options
      </p>
      <div className="mt-9">
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-blue-b1 px-4 py-2 uppercase text-sm font-medium text-white shadow-sm hover:bg-blue-b1 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
          onClick={() => navigate(`/campaigns/${campaignId}`, { replace: true })}
        >
          Select influencers
        </button>
      </div>
    </div>
  );
};
