import { FC, Fragment, ReactNode } from 'react';
import cn from 'classnames';
import { Popover as HPopover, Transition } from '@headlessui/react';

interface IPopoverProps {
  content: ReactNode | ((props: any) => ReactNode);
  children?: ReactNode | ((props: any) => ReactNode);
  panelClass?: string;
  isTransition?: boolean;
}

export const Popover: FC<IPopoverProps> = ({ children, content, panelClass = '', isTransition = true }) => {
  return (
    <HPopover as="div" className="relative">
      {({ open, close }) => (
        <>
          <HPopover.Button as={Fragment}>
            {children ? (
              typeof children === 'function' ? (
                children({ open })
              ) : (
                children
              )
            ) : (
              <button
                type="button"
                className={cn(
                  'flex items-center justify-between w-44 h-12 appearance-none text-xs leading-6 font-normal bg-gray-g1 rounded-[4px] border-2 border-gray-g1 p-3 focus:border-blue-b1 focus:outline-none focus:ring-blue-b1',
                  open ? 'text-blue-b1' : 'text-black'
                )}
              >
                Popover
              </button>
            )}
          </HPopover.Button>

          {/* Use the `Transition` component. */}
          {isTransition ? (
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <HPopover.Panel
                static
                className={cn(
                  'origin-top-left absolute top-1 z-40 w-auto rounded-[4px] border border-gray-g1 shadow-s1 bg-white ring-0 focus:outline-none',
                  panelClass
                )}
              >
                {typeof content === 'function' ? content({ open, close }) : content}
              </HPopover.Panel>
            </Transition>
          ) : (
            <HPopover.Panel
              className={cn(
                'origin-top-left absolute mt-1 z-40 w-auto rounded-[4px] border border-gray-g1 shadow-s1 bg-white ring-0 focus:outline-none',
                panelClass
              )}
            >
              {typeof content === 'function' ? content({ open, close }) : content}
            </HPopover.Panel>
          )}
        </>
      )}
    </HPopover>
  );
};
