import { FC } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useParams } from 'react-router-dom';
import { colors } from 'variables';
import { Tabs } from 'shared';
import {
  campaignClientActions,
  fetchCampaignClientThunk,
  selectCampaignClient,
  selectCampaignClientReportIds,
  selectCampaignManagementDataClient,
  selectCampaignSelectedChannelsCountClient,
  selectCampaignSelectedChannelsTwitchClient,
  selectCampaignSelectedChannelsYoutubeClient,
} from './reducer';
import { SkeletonCampaignClient } from './Skeleton';
import { NoCampaign } from 'pages/Campaign/empties/NoCampaign';
import { Selected } from './tabs/Selected';
import { Management } from './tabs/Management';
import { Report } from './tabs/Report';
import { StarIcon, StarSolidIcon } from 'assets/icons';

export const CampaignClient: FC = () => {
  const { campaignId } = useParams();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaignClient);
  const selectedChannelsYoutube = useAppSelector(selectCampaignSelectedChannelsYoutubeClient);
  const selectedChannelsTwitch = useAppSelector(selectCampaignSelectedChannelsTwitchClient);
  const selectedChannelsCount = useAppSelector(selectCampaignSelectedChannelsCountClient);
  const managementData = useAppSelector(selectCampaignManagementDataClient);
  const reportIds = useAppSelector(selectCampaignClientReportIds);

  useLegacyEffect(() => {
    if (campaignId) dispatch(fetchCampaignClientThunk(campaignId));
  }, [campaignId]);

  useLegacyEffect(() => {
    return () => {
      dispatch(campaignClientActions.resetState());
    };
  }, []);

  if (campaign === undefined || selectedChannelsYoutube == undefined || selectedChannelsTwitch == undefined || managementData == undefined)
    return <SkeletonCampaignClient />;
  if (campaign === null) return <NoCampaign />;

  return (
    <>
      <Tabs
        classRoot="pt-6"
        tabs={[
          {
            query: 'selected',
            title: ({ selected }: any) => (
              <div className="flex items-center">
                {selectedChannelsCount ? (
                  <StarSolidIcon className="mr-1.5" pathFill={selected ? colors.blue.b1 : colors.black.b1} aria-hidden="true" />
                ) : (
                  <StarIcon pathFill={selected ? colors.blue.b1 : colors.black.b1} className="mr-1.5" aria-hidden="true" />
                )}
                {`Selected${selectedChannelsCount ? ` (${selectedChannelsCount})` : ''}`}
              </div>
            ),
            content: <Selected />,
            panelClass: '!p-0',
          },
          {
            query: 'management',
            title: `Campaign management${managementData.length ? ` (${managementData.length})` : ''}`,
            content: <Management />,
            panelClass: '!p-0',
          },
          {
            query: 'report',
            title: `Report${reportIds.length ? ` (${reportIds.length})` : ''}`,
            content: <Report />,
            panelClass: '!p-0',
          },
        ]}
      />
    </>
  );
};
