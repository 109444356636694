import { FC, useState, useEffect, useMemo, useCallback, useRef, memo, useImperativeHandle, forwardRef } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useTable, useRowSelect, useBlockLayout, useSortBy, useResizeColumns } from 'react-table';
import { useVirtual } from 'react-virtual';
import moment from 'moment';
import { isEqual, orderBy } from 'lodash';
import cn from 'classnames';
import { influencerConfig } from 'utils/common';
import { dateSort, formatNumber } from 'utils/helper';
import { createInfluencerThunk, deleteInfluencerThunk, selectInfluencers, updateInfluencerThunk } from 'pages/Influencers/reducer';
import { ActionMenu, MenuActionWithFloat, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { Settings } from 'components/Settings';
import { SimpleSearch } from 'components/SimpleSearch';
import { LanguageFullName } from 'components/LanguageFullName';
import { CountryFullName } from 'components/CountryFullName';
import { PopupDescription } from 'components/PopupDescription';
import { IndeterminateCheckbox } from 'pages/Influencers/tabs/InfluencersTab';
import { DotsIcon } from 'assets/icons';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  fetchInfluencersAdminThunk,
  selectInfluencersAdmin,
  selectInfluencersSelectedRowsAdmin,
  selectSearchInfluencersAdmin,
  selectSearchTableFilters,
  selectSearchTableFiltersResizing,
  selectTotalAdmin,
  selectUsersLoadingToHeader,
  usersActions,
} from '../reducer';

export const ManageInfluencers: FC = () => {
  const dispatch = useAppDispatch();
  const influencers = useAppSelector(selectInfluencersAdmin);
  const total = useAppSelector(selectTotalAdmin);
  const searchInfluencers = useAppSelector(selectSearchInfluencersAdmin);
  const selectedRows = useAppSelector(selectInfluencersSelectedRowsAdmin);

  const columns = useMemo(
    () => [
      {
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="flex items-center space-x-2 truncate">
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
            <TableHeaderTruncate name={influencerConfig.full_name.name} />
          </div>
        ),
        accessor: 'full_name',
        Cell: ({ row, value }: any) => (
          <div className="flex items-center space-x-2 overflow-hidden">
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
            <TableCellTruncate linkTo={row.original?.influencer_id != null ? `/admin/${row.original.influencer_id}` : undefined} value={value} />
            {/* <a href={row.original.channel_url} target="_blank" rel="noopener">
              <LinkIcon />
            </a> */}
          </div>
        ),
        width: 220,
      },
      {
        Header: influencerConfig.first_name.name,
        accessor: 'first_name',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.last_name.name,
        accessor: 'last_name',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.email.name,
        accessor: 'email',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.language.name,
        accessor: 'language',
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.cell.name,
        accessor: 'cell',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.country.name,
        accessor: 'country',
        Cell: ({ value }: any) => <CountryFullName country={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.city.name,
        accessor: 'city',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.gender.name,
        accessor: 'gender',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.platform.name,
        accessor: 'platform',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.genre.name,
        accessor: 'genre',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.main_game.name,
        accessor: 'main_game',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.youtube_url.name,
        accessor: 'youtube_url',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={
              <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
                {value}
              </a>
            }
          />
        ),
        width: 130,
      },
      {
        Header: influencerConfig.twitch_url.name,
        accessor: 'twitch_url',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={
              <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
                {value}
              </a>
            }
          />
        ),
        width: 130,
      },
      {
        Header: influencerConfig.twitter_url.name,
        accessor: 'twitter_url',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={
              <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
                {value}
              </a>
            }
          />
        ),
        width: 130,
      },
      {
        Header: influencerConfig.instagram_url.name,
        accessor: 'instagram_url',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={
              <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
                {value}
              </a>
            }
          />
        ),
        width: 130,
      },
      {
        Header: influencerConfig.tiktok_url.name,
        accessor: 'tiktok_url',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={
              <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
                {value}
              </a>
            }
          />
        ),
        width: 130,
      },
      {
        Header: influencerConfig.discord.name,
        accessor: 'discord',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.whatsapp.name,
        accessor: 'whatsapp',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: influencerConfig.created_at.name,
        accessor: 'created_at',
        Cell: (row: any) => <TableCellTruncate value={moment(row.value).format('DD MMM YYYY')} />,
        width: 130,
        sortType: dateSort,
      },
      {
        Header: '',
        accessor: 'action',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        disabledResizing: true,
        disableSortBy: true,
        className: 'flex py-0 bg-gradient-to-l from-white via-white to-transparent group-hover:sticky group-hover:z-10 group-hover:right-0',
        Cell: (row: any) => (
          <MenuActionWithFloat
            className="flex"
            optionClass="!px-2.5"
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  dispatch(
                    showModal({
                      type: ModalTypes.AddInfluencer,
                      params: {
                        noPlatform: true,
                        influencer: row.row.original,
                        handleSave: async (data: any) =>
                          await dispatch(
                            updateInfluencerThunk({
                              influencer_id: row.row.original.influencer_id,
                              data,
                            })
                          ),
                      },
                    })
                  );
                },
              },
              {
                label: 'Delete',
                onClick: () =>
                  dispatch(
                    showModal({
                      type: ModalTypes.DeleteConfirmation,
                      params: {
                        name: row.row.original.first_name + ' ' + row.row.original.last_name,
                        handleDelete: async () => await dispatch(deleteInfluencerThunk([row.row.original.influencer_id])),
                      },
                    })
                  ),
              },
            ]}
          >
            {({ open }) => (
              <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                <DotsIcon aria-hidden="true" />
              </span>
            )}
          </MenuActionWithFloat>
        ),
      },
    ],
    []
  );

  const handleSearch = useCallback((value: any) => {
    dispatch(
      usersActions.setSearchInfluencers({
        ...searchInfluencers,
        search: value,
      })
    );
    dispatch(fetchInfluencersAdminThunk());
  }, []);

  useLegacyEffect(() => {
    dispatch(fetchInfluencersAdminThunk());
  }, []);

  return (
    <>
      <SimpleSearch className="py-4" placeholder="Search by name" onChange={handleSearch} />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl leading-[42px] font-semibold">{`Manage influencers (${formatNumber(total ? parseFloat(total.count || 0) : 0)})`}</h1>
          <div className="flex items-center space-x-4">
            <ActionMenu
              items={[
                {
                  label: 'Edit',
                  onClick: () => {},
                },
                {
                  label: 'Delete',
                  onClick: () => {
                    if (selectedRows.length)
                      dispatch(
                        showModal({
                          type: ModalTypes.DeleteConfirmation,
                          params: {
                            name: selectedRows.map((d: any) => d.name).join(', '),
                            handleDelete: async () => await dispatch(deleteInfluencerThunk(selectedRows.map((d: any) => d.id))),
                          },
                        })
                      );
                  },
                },
              ]}
            />
            {/* <Menu
              className="flex items-center justify-center"
              itemsClass="top-[2.7rem] !left-0" // min-w-[154px]
              optionClass="!px-4"
              items={[
                {
                  label: 'Excel',
                  onClick: () => {},
                },
                {
                  label: 'CSV',
                  onClick: () => {},
                },
              ]}
            >
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0',
                    open ? 'bg-blue-b1 text-white' : 'bg-white text-blue-b1'
                  )}
                >
                  <ExportIcon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </Menu> */}
            {/* <button
              type="button"
              className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
              onClick={() =>
                dispatch(
                  showModal({
                    type: ModalTypes.AddInfluencer,
                    params: {
                      noPlatform: true,
                      handleSave: async (data: any) =>
                        await dispatch(createInfluencerThunk(data)),
                    },
                  })
                )
              }
            >
              <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              <p className="mr-1.5">ADD INFLUENCERS</p>
            </button> */}
          </div>
        </div>

        <Settings searchChannels={{ filters: {} }} />

        <div className="mt-4 flex flex-col overflow-hidden">
          <div className="flex flex-col min-w-full align-middle overflow-hidden">
            <Table columns={columns} data={influencers} />
          </div>
        </div>
      </div>
    </>
  );
};

const Table: FC<any> = memo(
  forwardRef(({ columns, data }, ref: any) => {
    const dispatch = useAppDispatch();
    const total = useAppSelector(selectTotalAdmin);
    const searchInfluencers = useAppSelector(selectSearchInfluencersAdmin);
    const selectedRows = useAppSelector(selectInfluencersSelectedRowsAdmin);
    const loading = useAppSelector(selectUsersLoadingToHeader);
    const tableFilters = useAppSelector(selectSearchTableFilters);
    const tfResizing = useAppSelector(selectSearchTableFiltersResizing);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    const totalDBRowCount = parseFloat(total.count || 0);
    const totalFetched = data.length;

    const fetchMoreOnBottomReached = useCallback(
      (containerRefElement?: HTMLDivElement | null) => {
        if (containerRefElement) {
          const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
          // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
          if (scrollHeight - scrollTop - clientHeight < 300 && !loading && totalFetched < totalDBRowCount) {
            dispatch(
              fetchInfluencersAdminThunk({
                offset: totalFetched,
                isScrollFetch: true,
              })
            );
          }
        }
      },
      [totalFetched, totalDBRowCount, loading]
    );

    const _selectedRows = useMemo(() => {
      const ids: any = {};
      data.forEach((d: any, i: number) => {
        if (selectedRows.some((s: any) => s.id === d.influencer_id)) ids[i] = true;
      });
      return ids;
    }, [data]);

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      toggleAllRowsSelected,
      // @ts-ignore
      state: { selectedRowIds, columnResizing },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { selectedRowIds: _selectedRows },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      useRowSelect,
      useResizeColumns,
      useBlockLayout
    );

    useLegacyEffect(() => {
      const ids: any[] = Object.keys(selectedRowIds).map((d: any) => ({
        id: data[d].influencer_id,
        name: data[d].full_name || data[d].first_name + ' ' + data[d].last_name,
      }));

      if (!isEqual(selectedRows, ids)) dispatch(usersActions.setInfluencersRows(ids));
    }, [selectedRows, selectedRowIds]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          usersActions.setSearchInfluencers({
            tableFilters: {
              ...tableFilters,
              resizing: columnResizing.columnWidths,
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedRows: () => toggleAllRowsSelected(false),
      }),
      []
    );

    const handleSort = (order_by: string, order_dir: string | 'asc' | 'desc') => {
      dispatch(
        usersActions.setSearchInfluencers({
          ...searchInfluencers,
          order_by,
          order_dir,
        })
      );
      dispatch(fetchInfluencersAdminThunk());
    };

    const checkOrderDir = (column: string) => {
      return searchInfluencers.order_by === column && searchInfluencers.order_dir === 'desc' ? 'asc' : searchInfluencers.order_dir === 'asc' ? '' : 'desc';
    };

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    useLegacyEffect(() => {
      if (tableContainerRef.current && tableContainerRef.current.scrollTop) tableContainerRef.current.scrollTop = 0;
    }, [searchInfluencers]);

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]" onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}>
        <div {...getTableProps()} className="table">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps()}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      searchInfluencers.order_by === column.id ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div
                      className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}
                      onClick={() => {
                        handleSort(searchInfluencers.order_by === column.id && !checkOrderDir(column.id) ? '' : column.id, checkOrderDir(column.id));
                      }}
                    >
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort
                          column={column}
                          isSorted={searchInfluencers.order_by === column.id}
                          isSortedDesc={searchInfluencers.order_dir === 'desc'}
                        />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }),
  (prev, next) => isEqual(prev, next)
);
