import { FC, forwardRef } from 'react';
import cn from 'classnames';

interface IInputProps {
  className?: string;
  [propName: string]: any;
}

export const Input: FC<IInputProps> = forwardRef(({ error, className = '', type, ...rest }, ref: any) => {
  return (
    <>
      <input
        ref={ref}
        type={type}
        onWheel={(e: any) => {
          if (type === 'number') e.target.blur();
        }} // If user starts to scroll when cursor on input capture area
        {...rest}
        className={cn(
          'block w-full h-12 appearance-none text-xs leading-6 font-normal text-black-b1 placeholder-gray-gm rounded-[4px] border p-3 focus:ring-0 focus:outline-none',
          rest.disabled ? 'bg-gray-g1 text-gray-gm border-gray-g2' : '',
          className,
          error ? 'border-red-r1 focus:border-red-r1' : 'border-gray-g2 focus:border-blue-b1'
        )}
        aria-invalid={error ? 'true' : 'false'}
      />
      {/* {error ? (
          <div className="mt-0.5 text-red-r1 text-xs">{error.message}</div>
        ) : null} */}
    </>
  );
});
