import { useMemo, useState } from 'react';
import { useLegacyEffect } from './useLegacyEffect';
import { useAppSelector } from './store';
import { selectInfluencersLoadingToHeader } from 'pages/Influencers/reducer';
import { selectUsersLoadingToHeader } from 'pages/Admin/reducer';
import { selectCampaignsLoadingToHeader } from 'pages/Campaigns/reducer';
import { selectCampaignLoadingToHeader } from 'pages/Campaign/reducer';
import { selectCampaignsClientLoadingToHeader } from 'pages-client/Campaigns/reducer';
import { selectCampaignClientLoadingToHeader } from 'pages-client/Campaign/reducer';
import { selectAuthLoadingToHeader } from 'pages/Auth/reducer';

export const useGlobalLoader = () => {
  const authLoading = useAppSelector(selectAuthLoadingToHeader);
  const influencersLoading = useAppSelector(selectInfluencersLoadingToHeader);
  const usersLoading = useAppSelector(selectUsersLoadingToHeader);
  const campaignsLoading = useAppSelector(selectCampaignsLoadingToHeader);
  const campaignLoading = useAppSelector(selectCampaignLoadingToHeader);
  const campaignsClientLoading = useAppSelector(selectCampaignsClientLoadingToHeader);
  const campaignClientLoading = useAppSelector(selectCampaignClientLoadingToHeader);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loading = useMemo(
    () => authLoading || influencersLoading || usersLoading || campaignsLoading || campaignLoading || campaignsClientLoading || campaignClientLoading,
    [authLoading, influencersLoading, usersLoading, campaignsLoading, campaignLoading, campaignsClientLoading, campaignClientLoading]
  );

  useLegacyEffect(() => {
    let loadingTimeout: any = undefined;
    if (loading) {
      loadingTimeout = setTimeout(() => {
        setIsLoading(true);
      }, 500);
    } else {
      clearTimeout(loadingTimeout);
      setIsLoading(false);
    }

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [loading]);

  return isLoading;
};
