import moment from 'moment';
import numeral from 'numeral';
import { ISearchChannels, Platform } from 'services';
import { checkContentFormat, getColumnFieldByContentFormat, selectedColumnsForContentFormat } from 'pages/Campaign/tabs/Selected';
import tooltipsData from './tooltips.json';

export const publicationDateFormat = (date: any) => moment(date).format('MM/DD/YYYY hh:mm A');

export const formatNumber = (number: any, format?: string, type: 'integer' | 'float' = 'integer') => {
  if (number == null) return 'null';
  if (number === 0) return 0;

  switch (type) {
    case 'integer': {
      const _format = format || '0,0';
      const _number = Math.round(number);
      return numeral(_number).format(format ? format : _number >= 100000 ? '0,0.[0]a' : _format);
    }
    case 'float': {
      const _format = format || '0,0.[00]';
      return numeral(parseFloat(number)).format(_format);
    }
    default:
      return number;
  }
};

export const randomColor = (function () {
  var golden_ratio_conjugate = 0.618033988749895;
  var h = Math.random();

  const hslToRgb = function (h: number, s: number, l: number) {
    var r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p: number, q: number, t: number) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return '#' + Math.round(r * 255).toString(16) + Math.round(g * 255).toString(16) + Math.round(b * 255).toString(16);
  };

  return function () {
    h += golden_ratio_conjugate;
    h %= 1;
    return hslToRgb(h, 0.5, 0.6);
  };
})();

export const closestNumber = (a: number, b: number) => {
  return a + b - (a % b);
  const c1 = a - (a % b);
  const c2 = a + b - (a % b);
  if (a - c1 > c2 - a) {
    return c2;
  } else {
    return c1;
  }
};

const getCpvhFactor = (content_format: string) => {
  switch (content_format) {
    case '2hr_twitch_stream':
      return 2;

    default:
      return 1;
  }
};

// Selected (youtube, twitch)
export const getCpmSelectedDenominator = (data: any) => {
  const denominator =
    data?.sm_platform === 'youtube'
      ? checkContentFormat(data?.content_format)
        ? getColumnFieldByContentFormat[data.content_format]?.median_views_20
          ? data?.[getColumnFieldByContentFormat[data.content_format].median_views_20] || 0
          : 0
        : data?.median_views_20
      : data?.aaccv_20;

  if (data?.sm_platform === 'twitch') {
    return denominator * getCpvhFactor(data?.content_format);
  } else {
    return denominator;
  }
};

// Engagement, Management
export const getCpmOtherDenominator = (data: any) => {
  const denominator = checkContentFormat(data?.content_format)
    ? getColumnFieldByContentFormat[data.content_format]?.average_views
      ? data?.[getColumnFieldByContentFormat[data.content_format].average_views] || 0
      : 0
    : data?.average_views;

  if (data?.sm_platform === 'twitch') {
    return denominator * getCpvhFactor(data?.content_format);
  } else {
    return denominator;
  }
};

export const getCpm = (value: string | number, denominator: string | number, platform: Platform = 'youtube') => {
  if (value && denominator && +denominator > 0) return (+value * (platform === 'youtube' ? 1000 : 1)) / +denominator;
  return null;
};

export const getTotalForCPM = (rows: any[], accessor: string, platform?: string) => {
  let numberOfRows: number = 0;
  const summa: number =
    rows.reduce((sum: number, row: any) => {
      const n: number | null = getCpm(
        row.original[accessor],
        platform ? getCpmOtherDenominator(row.original) : getCpmSelectedDenominator(row.original),
        row.original.sm_platform
      );

      if (platform) {
        if (row.original.sm_platform === platform && n) {
          numberOfRows++;
          return n + sum;
        } else return sum;
      }
      if (n) numberOfRows++;
      return (n || 0) + sum;
    }, 0) || 0;

  return summa / (numberOfRows || 1);
};

// TODO may be memory leak because RTable docs tells that this func need to add useMemo. Parent function have useMemo yet
export const numberSort = (rowA: any, rowB: any, id: string) => {
  const a = parseFloat(rowA.values[id] || 0);
  const b = parseFloat(rowB.values[id] || 0);
  return a > b ? 1 : -1;
};

export const dateSort = (rowA: any, rowB: any, id: string) => {
  if (!rowA.values[id] || !rowB.values[id]) return -1; // TODO if !date
  return moment(rowA.values[id]).diff(moment(rowB.values[id]));
};

export const statusSort = (rowA: any, rowB: any, id: string, statuses: any) => {
  const defaultStatus = statuses.find((d: any) => d.default).value;

  const a = rowA.values[id] != null ? rowA.values[id] : defaultStatus;
  const b = rowB.values[id] != null ? rowB.values[id] : defaultStatus;
  return a > b ? 1 : -1;
};

export const localeSort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
  const compare = (firstRow: any, secondRow: any) => {
    return firstRow.values[columnId].localeCompare(secondRow.values[columnId], undefined, { sensitivity: 'accent' });
  };

  return desc ? -compare(rowB, rowA) : compare(rowA, rowB);
};

export const filterCampaignChannels = (channels: any[], search: ISearchChannels) => {
  if (search?.filters) {
    return channels.filter((d: any) =>
      Object.keys(search.filters).every((key: any) => {
        let value = d[key];

        if (Object.keys(selectedColumnsForContentFormat).includes(key) && checkContentFormat(d.content_format)) {
          value = getColumnFieldByContentFormat[d.content_format]?.[key] ? d[getColumnFieldByContentFormat[d.content_format][key]] : null;
        }

        switch (key) {
          case 'language':
          case 'country':
          case 'sm_platform':
          case 'approval':
          case 'approval_management':
          case 'outreach_status':
          case 'content_format':
          case 'contract_status':
          case 'agency_id':
          case 'campaign_status':
          case 'assets_status':
            return search.filters[key].includes(value);
          case 'subscribers':
          case 'median_views_20':
          case 'engagement_score':
          case 'true_reach_20':
          case 'followers':
          case 'aaccv_20':
          case 'average_peak_20':
            return parseFloat(value) >= parseFloat(search.filters[key][0]) && parseFloat(value) <= parseFloat(search.filters[key][1]);
          default:
            return true;
        }
      })
    );
  } else {
    return channels;
  }
};

export const replaceSpaceToUnderscore = (str: string) => {
  return (str || '').replace(/ /g, '_');
};

export const getTooltip = (type: string, platform: string, column: string) =>
  tooltipsData.find((d: any) => d.type === type && d.platform === platform && d.column === column)?.tooltip || '';
