import queryString from 'query-string';

const { origin } = new URL(window.location.href);

const redirect_uri: any = {
  google: `${origin}/login?oauth=google`,
  facebook: `${origin}/login?oauth=facebook`,
};

// Google
const stringifiedGoogleOAuthParams = queryString.stringify({
  client_id: '566425285379-11b4ahemsg5m4d3on960ahl2m9gln3n7.apps.googleusercontent.com',
  redirect_uri: redirect_uri.google,
  scope: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'].join(' '), // space seperated string
  response_type: 'code',
  access_type: 'offline',
  prompt: 'consent',
});

export const googleLoginUrl = `https://accounts.google.com/o/oauth2/auth?${stringifiedGoogleOAuthParams}`;

// Facebook
const stringifiedFacebookOAuthParams = queryString.stringify({
  client_id: '244877484950983',
  redirect_uri: redirect_uri.facebook,
  scope: ['email', 'public_profile'].join(','), // comma seperated string
  response_type: 'code',
  auth_type: 'rerequest',
  display: 'popup',
});

export const facebookLoginUrl = `https://www.facebook.com/v17.0/dialog/oauth?${stringifiedFacebookOAuthParams}`;

// common
export const getOAuthData = (search: string) => {
  const params = queryString.parse(search);
  const oauth: string = typeof params.oauth == 'string' ? params.oauth : '';
  const code: string = typeof params.code == 'string' ? params.code : '';
  return { oauth, code, redirect_uri: redirect_uri[oauth] };
};
