import { useAppSelector } from 'hooks/store';
import { Navigate } from 'react-router-dom';
import { selectRole } from 'pages/Auth/reducer';

export const RedirectBasePath = () => {
  const role = useAppSelector(selectRole);
  let redirectPath: string;

  switch (role) {
    case 'admin':
    case 'test':
      redirectPath = 'influencers';
      break;
    default:
      redirectPath = 'campaigns';
  }

  if (redirectPath) return <Navigate to={redirectPath} replace />;
  else return <div>Access Denied</div>;
};
