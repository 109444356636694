import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { cloneDeep, isArray } from 'lodash';
import {
  createCampaignReportApi,
  createCampaignReportTwitchApi,
  getCampaignApi,
  getCampaignManagementDataApi,
  getCampaignSelectedChannelsApi,
  ICreateCampaignReport,
  IUpdateCampaignManagementData,
  IUpdateCampaignSelectedChannel,
  Platform,
  updateCampaignManagementDataApi,
  updateCampaignSelectedChannelApi,
} from 'services';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

interface ISelectedSettingsProps {
  platform: Platform;
  searchValue: string;
  filters: {
    youtube: any;
    twitch: any;
  };
  tableFilters: {
    youtube: any;
    twitch: any;
  };
}

interface IManagementSettingsProps {
  searchValue: string;
  tableFilters: any;
  filters: object;
}

interface IReportSettingsProps {
  platform: Platform;
  tableFilters: {
    youtube: any;
    twitch: any;
  };
}

interface ICampaignClient {
  campaign: any | null;
  selectedChannelsYoutube: any[] | undefined;
  selectedChannelsTwitch: any[] | undefined;
  managementData: any[] | undefined;
  reportYoutubeData: any;
  reportTwitchData: any;
  reportIds: string[];
  savedManagementIds: string[];

  selectedSettings: ISelectedSettingsProps;
  managementSettings: IManagementSettingsProps;
  reportSettings: IReportSettingsProps;

  loading: Loading;
}

const initialState: ICampaignClient = {
  campaign: undefined,
  selectedChannelsYoutube: undefined,
  selectedChannelsTwitch: undefined,
  managementData: undefined,
  reportYoutubeData: {},
  reportTwitchData: {},
  reportIds: [],
  savedManagementIds: [], // For report

  selectedSettings: {
    platform: 'youtube',
    searchValue: '',
    filters: { youtube: {}, twitch: {} },
    tableFilters: { youtube: {}, twitch: {} },
  },
  managementSettings: { searchValue: '', tableFilters: {}, filters: {} },
  reportSettings: {
    platform: 'youtube',
    tableFilters: { youtube: {}, twitch: {} },
  },

  loading: defaultLoading,
};

const slice = createSlice({
  name: 'campaign-client',
  initialState,
  reducers: {
    setSavedManagementIds: (state, { payload }: PayloadAction<string[]>) => {
      if (state.managementData) {
        state.reportIds = payload.length ? payload : state.managementData.filter((d: any) => d.published).map((d: any) => d.selected_id);
        state.savedManagementIds = payload;
      }
    },
    setSelectedSettings: (state, { payload }: PayloadAction<Partial<ISelectedSettingsProps>>) => {
      state.selectedSettings = { ...state.selectedSettings, ...payload };
    },
    setManagementSettings: (state, { payload }: PayloadAction<Partial<IManagementSettingsProps>>) => {
      state.managementSettings = { ...state.managementSettings, ...payload };
    },
    setReportSettings: (state, { payload }: PayloadAction<Partial<IReportSettingsProps>>) => {
      state.reportSettings = { ...state.reportSettings, ...payload };
    },
    updateChannelsComments: (state, { payload }: PayloadAction<any>) => {
      if (state.selectedChannelsYoutube && state.selectedSettings.platform === 'youtube') {
        const idx = state.selectedChannelsYoutube.findIndex((d: any) => d.selected_id === payload.selected_id);
        if (idx !== -1)
          state.selectedChannelsYoutube.splice(idx, 1, {
            ...state.selectedChannelsYoutube[idx],
            comments: payload.data.length,
          });
      }
      if (state.selectedChannelsTwitch && state.selectedSettings.platform === 'twitch') {
        const idx = state.selectedChannelsTwitch.findIndex((d: any) => d.selected_id === payload.selected_id);
        if (idx !== -1)
          state.selectedChannelsTwitch.splice(idx, 1, {
            ...state.selectedChannelsTwitch[idx],
            comments: payload.data.length,
          });
      }
      if (state.managementData) {
        const idx = state.managementData.findIndex((d: any) => d.selected_id === payload.selected_id);
        if (idx !== -1)
          state.managementData.splice(idx, 1, {
            ...state.managementData[idx],
            comments: payload.data.length,
          });
      }
    },
    resetState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignClientThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CAMPAIGN CLIENT', payload);

        state.campaign = isArray(payload) && payload.length ? payload[0] : null;
      })

      .addCase(fetchCampaignSelectedChannelsClientThunk.pending, (state, { meta }: any) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(fetchCampaignSelectedChannelsClientThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CAMPAIGN SELECTED CLIENT', payload);

        state.selectedChannelsYoutube = payload.youtube;
        state.selectedChannelsTwitch = payload.twitch;
        state.loading.is = false;
      })
      .addCase(fetchCampaignSelectedChannelsClientThunk.rejected, (state) => {
        console.log('REJECTED FETCH CAMPAIGN SELECTED CLIENT');

        state.loading.is = false;
      })

      .addCase(updateCampaignSelectedChannelClientThunk.pending, (state, { meta }) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(updateCampaignSelectedChannelClientThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE CAMPAIGN SELECTED CLIENT', payload);

        if (state.selectedChannelsYoutube && state.selectedSettings.platform === 'youtube') {
          const idx = state.selectedChannelsYoutube.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1)
            state.selectedChannelsYoutube.splice(idx, 1, {
              ...state.selectedChannelsYoutube[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
        }
        if (state.selectedChannelsTwitch && state.selectedSettings.platform === 'twitch') {
          const idx = state.selectedChannelsTwitch.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1)
            state.selectedChannelsTwitch.splice(idx, 1, {
              ...state.selectedChannelsTwitch[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
        }
        state.loading.is = false;
      })
      .addCase(updateCampaignSelectedChannelClientThunk.rejected, (state) => {
        console.log('REJECTED DELETE CAMPAIGN SELECTED CLIENT');

        state.loading.is = false;
      })

      // MANAGEMENT

      .addCase(fetchCampaignManagementDataClientThunk.pending, (state, { meta }: any) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(fetchCampaignManagementDataClientThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH MANAGEMENT DATA CLIENT', payload);

        state.reportIds = state.savedManagementIds.length ? state.savedManagementIds : payload.filter((d: any) => d.published).map((d: any) => d.selected_id);

        state.managementData = payload;
        state.loading.is = false;
      })
      .addCase(fetchCampaignManagementDataClientThunk.rejected, (state) => {
        console.log('REJECTED FETCH MANAGEMENT DATA CLIENT');

        state.loading.is = false;
      })

      .addCase(updateCampaignManagementDataClientThunk.pending, (state, { meta }) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(updateCampaignManagementDataClientThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE MANAGEMENT DATA CLIENT', payload);

        if (state.managementData && isArray(payload.response) && payload.response.length) {
          const idx = state.managementData.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1) {
            state.managementData.splice(idx, 1, {
              ...state.managementData[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
            if (payload.args.data.published && !state.savedManagementIds.length) state.reportIds.push(state.managementData[idx].selected_id);
          }
        }
        state.loading.is = false;
      })
      .addCase(updateCampaignManagementDataClientThunk.rejected, (state) => {
        console.log('REJECTED DELETE MANAGEMENT DATA CLIENT');

        state.loading.is = false;
      })

      // REPORT

      .addCase(createCampaignClientReportDataThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(createCampaignClientReportDataThunk.fulfilled, (state, { payload, meta }) => {
        console.log('FULFILLED FETCH CLIENT REPORT DATA', payload);

        if (meta.arg?.data.sm_platform === 'youtube') state.reportYoutubeData = payload;
        else state.reportTwitchData = payload;

        state.loading.is = false;
      })
      .addCase(createCampaignClientReportDataThunk.rejected, (state) => {
        console.log('REJECTED FETCH CLIENT REPORT DATA');

        state.reportYoutubeData = {};
        state.reportTwitchData = {};

        state.loading.is = false;
      });
  },
});

export const campaignClientActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchCampaignClientThunk = createAsyncThunk('fetch_campaign_client_thunk', async (campaign_id: string, { dispatch }: any) => {
  const response: any = await getCampaignApi(campaign_id);
  if (isArray(response) && response.length) {
    const sm_platform = response[0].sm_platform.split(',');
    if (sm_platform.length) {
      dispatch(
        campaignClientActions.setSelectedSettings({
          platform: sm_platform[0],
        })
      );
      dispatch(campaignClientActions.setReportSettings({ platform: sm_platform[0] }));
    }

    dispatch(
      fetchCampaignSelectedChannelsClientThunk({
        campaign_id,
        thunk: { noLoading: true },
      })
    );
    dispatch(
      fetchCampaignManagementDataClientThunk({
        campaign_id,
        thunk: { noLoading: true },
      })
    );
  }
  return typeof response === 'string' ? [] : response;
});

export const fetchCampaignSelectedChannelsClientThunk = createAsyncThunk(
  'fetch_campaign_selected_channels_client_thunk',
  async ({ campaign_id }: { campaign_id: string; thunk?: any }) => {
    const respYoutube: any[] = await getCampaignSelectedChannelsApi({
      campaign_id,
      sm_platform: 'youtube',
    });
    const respTwitch: any[] = await getCampaignSelectedChannelsApi({
      campaign_id,
      sm_platform: 'twitch',
    });
    return {
      youtube: !isArray(respYoutube) ? [] : respYoutube,
      twitch: !isArray(respTwitch) ? [] : respTwitch,
    };
  }
);

export const updateCampaignSelectedChannelClientThunk = createAsyncThunk(
  'update_campaign_selected_channel_client_thunk',
  async (args: IUpdateCampaignSelectedChannel & { thunk?: any }) => {
    const copyArgs = cloneDeep(args);
    if (copyArgs.thunk) delete copyArgs.thunk;
    const response: any[] = await updateCampaignSelectedChannelApi(copyArgs);

    if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();

    return { args, response };
  }
);

// MANAGEMENT

export const fetchCampaignManagementDataClientThunk = createAsyncThunk(
  'fetch_campaign_management_data_client_thunk',
  async ({ campaign_id }: { campaign_id: string; thunk?: any }) => {
    const response: any[] = await getCampaignManagementDataApi(campaign_id);
    return typeof response === 'string' ? [] : response;
  }
);

export const updateCampaignManagementDataClientThunk = createAsyncThunk(
  'update_campaign_management_data_client_thunk',
  async (args: IUpdateCampaignManagementData & { thunk?: any }) => {
    const copyArgs = cloneDeep(args);
    if (copyArgs.thunk) delete copyArgs.thunk;
    const response: any[] = await updateCampaignManagementDataApi(copyArgs);
    return { args, response };
  }
);

// REPORT

export const createCampaignClientReportDataThunk = createAsyncThunk('create_campaign_client_report_data_thunk', async (args: ICreateCampaignReport) => {
  const response: any = args.data.sm_platform === 'youtube' ? await createCampaignReportApi(args) : await createCampaignReportTwitchApi(args);

  return typeof response === 'string' || !response ? {} : response;
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.campaignClient;

export const selectCampaignClient = createSelector(selectState, (state) => state.campaign);

export const selectCampaignSelectedChannelsYoutubeClient = createSelector(selectState, (state) => state.selectedChannelsYoutube);

export const selectCampaignSelectedChannelsTwitchClient = createSelector(selectState, (state) => state.selectedChannelsTwitch);

export const selectCampaignSelectedChannelsCountClient = createSelector(
  selectCampaignSelectedChannelsYoutubeClient,
  selectCampaignSelectedChannelsTwitchClient,
  (youtube, twitch) => (youtube && twitch ? youtube.length + twitch.length : 0)
);

export const selectCampaignManagementDataClient = createSelector(selectState, (state) => state.managementData);

export const selectCampaignSavedManagementIdsClient = createSelector(selectState, (state) => state.savedManagementIds);

export const selectCampaignReportYoutubeDataClient = createSelector(selectState, (state) => state.reportYoutubeData);

export const selectCampaignReportTwitchDataClient = createSelector(selectState, (state) => state.reportTwitchData);

export const selectCampaignClientReportIds = createSelector(selectState, (state) => state.reportIds);

const array: any[] = [];

// Selected

export const selectCampaignSelectedSettingsClient = createSelector(selectState, (state) => state.selectedSettings);

export const selectCampaignSelectedSettingsPlatformClient = createSelector(selectCampaignSelectedSettingsClient, (settings) => settings.platform);

export const selectCampaignSelectedSettingsSearchValueClient = createSelector(selectCampaignSelectedSettingsClient, (settings) => settings.searchValue);

export const selectCampaignSelectedSettingsFiltersClient = createSelector(selectCampaignSelectedSettingsClient, (settings) => settings.filters);

export const selectCampaignSelectedSettingsTableFilterClient = createSelector(selectCampaignSelectedSettingsClient, (settings) => settings.tableFilters);

export const selectCampaignSelectedChannelsByPlatformClient = createSelector(
  selectCampaignSelectedSettingsPlatformClient,
  selectCampaignSelectedChannelsYoutubeClient,
  selectCampaignSelectedChannelsTwitchClient,
  (platform, youtube, twitch) => (platform === 'youtube' ? youtube : twitch)
);

export const selectCampaignSelectedSettingsTableFilterSortByClient = createSelector(
  selectCampaignSelectedSettingsPlatformClient,
  selectCampaignSelectedSettingsTableFilterClient,
  (platform, tableFilters) => tableFilters[platform].sortBy || array
);

const defaultResizing = {};

export const selectCampaignSelectedSettingsTableFilterResizingClient = createSelector(
  selectCampaignSelectedSettingsPlatformClient,
  selectCampaignSelectedSettingsTableFilterClient,
  (platform, tableFilters) => tableFilters[platform]?.resizing || defaultResizing
);

export const selectCampaignSelectedSettingsTableFilterHiddenColumnsClient = createSelector(
  selectCampaignSelectedSettingsPlatformClient,
  selectCampaignSelectedSettingsTableFilterClient,
  (platform, tableFilters) => tableFilters[platform].hiddenColumns
);

// Management

export const selectCampaignManagementSettingsClient = createSelector(selectState, (state) => state.managementSettings);

export const selectCampaignManagementSettingsFiltersClient = createSelector(selectCampaignManagementSettingsClient, (settings) => settings.filters);

export const selectCampaignManagementSettingsSearchValueClient = createSelector(selectCampaignManagementSettingsClient, (settings) => settings.searchValue);

export const selectCampaignManagementSettingsTableFilterClient = createSelector(selectCampaignManagementSettingsClient, (settings) => settings.tableFilters);

export const selectCampaignManagementSettingsTableFilterSortByClient = createSelector(
  selectCampaignManagementSettingsTableFilterClient,
  (tableFilters) => tableFilters.sortBy || array
);

export const selectCampaignManagementSettingsTableFilterResizingClient = createSelector(
  selectCampaignManagementSettingsTableFilterClient,
  (tableFilters) => tableFilters?.resizing || defaultResizing
);

export const selectCampaignManagementSettingsTableFilterHiddenColumnsClient = createSelector(
  selectCampaignManagementSettingsTableFilterClient,
  (tableFilters) => tableFilters.hiddenColumns
);

// Report

export const selectCampaignReportSettingsClient = createSelector(selectState, (state) => state.reportSettings);

export const selectCampaignReportSettingsPlatformClient = createSelector(selectCampaignReportSettingsClient, (settings) => settings.platform);

export const selectCampaignReportSettingsTableFilterClient = createSelector(selectCampaignReportSettingsClient, (settings) => settings.tableFilters);

export const selectCampaignReportSettingsTableFilterSortByClient = createSelector(
  selectCampaignReportSettingsPlatformClient,
  selectCampaignReportSettingsTableFilterClient,
  (platform, tableFilters) => tableFilters[platform].sortBy || array
);

export const selectCampaignReportSettingsTableFilterResizingClient = createSelector(
  selectCampaignReportSettingsPlatformClient,
  selectCampaignReportSettingsTableFilterClient,
  (platform, tableFilters) => tableFilters[platform]?.resizing || defaultResizing
);

export const selectCampaignReportDataClient = createSelector(
  selectCampaignReportSettingsPlatformClient,
  selectCampaignReportYoutubeDataClient,
  selectCampaignReportTwitchDataClient,
  (platform, youtubeData, twitchData) => (platform === 'youtube' ? youtubeData : twitchData)
);

export const selectCampaignClientLoading = createSelector(selectState, (state) => state.loading);

export const selectCampaignClientLoadingToHeader = createSelector(selectCampaignClientLoading, (loading) => Boolean(loading.is && !loading.id));
