import { makeRequest } from './http';

// OAuth
export const oauth = async (data: any) => {
  const response = await makeRequest({
    path: '/auth/v2/oauth',
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

// User login
export const login = async (data: any) => {
  const response = await makeRequest({
    path: '/auth/v2/login',
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

// User logout
export const logout = () => {
  makeRequest({
    path: '/auth/v2/logout',
    method: 'GET',
  });
};

// TYPES

// useLogin

export interface IUserLogin {
  // main_address: string
  // hash: string
}
