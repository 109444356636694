import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { hideSearch, selectIsOpen, selectLoading, selectParams, selectType, startLoading, stopLoading } from './reducer';
import { Campaigns } from './searches/Campaigns';
import { ArrowLeftIcon, CloseIcon, CSVImportIcon } from 'assets/icons';

export const SearchTypes = {
  Campaigns: 'Campaigns',
};

export const SEARCHES = {
  [SearchTypes.Campaigns]: (props: any) => <Campaigns {...props} />,
};

export const Search: FC = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(selectType);
  const isOpen = useAppSelector(selectIsOpen);
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-40 inset-x-0 bottom-0 top-[72px] overflow-y-auto"
        // initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={() => dispatch(hideSearch())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="flex items-center w-full h-full text-center bg-white">
            <Dialog.Panel className="relative flex items-center h-full w-full transform transition-all">
              <CloseIcon className="absolute top-6 right-6 cursor-pointer" onClick={() => dispatch(hideSearch())} />
              <div className="w-full max-w-6xl mx-auto">
                {type
                  ? SEARCHES[type]({
                      isOpen,
                      loading,
                      params,
                      startLoading: (_name: string) => dispatch(startLoading(_name)),
                      stopLoading: () => dispatch(stopLoading()),
                      hideSearch: () => dispatch(hideSearch()),
                    })
                  : null}
              </div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

interface ISearchHeaderProps {
  title: string;
}

export const SearchHeader: FC<ISearchHeaderProps> = ({ title = 'Unknown title' }) => {
  return (
    <div className="mb-20 text-start">
      <p className="text-2xl leading-[42px] font-semibold text-black-b1">{title}</p>
    </div>
  );
};

interface ISearchFooterProps {
  back: () => void;
}

export const SearchFooter: FC<ISearchFooterProps> = ({ back }) => {
  return (
    <div className="mt-10 flex items-center justify-between text-sm leading-6 font-semibold text-blue-b1">
      <div className="flex items-center space-x-2 cursor-pointer" onClick={back}>
        <ArrowLeftIcon />
        <p>Back to campaigns</p>
      </div>
      <div className="flex items-center space-x-2 cursor-pointer">
        <CSVImportIcon />
        <p>Import from csv file</p>
      </div>
    </div>
  );
};
