import { FC, memo, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryLocation } from 'hooks/useQueryLocation';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { Tab } from '@headlessui/react';

export interface ITab {
  query: string;
  title: ((props: any) => ReactNode | string) | ReactNode | string;
  content: ReactNode;
  panelClass?: string;
}

interface ITabsProps {
  tabs: ITab[];
  classRoot?: string;
  classList?: string;
  classPanels?: string;
  classPanel?: string;
  disabledPanel?: boolean;
}

export const Tabs: FC<ITabsProps> = memo(
  ({ tabs, classRoot = '', classList = '', classPanels = '', classPanel = '', disabledPanel }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const query = useQueryLocation();
    const searchTab = query.get('tab');
    const [selectedIndex, setSelectedIndex] = useState<number>(() => tabs.findIndex((d) => d.query === searchTab) || 0);
    useEffect(() => {
      const index = tabs.findIndex((d) => d.query === searchTab);
      if (searchTab === null)
        setSelectedIndex(0); // Pathname, index === 0
      else if (index !== -1) setSelectedIndex(index);
      else navigate(pathname); // Bad query
    }, [query]);

    return (
      <div className={cn('flex flex-1 flex-col overflow-hidden bg-white', classRoot)}>
        {/* TODO when tabs focused alt + keayboard bad working */}
        <Tab.Group selectedIndex={selectedIndex} onChange={(index: number) => navigate(index === 0 ? '' : `?tab=${tabs[index].query}`)}>
          <Tab.List className={cn('flex space-x-6 px-6 border-b border-gray-g2 overflow-x-auto', classList)}>
            {tabs.map((tab: ITab) => (
              <Tab
                key={tab.query}
                className={({ selected }) =>
                  cn(
                    'whitespace-nowrap text-base py-1 border-b-4 focus:outline-none',
                    selected ? 'text-blue-b1 border-blue-b1' : 'text-black-b1 border-transparent'
                  )
                }
              >
                {({ selected }) => (typeof tab.title === 'function' ? <>{tab.title({ selected })}</> : <>{tab.title}</>)}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels
            className={cn(
              'flex flex-col flex-1 landscape:overflow-y-auto max-lg:overflow-y-auto lg:overflow-hidden',
              // 'relative flex-1 p-6 bg-gray-g1 overflow-hidden',
              classPanels
            )}
          >
            {tabs.map((tab: ITab) => (
              <Tab.Panel
                key={tab.query}
                className={
                  cn('relative flex-1 p-6 bg-gray-g1 lg:overflow-hidden', tab.panelClass || '')
                  // cn('h-full flex flex-col')
                  // 'bg-neutral-500/20 p-4 lg:p-6 focus:outline-none',
                  // classPanel
                  // { 'pointer-events-none': disabledPanel }
                }
              >
                {/* {tab.content} */}
                <div className="h-full flex flex-col">{tab.content}</div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  },
  ({ tabs, disabledPanel }, next) => isEqual(tabs, next.tabs) && isEqual(disabledPanel, next.disabledPanel)
);
