import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import cn from 'classnames';
import { ValidationTypes } from 'utils/validation';
import { ModalState } from 'components/Modal/reducer';
import { Checkbox, ClipLoader, Form, Input, Label, RSelect, RSelectStyles } from 'shared';
import { ModalHeader } from '../../index';
import { RoleView } from 'pages/Admin/tabs/PeopleManagementTable';
import { StylesConfig } from 'react-select';
import { useAppSelector } from 'hooks/store';
import { selectClients } from 'pages/Admin/reducer';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

interface IAdminAddNewUserProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AdminAddNewUser: FC<IAdminAddNewUserProps> = ({ params, hideModal }) => {
  const clients = useAppSelector(selectClients);

  const isUpdate = Boolean(params?.user?.user_id);

  const defaultValues: any = useMemo(
    () => ({
      full_name: params?.user?.full_name || '',
      email: params?.user?.email || '',
      client_id: params?.user?.client_id || null,
      role: params?.user?.role || 'user',
    }),
    [params?.user]
  );

  const clientsOptions = useMemo(
    () =>
      clients.map((d: any) => ({
        value: d.client_id,
        label: d.client_name,
      })),
    [clients]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isUpdate ? 'Update user' : 'Add new user'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AdminAddNewUser}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params
            .handleSave(
              // TODO may be need remove because this handle on server
              Object.keys(values).reduce((acc: any, cur: any) => {
                if (values[cur] != null) {
                  acc[cur] = values[cur];
                }

                return acc;
              }, {})
            )
            .then(() => hideModal());
        }}
      >
        {({ register, control, watch, setValue, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="full_name" label="User name" error={errors.full_name} />
              <div className="mt-0.5">
                <Input id="full_name" type="text" placeholder="User name" error={errors.full_name} {...register('full_name')} />
              </div>
            </div>
            <div>
              <Label htmlFor="email" label="Email" error={errors.email} />
              <div className="mt-0.5">
                <Input id="email" type="email" placeholder="Email address" error={errors.email} {...register('email')} />
              </div>
            </div>
            <div>
              <Label htmlFor="client_id" label="Client name" error={errors.client_id} />
              <div className="mt-0.5">
                <Controller
                  name="client_id"
                  control={control}
                  render={(props: any) => {
                    const { field } = props;
                    return (
                      <RSelect
                        {...field}
                        styles={selectStyles}
                        placeholder="Not Selected"
                        options={clientsOptions}
                        error={errors.client_id}
                        components={{
                          ClearIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={clientsOptions.find((option) => option.value === field.value) || null}
                        onChange={(option: any) => {
                          field.onChange(option.value);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="role" label="Role" />

              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'user'} onChange={() => setValue('role', 'user')} />
                <RoleView role="user" />
              </div>

              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'test'} onChange={() => setValue('role', 'test')} />
                <RoleView role="test" />
              </div>
            </div>
            <div className="!mt-8 grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                {isUpdate ? <p>Save</p> : <p>Create</p>}
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
