import { FC, useMemo } from 'react';
import { useAppSelector } from 'hooks/store';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import numeral from 'numeral';
import cn from 'classnames';
import { contentFormatStatuses, contractStatuses, outreachStatuses } from 'utils/common';
import { getCpm, getCpmOtherDenominator } from 'utils/helper';
import { selectAgencies } from 'pages/Admin/reducer';
import { Form, Input, Label, RSelect, RSelectStyles, ClipLoader } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { getStatus } from 'pages/Campaign/tabs/Selected';
import { CustomColoredSelectOption, CustomColoredSelectSingleValue, customColoredSelectStyles } from '../selected/EditSelectedChannel';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

interface IEngagementEditProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const EngagementEdit: FC<IEngagementEditProps> = ({ params, hideModal }) => {
  const agencies = useAppSelector(selectAgencies);

  const defaultValues: any = useMemo(
    () => ({
      channel_name: params?.influencer?.channel_name || '',
      contract_status: params?.influencer?.contract_status || getStatus('', contractStatuses).value,
      outreach_status: params?.influencer?.outreach_status || getStatus('', outreachStatuses).value,
      content_format: params?.influencer?.content_format || getStatus('', contentFormatStatuses).value,
      theme: params?.influencer?.theme || '',
      price: params?.influencer?.price || '',
      internal_price: params?.influencer?.internal_price || '',
      cpm: getCpm(params?.influencer?.price, getCpmOtherDenominator(params?.influencer), params?.influencer?.sm_platform),
      internal_cpm: getCpm(params?.influencer?.internal_price, getCpmOtherDenominator(params?.influencer), params?.influencer?.sm_platform),
      direct_email: params?.influencer?.direct_email || '',
      agency_id: params?.influencer?.agency_id || '',
    }),
    [params?.influencer]
  );

  const agencyOptions = useMemo(() => agencies.map((d: any) => ({ value: d.agency_id, label: d.agency_name })), [agencies]);

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title="Update engagement influencer" close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        // validationType={ValidationTypes.AdminAddInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          const copyValues = Object.assign({}, values);
          delete copyValues.channel_name;
          return params.handleSave(copyValues).then(() => hideModal());
        }}
      >
        {({ register, control, watch, setValue, formState }) => {
          const { errors, isSubmitting } = formState;
          const isDisabledContractStatus = watch('outreach_status') !== 'successful_engagement';

          return (
            <>
              <div className="pointer-events-none">
                <Label htmlFor="channel_name" label="Name" error={errors.channel_name} className="text-gray-gm" />
                <div className="mt-0.5">
                  <Input id="channel_name" type="text" placeholder="Name" error={errors.channel_name} disabled {...register('channel_name')} />
                </div>
              </div>
              <div>
                <Label htmlFor="outreach_status" label="Outreach status" error={errors.outreach_status} />
                <div className="mt-0.5">
                  <Controller
                    name="outreach_status"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={outreachStatuses}
                          error={errors.outreach_status}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={outreachStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label
                  htmlFor="contract_status"
                  label="Contract status"
                  error={errors.contract_status}
                  className={isDisabledContractStatus ? 'text-gray-gm' : ''}
                />
                <div className="mt-0.5">
                  <Controller
                    name="contract_status"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={contractStatuses}
                          error={errors.contract_status}
                          isClearable={false}
                          isSearchable={false}
                          isDisabled={isDisabledContractStatus}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={contractStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="content_format" label="Content format" error={errors.content_format} />
                <div className="mt-0.5">
                  <Controller
                    name="content_format"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={contentFormatStatuses}
                          error={errors.content_format}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={contentFormatStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="theme" label="Theme" error={errors.theme} />
                <div className="relative mt-0.5">
                  <Input id="theme" type="text" placeholder="Theme" error={errors.theme} {...register('theme')} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <Label htmlFor="internal_price" label="Internal price" error={errors.internal_price} />
                  <div className="relative mt-0.5">
                    <Input
                      id="internal_price"
                      type="number"
                      placeholder="Internal price"
                      error={errors.internal_price}
                      className={watch('internal_price') ? '!pl-5' : ''}
                      {...register('internal_price', {
                        onChange: (e: any) => {
                          const value = e.target.value;
                          const cpmValue = getCpm(value, getCpmOtherDenominator(params?.influencer), params?.influencer?.sm_platform);
                          setValue('internal_price', value);
                          setValue('internal_cpm', cpmValue);
                        },
                      })}
                    />
                    {watch('internal_price') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                  </div>
                </div>
                <div>
                  <Label htmlFor="price" label="Price" error={errors.price} />
                  <div className="relative mt-0.5">
                    <Input
                      id="price"
                      type="number"
                      placeholder="Price"
                      error={errors.price}
                      className={watch('price') ? '!pl-5' : ''}
                      {...register('price', {
                        onChange: (e: any) => {
                          const value = e.target.value;
                          const cpmValue = getCpm(value, getCpmOtherDenominator(params?.influencer), params?.influencer?.sm_platform);
                          setValue('price', value);
                          setValue('cpm', cpmValue);
                        },
                      })}
                    />
                    {watch('price') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="pointer-events-none">
                  <Label
                    htmlFor="internal_cpm"
                    label={params?.influencer?.sm_platform === 'youtube' ? 'Internal cpm' : 'Internal cpvh'}
                    error={errors.internal_cpm}
                  />
                  <div className="relative mt-0.5">
                    <Input
                      id="internal_cpm"
                      name="internal_cpm"
                      type="text" // number
                      placeholder={params?.influencer?.sm_platform === 'youtube' ? 'Internal cpm' : 'Internal cpvh'}
                      // error={errors.internal_cpm}
                      className={watch('internal_cpm') ? '!pl-5' : ''}
                      // {...register('internal_cpm')}
                      value={
                        // TODO update formatNumber without 'a' after > 100000
                        watch('internal_cpm') ? numeral(watch('internal_cpm')).format('0,0.[00]') : ''
                      }
                      onChange={() => null}
                    />
                    {watch('internal_cpm') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                  </div>
                </div>
                <div className="pointer-events-none">
                  <Label htmlFor="cpm" label={params?.influencer?.sm_platform === 'youtube' ? 'Cpm' : 'Cpvh'} error={errors.cpm} />
                  <div className="relative mt-0.5">
                    <Input
                      id="cpm"
                      type="text" // number
                      placeholder={params?.influencer?.sm_platform === 'youtube' ? 'Cpm' : 'Cpvh'}
                      // error={errors.cpm}
                      className={watch('cpm') ? '!pl-5' : ''}
                      // {...register('cpm')}
                      value={
                        // TODO update formatNumber without 'a' after > 100000
                        watch('cpm') ? numeral(watch('cpm')).format('0,0.[00]') : ''
                      }
                      onChange={() => null}
                    />
                    {watch('cpm') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                  </div>
                </div>
              </div>
              <div>
                <Label htmlFor="direct_email" label="Email" error={errors.direct_email} />
                <div className="relative mt-0.5">
                  <Input id="direct_email" type="email" placeholder="Email" error={errors.direct_email} {...register('direct_email')} />
                </div>
              </div>
              <div>
                <Label htmlFor="agency_id" label="Agency" error={errors.agency_id} />
                <div className="relative mt-0.5">
                  <Controller
                    name="agency_id"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={selectStyles}
                          placeholder="Not Selected"
                          options={agencyOptions}
                          error={errors.agency_id}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          value={agencyOptions.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <button
                  type="button"
                  className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  <p>Save</p>
                  <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                </button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
