import { FC } from 'react';
import { useLanguagesAsObject } from 'hooks/useLanguages';
import { TableCellTruncate } from 'shared';

interface ILanguageFullNameProps {
  language: string;
  className?: string;
}

export const LanguageFullName: FC<ILanguageFullNameProps> = ({ language = '', className = '' }) => {
  const languages = useLanguagesAsObject();

  const lang = languages?.[language]?.English || language || '';

  return <TableCellTruncate value={lang} className={className} />;
};
