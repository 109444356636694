import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import { colors } from 'variables';
import cn from 'classnames';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon } from 'assets/icons';

interface ITableHeaderSortProps {
  column: any;
  isSorted?: boolean;
  isSortedDesc?: boolean;
  fill?: string;
  activeFill?: string;
}

export const TableHeaderSort: FC<ITableHeaderSortProps> = ({ column, isSorted, isSortedDesc, fill = colors.gray.gm, activeFill = colors.white.default }) => {
  const _isSorted = isSorted || column.isSorted;
  const _isSortedDesc = isSortedDesc || column.isSortedDesc;

  if (column?.disableSortBy) return null;
  return (
    <>
      <div className="relative w-2 flex flex-col items-center justify-center">
        <ArrowUpDropdownSolidIcon className="absolute -top-4 -right-2 shrink-0" pathFill={_isSorted ? (_isSortedDesc ? fill : activeFill) : fill} />
        <ArrowDownDropdownSolidIcon className="absolute -top-2 -right-2 shrink-0" pathFill={_isSorted ? (_isSortedDesc ? activeFill : fill) : fill} />
      </div>
    </>
  );
};

interface ITableCellTruncateProps {
  value: any;
  linkTo?: string;
  className?: string;
}

export const TableCellTruncate: FC<ITableCellTruncateProps> = memo(
  ({ className = '', linkTo, value }) => {
    const options = {
      onMouseEnter: (e: any) => {
        e.target.style.overflow = 'auto';
        e.target.style.textOverflow = 'clip';

        // Set padding top that if show scrollbar not moving container
        if (e.target.scrollWidth > e.target.parentNode.scrollWidth) e.target.style.paddingTop = '4px';
      },
      onMouseLeave: (e: any) => {
        e.target.style.overflow = 'hidden';
        e.target.style.textOverflow = 'ellipsis';

        e.target.scrollLeft = 0; // Return back scroll if it place not left
        e.target.style.paddingTop = '0'; // remove padding top when leave container
      },
    };

    return (
      <div className="flex justify-center items-center overflow-hidden">
        {linkTo ? (
          <Link to={linkTo} className="truncate hover:underline hover:text-blue-b1" {...options}>
            {value}
          </Link>
        ) : (
          <p
            className={cn('truncate', className)}
            // title={value}
            {...options}
          >
            {value}
          </p>
        )}
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export const TableResizer: FC<any> = ({ column }) => {
  if (column?.disabledResizing) return null;
  return (
    <div
      {...column.getResizerProps()}
      className={`resizer absolute z-10 top-0 -right-2 h-full w-4 select-none touch-none cursor-col-resize ${
        column.isResizing ? 'isResizing !w-32 !-right-16' : ''
      }`}
      title=""
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    />
  );
};

export const TableHeaderTruncate: FC<any> = ({ name }) => {
  return (
    <p className="truncate" title={name}>
      {name}
    </p>
  );
};
