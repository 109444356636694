import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface ILabelProps {
  htmlFor: string;
  label: string | ReactNode;
  className?: string;
  error?: any;
}

// TODO add children

export const Label: FC<ILabelProps> = ({ htmlFor, label, className = '', error = '' }) => (
  <label htmlFor={htmlFor} className={cn('block truncate text-xs font-normal leading-6', className, error ? 'text-red-r1' : 'text-black')}>
    {label}
  </label>
);
