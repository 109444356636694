import { FC, Fragment } from 'react';
import { Switch as HSwitch } from '@headlessui/react';

interface ISwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  [prop: string]: any;
}

export const Switch: FC<ISwitchProps> = ({ checked, onChange = () => {}, ...rest }) => {
  return (
    <HSwitch
      // defaultChecked={checked}
      checked={checked}
      onChange={onChange}
      className={`${checked ? 'bg-blue-b1' : 'bg-gray-g2'} relative inline-flex h-4 w-[30px] items-center rounded-full`}
      {...rest}
    >
      <span className="sr-only">Enable</span>
      <span className={`${checked ? 'translate-x-4' : 'translate-x-1'} inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`} />
    </HSwitch>
  );
};

interface ISwitchComponentProps {
  checked: boolean;
}

export const SwitchComponent: FC<ISwitchComponentProps> = ({ checked }) => {
  return (
    <button type="button" className={`${checked ? 'bg-blue-b1' : 'bg-gray-g2'} relative inline-flex h-4 w-[30px] items-center rounded-full`}>
      <span className="sr-only">Enable</span>
      <span className={`${checked ? 'translate-x-4' : 'translate-x-1'} inline-block h-2.5 w-2.5 transform rounded-full bg-white transition`} />
    </button>
  );
};
