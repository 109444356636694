import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { cloneDeep, isArray } from 'lodash';
import {
  addChannelListToExistApi,
  createInfluencerApi,
  deleteInfluencerApi,
  deleteSavedChannelListApi,
  deleteSavedInfluencersApi,
  getInfluencerApi,
  getInfluencersApi,
  getSavedChannelListApi,
  getSavedChannelListInfluencersTwitchApi,
  getSavedChannelListInfluencersYoutubeApi,
  ICreateInfluencer,
  ISearchChannels,
  IUpdateInfluencer,
  IUpdateInfluencerByChannelId,
  IUpdateSavedChannelList,
  Platform,
  saveChannelListApi,
  searchChannelsApi,
  SearchChannelsResponse,
  updateInfluencerApi,
  updateInfluencerByChannelIdApi,
  updateSavedChannelListApi,
} from 'services';
import { toast } from 'shared';
import { channelsLimit } from 'utils/common';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

const defaultTableFilters: any = { youtube: {}, twitch: {} };

interface ISearchChannelsProps extends ISearchChannels {
  searchBy?: string;
  searchMode?: string;
  order_by?: any;
  order_dir?: any;
  filters?: any;
  advancesFilters?: any;
  tableFilters?: {
    youtube: any;
    twitch: any;
  };
}

interface IInfluencers {
  influencers: any[];
  influencer: any;

  channels: any[];
  total: any;
  platform: Platform;
  searchChannels: ISearchChannelsProps;
  selectedInfluencersIds: string[];
  savedChannelList: any[];
  savedInfluencers: any[];
  loading: Loading;
}

const initialState: IInfluencers = {
  // For influencers in Influencers page
  influencers: [],
  influencer: {},

  // For channels in Influencers page
  channels: [],
  total: {},
  platform: 'youtube',
  searchChannels: {
    keywords: [],
    searchBy: 'name',
    searchMode: 'or',
    order_by: {},
    order_dir: {},
    filters: {},
    advancesFilters: {},
    tableFilters: defaultTableFilters,
  },
  selectedInfluencersIds: [],
  savedChannelList: [],
  savedInfluencers: [],
  loading: defaultLoading,
};

const slice = createSlice({
  name: 'influencers',
  initialState,
  reducers: {
    setPlatform: (state, { payload }: PayloadAction<Platform>) => {
      state.platform = payload;
    },
    setSearchChannels: (state, { payload }: PayloadAction<Partial<ISearchChannelsProps>>) => {
      state.searchChannels = { ...state.searchChannels, ...payload };
    },
    setInfluencersIds: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedInfluencersIds = payload;
    },
    resetSavedInfluencers: (state) => {
      state.savedInfluencers = [];
    },
    resetInfluencer: (state) => {
      state.influencer = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInfluencersThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchInfluencersThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH INFLUENCERS', payload);

        state.influencers = payload;
        state.loading.is = false;
      })
      .addCase(fetchInfluencersThunk.rejected, (state) => {
        console.log('REJECTED FETCH INFLUENCERS');

        state.loading.is = false;
      })

      .addCase(fetchInfluencerThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchInfluencerThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH INFLUENCER', payload);

        state.influencer = payload;
        state.loading.is = false;
      })
      .addCase(fetchInfluencerThunk.rejected, (state) => {
        console.log('REJECTED FETCH INFLUENCER');

        state.loading.is = false;
      })

      .addCase(createInfluencerThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED CREATE INFLUENCER', payload);

        if (isArray(payload) && payload.length) {
          state.influencers.push(payload[0]);
        }
      })

      .addCase(updateInfluencerThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE INFLUENCER', payload);

        if (isArray(payload) && payload.length) {
          const idx = state.influencers.findIndex((d: any) => d.influencer_id === payload[0].influencer_id);
          if (idx !== -1) state.influencers.splice(idx, 1, payload[0]);
        }
      })

      .addCase(updateInfluencerByChannelIdThunk.fulfilled, (state, { payload }: PayloadAction<any>) => {
        if (state.influencer.influencer[payload.data.sm_platform + '_channel_id'] === payload.data[payload.data.sm_platform + '_channel_id']) {
          state.influencer[payload.data.sm_platform] = {
            ...state.influencer[payload.data.sm_platform],
            channel_description: payload.response[0]?.channel_description || state.influencer[payload.data.sm_platform]?.channel_description || '',
          };
        }
      })

      .addCase(deleteInfluencerThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE INFLUENCER', payload);

        state.influencers = state.influencers.filter((d: any) => !payload.includes(d.influencer_id));
      })

      .addCase(fetchChannelsThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchChannelsThunk.fulfilled, (state, { payload, meta }) => {
        console.log('FULFILLED FETCH CHANNELS', payload);

        if (payload.data && isArray(payload.data) && payload.total) {
          state.channels = meta.arg?.isScrollFetch ? [...state.channels, ...payload.data] : payload.data;
          state.total = payload.total;
        }
        state.loading.is = false;
      })
      .addCase(fetchChannelsThunk.rejected, (state) => {
        console.log('REJECTED FETCH CHANNELS');

        state.loading.is = false;
      })

      .addCase(fetchSavedChannelListWithoutLoadingThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH SAVES CHANNEL LIST', payload);

        state.savedChannelList = payload;
      })

      .addCase(saveChannelListThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED SAVE CHANNEL LIST', payload);

        switch (payload.action) {
          case 'add': {
            if (isArray(payload.data) && payload.data.length) {
              state.savedChannelList.push(payload.data[0]);
            }
            break;
          }
          case 'update': {
            if (isArray(payload.data) && payload.data.length) {
              const idx = state.savedChannelList.findIndex((d: any) => d.list_id === payload.data[0].list_id);
              if (idx !== -1) state.savedChannelList.splice(idx, 1, payload.data[0]);
            }
            break;
          }
          default:
            break;
        }
      })

      .addCase(createChannelListThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED CREATE CHANNEL LIST', payload);

        if (isArray(payload) && payload.length) {
          state.savedChannelList.push(payload[0]);
        }
      })

      .addCase(updateSavedChannelListThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE SAVE CHANNEL LIST', payload);

        if (isArray(payload) && payload.length) {
          const idx = state.savedChannelList.findIndex((d: any) => d.list_id === payload[0].list_id);
          if (idx !== -1) state.savedChannelList.splice(idx, 1, payload[0]);
        }
      })

      .addCase(deleteSavedChannelListThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(deleteSavedChannelListThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE SAVE CHANNEL LIST', payload);

        state.savedChannelList = state.savedChannelList.filter((d: any) => d.list_id !== payload);
        state.loading.is = false;
      })
      .addCase(deleteSavedChannelListThunk.rejected, (state) => {
        console.log('REJECTED DELETE SAVE CHANNEL LIST');

        state.loading.is = false;
      })

      .addCase(fetchSavedChannelListInfluencersThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchSavedChannelListInfluencersThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH SAVED INFLUENCERS', payload);

        state.savedInfluencers = payload;
        state.loading.is = false;
      })
      .addCase(fetchSavedChannelListInfluencersThunk.rejected, (state) => {
        console.log('REJECTED FETCH SAVED INFLUENCERS');

        state.loading.is = false;
      })

      .addCase(deleteSavedInfluencersThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(deleteSavedInfluencersThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE SAVE INFLUENCERS', payload);

        state.savedInfluencers = state.savedInfluencers.filter((d: any) => !d[payload.platform + '_channel_id'].includes(payload.ids));

        if (isArray(payload.data)) {
          const idxSavedChannelList = state.savedChannelList.findIndex((d: any) => d.list_id === payload.data[0].list_id);
          if (idxSavedChannelList !== -1) state.savedChannelList.splice(idxSavedChannelList, 1, payload.data[0]);
        }
        state.loading.is = false;
      })
      .addCase(deleteSavedInfluencersThunk.rejected, (state) => {
        console.log('REJECTED DELETE SAVE INFLUENCERS');

        state.loading.is = false;
      });
  },
});

export const influencersActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

// initialize Influencers page
export const initInfluencersTabThunk = createAsyncThunk('init_influencers_tab_thunk', async (args, { dispatch }: any) => {
  dispatch(fetchSavedChannelListWithoutLoadingThunk());
  return null;
});

// For parallel api fetch in 'initInfluencersTabThunk'
export const fetchSavedChannelListWithoutLoadingThunk = createAsyncThunk('fetch_saved_channel_list_without_loading_thunk', async (args) => {
  const response: any[] = await getSavedChannelListApi();
  return response;
});

export const fetchChannelsThunk = createAsyncThunk(
  'fetch_channels_thunk',
  async (args: (ISearchChannelsProps & { isScrollFetch?: boolean }) | void, { getState }: any) => {
    const { platform, searchChannels } = getState().influencers;

    const search = cloneDeep({ ...searchChannels, ...args });
    const keys = Object.keys(search);
    const payloadObj: any = {};

    if (keys.length && search.keywords.length) {
      payloadObj.keywords = search.keywords.join(',');
      payloadObj.limit = channelsLimit;

      if (search.searchBy) payloadObj.searchBy = search.searchBy;
      if (search.searchMode) payloadObj.searchMode = search.searchMode;
    }

    if (keys.length && search.filters && search.filters[platform]) {
      if (search.filters[platform].language && search.filters[platform].language.includes('all')) delete search.filters[platform].language;

      if (search.filters[platform].country && search.filters[platform].country.includes('all')) delete search.filters[platform].country;

      payloadObj.filters = search.filters[platform];
    }

    if (keys.length && search.advancesFilters && search.advancesFilters[platform])
      payloadObj.filters = {
        ...(payloadObj.filters || {}),
        ...search.advancesFilters[platform],
      };

    if (keys.length && search.order_by && search.order_by[platform]) payloadObj.order_by = search.order_by[platform];

    if (keys.length && search.order_dir && search.order_dir[platform]) payloadObj.order_dir = search.order_dir[platform];

    if (keys.length && search.offset) payloadObj.offset = search.offset;

    const response: SearchChannelsResponse = await searchChannelsApi({
      platform,
      data: payloadObj,
    });
    // await new Promise((resolve) => setTimeout(resolve, 1500))
    return typeof response === 'string' ? { data: [], total: {} } : response;
  }
);

// TODO add typescript list_name | list_id, ids
export const saveChannelListThunk = createAsyncThunk('save_channel_list_thunk', async (args: any, { getState }: any) => {
  const { platform } = getState().influencers;
  let response: any;

  if (args?.list_id)
    response = await addChannelListToExistApi({
      list_id: args.list_id,
      data: { [platform + '_channel_ids']: args.ids },
    });
  else
    response = await saveChannelListApi({
      list_name: args.list_name,
      [platform + '_channel_ids']: args.ids,
    });
  return { action: args?.list_id ? 'update' : 'add', data: response };
});

export const createChannelListThunk = createAsyncThunk('create_channel_list_thunk', async (list_name: string) => {
  let response: any = await saveChannelListApi({
    list_name,
  });
  return response;
});

export const updateSavedChannelListThunk = createAsyncThunk('update_saved_channel_list_thunk', async ({ list_id, data }: IUpdateSavedChannelList) => {
  const response: any[] = await updateSavedChannelListApi({
    list_id,
    data,
  });
  return response;
});

export const deleteSavedChannelListThunk = createAsyncThunk('delete_saved_channel_list_thunk', async (list_id: string) => {
  await deleteSavedChannelListApi(list_id);
  return list_id;
});

export const fetchSavedChannelListInfluencersThunk = createAsyncThunk(
  'fetch_saved_channel_list_influencers_thunk',
  async ({ list_id, platform }: { list_id: string; platform: Platform }) => {
    let response: any[] = [];
    switch (platform) {
      case 'youtube': {
        response = await getSavedChannelListInfluencersYoutubeApi(list_id);
        break;
      }
      case 'twitch': {
        response = await getSavedChannelListInfluencersTwitchApi(list_id);
        break;
      }
      default:
        break;
    }
    return response;
  }
);

export const deleteSavedInfluencersThunk = createAsyncThunk(
  'delete_saved_influencers_thunk',
  async ({ list_id, platform, ids }: { list_id: string; platform: Platform; ids: string[] }) => {
    const response: any[] = await deleteSavedInfluencersApi({
      list_id,
      data: {
        [platform + '_channel_ids']: ids,
      },
    });
    return { ids, platform, data: response };
  }
);

// ------------- INFLUENCERS ------------- //

export const fetchInfluencersThunk = createAsyncThunk('fetch_influencers_thunk', async (search: string | void) => {
  const response: any[] = await getInfluencersApi(search || '');
  return typeof response === 'string' ? [] : response;
});

export const fetchInfluencerThunk = createAsyncThunk('fetch_influencer_thunk', async (influencer_id: string) => {
  const response: any = await getInfluencerApi(influencer_id);
  return typeof response === 'string' ? {} : response;
});

export const createInfluencerThunk = createAsyncThunk('create_influencer_thunk', async (data: ICreateInfluencer) => {
  const response: any = await createInfluencerApi(data);
  return response;
});

export const updateInfluencerThunk = createAsyncThunk('update_influencer_thunk', async ({ influencer_id, data }: IUpdateInfluencer) => {
  const response: any[] = await updateInfluencerApi({ influencer_id, data });
  return response;
});

export const updateInfluencerByChannelIdThunk = createAsyncThunk('update_influencer_by_channel_id_thunk', async ({ data }: IUpdateInfluencerByChannelId) => {
  const response: any = await updateInfluencerByChannelIdApi({ data });

  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to update channel description', {
      type: 'error',
    });
    throw new Error();
  }
  return { data, response };
});

export const deleteInfluencerThunk = createAsyncThunk('delete_influencer_thunk', async (ids: string[]) => {
  await deleteInfluencerApi({ ids });
  return ids;
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.influencers;

export const selectInfluencers = createSelector(selectState, (state) => state.influencers);

export const selectInfluencer = createSelector(selectState, (state) => state.influencer);

export const selectInfluencerObject = createSelector(selectInfluencer, (influencer) => influencer.influencer);

export const selectInfluencerYoutube = createSelector(selectInfluencer, (influencer) => influencer.youtube);

export const selectInfluencerTwitch = createSelector(selectInfluencer, (influencer) => influencer.twitch);

export const selectInfluencerPlatformObject = createSelector(selectInfluencerYoutube, selectInfluencerTwitch, (youtube, twitch) => youtube?.[0] ?? twitch?.[0]);

export const selectChannels = createSelector(selectState, (state) => state.channels);

export const selectTotal = createSelector(selectState, (state) => state.total);

export const selectPlatform = createSelector(selectState, (state) => state.platform);

export const selectSearchChannels = createSelector(selectState, (state) => state.searchChannels);

export const selectSearchTableFilters = createSelector(selectSearchChannels, (search) => search.tableFilters || defaultTableFilters);

const defaultResizing = {};

export const selectSearchTableFiltersResizing = createSelector(
  selectPlatform,
  selectSearchTableFilters,
  (platform, tableFilters) => tableFilters[platform]?.resizing || defaultResizing
);

export const selectInfluencersSelectedIds = createSelector(selectState, (state) => state.selectedInfluencersIds);

export const selectSavedChannelList = createSelector(selectState, (state) => state.savedChannelList);

export const selectSavedInfluencers = createSelector(selectState, (state) => state.savedInfluencers);

export const selectInfluencersLoading = createSelector(selectState, (state) => state.loading);

export const selectInfluencersLoadingToHeader = createSelector(selectInfluencersLoading, (loading) => Boolean(loading.is && !loading.id));
