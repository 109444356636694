import { FC, forwardRef } from 'react';
import cn from 'classnames';

interface ICheckbox {
  checked: boolean;
  onChange: (props: any) => void;
  className?: string;
  [propName: string]: any;
}

export const Checkbox: FC<ICheckbox> = forwardRef(({ className = '', onChange = () => {}, ...rest }, ref: any) => {
  return (
    <input
      ref={ref}
      type="checkbox"
      onChange={onChange}
      {...rest}
      className={cn(
        'w-[18px] h-[18px] rounded-[2px] cursor-pointer border-2 border-gray-gm text-blue-b1 !ring-0 !ring-offset-0 focus:!outline-none',
        className
      )}
    />
  );
});
