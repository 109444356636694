import { FC, useMemo } from 'react';
import { useAppSelector } from 'hooks/store';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ValidationTypes } from 'utils/validation';
import { ClipLoader, Form, Input, Label } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { selectUser } from 'pages/Auth/reducer';

interface IChangePasswordProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const ChangePassword: FC<IChangePasswordProps> = ({ params, hideModal }) => {
  const user = useAppSelector(selectUser);

  const defaultValues: any = useMemo(
    () => ({
      old_password: '',
      new_password: '',
    }),
    []
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title="Change password" close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.ChangePassword}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params.handleSave(values).then(() => hideModal());
        }}
      >
        {({ register, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="old_password" label="Old Password" error={errors.old_password} />
              <div className="mt-0.5">
                <Input id="old_password" type="password" placeholder="Enter old password" error={errors.old_password} {...register('old_password')} />
              </div>
            </div>
            <div>
              <Label htmlFor="new_password" label="New password" error={errors.new_password} />
              <div className="mt-0.5">
                <Input id="new_password" type="password" placeholder="Enter new password" error={errors.new_password} {...register('new_password')} />
              </div>
            </div>
            <div className="!mt-7 flex items-center justify-end">
              <Link
                to="/forgot-password"
                state={{ from: '/profile', email: user?.email || '' }}
                className="text-[10px] font-normal text-blue-b1 hover:text-blue-b1"
              >
                Forgot password?
              </Link>
            </div>
            <div className="!mt-7 grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                <p>Save</p>
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
