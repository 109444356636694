import { FC } from 'react';

import { Tabs } from '../../shared';
import CampaignsTab from './tabs/CampaignsTab';
import ClientsTab from './tabs/ClientsTab';

export const Campaigns: FC = () => {
  return (
    <Tabs
      classRoot="relative pt-6"
      tabs={[
        {
          query: 'campaigns',
          title: 'Campaigns',
          content: <CampaignsTab />,
          panelClass: '!p-0',
        },
        {
          query: 'clients',
          title: 'Clients',
          content: <ClientsTab />,
          panelClass: '!p-0',
        },
      ]}
    />
  );
};
