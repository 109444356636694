import { makeRequest } from './http';

export const getChatApi = async ({ campaign_id, selected_id }: IGetChat) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected/${selected_id}/messages`,
    method: 'GET',
  });
  return response;
};

export const createMessageChatApi = async ({ campaign_id, selected_id, data }: ICreateMessageChat) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected/${selected_id}/messages`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

// TYPES

export interface IGetChat {
  campaign_id: string;
  selected_id: string;
}

export interface ICreateMessageChat {
  campaign_id: string;
  selected_id: string;
  data: {
    message: string;
  };
}
