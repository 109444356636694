import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { cloneDeep, isArray } from 'lodash';
import {
  createCampaignReportApi,
  createCampaignReportTwitchApi,
  createCampaignSelectedChannelApi,
  deleteCampaignSelectedChannelApi,
  deleteCampaignSelectedChannelsApi,
  deleteInManagementApi,
  getCampaignApi,
  getCampaignEngagementDataApi,
  getCampaignManagementDataApi,
  getCampaignSelectedChannelsApi,
  ICreateCampaignReport,
  ICreateCampaignSelectedChannel,
  IDeleteCampaignSelectedChannel,
  IDeleteCampaignSelectedChannels,
  IDeleteInManagement,
  IOpenCampaignSelectedChannels,
  ISearchChannels,
  IUpdateCampaignEngagementData,
  IUpdateCampaignManagementData,
  IUpdateCampaignSelectedChannel,
  openCampaignSelectedChannelsApi,
  Platform,
  searchChannelsApi,
  SearchChannelsResponse,
  updateCampaignEngagementDataApi,
  updateCampaignManagementDataApi,
  updateCampaignSelectedChannelApi,
} from 'services';
import { channelsLimit } from 'utils/common';
import { toast } from 'shared';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

interface ISearchChannelsProps extends ISearchChannels {
  searchBy?: string;
  searchMode?: string;
  order_by?: any;
  order_dir?: any;
  filters?: any;
  advancesFilters: any;
  tableFilters: {
    youtube: any;
    twitch: any;
  };
}

interface ISelectedSettingsProps {
  platform: Platform;
  searchValue: string;
  filters: {
    youtube: any;
    twitch: any;
  };
  tableFilters: {
    youtube: any;
    twitch: any;
  };
}

interface IEngagementSettingsProps {
  searchValue: string;
  tableFilters: any;
  filters: object;
}

interface IManagementSettingsProps {
  searchValue: string;
  tableFilters: any;
  filters: object;
}

interface IReportSettingsProps {
  platform: Platform;
  tableFilters: {
    youtube: any;
    twitch: any;
  };
}

interface ICampaign {
  campaign: any | null;
  channels: any[];
  total: any;
  platform: Platform;
  search: ISearchChannelsProps;
  selectedInfluencersIds: string[];

  selectedChannelsYoutube: any[];
  selectedChannelsTwitch: any[];
  engagementData: any[];
  managementData: any[];
  reportYoutubeData: any;
  reportTwitchData: any;
  reportIds: string[];
  savedManagementIds: string[]; // TODO add to managementSettings

  selectedSettings: ISelectedSettingsProps;
  engagementSettings: IEngagementSettingsProps;
  managementSettings: IManagementSettingsProps;
  reportSettings: IReportSettingsProps;

  loading: Loading;
}

const initialState: ICampaign = {
  campaign: undefined,
  channels: [],
  total: {},
  platform: 'youtube',
  search: {
    keywords: [],
    searchBy: 'name',
    searchMode: 'or',
    order_by: {},
    order_dir: {},
    filters: {},
    advancesFilters: {},
    tableFilters: { youtube: {}, twitch: {} },
  },
  selectedInfluencersIds: [],

  selectedChannelsYoutube: [],
  selectedChannelsTwitch: [],
  engagementData: [],
  managementData: [],
  reportYoutubeData: {},
  reportTwitchData: {},
  reportIds: [],
  savedManagementIds: [], // For report

  selectedSettings: {
    platform: 'youtube',
    searchValue: '',
    filters: { youtube: {}, twitch: {} },
    tableFilters: { youtube: {}, twitch: {} },
  },
  engagementSettings: { searchValue: '', tableFilters: {}, filters: {} },
  managementSettings: { searchValue: '', tableFilters: {}, filters: {} },
  reportSettings: {
    platform: 'youtube',
    tableFilters: { youtube: {}, twitch: {} },
  },

  loading: defaultLoading,
};

const slice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setPlatform: (state, { payload }: PayloadAction<Platform>) => {
      state.platform = payload;
    },
    setInfluencersSettings: (state, { payload }: PayloadAction<Partial<ISearchChannelsProps>>) => {
      state.search = { ...state.search, ...payload };
    },
    setInfluencersIds: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedInfluencersIds = payload;
    },
    setSavedManagementIds: (state, { payload }: PayloadAction<string[]>) => {
      state.reportIds = payload.length ? payload : state.managementData.filter((d: any) => d.published).map((d: any) => d.selected_id);
      state.savedManagementIds = payload;
    },
    setSelectedSettings: (state, { payload }: PayloadAction<Partial<ISelectedSettingsProps>>) => {
      state.selectedSettings = { ...state.selectedSettings, ...payload };
    },
    setEngagementSettings: (state, { payload }: PayloadAction<Partial<IEngagementSettingsProps>>) => {
      state.engagementSettings = { ...state.engagementSettings, ...payload };
    },
    setManagementSettings: (state, { payload }: PayloadAction<Partial<IManagementSettingsProps>>) => {
      state.managementSettings = { ...state.managementSettings, ...payload };
    },
    setReportSettings: (state, { payload }: PayloadAction<Partial<IReportSettingsProps>>) => {
      state.reportSettings = { ...state.reportSettings, ...payload };
    },
    updateChannelsComments: (state, { payload }: PayloadAction<any>) => {
      if (state.selectedSettings.platform === 'youtube') {
        const idx = state.selectedChannelsYoutube.findIndex((d: any) => d.selected_id === payload.selected_id);
        if (idx !== -1)
          state.selectedChannelsYoutube.splice(idx, 1, {
            ...state.selectedChannelsYoutube[idx],
            comments: payload.data.length,
          });
      }
      if (state.selectedSettings.platform === 'twitch') {
        const idx = state.selectedChannelsTwitch.findIndex((d: any) => d.selected_id === payload.selected_id);
        if (idx !== -1)
          state.selectedChannelsTwitch.splice(idx, 1, {
            ...state.selectedChannelsTwitch[idx],
            comments: payload.data.length,
          });
      }
      const idx = state.managementData.findIndex((d: any) => d.selected_id === payload.selected_id);
      if (idx !== -1)
        state.managementData.splice(idx, 1, {
          ...state.managementData[idx],
          comments: payload.data.length,
        });
    },
    resetState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchCampaignThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CAMPAIGN', payload);

        state.campaign = isArray(payload) && payload.length ? payload[0] : null;

        state.loading.is = false;
      })
      .addCase(fetchCampaignThunk.rejected, (state) => {
        console.log('REJECTED FETCH CAMPAIGN');

        state.loading.is = false;
      })

      .addCase(fetchCampaignChannelsThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchCampaignChannelsThunk.fulfilled, (state, { payload, meta }) => {
        console.log('FULFILLED FETCH CAMPAIGN CHANNELS', payload);

        if (payload.data && isArray(payload.data) && payload.total) {
          state.channels = meta.arg?.isScrollFetch ? [...state.channels, ...payload.data] : payload.data;
          state.total = payload.total;
        }
        state.loading.is = false;
      })
      .addCase(fetchCampaignChannelsThunk.rejected, (state) => {
        console.log('REJECTED FETCH CAMPAIGN CHANNELS');

        state.loading.is = false;
      })

      .addCase(fetchCampaignSelectedChannelsThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchCampaignSelectedChannelsThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CAMPAIGN SELECTED', payload);

        state.selectedChannelsYoutube = payload.youtube;
        state.selectedChannelsTwitch = payload.twitch;
        state.loading.is = false;
      })
      .addCase(fetchCampaignSelectedChannelsThunk.rejected, (state) => {
        console.log('REJECTED FETCH CAMPAIGN SELECTED');

        state.loading.is = false;
      })

      .addCase(createCampaignSelectedChannelThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(createCampaignSelectedChannelThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED CREATE CAMPAIGN SELECTED', payload);

        if (payload.sm_platform === 'youtube') state.selectedChannelsYoutube = [...state.selectedChannelsYoutube, ...payload.response];
        if (payload.sm_platform === 'twitch') state.selectedChannelsTwitch = [...state.selectedChannelsTwitch, ...payload.response];
        state.loading.is = false;
      })
      .addCase(createCampaignSelectedChannelThunk.rejected, (state) => {
        console.log('REJECTED CREATE CAMPAIGN SELECTED');

        state.loading.is = false;
      })

      .addCase(updateCampaignSelectedChannelThunk.pending, (state, { meta }) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(updateCampaignSelectedChannelThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE CAMPAIGN SELECTED', payload);

        if (state.selectedSettings.platform === 'youtube') {
          const idx = state.selectedChannelsYoutube.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1)
            state.selectedChannelsYoutube.splice(idx, 1, {
              ...state.selectedChannelsYoutube[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
        }
        if (state.selectedSettings.platform === 'twitch') {
          const idx = state.selectedChannelsTwitch.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1)
            state.selectedChannelsTwitch.splice(idx, 1, {
              ...state.selectedChannelsTwitch[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
        }
        state.loading.is = false;
      })
      .addCase(updateCampaignSelectedChannelThunk.rejected, (state) => {
        console.log('REJECTED UPDATE CAMPAIGN SELECTED');

        state.loading.is = false;
      })

      .addCase(openCampaignSelectedChannelsThunk.pending, (state, { meta }) => {
        state.loading.is = true;
      })
      .addCase(openCampaignSelectedChannelsThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED OPEN CAMPAIGN SELECTED CHANNELS', payload);

        if (state.selectedSettings.platform === 'youtube') {
          payload.response.forEach((res: any) => {
            const idx = state.selectedChannelsYoutube.findIndex((d: any) => d.selected_id === res.selected_id);
            if (idx !== -1)
              state.selectedChannelsYoutube.splice(idx, 1, {
                ...state.selectedChannelsYoutube[idx],
                ...res,
              });
          });
        }
        if (state.selectedSettings.platform === 'twitch') {
          payload.response.forEach((res: any) => {
            const idx = state.selectedChannelsTwitch.findIndex((d: any) => d.selected_id === res.selected_id);
            if (idx !== -1)
              state.selectedChannelsTwitch.splice(idx, 1, {
                ...state.selectedChannelsTwitch[idx],
                ...res,
              });
          });
        }
        state.loading.is = false;
      })
      .addCase(openCampaignSelectedChannelsThunk.rejected, (state) => {
        console.log('REJECTED OPEN CAMPAIGN SELECTED CHANNELS');

        state.loading.is = false;
      })

      .addCase(deleteCampaignSelectedChannelThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(deleteCampaignSelectedChannelThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE CAMPAIGN SELECTED', payload);

        if (state.selectedSettings.platform === 'youtube') {
          state.selectedChannelsYoutube = state.selectedChannelsYoutube.filter((d: any) => d.selected_id !== payload);
        }

        if (state.selectedSettings.platform === 'twitch') {
          state.selectedChannelsTwitch = state.selectedChannelsTwitch.filter((d: any) => d.selected_id !== payload);
        }

        state.loading.is = false;
      })
      .addCase(deleteCampaignSelectedChannelThunk.rejected, (state) => {
        console.log('REJECTED DELETE CAMPAIGN SELECTED');

        state.loading.is = false;
      })

      .addCase(deleteCampaignSelectedChannelsThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(deleteCampaignSelectedChannelsThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE CAMPAIGN SELECTED CHANNELS', payload);

        if (state.selectedSettings.platform === 'youtube') {
          state.selectedChannelsYoutube = state.selectedChannelsYoutube.filter((d: any) => !payload.includes(d.selected_id));
        }

        if (state.selectedSettings.platform === 'twitch') {
          state.selectedChannelsTwitch = state.selectedChannelsTwitch.filter((d: any) => !payload.includes(d.selected_id));
        }

        state.loading.is = false;
      })
      .addCase(deleteCampaignSelectedChannelsThunk.rejected, (state) => {
        console.log('REJECTED DELETE CAMPAIGN SELECTED CHANNELS');

        state.loading.is = false;
      })

      // ENGAGEMENT

      .addCase(fetchCampaignEngagementDataThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchCampaignEngagementDataThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH ENGAGEMENT DATA', payload);

        state.engagementData = payload;
        state.loading.is = false;
      })
      .addCase(fetchCampaignEngagementDataThunk.rejected, (state) => {
        console.log('REJECTED FETCH ENGAGEMENT DATA');

        state.loading.is = false;
      })

      .addCase(updateCampaignEngagementDataThunk.pending, (state, { meta }) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(updateCampaignEngagementDataThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE ENGAGEMENT DATA', payload);

        if (isArray(payload.response) && payload.response.length) {
          const idx = state.engagementData.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1)
            state.engagementData.splice(idx, 1, {
              ...state.engagementData[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
        }
        state.loading.is = false;
      })
      .addCase(updateCampaignEngagementDataThunk.rejected, (state) => {
        console.log('REJECTED DELETE ENGAGEMENT DATA');

        state.loading.is = false;
      })

      // MANAGEMENT

      .addCase(fetchCampaignManagementDataThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchCampaignManagementDataThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH MANAGEMENT DATA', payload);

        state.reportIds = state.savedManagementIds.length ? state.savedManagementIds : payload.filter((d: any) => d.published).map((d: any) => d.selected_id);

        state.managementData = payload;
        state.loading.is = false;
      })
      .addCase(fetchCampaignManagementDataThunk.rejected, (state) => {
        console.log('REJECTED FETCH MANAGEMENT DATA');

        state.loading.is = false;
      })

      .addCase(updateCampaignManagementDataThunk.pending, (state, { meta }) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(updateCampaignManagementDataThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE MANAGEMENT DATA', payload);

        if (isArray(payload.response) && payload.response.length) {
          const idx = state.managementData.findIndex((d: any) => d.selected_id === payload.args.selected_id);
          if (idx !== -1) {
            state.managementData.splice(idx, 1, {
              ...state.managementData[idx],
              ...payload.args.data,
              ...payload.response[0],
            });
            if (payload.args.data.published && !state.savedManagementIds.length) state.reportIds.push(state.managementData[idx].selected_id);
          }
        }
        state.loading.is = false;
      })
      .addCase(updateCampaignManagementDataThunk.rejected, (state) => {
        console.log('REJECTED DELETE MANAGEMENT DATA');

        state.loading.is = false;
      })

      .addCase(deleteInManagementThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE IN MANAGEMENT', payload);
        const { selected_id: selectedId } = payload.args;

        state.managementData = state.managementData.filter((data: any) => {
          return data.selected_id !== selectedId;
        });
      })
      // REPORT

      .addCase(createCampaignReportDataThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(createCampaignReportDataThunk.fulfilled, (state, { payload, meta }) => {
        console.log('FULFILLED FETCH REPORT DATA', payload);

        if (meta.arg?.data.sm_platform === 'youtube') state.reportYoutubeData = payload;
        else state.reportTwitchData = payload;

        state.loading.is = false;
      })
      .addCase(createCampaignReportDataThunk.rejected, (state) => {
        console.log('REJECTED FETCH REPORT DATA');

        state.reportYoutubeData = {};
        state.reportTwitchData = {};

        state.loading.is = false;
      });
  },
});

export const campaignActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchCampaignThunk = createAsyncThunk('fetch_campaign_thunk', async (campaign_id: string, { dispatch }: any) => {
  const response: any = await getCampaignApi(campaign_id);
  if (isArray(response) && response.length) {
    const sm_platform = response[0].sm_platform.split(',');
    if (sm_platform.length) {
      dispatch(campaignActions.setPlatform(sm_platform[0]));
      dispatch(campaignActions.setSelectedSettings({ platform: sm_platform[0] }));
      dispatch(campaignActions.setReportSettings({ platform: sm_platform[0] }));
    }
  }

  return typeof response === 'string' ? [] : response;
});

export const fetchCampaignChannelsThunk = createAsyncThunk(
  'fetch_campaign_channels_thunk',
  async (args: (Partial<ISearchChannelsProps> & { isScrollFetch?: boolean }) | void, { getState }: any) => {
    const { platform, search: _search } = getState().campaign;

    const search = cloneDeep({ ..._search, ...args });
    const keys = Object.keys(search);
    const payloadObj: any = {};

    if (keys.length && search.keywords.length) {
      payloadObj.keywords = search.keywords.join(',');
      payloadObj.limit = channelsLimit;

      if (search.searchBy) payloadObj.searchBy = search.searchBy;
      if (search.searchMode) payloadObj.searchMode = search.searchMode;
    }

    if (keys.length && search.filters && search.filters[platform]) {
      if (search.filters[platform].language && search.filters[platform].language.includes('all')) delete search.filters[platform].language;

      if (search.filters[platform].country && search.filters[platform].country.includes('all')) delete search.filters[platform].country;

      payloadObj.filters = search.filters[platform];
    }

    if (keys.length && search.advancesFilters && search.advancesFilters[platform])
      payloadObj.filters = {
        ...(payloadObj.filters || {}),
        ...search.advancesFilters[platform],
      };

    if (keys.length && search.order_by && search.order_by[platform]) payloadObj.order_by = search.order_by[platform];

    if (keys.length && search.order_dir && search.order_dir[platform]) payloadObj.order_dir = search.order_dir[platform];

    if (keys.length && search.offset) payloadObj.offset = search.offset;

    const response: SearchChannelsResponse = await searchChannelsApi({
      platform,
      data: payloadObj,
    });
    return typeof response === 'string' ? { data: [], total: {} } : response;
  }
);

// Temporary, make separate on two thunks. One for Youtube, one for Twitch
export const fetchCampaignSelectedChannelsThunk = createAsyncThunk('fetch_campaign_selected_channels_thunk', async (campaign_id: string) => {
  const respYoutube: any[] = await getCampaignSelectedChannelsApi({
    campaign_id,
    sm_platform: 'youtube',
  });
  const respTwitch: any[] = await getCampaignSelectedChannelsApi({
    campaign_id,
    sm_platform: 'twitch',
  });
  return {
    youtube: !isArray(respYoutube) ? [] : respYoutube,
    twitch: !isArray(respTwitch) ? [] : respTwitch,
  };
});

export const createCampaignSelectedChannelThunk = createAsyncThunk('create_campaign_selected_channel_thunk', async (args: ICreateCampaignSelectedChannel) => {
  const response: any[] = await createCampaignSelectedChannelApi(args);

  if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();

  // Need to compare Selected platfrom with Influencers and update selected channels or not
  return { response, sm_platform: args.data.sm_platform };
});

export const updateCampaignSelectedChannelThunk = createAsyncThunk(
  'update_campaign_selected_channel_thunk',
  async (args: IUpdateCampaignSelectedChannel & { thunk?: any }) => {
    const copyArgs = cloneDeep(args);
    if (copyArgs.thunk) delete copyArgs.thunk;
    const response: any[] = await updateCampaignSelectedChannelApi(copyArgs);

    if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();

    return { args, response };
  }
);

// Open array of selected channels
export const openCampaignSelectedChannelsThunk = createAsyncThunk('open_campaign_selected_channels_thunk', async (args: IOpenCampaignSelectedChannels) => {
  const response: any = await openCampaignSelectedChannelsApi(args);

  if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();

  return { args, response };
});

export const deleteCampaignSelectedChannelThunk = createAsyncThunk('delete_campaign_selected_channel_thunk', async (args: IDeleteCampaignSelectedChannel) => {
  const response: any = await deleteCampaignSelectedChannelApi(args);

  if (!isArray(response)) throw new Error();

  return args.selected_id;
});

// Delete array of selected channels
export const deleteCampaignSelectedChannelsThunk = createAsyncThunk(
  'delete_campaign_selected_channels_thunk',
  async (args: IDeleteCampaignSelectedChannels) => {
    const response: any = await deleteCampaignSelectedChannelsApi(args);

    if (!isArray(response)) throw new Error();

    return response.length ? args.data.selected_ids.filter((d: any) => !response.includes(d)) : args.data.selected_ids;
  }
);

// ENGAGEMENT

export const fetchCampaignEngagementDataThunk = createAsyncThunk('fetch_campaign_engagement_data_thunk', async (campaign_id: string) => {
  const response: any[] = await getCampaignEngagementDataApi(campaign_id);
  return typeof response === 'string' ? [] : response;
});

export const updateCampaignEngagementDataThunk = createAsyncThunk(
  'update_campaign_engagement_data_thunk',
  async (args: IUpdateCampaignEngagementData & { thunk?: any }) => {
    const copyArgs = cloneDeep(args);
    if (copyArgs.thunk) delete copyArgs.thunk;
    const response: any[] = await updateCampaignEngagementDataApi(copyArgs);
    return { args, response };
  }
);

// MANAGEMENT

export const fetchCampaignManagementDataThunk = createAsyncThunk('fetch_campaign_management_data_thunk', async (campaign_id: string) => {
  const response: any[] = await getCampaignManagementDataApi(campaign_id);
  return typeof response === 'string' ? [] : response;
});

export const updateCampaignManagementDataThunk = createAsyncThunk(
  'update_campaign_management_data_thunk',
  async (args: IUpdateCampaignManagementData & { thunk?: any }) => {
    const copyArgs = cloneDeep(args);
    if (copyArgs.thunk) delete copyArgs.thunk;
    const response: any[] = await updateCampaignManagementDataApi(copyArgs);
    return { args, response };
  }
);

export const deleteInManagementThunk = createAsyncThunk('delete_in_management_thunk', async (args: IDeleteInManagement) => {
  const response = await deleteInManagementApi(args);

  if (typeof response === 'string' && response === 'Success!') {
    toast('Successfully deleted from Campaign Management', { type: 'success' });
  } else {
    toast('Failed to from Campaign Management', { type: 'error' });
    throw new Error();
  }
  return { args, response };
});

// REPORT

export const createCampaignReportDataThunk = createAsyncThunk('create_campaign_report_data_thunk', async (args: ICreateCampaignReport) => {
  const response: any = args.data.sm_platform === 'youtube' ? await createCampaignReportApi(args) : await createCampaignReportTwitchApi(args);

  return typeof response === 'string' || !response ? {} : response;
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.campaign;

export const selectCampaign = createSelector(selectState, (state) => state.campaign);

export const selectCampaignChannels = createSelector(selectState, (state) => state.channels);

export const selectCampaignTotal = createSelector(selectState, (state) => state.total);

export const selectCampaignPlatform = createSelector(selectState, (state) => state.platform);

export const selectCampaignSelectedChannelsYoutube = createSelector(selectState, (state) => state.selectedChannelsYoutube);

export const selectCampaignSelectedChannelsTwitch = createSelector(selectState, (state) => state.selectedChannelsTwitch);

export const selectCampaignSelectedChannelsCount = createSelector(
  selectCampaignSelectedChannelsYoutube,
  selectCampaignSelectedChannelsTwitch,
  (youtube, twitch) => youtube.length + twitch.length
);

export const selectCampaignEngagementData = createSelector(selectState, (state) => state.engagementData);

export const selectCampaignManagementData = createSelector(selectState, (state) => state.managementData);

export const selectCampaignSavedManagementIds = createSelector(selectState, (state) => state.savedManagementIds);

export const selectCampaignReportYoutubeData = createSelector(selectState, (state) => state.reportYoutubeData);

export const selectCampaignReportTwitchData = createSelector(selectState, (state) => state.reportTwitchData);

export const selectCampaignReportIds = createSelector(selectState, (state) => state.reportIds);

const array: any[] = [];

// Influencers

export const selectCampaignSearch = createSelector(selectState, (state) => state.search);

export const selectCampaignInfluencersSelectedIds = createSelector(selectState, (state) => state.selectedInfluencersIds);

export const selectCampaignInfluencersSettingsTableFilter = createSelector(selectCampaignSearch, (settings) => settings.tableFilters);

export const selectCampaignInfluencersSettingsTableFilterHiddenColumns = createSelector(
  selectCampaignPlatform,
  selectCampaignInfluencersSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform].hiddenColumns
);

const defaultResizing = {};

export const selectCampaignInfluencersSettingsTableFilterResizing = createSelector(
  selectCampaignPlatform,
  selectCampaignInfluencersSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform]?.resizing || defaultResizing
);

// Selected

export const selectCampaignSelectedSettings = createSelector(selectState, (state) => state.selectedSettings);

export const selectCampaignSelectedSettingsPlatform = createSelector(selectCampaignSelectedSettings, (settings) => settings.platform);

export const selectCampaignSelectedSettingsSearchValue = createSelector(selectCampaignSelectedSettings, (settings) => settings.searchValue);

export const selectCampaignSelectedSettingsFilters = createSelector(selectCampaignSelectedSettings, (settings) => settings.filters);

export const selectCampaignSelectedSettingsTableFilter = createSelector(selectCampaignSelectedSettings, (settings) => settings.tableFilters);

// By influencers platform
export const selectCampaignSelectedChannelsByInfluencersPlatform = createSelector(
  selectCampaignPlatform,
  selectCampaignSelectedChannelsYoutube,
  selectCampaignSelectedChannelsTwitch,
  (platform, youtube, twitch) => (platform === 'youtube' ? youtube : twitch)
);

// By selected platform
export const selectCampaignSelectedChannelsByPlatform = createSelector(
  selectCampaignSelectedSettingsPlatform,
  selectCampaignSelectedChannelsYoutube,
  selectCampaignSelectedChannelsTwitch,
  (platform, youtube, twitch) => (platform === 'youtube' ? youtube : twitch)
);

export const selectCampaignSelectedSettingsTableFilterSortBy = createSelector(
  selectCampaignSelectedSettingsPlatform,
  selectCampaignSelectedSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform].sortBy || array
);

export const selectCampaignSelectedSettingsTableFilterSelectedRows = createSelector(
  selectCampaignSelectedSettingsPlatform,
  selectCampaignSelectedSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform].selectedRows || array
);

export const selectCampaignSelectedSettingsTableFilterHiddenColumns = createSelector(
  selectCampaignSelectedSettingsPlatform,
  selectCampaignSelectedSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform].hiddenColumns
);

export const selectCampaignSelectedSettingsTableFilterResizing = createSelector(
  selectCampaignSelectedSettingsPlatform,
  selectCampaignSelectedSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform]?.resizing || defaultResizing
);

// Engagement

export const selectCampaignEngagementSettings = createSelector(selectState, (state) => state.engagementSettings);

export const selectCampaignEngagementSettingsFilters = createSelector(selectCampaignEngagementSettings, (settings) => settings.filters);

export const selectCampaignEngagementSettingsSearchValue = createSelector(selectCampaignEngagementSettings, (settings) => settings.searchValue);

export const selectCampaignEngagementSettingsTableFilter = createSelector(selectCampaignEngagementSettings, (settings) => settings.tableFilters);

export const selectCampaignEngagementSettingsTableFilterSortBy = createSelector(
  selectCampaignEngagementSettingsTableFilter,
  (tableFilters) => tableFilters.sortBy || array
);

export const selectCampaignEngagementSettingsTableFilterHiddenColumns = createSelector(
  selectCampaignEngagementSettingsTableFilter,
  (tableFilters) => tableFilters.hiddenColumns
);

export const selectCampaignEngagementSettingsTableFilterResizing = createSelector(
  selectCampaignEngagementSettingsTableFilter,
  (tableFilters) => tableFilters?.resizing || defaultResizing
);

// Management

export const selectCampaignManagementSettings = createSelector(selectState, (state) => state.managementSettings);

export const selectCampaignManagementSettingsFilters = createSelector(selectCampaignManagementSettings, (settings) => settings.filters);

export const selectCampaignManagementSettingsSearchValue = createSelector(selectCampaignManagementSettings, (settings) => settings.searchValue);

export const selectCampaignManagementSettingsTableFilter = createSelector(selectCampaignManagementSettings, (settings) => settings.tableFilters);

export const selectCampaignManagementSettingsTableFilterSortBy = createSelector(
  selectCampaignManagementSettingsTableFilter,
  (tableFilters) => tableFilters.sortBy || array
);

export const selectCampaignManagementSettingsTableFilterHiddenColumns = createSelector(
  selectCampaignManagementSettingsTableFilter,
  (tableFilters) => tableFilters.hiddenColumns
);

export const selectCampaignManagementSettingsTableFilterResizing = createSelector(
  selectCampaignManagementSettingsTableFilter,
  (tableFilters) => tableFilters?.resizing || defaultResizing
);

// Report

export const selectCampaignReportSettings = createSelector(selectState, (state) => state.reportSettings);

export const selectCampaignReportSettingsPlatform = createSelector(selectCampaignReportSettings, (settings) => settings.platform);

export const selectCampaignReportSettingsTableFilter = createSelector(selectCampaignReportSettings, (settings) => settings.tableFilters);

export const selectCampaignReportSettingsTableFilterSortBy = createSelector(
  selectCampaignReportSettingsPlatform,
  selectCampaignReportSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform].sortBy || array
);

export const selectCampaignReportSettingsTableFilterResizing = createSelector(
  selectCampaignReportSettingsPlatform,
  selectCampaignReportSettingsTableFilter,
  (platform, tableFilters) => tableFilters[platform]?.resizing || defaultResizing
);

export const selectCampaignReportData = createSelector(
  selectCampaignReportSettingsPlatform,
  selectCampaignReportYoutubeData,
  selectCampaignReportTwitchData,
  (platform, youtubeData, twitchData) => (platform === 'youtube' ? youtubeData : twitchData)
);

export const selectCampaignLoading = createSelector(selectState, (state) => state.loading);

export const selectCampaignLoadingToHeader = createSelector(selectCampaignLoading, (loading) => Boolean(loading.is && !loading.id));
