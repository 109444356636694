import { FC } from 'react';
import { default as RSClipLoader } from 'react-spinners/ClipLoader';
import { colors } from 'variables';

interface ILoader {
  loading: boolean;
  color?: string;
  size?: number;
  speedMultiplier?: number;
  styles?: any;
}

export const ClipLoader: FC<ILoader> = ({ loading = false, color = colors.green.light, size = 25, speedMultiplier = 1, styles = {} }) => {
  return (
    <RSClipLoader
      color={color}
      loading={loading}
      cssOverride={styles}
      size={size}
      speedMultiplier={speedMultiplier}
      aria-label="Clip loader"
      data-testid="clip_loader"
    />
  );
};
