import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useFlexLayout, useSortBy, useTable } from 'react-table';
import moment from 'moment';
import { orderBy } from 'lodash';
import { colors } from 'variables';
import cn from 'classnames';
import { MenuActionWithFloat, TableHeaderSort } from 'shared';
import { createChannelListThunk, deleteSavedChannelListThunk, selectSavedChannelList, updateSavedChannelListThunk } from '../reducer';
import { showModal } from 'components/Modal/reducer';
import { createCampaignSelectedChannelThunk } from 'pages/Campaign/reducer';
import { ModalTypes } from 'components/Modal';
import { DotsIcon } from 'assets/icons';
import { PlusIcon } from '@heroicons/react/24/outline';

interface ISearchTabProps {
  searchValue: string;
}

// Make init saved list in useEffect here. Now init run in InfluencersTab because InfluencersTab run before SearchesTab. Check <Tabs />
export const SearchesTab: FC<ISearchTabProps> = ({ searchValue = '' }) => {
  const dispatch = useAppDispatch();
  const savedChannelList = useAppSelector(selectSavedChannelList);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'list_name',
        Cell: ({ value }: any) => (
          <p className="truncate" title={value}>
            {value}
          </p>
        ),
      },
      {
        Header: 'Creation Dates',
        accessor: 'created_at',
        Cell: ({ value }: any) => (value ? moment(value).format('DD MMM YYYY') : ''),
      },
      {
        Header: '',
        accessor: 'action',
        width: 45,
        disableSortBy: true,
        className: 'sticky right-0 pl-0 py-0 !flex-none bg-gradient-to-l from-white via-white to-transparent opacity-0 group-hover:opacity-100',
        headerClassName: '!flex-none',
        Cell: ({ row }: any) => (
          <div
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MenuActionWithFloat
              className="flex"
              optionClass="!px-2.5"
              items={[
                {
                  label: 'Add to campaign',
                  disabled: Boolean(!row.original.youtube_channel_ids.length && !row.original.twitch_channel_ids.length),
                  onClick: () => {
                    const platforms: any = {};
                    if (row.original.youtube_channel_ids.length)
                      platforms.youtube = {
                        sm_platform: 'youtube',
                        channel_ids: row.original.youtube_channel_ids,
                      };
                    if (row.original.twitch_channel_ids.length)
                      platforms.twitch = {
                        sm_platform: 'twitch',
                        channel_ids: row.original.twitch_channel_ids,
                      };

                    const keys = Object.keys(platforms);
                    return dispatch(
                      showModal({
                        type: ModalTypes.AddToCampaign,
                        params: {
                          handleSave: async (data: any) =>
                            await dispatch(
                              createCampaignSelectedChannelThunk({
                                campaign_id: data.campaign_id,
                                data: platforms[keys[0]],
                              })
                            ).then(async () =>
                              keys[1]
                                ? await dispatch(
                                    createCampaignSelectedChannelThunk({
                                      campaign_id: data.campaign_id,
                                      data: platforms[keys[1]],
                                    })
                                  )
                                : null
                            ),
                        },
                      })
                    );
                  },
                },
                {
                  label: 'Edit',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.EditSearchInfluencer,
                        params: {
                          name: row.original.list_name,
                          handleSave: async (list_name: string) =>
                            await dispatch(
                              updateSavedChannelListThunk({
                                list_id: row.original.list_id,
                                data: { list_name },
                              })
                            ),
                        },
                      })
                    ),
                },
                {
                  label: 'Delete',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.DeleteConfirmation,
                        params: {
                          name: row.original.list_name,
                          handleDelete: async () => await dispatch(deleteSavedChannelListThunk(row.original.list_id)),
                        },
                      })
                    ),
                },
              ]}
            >
              {({ open }) => (
                <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                  <DotsIcon aria-hidden="true" />
                </span>
              )}
            </MenuActionWithFloat>
          </div>
        ),
      },
    ],
    []
  );

  const listSorted = useMemo(() => orderBy(savedChannelList, [(d) => d.list_name || '', (d) => d.list_id], ['asc', 'asc']), [savedChannelList]);

  const listSearched = useMemo(
    () => listSorted.filter((d: any) => (d.list_name || '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1),
    [searchValue, listSorted]
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl leading-[42px] font-semibold">Saved lists ({listSearched.length})</h1>
        <div className="flex items-center space-x-4">
          <button
            type="button"
            className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none"
            onClick={() =>
              dispatch(
                showModal({
                  type: ModalTypes.EditSearchInfluencer,
                  params: {
                    handleCreate: async (list_name: string) => await dispatch(createChannelListThunk(list_name)),
                  },
                })
              )
            }
          >
            <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            <p className="mr-1.5">Create new list</p>
          </button>
        </div>
      </div>

      <div className="mt-3 flex flex-col overflow-hidden">
        <div className="flex flex-col min-w-full align-middle overflow-hidden">
          <div className="overflow-auto">
            <Table columns={columns} data={listSearched} />
          </div>
        </div>
      </div>
    </>
  );
};

function Table({ columns, data }: any) {
  const navigate = useNavigate();

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useFlexLayout
  );

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="min-w-full w-full rounded-[4px]">
      <thead className="sticky top-0 z-30 bg-gray-g1">
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="py-1.5 pl-6">
            {headerGroup.headers.map((column: any, hidx: number) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={cn(
                  'text-left text-xs font-normal text-black-b1',
                  typeof column.render('Header') === 'string' ? 'truncate' : '',
                  hidx === 0 ? 'pl-0 pr-2' : 'px-2',
                  column?.headerClassName || ''
                )}
              >
                <div
                  title={typeof column.Header === 'string' ? column.Header : ''}
                  className={cn('h-[18px] flex items-center space-x-3', column?.disableSortBy ? '' : 'cursor-pointer')}
                >
                  <p>{column.render('Header')}</p>
                  <TableHeaderSort column={column} activeFill={colors.black.b1} />
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="space-y-3">
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="relative group pl-6 rounded-[4px] bg-white cursor-pointer hover:shadow-s1"
              onClick={() =>
                navigate(`searches/${row.original.list_id}`, {
                  state: {
                    searchesName: row.original.list_name,
                  },
                })
              }
            >
              {row.cells.map((cell: any, cidx: number) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={cn(
                      'relative flex items-center h-[58px] whitespace-nowrap text-sm leading-6 font-normal text-black-b1',
                      cidx === 0 ? 'pl-0 pr-2' : 'px-2',
                      cell.column?.className || ''
                    )}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
