import { FC, Fragment, useRef } from 'react';
import cn from 'classnames';
import { InformationIcon } from 'assets/icons';
import { Popover, Transition } from '@headlessui/react';

interface IPopupDescriptionProps {
  content?: string;
  rootClass?: string;
  buttonClass?: string;
  panelClass?: string;
  position?: string;
  alwaysVisible?: boolean;
}

const timeoutDuration = 120;

const positionPanel: any = {
  center: 'left-1/2 -translate-x-1/2',
  right: 'left-auto right-0 translate-x-0',
};

export const PopupDescription: FC<IPopupDescriptionProps> = ({
  content = '',
  rootClass = '',
  buttonClass = '',
  panelClass = '',
  position = 'center',
  alwaysVisible = false,
}) => {
  const triggerRef = useRef<any>(null);
  const timeOutRef = useRef<any>(null);

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    if (!isOpen && triggerRef.current) triggerRef.current?.click();
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className={cn(alwaysVisible ? 'flex' : 'hidden group-hover:flex', rootClass)}>
      {({ open }) => (
        <div className="relative flex" onMouseEnter={() => handleEnter(open)} onMouseLeave={() => handleLeave(open)}>
          <Popover.Button ref={triggerRef} className={cn('outline-none', buttonClass)}>
            <InformationIcon />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={cn('absolute z-50 transform w-[17.5rem] mt-7 px-2.5 py-1 rounded shadow-s7 bg-white', positionPanel[position], panelClass)}
            >
              <div className="flex">
                <p className="whitespace-normal leading-4 text-[10px] text-black-b1">{content}</p>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};
