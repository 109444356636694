import { FC, ReactNode } from 'react';
import { useGlobalLoader } from 'hooks/useGlobalLoader';
import cn from 'classnames';

interface IContentOverlayLoadingProps {
  children: ReactNode;
}

export const ContentOverlayLoading: FC<IContentOverlayLoadingProps> = ({ children }) => {
  const loading = useGlobalLoader();

  return (
    <div
      aria-disabled={loading}
      className={cn('flex flex-1 flex-col landscape:overflow-y-auto max-lg:overflow-y-auto lg:overflow-hidden', loading ? 'pointer-events-none' : '')}
      onClick={(e: any) => {
        if (loading) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      {loading ? <div className="absolute inset-0 z-50 bg-gray-500/5"></div> : null}
      {children}
    </div>
  );
};
