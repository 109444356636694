import { FC, useMemo } from 'react';
import { components, OptionProps, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import cn from 'classnames';
import { useAppSelector } from 'hooks/store';
import { ValidationTypes } from 'utils/validation';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../index';
import {
  AsyncSelectMulti,
  AsyncSelectMultiStyles,
  ClipLoader,
  DatePicker,
  DropdownIndicator,
  Form,
  Input,
  Label,
  RSelect,
  RSelectStyles,
  SelectCheckbox,
  settingsSelectCheckboxMultiStyles,
  ValueContainer,
} from 'shared';
import { selectClients } from 'pages/Admin/reducer';
import { debounce } from 'lodash';
import { getUsersAutocompleteApi } from 'services';
import { colors } from 'variables';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

const selectPlatformMultiStyles: StylesConfig<any, true> = {
  ...settingsSelectCheckboxMultiStyles,
  control: (styles, props) => ({
    ...(settingsSelectCheckboxMultiStyles?.control ? settingsSelectCheckboxMultiStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
  placeholder: (styles, props) => ({
    ...(settingsSelectCheckboxMultiStyles?.placeholder ? settingsSelectCheckboxMultiStyles?.placeholder(styles, props) : {}),
    color: colors.gray.gm,
    fontSize: '12px',
  }),
};

const AutocompleteOption = ({ children, ...props }: OptionProps<any, true>) => {
  const { data } = props;

  return (
    <components.Option {...props} className="!flex items-center space-x-2.5">
      <span className="flex items-center space-x-1">
        <p>{data.label}</p>
        <p className="text-gray-gm">({data.value})</p>
      </span>
    </components.Option>
  );
};

const platformOptions: any[] = [
  {
    value: 'youtube',
    label: 'Youtube',
  },
  {
    value: 'twitch',
    label: 'Twitch',
  },
];

interface ICreateCampaignProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const CreateCampaign: FC<ICreateCampaignProps> = ({ loading, params, startLoading, stopLoading, hideModal }) => {
  const clients = useAppSelector(selectClients);

  const defaultValues: any = useMemo(
    () => ({
      campaign_name: params?.campaign?.campaign_name || '',
      client_id: params?.campaign?.client_id || null,
      client_emails:
        params?.campaign?.client_emails != null
          ? params?.campaign?.client_emails.map((d: any) => ({
              value: d,
              label: d,
            }))
          : [],
      product: params?.campaign?.product || '',
      sm_platform: params?.campaign?.sm_platform != null ? params?.campaign?.sm_platform.split(',') : ['youtube', 'twitch'],
      launch_date: params?.campaign?.launch_date || null,
      shared_with:
        params?.campaign?.shared_with != null
          ? params?.campaign?.shared_with.map((d: any) => ({
              value: d,
              label: d, // TODO now get array of emails. Need show user name instead of email
            }))
          : [],
    }),
    [params?.campaign]
  );

  const clientsOptions = useMemo(
    () =>
      clients.map((d: any) => ({
        value: d.client_id,
        label: d.client_name,
      })),
    [clients]
  );

  const loadOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        if (inputValue) getUsersAutocompleteApi(inputValue).then((res: any) => callback(res.map((d: any) => ({ value: d.email, label: d.full_name }))));
        else callback([]);
      }, 300),
    []
  );

  const isUpdate = Boolean(params?.campaign?.campaign_id);
  const isDuplicate = isUpdate && Boolean(params?.duplicate);

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isDuplicate ? 'Duplicate campaign' : isUpdate ? 'Update campaign' : 'Create new campaign'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.CreateCampaign}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params
            .handleSave({
              ...values,
              client_emails: values.client_emails.map((d: any) => d.value),
              launch_date: values.launch_date ? moment(values.launch_date).toISOString() : null,
              sm_platform: values.sm_platform.join(','),
              shared_with: values.shared_with.map((d: any) => d.value),
            })
            .then(() => hideModal());
        }}
      >
        {({ register, watch, control, setValue, setFocus, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="campaign_name" label="Campaign name" error={errors.campaign_name} />
              <div className="mt-0.5">
                <Input id="campaign_name" type="text" placeholder="Campaign name" error={errors.campaign_name} {...register('campaign_name')} />
              </div>
            </div>
            <div>
              <Label htmlFor="client_id" label="Company name" error={errors.client_id} />
              <div className="mt-0.5">
                <Controller
                  name="client_id"
                  control={control}
                  render={(props: any) => {
                    const { field } = props;
                    return (
                      <RSelect
                        {...field}
                        styles={selectStyles}
                        placeholder="Not selected"
                        options={clientsOptions}
                        error={errors.client_id}
                        components={{
                          ClearIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        value={clientsOptions.find((option) => option.value === field.value) || null}
                        onChange={(option: any) => {
                          field.onChange(option.value);
                          setValue('client_emails', []);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="client_emails" label="Client email address" error={errors.client_emails} />
              <div className="mt-0.5">
                <Controller
                  name="client_emails"
                  control={control}
                  render={({ field }: any) => {
                    const client_id = watch('client_id');
                    const client = clients.find((cl: any) => cl.client_id === client_id);
                    const emailsOptions =
                      client && client.emails
                        ? client.emails.filter(Boolean).map((em: any) => ({
                            value: em,
                            label: em,
                          }))
                        : [];
                    return (
                      <CreatableSelect
                        {...field}
                        styles={AsyncSelectMultiStyles}
                        placeholder="Not selected"
                        options={emailsOptions}
                        error={errors.client_emails}
                        isMulti
                        menuPlacement="auto"
                        components={{
                          ClearIndicator: () => null,
                          IndicatorSeparator: () => null,
                          DropdownIndicator,
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="product" label="Product name" error={errors.product} />
              <div className="mt-0.5">
                <Input id="product" type="text" placeholder="Product name" error={errors.product} {...register('product')} />
              </div>
            </div>
            <div>
              <Label htmlFor="sm_platform" label="SM Platform" error={errors.sm_platform} />
              <div className="mt-0.5">
                <Controller
                  name="sm_platform"
                  control={control}
                  render={({ field }: any) => (
                    <SelectCheckbox
                      {...field}
                      styles={selectPlatformMultiStyles}
                      placeholder="Not selected"
                      options={platformOptions}
                      error={errors.sm_platform}
                      defaultValue={platformOptions.filter((option: any) => field.value.includes(option.value)) || null}
                      value={platformOptions.filter((option: any) => field.value.includes(option.value)) || null}
                      onChange={(option: any) => {
                        field.onChange(option.map((d: any) => d.value));
                      }}
                      components={{ ValueContainer }}
                    />
                  )}
                />
                {/* <Controller
                  name="platform"
                  control={control}
                  render={({ field }: any) => (
                    <RSelect
                      {...field}
                      styles={selectStyles}
                      placeholder="Not selected"
                      options={platformOptions}
                      error={errors.platform}
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={
                        platformOptions.find(
                          (option) => option.value === field.value
                        ) || null
                      }
                      onChange={(option: any) => {
                        field.onChange(option.value)
                      }}
                    />
                  )}
                /> */}
              </div>
            </div>
            <div>
              <Label htmlFor="launch_date" label="Launch date" error={errors.launch_date} />
              <div className="relative mt-0.5">
                <Controller
                  name="launch_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholder="Select launch date"
                      error={errors.launch_date}
                      selected={field.value ? new Date(field.value) : null}
                      onChange={(value: Date | null) => {
                        field.onChange(value);
                        setFocus('launch_date');
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="shared_with" label="Share with:" error={errors.shared_with} />
              <div className="mt-0.5">
                <Controller
                  name="shared_with"
                  control={control}
                  render={({ field }: any) => (
                    <AsyncSelectMulti
                      {...field}
                      error={errors.shared_with}
                      openMenuOnClick={false}
                      isClearable={false}
                      placeholder="Ex. Leo  Hao"
                      menuPlacement="top"
                      components={{
                        ClearIndicator: () => null,
                        Option: AutocompleteOption,
                      }}
                      loadOptions={loadOptions}
                    />
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center uppercase justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                {isDuplicate ? <p>Duplicate</p> : isUpdate ? <p>Save</p> : <p>Create</p>}
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
