import { FC, useCallback } from 'react';
import cn from 'classnames';
import { Menu } from 'shared';
import { ExportIcon } from 'assets/icons';
import { colors } from 'variables';
import { IExport, exportApi } from 'services';

interface IExportMenuProps {
  data: any[];
  filename?: string;
  columns?: any[];
  className?: string;
  buttonClass?: string;
}

export const ExportMenu: FC<IExportMenuProps> = ({ data = [], filename = 'cloutboost_export', columns = [], className = '', buttonClass = '' }) => {
  const handleExport = useCallback(
    async (args: IExport) => {
      await exportApi(args)
        .then((blob: any) => {
          const url = URL.createObjectURL(new Blob([blob]));

          const link = document.createElement('a');
          link.href = url;
          link.download = `${filename}.${args.type}`;

          document.body.appendChild(link);

          link.click();

          if (link.parentNode) link.parentNode.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch((err: any) => {});
    },
    [filename]
  );

  return (
    <Menu
      className={cn('flex items-center justify-center', className)}
      itemsClass="top-[2.7rem] !left-0" // min-w-[154px]
      optionClass="!px-4"
      items={[
        {
          label: 'Excel',
          onClick: () =>
            handleExport({
              type: 'xlsx',
              data,
              columns,
            }),
        },
        {
          label: 'CSV',
          onClick: () =>
            handleExport({
              type: 'csv',
              data,
              columns,
            }),
        },
      ]}
    >
      {({ open }) => (
        <button
          type="button"
          className={cn(
            'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0',
            open ? 'bg-blue-b1 text-white' : 'bg-white text-blue-b1',
            buttonClass
          )}
        >
          <ExportIcon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
        </button>
      )}
    </Menu>
  );
};
