import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { isArray } from 'lodash';
import { ICreateMessageChat, IGetChat, createMessageChatApi, getChatApi } from 'services';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

export interface ChatState {
  isOpen: boolean;
  params: any;
  chatData: any[] | undefined;
  loading: Loading;
}

const initialState: ChatState = {
  isOpen: false,
  params: {},
  chatData: undefined,
  loading: defaultLoading,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    showChat: (state: ChatState, { payload }: PayloadAction<Partial<ChatState>>) => {
      state.isOpen = true;
      state.params = payload;
    },
    hideChat: () => {
      return { ...initialState };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChatThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CHAT', payload);

        state.chatData = payload;
      })
      .addCase(fetchChatThunk.rejected, (state) => {
        console.log('REJECTED FETCH CHAT');

        state.chatData = [];
      })

      .addCase(createMessageChatThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(createMessageChatThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED CREATE MESSAGE CHAT', payload);

        state.chatData = [...(state.chatData || []), ...payload];
        state.loading.is = false;
      })
      .addCase(createMessageChatThunk.rejected, (state) => {
        console.log('REJECTED CREATE MESSAGE CHAT');

        state.loading.is = false;
      });
  },
});

export const { showChat, hideChat } = slice.actions;

export default slice.reducer;

export const fetchChatThunk = createAsyncThunk(
  'fetch_chat_thunk',
  async (args: IGetChat) => {
    const response: any[] = await getChatApi(args);

    if (!isArray(response)) throw new Error();

    return response;
  },
  {
    condition: (args: IGetChat) => {
      if (!args.campaign_id || !args.selected_id) return false;
    },
    // dispatchConditionRejection: true,
  }
);

export const createMessageChatThunk = createAsyncThunk(
  'create_message_chat_thunk',
  async (args: ICreateMessageChat, { getState }: any) => {
    const { user } = getState().auth;
    const response: any[] = await createMessageChatApi(args);

    if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();

    response[0] = {
      ...response[0],
      avatar_url: user.avatar_url,
      full_name: user.full_name,
    };

    return response;
  },
  {
    condition: (args: ICreateMessageChat) => {
      if (!args.selected_id || !args.campaign_id || !args.data.message) return false;
    },
  }
);

//
// ------------------ SELECTORS ------------------ //
//

export const selectState = (state: RootState) => state.chat;

export const selectIsOpen = createSelector(selectState, (state) => state.isOpen);

export const selectParams = createSelector(selectState, (state) => state.params);

export const selectChatData = createSelector(selectState, (state) => state.chatData);

export const selectChatLoading = createSelector(selectState, (state) => state.loading);

export const selectCampaignLoadingBase = createSelector(selectChatLoading, (loading) => Boolean(loading.is && !loading.id));
