import { FC } from 'react';
import { EmptyIcon } from 'assets/icons';

export const ManagementEmpty: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <EmptyIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">No influencers here yet</h1>
      <p className="mt-2 text-center text-sm leading-6 text-black-b1">
        The manager didn't start yet this process.
        <br /> You will be notified once it's started
      </p>
    </div>
  );
};
