import { FC } from 'react';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { colors } from 'variables';
import { CloseIcon } from 'assets/icons';

import 'react-toastify/dist/ReactToastify.css';

export { toast };

export const Notification: FC = () => {
  return (
    <ToastContainer
      transition={Zoom}
      position="top-center"
      theme="dark"
      limit={3}
      toastClassName="min-h-[3.5rem] pl-4 pr-3 rounded-[4px] !bg-black-b1 text-sm leading-6 font-semibold text-white" // h-14
      bodyClassName="pl-0"
      closeButton={
        <div className="flex items-center">
          <CloseIcon pathFill={colors.gray.gm} />
        </div>
      }
    />
  );
};
