import { FC, useState } from 'react';
import { SearchState } from 'components/Search/reducer';
import { components, DropdownIndicatorProps, OptionProps, SingleValueProps, StylesConfig } from 'react-select';
// import makeAnimated from 'react-select/animated'
import { AsyncSelectMulti, RSelect } from 'shared';
import cn from 'classnames';
import { SearchFooter, SearchHeader } from '..';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon, SearchIcon, TwitchLogoIcon, YoutubeLogoIcon } from 'assets/icons';

import './stylesCampaigns.css';
import { colors } from 'variables';

// const animatedComponents = makeAnimated()

const colourOptions: any[] = [
  { value: 'campaign_0', label: 'Campaing name 0', type: 'search' },
  { value: 'campaign_1', label: 'Camapign name 1', type: 'search' },
  { value: 'campaign_2', label: 'Campaign name 2', type: 'search' },
  { value: 'campaign_3', label: 'Campaign name 3', type: 'search' },
  { value: 'campaign_4', label: 'Campaign name 4', type: 'search' },
];

const selectConfig: any[] = [
  {
    id: 'youtube',
    value: 'youtube',
    type: 'channel',
    label: 'Youtube',
    icon: YoutubeLogoIcon,
  },
  {
    id: 'twitch',
    value: 'twitch',
    type: 'channel',
    label: 'Twitch',
    icon: TwitchLogoIcon,
  },
];

const channelsStyles: StylesConfig<any, false> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: colors.gray.g1,
    minHeight: '54px !important',
    height: '54px !important',
    borderWidth: 0,
    boxShadow: 'none',
    borderRadius: '4px',
  }),
  placeholder: (styles, props) => ({
    ...styles,
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    color: props.selectProps.menuIsOpen ? colors.blue.b1 : colors.black.b1,
  }),
  valueContainer: (styles) => ({
    ...styles,
    // paddingLeft: 0,
    paddingRight: 0,
  }),
  option: (styles) => ({
    ...styles,
    height: '42px !important',
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  singleValue: (styles) => ({ ...styles, marginLeft: '5px' }),
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: 0 }),
};

const Option = ({ children, ...props }: OptionProps<any, false>) => {
  const { data } = props;

  return (
    <components.Option {...props} className="!flex items-center space-x-2">
      <data.icon className="w-4 h-4 text-red-r1" />
      <p className="text-sm text-black-b1">{children}</p>
    </components.Option>
  );
};

const CustomSingleValue = ({ children, ...props }: SingleValueProps<any, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props} className="flex items-center space-x-2">
      <data.icon />
      <p className="text-sm leading-6 text-black-b1">{children}</p>
    </components.SingleValue>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? <ArrowUpDropdownSolidIcon pathFill={colors.blue.b1} /> : <ArrowDownDropdownSolidIcon />}
    </components.DropdownIndicator>
  );
};

const searchStyles: StylesConfig<any, true> = {
  control: (styles, { isFocused }) => ({
    ...styles,
    minHeight: '54px',
    backgroundColor: colors.gray.g1,
    padding: '0 6px',
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.black.b1,
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isFocused ? colors.blue.b1 : colors.gray.g1,
    boxShadow: 'none',
    '&:hover': {
      borderColor: isFocused ? colors.blue.b1 : colors.gray.g1,
    },
  }),
  input: (styles) => ({
    ...styles,
    '& > input': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.gray.gm,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
  }),
  option: (styles) => ({
    ...styles,
    display: 'flex',
    padding: '6px 16px',
    fontSize: '14px',
    color: colors.black.b1,
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 1000,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: '10px 0' }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: colors.blue.light,
    borderRadius: '4px',
    padding: '0 10px',
    margin: '4px 8px 4px 0',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: colors.blue.b1,
    '&:hover': {
      backgroundColor: colors.blue.light,
    },
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    '&:hover': {
      backgroundColor: colors.blue.light,
      color: colors.blue.b1,
    },
  }),
};

interface ICampaignsProps extends SearchState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideSearch: () => void;
}

export const Campaigns: FC<ICampaignsProps> = ({ loading, params, startLoading, stopLoading, hideSearch }) => {
  const [formState, setFormState] = useState<any>({
    channels: params?.options.filter((d: any) => d.type === 'channel') || [],
    search: params?.options.filter((d: any) => d.type === 'search') || [],
  });

  const handleChange = (name: string, value: any) => setFormState((prev: any) => ({ ...prev, [name]: value }));

  return (
    <>
      <SearchHeader title="Start with searching influencers" />
      <form
        className="flex flex-1 items-center space-x-4"
        onSubmit={(e: any) => {
          e.preventDefault();

          const transformState = [].concat(formState.channels, formState.search);
          if (params.changeOptions) params.changeOptions(transformState);
          hideSearch();
        }}
      >
        <RSelect
          className="w-[220px]"
          styles={channelsStyles}
          defaultValue={formState.channels[0]}
          name="select-channels"
          isSearchable={false}
          isClearable={false}
          hideSelectedOptions
          placeholder="Select channel"
          options={selectConfig}
          components={{
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator,
            Option,
            SingleValue: CustomSingleValue,
          }}
          onChange={(props: any) => handleChange('channels', [props])}
        />
        <AsyncSelectMulti
          styles={searchStyles}
          options={colourOptions}
          required={true}
          openMenuOnClick={false}
          isClearable={false}
          placeholder="Search by influencer name/keyword/game name/channels"
          defaultValue={formState.search}
          onChange={(props: any) => handleChange('search', props)}
        />
        <button
          type="submit"
          className={cn(
            'flex w-[84px] h-[54px] items-center uppercase justify-center text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2',
            ![].concat(formState.channels, formState.search).length ? 'bg-gray-g1' : 'bg-blue-b1'
          )}
          disabled={![].concat(formState.channels, formState.search).length}
        >
          <SearchIcon pathFill={![].concat(formState.channels, formState.search).length ? colors.gray.g2 : colors.white.default} />
        </button>
      </form>
      <SearchFooter back={hideSearch} />
    </>
  );
};
