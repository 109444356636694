import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { components, OptionProps, SingleValueProps, StylesConfig } from 'react-select';
import { colors } from 'variables';
import { ValidationTypes } from 'utils/validation';
import { Form, Input, Label, RSelect, RSelectStyles } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';

import 'components/Search/searches/stylesCampaigns.css';

const statusOptions: any[] = [
  {
    value: 'responded',
    label: 'responded',
    color: colors.green.light,
    bg: 'bg-green-gl',
  },
  {
    value: 'not_interested',
    label: 'not interested',
    color: colors.pink.light,
    bg: 'bg-pink-pl',
  },
  {
    value: '1st_email_sent',
    label: '1st email sent',
    color: colors.blue.light,
    bg: 'bg-blue-bl',
  },
  {
    value: 'follow up 1',
    label: 'follow up 1',
    color: colors.yellow.light,
    bg: 'bg-yellow-yl',
  },
  {
    value: 'follow_up_2',
    label: 'follow up 2',
    color: colors.yellow.light,
    bg: 'bg-yellow-yl',
  },
  {
    value: 'need_to_reach_out',
    label: 'need to reach out',
    color: colors.gray.g1,
    bg: 'bg-gray-g1',
  },
];

const countryOptions: any[] = [
  {
    value: 'US',
    label: 'US',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
];

const statusStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
    height: '48px !important',
  }),
  valueContainer: (styles, props) => ({
    ...(RSelectStyles?.valueContainer ? RSelectStyles?.valueContainer(styles, props) : {}),
    display: 'flex',
  }),
};

const Option = ({ children, ...props }: OptionProps<any, false>) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          borderRadius: '4px',
          padding: '0 10px',
          backgroundColor: data.color,
          height: '24px',
          fontSize: '10px',
        }}
      >
        {children}
      </div>
    </components.Option>
  );
};

const CustomSingleValue = ({ children, ...props }: SingleValueProps<any, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props} className="flex items-center">
      <div className={`flex items-center h-6 px-2.5 rounded-[4px] text-black-b1 text-[10px] ${data.bg}`}>{children}</div>
    </components.SingleValue>
  );
};

interface IOutreachEditProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}
export const OutreachEdit: FC<IOutreachEditProps> = ({ params, hideModal }) => {
  const defaultValues: any = useMemo(
    () => ({
      name: params?.influencers || '',
      url: params?.url || '',
      status: params?.status || 'need_to_reach_out',
      subscribers: params?.subscribers || '0',
      average_views: params?.average_views || '0',
      country: params?.country,
      email: params?.email || '',
      agency: params?.agency || '',
      agency_name: params?.agency_name || '',
    }),
    [params]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={'Edit "Theking35"'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.OutreachEdit}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          hideModal();
        }}
      >
        {({ register, control, formState: { errors } }) => (
          <>
            <div>
              <Label htmlFor="name" label="Name" error={errors.name} />
              <div className="mt-0.5">
                <Input id="name" type="text" placeholder="Enter name" error={errors.name} {...register('name')} />
              </div>
            </div>
            <div>
              <Label htmlFor="url" label="Channel URL" error={errors.url} />
              <div className="mt-0.5">
                <Input id="url" type="text" placeholder="Place link" error={errors.url} {...register('url')} />
              </div>
            </div>
            <div>
              <Label htmlFor="status" label="Outreach status" error={errors.status} />
              <div className="mt-0.5">
                <Controller
                  name="status"
                  control={control}
                  render={({ field }: any) => (
                    <RSelect
                      {...field}
                      isClearable={false}
                      isSearchable={false}
                      options={statusOptions}
                      styles={statusStyles}
                      error={errors.status}
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                        SingleValue: CustomSingleValue,
                        Option,
                      }}
                      value={statusOptions.find((option) => option.value === field.value) || null}
                      onChange={(option: any) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <Label htmlFor="subscribers" label="# of Subscribers" error={errors.subscribers} />
                <div className="mt-0.5">
                  <Input id="subscribers" type="text" placeholder="Enter subscribers" error={errors.subscribers} {...register('subscribers')} />
                </div>
              </div>
              <div>
                <Label htmlFor="average_views" label="# Average Views" error={errors.average_views} />
                <div className="mt-0.5">
                  <Input id="average_views" type="text" placeholder="Enter average views" error={errors.average_views} {...register('average_views')} />
                </div>
              </div>
            </div>
            <div>
              <Label htmlFor="country" label="Country" error={errors.country} />
              <div className="mt-0.5">
                <Controller
                  name="country"
                  control={control}
                  render={({ field }: any) => (
                    <RSelect
                      {...field}
                      isClearable={false}
                      isSearchable={false}
                      options={countryOptions}
                      styles={statusStyles}
                      error={errors.country}
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={countryOptions.find((option) => option.value === field.value) || null}
                      onChange={(option: any) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="email" label="Email" error={errors.email} />
              <div className="mt-0.5">
                <Input id="email" type="email" placeholder="Enter email" error={errors.email} {...register('email')} />
              </div>
            </div>
            <div>
              <Label htmlFor="agency" label="Agency" error={errors.agency} />
              <div className="mt-0.5">
                <Input id="agency" type="text" placeholder="Enter agency" error={errors.agency} {...register('agency')} />
              </div>
            </div>
            <div>
              <Label htmlFor="agency_name" label="Agency contact name" error={errors.agency_name} />
              <div className="mt-0.5">
                <Input id="agency_name" type="text" placeholder="Enter name" error={errors.agency_name} {...register('agency_name')} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
