import { FC } from 'react';
import { Link } from 'react-router-dom';
import { EmptyReportIcon } from 'assets/icons';

export const ReportEmpty: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <EmptyReportIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">No report generated</h1>
      <p className="mt-2 text-center text-sm leading-6 text-black-b1">
        Go to the{' '}
        <Link to="?tab=management" className="text-blue-b1 hover:underline">
          Campaign management
        </Link>{' '}
        <br />
        to generate report
      </p>
    </div>
  );
};
