import { FC, useMemo, memo, useRef } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useSMPlatforms } from 'hooks/useSMPlatforms';
import { useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { useBlockLayout, useSortBy, useResizeColumns, useTable } from 'react-table';
import { useVirtual } from 'react-virtual';
import moment from 'moment';
import { isEqual, orderBy } from 'lodash';
import cn from 'classnames';
import { colors } from 'variables';
import { Platform } from 'services';
import { approvalSelectedStatuses, contentFormatStatuses, outreachStatuses, platformClientConfig, tags } from 'utils/common';
import {
  dateSort,
  filterCampaignChannels,
  formatNumber,
  getCpm,
  getCpmSelectedDenominator,
  getTooltip,
  getTotalForCPM,
  numberSort,
  replaceSpaceToUnderscore,
  statusSort,
  localeSort,
} from 'utils/helper';
import { SelectedEmpty } from '../empties/SelectedEmpty';
import { getTotalForColumn, PlatformSelectCustomSingleValue, PlatformSelectOption, platformSelectStyles } from 'pages/Influencers/tabs/InfluencersTab';
import { CampaignSelectedSettings } from 'components/Settings';
import { SimpleSearch } from 'components/SimpleSearch';
import { LanguageFullName } from 'components/LanguageFullName';
import { CountryFullName } from 'components/CountryFullName';
import { ChatTableView } from 'components/Chat/ChatTableView';
import { ExportMenu } from 'components/ExportMenu';
import { PopupDescription } from 'components/PopupDescription';
import { Breadcrumbs, HSelect, RSelect, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import { FormatSelect } from 'pages/Campaign/components/FormatSelect';
import {
  campaignClientActions,
  fetchCampaignSelectedChannelsClientThunk,
  selectCampaignClient,
  selectCampaignSelectedChannelsByPlatformClient,
  selectCampaignSelectedChannelsCountClient,
  selectCampaignSelectedSettingsFiltersClient,
  selectCampaignSelectedSettingsPlatformClient,
  selectCampaignSelectedSettingsSearchValueClient,
  selectCampaignSelectedSettingsTableFilterClient,
  selectCampaignSelectedSettingsTableFilterHiddenColumnsClient,
  selectCampaignSelectedSettingsTableFilterResizingClient,
  selectCampaignSelectedSettingsTableFilterSortByClient,
  updateCampaignSelectedChannelClientThunk,
} from '../reducer';
import { selectRole } from 'pages/Auth/reducer';
import { checkContentFormat, getColumnFieldByContentFormat, getTotalForContentFormat, selectedColumnsForContentFormat } from 'pages/Campaign/tabs/Selected';
import { Bars3Icon, LinkIcon } from 'assets/icons';

import './styles.css';

const getTag = (row: any, field: string) => {
  return row.published ? tags.report[field] : row.in_management ? tags.management[field] : '';
};

export const getStatus = (status: string, statuses: any[]) => {
  if (!status) {
    return statuses.find((d: any) => d.default);
  } else return statuses.find((d: any) => d.value === status);
};

const platformColumns = ({ dispatch, campaignId }: any): any => {
  return {
    youtube: [
      {
        Header: platformClientConfig.youtube.channel_name.name,
        accessor: 'channel_name',
        Cell: ({ row, value }: any) => (
          <div className="flex flex-1 items-center justify-between overflow-hidden">
            <div className="flex items-center truncate space-x-2 overflow-hidden">
              <TableCellTruncate
                linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
                value={value}
              />
              <a href={row.original.channel_url} target="_blank" rel="noopener">
                <LinkIcon />
              </a>
            </div>
            {getTag(row.original, 'full_label') ? (
              <div className={cn('flex items-center justify-center px-3 rounded bg-gray-g1')}>
                <p className="text-[10px] leading-4 text-gray-gd">{getTag(row.original, 'full_label')}</p>
              </div>
            ) : null}
          </div>
        ),
        Footer: 'Total',
        width: 300,
        sortType: localeSort,
      },
      {
        Header: platformClientConfig.youtube.channel_description.name,
        accessor: 'channel_description',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformClientConfig.youtube.channel_creation_date.name,
        accessor: 'channel_creation_date',
        Cell: (row: any) => <TableCellTruncate value={moment(row.value).format('DD MMM YYYY')} />,
        width: 130,
        sortType: dateSort,
      },
      {
        Header: platformClientConfig.youtube.approval.name,
        accessor: 'approval',
        Cell: ({ row, value }: any) => (
          <FormatSelect
            value={value}
            options={approvalSelectedStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignSelectedChannelClientThunk({
                    campaign_id: campaignId || '',
                    selected_id: row.original.selected_id,
                    data: {
                      approval: value,
                    },
                  })
                );
            }}
          />
        ),
        width: 130,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, approvalSelectedStatuses),
      },
      {
        Header: platformClientConfig.youtube.outreach_status.name,
        accessor: platformClientConfig.youtube.outreach_status.id,
        Cell: ({ value }: any) => <FormatSelect isDisabled={true} noDropdownIndicator={true} value={value} options={outreachStatuses} onChange={() => null} />,
        width: 170,
        minWidth: 170,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, outreachStatuses),
      },
      {
        Header: platformClientConfig.youtube.content_format.name,
        accessor: 'content_format',
        Cell: ({ value }: any) => (
          <FormatSelect isDisabled={true} noDropdownIndicator={true} value={value} options={contentFormatStatuses} onChange={() => null} />
        ),
        width: 150,
        minWidth: 150,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contentFormatStatuses),
      },
      {
        Header: platformClientConfig.youtube.theme.name,
        accessor: platformClientConfig.youtube.theme.id,
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformClientConfig.youtube.price.name,
        accessor: 'price',
        Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.cpm.name,
        accessor: 'cpm',
        Cell: ({ row }: any) => (
          <TableCellTruncate
            value={formatNumber(getCpm(row.original.price, getCpmSelectedDenominator(row.original), row.original.sm_platform), '$0,0.[00]', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.subscribers.name,
        accessor: 'subscribers',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'subscribers'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.total_videos.name,
        accessor: 'total_videos',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'total_videos'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.total_views.name,
        accessor: 'total_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'total_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.recent_videos_60.name,
        accessor: 'recent_videos_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'recent_videos_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.recent_videos_30.name,
        accessor: 'recent_videos_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'recent_videos_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.median_views_60.name,
        accessor: 'median_views_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_views_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.median_views_30.name,
        accessor: 'median_views_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_views_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.median_views_20.name,
        accessor: 'median_views_20',
        Cell: (info: any) => (
          <TableCellTruncate value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_views : info.value, '0,0')} />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'median_views_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.mean_views_60.name,
        accessor: 'mean_views_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'mean_views_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.mean_views_30.name,
        accessor: 'mean_views_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'mean_views_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.mean_views_20.name,
        accessor: 'mean_views_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'mean_views_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.true_reach_60.name,
        accessor: 'true_reach_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'true_reach_60') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.true_reach_30.name,
        accessor: 'true_reach_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'true_reach_30') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.true_reach_20.name,
        accessor: 'true_reach_20',
        Cell: (info: any) => (
          <TableCellTruncate
            value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_true_reach : info.value, '0.[00]%', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'true_reach_20') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.frequency_60.name,
        accessor: 'frequency_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'frequency_60') / info.rows.length, undefined, 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.frequency_30.name,
        accessor: 'frequency_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'frequency_30') / info.rows.length, undefined, 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.engagement_score.name,
        accessor: 'engagement_score',
        Cell: (info: any) => (
          <TableCellTruncate
            value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_eng_score : info.value, '0.[00]%', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'engagement_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.average_likes.name,
        accessor: 'average_likes',
        Cell: (info: any) => (
          <TableCellTruncate value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_likes : info.value, '0,0')} />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'average_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.average_comments.name,
        accessor: 'average_comments',
        Cell: (info: any) => (
          <TableCellTruncate
            value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_comments : info.value, '0,0')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'average_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.avg_video_duration.name,
        accessor: 'avg_video_duration',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_video_duration'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.avg_video_month.name,
        accessor: 'avg_video_month',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_video_month'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.short_views.name,
        accessor: 'short_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.short_comments.name,
        accessor: 'short_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.short_likes.name,
        accessor: 'short_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.short_count.name,
        accessor: 'short_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_count'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.short_true_reach.name,
        accessor: 'short_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_true_reach') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.short_eng_score.name,
        accessor: 'short_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_eng_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.live_views.name,
        accessor: 'live_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.live_comments.name,
        accessor: 'live_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.live_likes.name,
        accessor: 'live_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.live_count.name,
        accessor: 'live_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_count'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.live_true_reach.name,
        accessor: 'live_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_true_reach') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.live_eng_score.name,
        accessor: 'live_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_eng_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.video_views.name,
        accessor: 'video_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.video_comments.name,
        accessor: 'video_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.video_likes.name,
        accessor: 'video_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.video_count.name,
        accessor: 'video_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_count'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.video_true_reach.name,
        accessor: 'video_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_true_reach') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.video_eng_score.name,
        accessor: 'video_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_eng_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.youtube.language.name,
        accessor: 'language',
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
        width: 130,
      },
      {
        Header: platformClientConfig.youtube.country.name,
        accessor: 'country',
        Cell: ({ value }: any) => <CountryFullName country={value} />,
        width: 130,
      },
      // {
      //   Header: platformClientConfig.youtube.topics.name,
      //   accessor: 'topics',
      // width: 130,
      // },
      {
        Header: platformClientConfig.youtube.categories.name,
        accessor: 'categories',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      // {
      //   Header: platformClientConfig.youtube.keywords.name,
      //   accessor: 'keywords',
      // width: 130,
      // },
      {
        Header: platformClientConfig.youtube.top_tags.name,
        accessor: 'top_tags',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformClientConfig.youtube.youtube_games.name,
        accessor: 'youtube_games',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformClientConfig.youtube.youtube_games_longterm.name,
        accessor: 'youtube_games_longterm',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformClientConfig.youtube.comments.name,
        accessor: 'comments',
        Cell: (row: any) => <ChatTableView row={row} from="client" />,
        width: 130,
        className: 'justify-center',
        sortType: numberSort,
      },
    ].map((d: any) => ({
      ...d,
      tooltip: getTooltip('table', 'youtube', d.accessor),
    })),
    twitch: [
      {
        Header: platformClientConfig.twitch.channel_name.name,
        accessor: 'channel_name',
        Cell: ({ row, value }: any) => (
          <div className="flex flex-1 items-center justify-between overflow-hidden">
            <div className="flex items-center truncate space-x-2 overflow-hidden">
              <TableCellTruncate
                linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
                value={value}
              />
              <a href={row.original.channel_url} target="_blank" rel="noopener">
                <LinkIcon />
              </a>
            </div>
            {getTag(row.original, 'full_label') ? (
              <div className={cn('flex items-center justify-center px-3 rounded bg-gray-g1')}>
                <p className="text-[10px] leading-4 text-gray-gd">{getTag(row.original, 'full_label')}</p>
              </div>
            ) : null}
          </div>
        ),
        Footer: 'Total',
        width: 300,
        sortType: localeSort,
      },
      {
        Header: platformClientConfig.twitch.channel_description.name,
        accessor: 'channel_description',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformClientConfig.twitch.approval.name,
        accessor: 'approval',
        Cell: ({ row, value }: any) => (
          <FormatSelect
            value={value}
            options={approvalSelectedStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignSelectedChannelClientThunk({
                    campaign_id: campaignId || '',
                    selected_id: row.original.selected_id,
                    data: {
                      approval: value,
                    },
                  })
                );
            }}
          />
        ),
        width: 130,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, approvalSelectedStatuses),
      },
      {
        Header: platformClientConfig.twitch.outreach_status.name,
        accessor: platformClientConfig.twitch.outreach_status.id,
        Cell: ({ value }: any) => <FormatSelect isDisabled={true} noDropdownIndicator={true} value={value} options={outreachStatuses} onChange={() => null} />,
        width: 185,
        minWidth: 185,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, outreachStatuses),
      },
      {
        Header: platformClientConfig.twitch.content_format.name,
        accessor: 'content_format',
        Cell: ({ value }: any) => (
          <FormatSelect isDisabled={true} noDropdownIndicator={true} value={value} options={contentFormatStatuses} onChange={() => null} />
        ),
        width: 150,
        minWidth: 150,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contentFormatStatuses),
      },
      {
        Header: platformClientConfig.twitch.theme.name,
        accessor: platformClientConfig.twitch.theme.id,
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformClientConfig.twitch.price.name,
        accessor: 'price',
        Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.cpm.name,
        accessor: 'cpm',
        Cell: ({ row }: any) => (
          <TableCellTruncate
            value={formatNumber(getCpm(row.original.price, getCpmSelectedDenominator(row.original), row.original.sm_platform), '$0,0.[00]', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.followers.name,
        accessor: 'followers',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'followers'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      // {
      //   Header: platformClientConfig.twitch.total_views.name,
      //   accessor: 'total_views',
      //   Cell: (row: any) => formatNumber(row.value),
      // },
      {
        Header: platformClientConfig.twitch.days_week_30.name,
        accessor: 'days_week_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'days_week_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.aaccv_20.name,
        accessor: 'aaccv_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'aaccv_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.average_peak_20.name,
        accessor: 'average_peak_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'average_peak_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.median_accv_60.name,
        accessor: 'median_accv_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_accv_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.median_accv_30.name,
        accessor: 'median_accv_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_accv_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.median_accv_20.name,
        accessor: 'median_accv_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_accv_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.median_peak_60.name,
        accessor: 'median_peak_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_peak_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.median_peak_30.name,
        accessor: 'median_peak_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_peak_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.median_peak_20.name,
        accessor: 'median_peak_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_peak_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.average_stream_duration_20.name,
        accessor: 'average_stream_duration_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'average_stream_duration_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.avg_streams_month.name,
        accessor: 'avg_streams_month',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_streams_month') / info.rows.length, undefined, 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.hours_live_30.name,
        accessor: 'hours_live_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'hours_live_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.viewer_hours_30.name,
        accessor: 'viewer_hours_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'viewer_hours_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.daily_broadcast_30.name,
        accessor: 'daily_broadcast_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(+row.value / 3600 || 0, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'daily_broadcast_30') / 3600, '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformClientConfig.twitch.language.name,
        accessor: 'language',
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
        width: 130,
      },
      {
        Header: platformClientConfig.twitch.recently_streamed_games.name,
        accessor: 'recently_streamed_games',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 130,
      },
      {
        Header: platformClientConfig.twitch.games_longterm.name,
        accessor: 'games_longterm',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 130,
      },
      {
        Header: platformClientConfig.twitch.last_seen.name,
        accessor: 'last_seen',
        Cell: ({ value }: any) => <TableCellTruncate value={moment(value).format('MM-DD-YYYY')} />,
        width: 130,
      },
      {
        Header: platformClientConfig.twitch.comments.name,
        accessor: 'comments',
        Cell: (row: any) => <ChatTableView row={row} from="client" />,
        width: 130,
        className: 'justify-center',
        sortType: numberSort,
      },
    ].map((d: any) => ({
      ...d,
      tooltip: getTooltip('table', 'twitch', d.accessor),
    })),
  };
};

const makeColumnHiddenCheckboxes = (props: any): any[] => {
  const arr: any[] = [];

  platformColumns(props)[props.platform].forEach((d: any) => {
    if (platformClientConfig[props.platform][d.accessor].role === 'main')
      arr.push({
        id: platformClientConfig[props.platform][d.accessor].id,
        label: platformClientConfig[props.platform][d.accessor].name,
        active: true,
      });
  });

  return arr;
};

interface ISelectedProps {}

export const Selected: FC<ISelectedProps> = () => {
  const { campaignId } = useParams();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaignClient);
  const selectedChannels = useAppSelector(selectCampaignSelectedChannelsByPlatformClient);
  const selectedChannelsCount = useAppSelector(selectCampaignSelectedChannelsCountClient);
  const platform = useAppSelector(selectCampaignSelectedSettingsPlatformClient);
  const searchValue = useAppSelector(selectCampaignSelectedSettingsSearchValueClient);
  const filters = useAppSelector(selectCampaignSelectedSettingsFiltersClient);
  const tableFilters = useAppSelector(selectCampaignSelectedSettingsTableFilterClient);
  const hiddenColumns = useAppSelector(selectCampaignSelectedSettingsTableFilterHiddenColumnsClient);

  const platformSelectOptions = useSMPlatforms(campaign);

  const handleChangeSelect = (props: any) =>
    dispatch(
      campaignClientActions.setSelectedSettings({
        tableFilters: {
          ...tableFilters,
          [platform]: {
            ...tableFilters[platform],
            hiddenColumns: props,
          },
        },
      })
    );

  const handleSelectChange = (newValue: SingleValue<any>, _platform: Platform) => {
    dispatch(
      campaignClientActions.setSelectedSettings({
        platform: newValue.value,
      })
    );
    dispatch(
      fetchCampaignSelectedChannelsClientThunk({
        campaign_id: campaignId || '',
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          campaignClientActions.setSelectedSettings({
            tableFilters: {
              ...tableFilters,
              [newValue.value]: {
                ...tableFilters[newValue.value as Platform],
                hiddenColumns: !tableFilters[newValue.value as Platform].hiddenColumns
                  ? makeColumnHiddenCheckboxes({
                      dispatch,
                      campaignId,
                      platform: newValue.value,
                    })
                  : tableFilters[newValue.value as Platform].hiddenColumns,
              },
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          campaignClientActions.setSelectedSettings({
            platform: _platform,
            tableFilters: {
              ...tableFilters,
              [_platform]: {
                ...tableFilters[_platform],
                hiddenColumns: !tableFilters[_platform].hiddenColumns
                  ? makeColumnHiddenCheckboxes({
                      dispatch,
                      campaignId,
                      platform: _platform,
                    })
                  : tableFilters[_platform].hiddenColumns,
              },
            },
          })
        );
      });
  };
  const isEmpty = !selectedChannels || !Boolean(selectedChannels.length);

  const columns = useMemo(() => {
    if (campaignId)
      return (
        [
          ...platformColumns({
            dispatch,
            campaignId,
          })[platform],
        ].filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || []
      );

    return [];
  }, [campaignId, hiddenColumns]);

  const hiddenColumnsOptions = useMemo(
    () =>
      platformColumns({
        dispatch,
        campaignId,
      })[platform].map((d: any) => ({
        id: d.accessor,
        name: platformClientConfig[platform][d.accessor].name,
      })),
    [campaignId, platform]
  );

  const selectedChannelsSorted = useMemo(
    () => orderBy(selectedChannels, [(d) => d.channel_name || '', (d) => d.selected_id], ['asc', 'asc']),
    [selectedChannels]
  );

  const selectedChannelsFiltered = useMemo(
    () => filterCampaignChannels(selectedChannelsSorted, { filters: filters[platform] }),
    [selectedChannelsSorted, filters, platform]
  );

  const selectedChannelsSearched = useMemo(
    () => selectedChannelsFiltered.filter((d: any) => (d.channel_name || '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1),
    [searchValue, selectedChannelsFiltered]
  );

  const exportColumns = useMemo(() => {
    const columns = platformColumns({ dispatch, campaignId, platform })
      [platform].filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action'))
      .map((d: any) => ({
        key: platformClientConfig[platform][d.accessor].id,
        header: platformClientConfig[platform][d.accessor].name,
      }));

    columns.splice(1, 0, {
      key: 'channel_url',
      header: platformClientConfig[platform]['channel_url'].name,
    });
    return columns;
  }, [campaignId, platform, hiddenColumns]);

  const exportData = useMemo(() => {
    return selectedChannelsSearched.map((d: any) => {
      const obj: any = {};
      exportColumns.forEach((dd: any) => {
        obj[dd.key] =
          Object.keys(selectedColumnsForContentFormat).includes(dd.key) && checkContentFormat(d.content_format)
            ? getColumnFieldByContentFormat[d.content_format]?.[dd.key]
              ? d[getColumnFieldByContentFormat[d.content_format][dd.key]] || 0
              : 0
            : d[dd.key];
      });
      return obj;
    });
  }, [selectedChannelsSearched, exportColumns]);

  const handleApply = (value: any) => {
    dispatch(
      campaignClientActions.setSelectedSettings({
        filters: {
          ...filters,
          [platform]: value,
        },
      })
    );
  };

  useLegacyEffect(() => {
    if (campaignId) dispatch(fetchCampaignSelectedChannelsClientThunk({ campaign_id: campaignId }));
  }, [campaignId]);

  // Initialize hiddenColumns
  useLegacyEffect(() => {
    if (!hiddenColumns)
      dispatch(
        campaignClientActions.setSelectedSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              hiddenColumns: makeColumnHiddenCheckboxes({
                dispatch,
                campaignId,
                platform,
              }),
            },
          },
        })
      );
  }, [hiddenColumns]);

  const role = useAppSelector(selectRole);

  return (
    <>
      <SimpleSearch
        className="py-4"
        placeholder="Search by selected"
        defaultValue={searchValue}
        onChange={(value: string) =>
          dispatch(
            campaignClientActions.setSelectedSettings({
              searchValue: value,
            })
          )
        }
      />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex flex-col mb-3 sm:mb-0 sm:flex-row sm:items-start sm:justify-between">
          <div>
            <h1 className="text-2xl leading-[42px] font-semibold truncate">{`Selected${selectedChannelsCount ? ` (${selectedChannelsCount})` : ''}`}</h1>
            <Breadcrumbs
              paths={[
                { name: `Campaigns`, to: `/campaigns` },
                {
                  name: `${campaign.campaign_name}`,
                  to: `/campaigns/${campaign.campaign_id}`,
                },
                {
                  name: `Selected`,
                  to: '',
                },
              ]}
            />
          </div>
          <div className="flex flex-wrap">
            <ExportMenu
              className="!items-start"
              buttonClass="mr-4"
              data={exportData}
              columns={exportColumns}
              filename={`${replaceSpaceToUnderscore(campaign.campaign_name)}_selected`}
            />
            <HSelect menuClass="w-44" selected={hiddenColumns} options={hiddenColumnsOptions} onChange={handleChangeSelect}>
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'mr-4 mb-3 inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                    open ? 'bg-blue-b1' : 'bg-white'
                  )}
                >
                  <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </HSelect>
            <div className="relative">
              <RSelect
                className="w-[150px]"
                styles={platformSelectStyles}
                defaultValue={platformSelectOptions[platform]}
                name="select-channels"
                isSearchable={false}
                isClearable={false}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                menuShouldScrollIntoView={false}
                options={Object.keys(platformSelectOptions)
                  .filter((d: any) => d !== platform)
                  .map((d: any) => platformSelectOptions[d])}
                components={{
                  ClearIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Option: PlatformSelectOption,
                  SingleValue: PlatformSelectCustomSingleValue,
                }}
                onChange={(newValue: SingleValue<any>) => handleSelectChange(newValue, platform)}
              />
              {selectedChannelsSearched.length ? (
                <span className="absolute -top-2 -right-2 min-w-[1.5rem] h-[1.5rem] inline-flex items-center justify-center rounded-full px-1.5 text-xs font-medium text-blue-b1 bg-[#ece8fd]">
                  {selectedChannelsSearched.length}
                </span>
              ) : null}
            </div>
          </div>
        </div>

        {isEmpty ? (
          <SelectedEmpty />
        ) : (
          <>
            {/* <CampaignSelectedSettings
              platform={platform}
              searchChannels={{ filters }}
              role={role}
              handleSubmit={handleApply}
            /> */}

            <div className="mt-4 flex flex-col overflow-hidden">
              <div className="flex flex-col min-w-full align-middle overflow-hidden">
                <Table columns={columns} data={selectedChannelsSearched} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface ITableProps {
  data: any[];
  columns: any[];
}

const Table: FC<ITableProps> = memo(
  ({ columns, data }) => {
    const dispatch = useAppDispatch();
    const platform = useAppSelector(selectCampaignSelectedSettingsPlatformClient);
    const tableFilters = useAppSelector(selectCampaignSelectedSettingsTableFilterClient);
    const tfSortBy = useAppSelector(selectCampaignSelectedSettingsTableFilterSortByClient);
    const tfResizing = useAppSelector(selectCampaignSelectedSettingsTableFilterResizingClient);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      state: { sortBy, columnResizing },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { sortBy: tfSortBy },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      useSortBy,
      useResizeColumns,
      useBlockLayout
    );

    // TODO make better update tableFilters. May be use the getSortByToggleProps hook to extend its functionality
    useLegacyEffect(() => {
      dispatch(
        campaignClientActions.setSelectedSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              sortBy,
            },
          },
        })
      );
    }, [sortBy]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          campaignClientActions.setSelectedSettings({
            tableFilters: {
              ...tableFilters,
              [platform]: {
                ...tableFilters[platform],
                resizing: columnResizing.columnWidths,
              },
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          cell.column.id !== 'action' && cell.column.id !== 'approval' && cell.column.id !== 'content_format' ? 'truncate' : '',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);
