import { STORAGE, SESSION_TOKEN, USER_INFO } from './constants';

class User {
  isAuth() {
    return STORAGE.getItem(SESSION_TOKEN);
  }

  login(data: any) {
    if (data) {
      STORAGE.setItem(SESSION_TOKEN, data.token);
      STORAGE.setItem(USER_INFO, JSON.stringify(data));
    }
  }

  updateUserInfo(data: any) {
    if (data) {
      STORAGE.setItem(USER_INFO, JSON.stringify({ ...this.info, ...data }));
    }
  }

  logout() {
    STORAGE.removeItem(SESSION_TOKEN);
    STORAGE.removeItem(USER_INFO);

    // storage.clean()
  }

  get token() {
    return STORAGE.getItem(SESSION_TOKEN);
  }

  get info() {
    return JSON.parse(`${STORAGE.getItem(USER_INFO) || '{}'}`);
  }

  get userId() {
    return this.info.user_id;
  }

  get role() {
    return this.info.role;
  }

  get avatarUrl() {
    return this.info.avatar_url;
  }
}

export default new User();
