import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from 'utils/validation';

interface IFormProps {
  children: (props: any) => ReactNode;
  onSubmit: (data: any, e: any, formState: any) => void;
  className?: string;
  defaultValues?: any;
  formOptions?: any;
  validationType?: string;
}

export const Form: FC<IFormProps> = ({ children, className, defaultValues = {}, formOptions = {}, validationType = '', onSubmit }) => {
  const resolver = useMemo(
    () => (validationType && validationSchema[validationType] ? { resolver: yupResolver(validationSchema[validationType]) } : {}),
    [validationType]
  );

  const formProps = useForm({
    defaultValues,
    ...resolver,
    ...formOptions,
  });
  const { handleSubmit, reset } = formProps;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <form className={className} onSubmit={handleSubmit((values: any, event: any) => onSubmit(values, event, { ...formProps }))}>
      {children({ ...formProps, onSubmit })}
    </form>
  );
};
