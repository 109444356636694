import { FC } from 'react';
import cn from 'classnames';
import { ModalState } from 'components/Modal/reducer';
import { ClipLoader, Form } from 'shared';

interface IDeleteConfirmationProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const DeleteConfirmation: FC<IDeleteConfirmationProps> = ({ params, hideModal }) => {
  return (
    <div className="flex h-full flex-col">
      <div className="p-6 sm:p-8">
        <div className="relative flex">
          <p className="text-base text-black-b1">
            Are you sure you want to delete <span className="font-bold">{params.name || ''}</span>?
          </p>
        </div>
      </div>
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={{}}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params.handleDelete().then(() => hideModal());
        }}
      >
        {({ formState: { isSubmitting } }) => (
          <>
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center uppercase justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-red-r1 py-2 px-4 hover:bg-red-r1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                <p>Delete</p>
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
