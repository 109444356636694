import { FC, useMemo } from 'react';
import cn from 'classnames';
import { Form, Label, ClipLoader, Textarea } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';

interface IEditInfluencerDescriptionProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const EditInfluencerDescription: FC<IEditInfluencerDescriptionProps> = ({ params, hideModal }) => {
  const defaultValues: any = useMemo(
    () => ({
      channel_description: params?.influencer?.channel_description || '',
    }),
    [params?.influencer]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title="Update influencer description" close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        // validationType={ValidationTypes.AdminAddInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params.handleSave(values).then(() => hideModal());
        }}
      >
        {({ register, control, watch, setValue, formState }) => {
          const { errors, isSubmitting } = formState;
          return (
            <>
              <div>
                <Label htmlFor="channel_description" label="Channel description" error={errors.channel_description} />
                <div className="mt-0.5">
                  <Textarea id="channel_description" name="channel_description" rows={8} placeholder="Enter..." {...register('channel_description')} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <button
                  type="button"
                  className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  <p>Save</p>
                  <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                </button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
