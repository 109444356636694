import { FC, forwardRef } from 'react';
import Select, { components, DropdownIndicatorProps, StylesConfig } from 'react-select';
import { colors } from 'variables';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon } from 'assets/icons';

export const RSelectStyles: StylesConfig<any, false> = {
  control: (styles, { isFocused, selectProps: { error, isDisabled } }: any) => ({
    ...styles,
    minHeight: '42px',
    backgroundColor: isDisabled ? colors.gray.g1 : 'white',
    fontSize: '12px',
    color: isDisabled ? colors.gray.gm : colors.black.b1,
    opacity: isDisabled ? 0.75 : 1,
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    boxShadow: 'none',
    '&:hover': {
      borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    },
  }),
  input: (styles) => ({
    ...styles,
    '& > input': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  singleValue: (styles) => ({ ...styles, marginLeft: 0, marginRight: 0 }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.gray.gm,
    fontSize: '12px',
    marginLeft: 0,
    marginRight: 0,
  }),
  option: (styles) => ({
    ...styles,
    padding: '6px 16px',
    fontSize: '14px',
    color: colors.black.b1,
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 1000,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: '10px 0' }),
  valueContainer: (styles) => ({ ...styles, paddingLeft: '12px' }),
};

export const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  const { menuIsOpen } = props.selectProps;
  return <components.DropdownIndicator {...props}>{menuIsOpen ? <ArrowUpDropdownSolidIcon /> : <ArrowDownDropdownSolidIcon />}</components.DropdownIndicator>;
};

interface IRSelectProps {
  name?: string;
  [prop: string]: any;
}

export const RSelect: FC<IRSelectProps> = forwardRef(({ name = 'react-select', ...rest }, ref: any) => {
  return (
    <Select
      ref={ref}
      styles={{ ...RSelectStyles, ...(rest.styles || {}) }}
      name={name}
      menuPlacement="auto"
      {...rest}
      components={{
        // ...animatedComponents, // width: auto not working with animation
        DropdownIndicator,
        ...(rest.components || {}),
      }}
    />
  );
});
