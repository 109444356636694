import { FC } from 'react';
import { useAppDispatch } from 'hooks/store';
import { createCampaignThunk } from '../reducer';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { NoCampaignsIcon } from 'assets/icons';
import { PlusIcon } from '@heroicons/react/24/outline';

export const NoCampaigns: FC = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col items-center">
      <NoCampaignsIcon />
      <h1 className="mt-6 text-2xl leading-[42px] font-semibold text-black-b1">No created campaigns yet</h1>
      <div className="mt-9">
        <button
          type="button"
          className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-0"
          onClick={() =>
            dispatch(
              showModal({
                type: ModalTypes.CreateCampaign,
                params: {
                  handleSave: async (data: any) => await dispatch(createCampaignThunk(data)),
                },
              })
            )
          }
        >
          <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          Create new campaign
        </button>
      </div>
    </div>
  );
};
