import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IGetCampaigns, getCampaignsApi } from 'services';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

interface ICampaignsClient {
  campaigns: any | undefined;
  loading: Loading;
}

const initialState: ICampaignsClient = {
  campaigns: undefined,
  loading: defaultLoading,
};

const slice = createSlice({
  name: 'campaigns-client',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignsClientThunk.pending, (state, { meta }: any) => {
        if (!meta.arg?.thunk?.noLoading) state.loading.is = true;
      })
      .addCase(fetchCampaignsClientThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CAMPAIGNS CLIENT', payload);

        state.campaigns = payload;
        state.loading.is = false;
      })
      .addCase(fetchCampaignsClientThunk.rejected, (state) => {
        console.log('REJECTED FETCH CAMPAIGNS CLIENT');

        state.loading.is = false;
      });
  },
});

export const campaignsClientActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchCampaignsClientThunk = createAsyncThunk('fetch_campaigns_client_thunk', async (args: (IGetCampaigns & { thunk?: any }) | void) => {
  const response: any = await getCampaignsApi({
    page: args?.page || 1,
    order_by: args?.order_by || '',
    order_dir: args?.order_dir || 'desc',
    search: args?.search || '',
  });

  if (typeof response === 'string') throw new Error();

  return response;
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.campaignsClient;

export const selectCampaignsDataClient = createSelector(selectState, (state) => state.campaigns);

const campaigns: any = undefined;

export const selectCampaignsClient = createSelector(selectCampaignsDataClient, (state) => state?.campaigns || campaigns);

export const selectCampaignsPagesCountClient = createSelector(selectCampaignsDataClient, (state) => state?.pages_count || 1);

export const selectCampaignsCountClient = createSelector(selectCampaignsDataClient, (state) => state?.campaigns_count || 0);

export const selectCampaignsOrderByClient = createSelector(selectCampaignsDataClient, (state) => state?.order_by || '');

export const selectCampaignsOrderDirClient = createSelector(selectCampaignsDataClient, (state) => state?.order_dir || '');

export const selectCampaignsClientLoading = createSelector(selectState, (state) => state.loading);

export const selectCampaignsClientLoadingToHeader = createSelector(selectCampaignsClientLoading, (loading) => Boolean(loading.is && !loading.id));
