import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useNavigate } from 'react-router-dom';
import { useQueryLocation } from 'hooks/useQueryLocation';
import { useBlockLayout, useResizeColumns, useTable } from 'react-table';
import moment from 'moment';
import cn from 'classnames';
import { colors } from 'variables';
import { MenuActionWithFloat, TableHeaderSort, TableResizer } from 'shared';
import {
  duplicateCampaignThunk,
  deleteCampaignThunk,
  fetchCampaignsThunk,
  selectCampaignsOrderBy,
  selectCampaignsOrderDir,
  updateCampaignThunk,
  selectCampaignsSettingsTableFilterResizing,
  campaignsActions,
  selectCampaignsSettingsTableFilter,
} from '../reducer';
import { selectClients } from 'pages/Admin/reducer';
import { Pagination } from './Pagination';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { DotsIcon, TwitchSmallTableIcon, YoutubeSmallTableIcon } from 'assets/icons';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { isEqual } from 'lodash';

const statusConfig: any = {
  active: {
    label: 'Active',
    bg: 'bg-yellow-y1',
  },
  completed: {
    label: 'Completed',
    bg: 'bg-green-g1',
  },
};

interface ICampaignsTableProps {
  campaigns: any[];
}

export const CampaignsTable: FC<ICampaignsTableProps> = ({ campaigns }) => {
  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectClients);

  const transformClients = useMemo(() => {
    let clientObj: any = {};

    if (clients.length)
      clients.forEach((client: any) => {
        clientObj[client.client_id] = { ...client };
      });
    return clientObj;
  }, [clients]);

  const columns = useMemo(
    () => [
      {
        Header: 'Campaign Name',
        accessor: 'campaign_name',
        width: 250,
        Cell: ({ value }: any) => (
          <p className="truncate" title={value}>
            {value}
          </p>
        ),
      },
      {
        Header: 'Client Name',
        accessor: 'client_id',
        width: 250,
        disableSortBy: true,
        Cell: ({ value }: any) => {
          const _value = transformClients[value] ? transformClients[value].client_name : value;
          return (
            <p className="truncate" title={_value}>
              {_value}
            </p>
          );
        },
      },
      {
        Header: 'Product',
        accessor: 'product',
        width: 250,
        Cell: ({ value }: any) => (
          <p className="truncate" title={value}>
            {value}
          </p>
        ),
      },
      {
        Header: 'Manager Name',
        accessor: 'manager_full_name',
        width: 200,
        Cell: ({ row, value }: any) => (
          <p className="truncate" title={value || row.original.manager_id}>
            {value || row.original.manager_id}
          </p>
        ),
      },
      {
        Header: 'SM Platform',
        accessor: 'sm_platform',
        width: 120,
        Cell: ({ value }: any) => (
          <div className="flex space-x-3 pl-3">
            <YoutubeSmallTableIcon className="shrink-0" pathFill={value != null && value.includes('youtube') ? '#FF0000' : colors.gray.g2} />
            <TwitchSmallTableIcon className="shrink-0" pathFill={value != null && value.includes('twitch') ? '#64449E' : colors.gray.g2} />
          </div>
        ),
      },
      {
        // Header: () => (
        //   <Menu
        //     optionClass="!px-2"
        //     items={[
        //       {
        //         label: 'Sort Ascending',
        //         iconComponent: ArrowDownLong8x10Icon,
        //         onClick: () => {},
        //       },
        //       {
        //         label: 'Sort Descending',
        //         iconComponent: ArrowUpLong8x10Icon,
        //         onClick: () => {},
        //       },
        //     ].filter(Boolean)}
        //   >
        //     {({ open }) => (
        //       <div
        //         className={cn(
        //           'flex items-center cursor-pointer rounded-[4px] -ml-2 pl-2',
        //           open ? 'bg-blue-b1 text-white' : 'text-black-b1'
        //         )}
        //       >
        //         <p className="group mr-1 truncate">Launch Dates</p>
        //         <SortIcon
        //           className="shrink-0"
        //           fill={open ? colors.white.default : colors.black.b1}
        //           aria-hidden="true"
        //         />
        //       </div>
        //     )}
        //   </Menu>
        // ),
        Header: 'Launch Dates',
        accessor: 'launch_date',
        width: 130,
        Cell: ({ value }: any) => (value ? moment(value).format('DD MMM YYYY') : ''),
      },
      {
        // Header: () => {
        //   const options = useMemo(
        //     () =>
        //       Object.keys(statusConfig).map((d: any, i: number) => ({
        //         id: i,
        //         name: d,
        //         Name: () => (
        //           <div className="flex items-center">
        //             <span
        //               className={cn(
        //                 'mr-2 w-2 h-2 rounded-full',
        //                 `${statusConfig[d].bg}`
        //               )}
        //             ></span>
        //             <p className="text-xs">{statusConfig[d].label}</p>
        //           </div>
        //         ),
        //       })),
        //     []
        //   )
        //   return (
        //     <HSelect
        //       menuClass="!origin-top-left !right-auto top-6 -left-2"
        //       menuListClass="!py-1"
        //       optionClass="!px-2.5"
        //       options={options}
        //       onChange={(props: any) => console.log('select change', props)}
        //     >
        //       {({ open }) => (
        //         <div
        //           className={cn(
        //             'flex items-center cursor-pointer pointer-events-none rounded-[4px] -ml-2 pl-2',
        //             open ? 'bg-blue-b1 text-white' : 'text-black-b1'
        //           )}
        //         >
        //           <p className="group inline-flex mr-1">Status</p>
        //           <ArrowDownSolidIcon
        //             fill={open ? colors.white.default : colors.black.b1}
        //             aria-hidden="true"
        //           />
        //         </div>
        //       )}
        //     </HSelect>
        //   )
        // },
        Header: 'Status',
        accessor: 'status',
        width: 130,
        className: 'w-[130px] pr-0',
        Cell: ({ value }: any) => (
          <div className="flex items-center">
            <span className={cn('mr-2 w-2 h-2 rounded-full shrink-0', `${value ? statusConfig[value].bg : ''}`)}></span>
            <p className="truncate" title={value ? statusConfig[value].label : ''}>
              {value ? statusConfig[value].label : ''}
            </p>
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'action',
        width: 35,
        disableSortBy: true,
        className: 'sticky right-0 pl-0 py-0 bg-gradient-to-l from-white via-white to-transparent opacity-0 group-hover:opacity-100',
        Cell: ({ row }: any) => (
          <div
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <MenuActionWithFloat
              className="flex"
              optionClass="!px-2.5"
              items={[
                {
                  label: 'Edit',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.CreateCampaign,
                        params: {
                          campaign: row.original,
                          handleSave: async (data: any) =>
                            await dispatch(
                              updateCampaignThunk({
                                campaign_id: row.original.campaign_id,
                                data,
                              })
                            ),
                        },
                      })
                    ),
                },
                {
                  label: 'Duplicate',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.CreateCampaign,
                        params: {
                          campaign: row.original,
                          duplicate: true,
                          handleSave: async (data: any) =>
                            await dispatch(
                              duplicateCampaignThunk({
                                campaign_id: row.original.campaign_id,
                                data: data,
                              })
                            ),
                        },
                      })
                    ),
                },
                {
                  label: 'Delete',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.DeleteConfirmation,
                        params: {
                          name: row.original.campaign_name,
                          handleDelete: async () => await dispatch(deleteCampaignThunk(row.original.campaign_id)),
                        },
                      })
                    ),
                },
              ]}
            >
              {({ open }) => (
                <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                  <DotsIcon aria-hidden="true" />
                </span>
              )}
            </MenuActionWithFloat>
          </div>
        ),
      },
    ],
    [transformClients]
  );

  // const campaignsSorted = useMemo(
  //   () => orderBy(campaigns, (d) => moment(d.created_at), ['desc']),
  //   [campaigns]
  // )

  return (
    <div className="mt-5 flex flex-1 flex-col justify-between overflow-hidden">
      <div className="mb-2 flex flex-col overflow-hidden">
        <div className="flex flex-col min-w-full align-middle overflow-hidden">
          <div className="overflow-auto">
            <Table columns={columns} data={campaigns} />
          </div>
        </div>
      </div>
      <Pagination />
    </div>
  );
};

function Table({ columns, data }: any) {
  const navigate = useNavigate();
  const query = useQueryLocation();
  const searchPage = query.get('page');

  const dispatch = useAppDispatch();
  const orderBy = useAppSelector(selectCampaignsOrderBy);
  const orderDir = useAppSelector(selectCampaignsOrderDir);
  const tableFilters = useAppSelector(selectCampaignsSettingsTableFilter);
  const tfResizing = useAppSelector(selectCampaignsSettingsTableFilterResizing);

  const page = searchPage == null ? 1 : +searchPage;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    state: { columnResizing },
  } = useTable(
    {
      columns,
      data,
      useControlledState: (state) => {
        return useMemo(() => {
          return {
            ...state,
            columnResizing: {
              // @ts-ignore
              ...state?.columnResizing,
              // @ts-ignore
              columnWidths: state?.columnResizing?.headerIdWidths
                ? // @ts-ignore
                  {
                    ...tfResizing,
                    // @ts-ignore
                    ...state.columnResizing?.columnWidths,
                  }
                : tfResizing,
            },
          };
          // @ts-ignore
        }, [state, tfResizing]);
      },
    },
    useBlockLayout,
    useResizeColumns
  );

  useLegacyEffect(() => {
    if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
      dispatch(
        campaignsActions.setSettings({
          tableFilters: {
            ...tableFilters,
            resizing: columnResizing.columnWidths,
          },
        })
      );
  }, [columnResizing?.columnWidths]);

  const handleSort = (order_by: string, order_dir: string | 'asc' | 'desc') => {
    dispatch(fetchCampaignsThunk({ page, order_by, order_dir }));
  };

  const checkOrderDir = (column: string) => {
    return orderBy === column && orderDir === 'desc' ? 'asc' : orderDir === 'asc' ? '' : 'desc';
  };

  // Render the UI for your table
  return (
    <table {...getTableProps()} className="min-w-full w-full rounded-[4px]">
      <thead className="sticky top-0 z-30 bg-gray-g1">
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="py-1.5 pl-6">
            {headerGroup.headers.map((column: any, hidx: number) => (
              <th
                {...column.getHeaderProps()}
                className={cn(
                  'text-left text-xs font-normal text-black-b1',
                  typeof column.render('Header') === 'string' ? 'truncate' : '',
                  hidx === 0 ? 'pl-0 pr-2' : 'px-2',
                  column?.headerClassName || ''
                )}
              >
                <div
                  title={typeof column.Header === 'string' ? column.Header : ''}
                  className="h-[18px] flex items-center space-x-3 cursor-pointer"
                  onClick={() => {
                    if (!column.disableSortBy) handleSort(orderBy === column.id && !checkOrderDir(column.id) ? '' : column.id, checkOrderDir(column.id));
                  }}
                >
                  <p>{column.render('Header')}</p>
                  <TableHeaderSort column={column} isSorted={orderBy === column.id} isSortedDesc={orderDir === 'desc'} activeFill={colors.black.b1} />
                </div>

                <TableResizer column={column} />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="space-y-3">
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="relative group pl-6 rounded-[4px] bg-white cursor-pointer hover:shadow-s1"
              onClick={() =>
                navigate(`${row.original.campaign_id}`, {
                  state: {
                    campaignName: row.original.campaign_name,
                  },
                })
              }
            >
              {row.cells.map((cell: any, cidx: number) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={cn(
                      'relative !flex items-center h-[58px] whitespace-nowrap text-sm leading-6 font-normal text-black-b1',
                      cidx === 0 ? 'pl-0 pr-2' : 'px-2',
                      cell.column?.className || ''
                    )}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
