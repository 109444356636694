import { useMemo } from 'react';

export const useUserInitials = (initials: string | null) => {
  return useMemo(() => {
    const _match = (initials || 'Unknown').match(/(^\S\S?|\s\S)?/g) || [];
    if (_match !== null) {
      const __match = _match
        .map((v) => v.trim())
        .join('')
        .match(/(^\S|\S$)?/g);

      if (__match !== null) return __match.join('').toLocaleUpperCase();
    }
    return '';
  }, [initials]);
};
