import { FC } from 'react';
import { NoCampaignsIcon } from 'assets/icons';

export const NoCampaigns: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <NoCampaignsIcon />
      <h1 className="mt-6 text-2xl leading-[42px] font-semibold text-black-b1">No created campaigns yet</h1>
    </div>
  );
};
