import { FC, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useQueryLocation } from 'hooks/useQueryLocation';
import { fetchChannelsThunk, influencersActions, selectPlatform, selectSearchChannels } from './reducer';
import { Tabs } from 'shared';
import { InfluencersTab } from './tabs/InfluencersTab';
import { SearchesTab } from './tabs/SearchesTab';
import { HeaderSearchWithSettings } from 'components/HeaderSearchWithSettings';
import { SimpleSearch } from 'components/SimpleSearch';

export const Influencers: FC = () => {
  const dispatch = useAppDispatch();
  const platform = useAppSelector(selectPlatform);
  const searchChannels = useAppSelector(selectSearchChannels);
  const query = useQueryLocation();
  const searchTab = query.get('tab');

  const [searchValue, setSearchValue] = useState<string>('');

  const tabs = useMemo(
    () => [
      {
        query: 'influencers',
        title: 'Influencers',
        content: <InfluencersTab />,
      },
      {
        query: 'saved_list',
        title: 'Saved lists',
        content: <SearchesTab searchValue={searchValue} />,
      },
    ],
    [searchValue]
  );

  const handleSubmit = (props: any) => {
    dispatch(
      influencersActions.setSearchChannels({
        ...searchChannels,
        advancesFilters: {
          ...searchChannels.advancesFilters,
          [platform]: props,
        },
      })
    );
    dispatch(fetchChannelsThunk());
  };

  return (
    <>
      {searchTab === 'saved_list' ? (
        <SimpleSearch placeholder="Search by keywords" size="lg" iconPlace="right" onChange={(value: string) => setSearchValue(value)} />
      ) : (
        <HeaderSearchWithSettings
          size="lg"
          value={searchChannels.keywords || []}
          platform={platform}
          searchSettings={searchChannels}
          advancesFilters={searchChannels.advancesFilters[platform] || {}}
          onChange={(value: string[], searchBy: string, searchMode: string, from?: string) => {
            dispatch(
              influencersActions.setSearchChannels({
                keywords: value,
                searchBy,
                searchMode,
              })
            );
            if (from === 'searchBy' && !value.length) {
            } else dispatch(fetchChannelsThunk());
          }}
          onSubmit={handleSubmit}
        />
      )}

      <Tabs tabs={tabs} />
    </>
  );
};
