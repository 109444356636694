import { configureStore } from '@reduxjs/toolkit';
import appReducer from 'app/reducer';
import authReducer from 'pages/Auth/reducer';
import influencersReducer from 'pages/Influencers/reducer';
import usersReducer from 'pages/Admin/reducer';
import agenciesReducer from 'pages/Admin/agenciesReducer';
import campaignsReducer from 'pages/Campaigns/reducer';
import campaignReducer from 'pages/Campaign/reducer';
import modalReducer from 'components/Modal/reducer';
import searchReducer from 'components/Search/reducer';
import chatReducer from 'components/Chat/reducer';

// CLIENT

import campaignsClientReducer from 'pages-client/Campaigns/reducer';
import campaignClientReducer from 'pages-client/Campaign/reducer';

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    influencers: influencersReducer,
    users: usersReducer,
    campaigns: campaignsReducer,
    campaign: campaignReducer,
    modal: modalReducer,
    search: searchReducer,
    chat: chatReducer,
    agencies: agenciesReducer,

    // CLIENT

    campaignsClient: campaignsClientReducer,
    campaignClient: campaignClientReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
