import { FC } from 'react';
import { useCountriesAsObject } from 'hooks/useCountries';
import { TableCellTruncate } from 'shared';

interface ICountryFullNameProps {
  country: string;
  className?: string;
}

export const CountryFullName: FC<ICountryFullNameProps> = ({ country = '', className = '' }) => {
  const countries = useCountriesAsObject();

  const _country = countries?.[country]?.name || country || '';

  return <TableCellTruncate value={_country} className={className} />;
};
