import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { HeaderLoader } from './HeaderLoader';
import { Bars3Icon } from 'assets/icons';

import logo from 'assets/logo/Cloutboost_Horizontal_logo_(White)@4x.png';

export const headerNavigation = [
  { name: 'About', to: 'https://www.cloutboost.com/about' },
  { name: 'Blog', to: 'https://www.cloutboost.com/blog' },
  { name: 'Contact', to: 'https://www.cloutboost.com/blog#footer' },
];

interface IHeaderProps {
  setSidebarOpen: (value: boolean) => void;
}

export const Header: FC<IHeaderProps> = ({ setSidebarOpen }) => {
  return (
    <nav className="bg-header bg-no-repeat" style={{ backgroundSize: '100% 100%' }}>
      <div className="mx-auto max-w-full px-4 lg:px-6">
        <div className="relative flex h-[72px] items-center justify-between">
          <HeaderLoader />
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            {/* Mobile menu button*/}
            <button type="button" className="-ml-1.5 p-2.5 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6 rotate-90" pathFill="#ffffff" aria-hidden="true" />
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-between sm:ml-12 lg:ml-0">
            <Link to="/" className="flex flex-shrink-0 items-center">
              <img className="w-[187px]" src={logo} alt="Cloutboost" />
            </Link>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <div className="flex space-x-8">
                {headerNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.to}
                    target="_blank"
                    rel="noopener"
                    className={cn('text-sm leading-5 uppercase text-gray-g1 font-normal border-b-2 border-transparent hover:border-gray-g1')}
                    // aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
