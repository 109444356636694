import { FC, useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch } from 'hooks/store';
import cn from 'classnames';
import { Chat } from 'components/Chat';
import { Header } from 'components/Header';
import { Modal } from 'components/Modal';
import { Search } from 'components/Search';
import { Sidebar } from 'components/Sidebar';
import { fetchAgenciesThunk, fetchClientsThunk } from 'pages/Admin/reducer';
import { fetchCommonThunk } from './reducer';
import { ContentOverlayLoading } from 'components/ContentOverlayLoading';

function App() {
  const dispatch = useAppDispatch();

  // TODO make better handle for common states
  useLegacyEffect(() => {
    dispatch(fetchCommonThunk());
    dispatch(fetchClientsThunk());
    dispatch(fetchAgenciesThunk());
  }, []);

  return (
    <div className="h-full flex flex-col">
      <ScreenLayout />

      <Modal />
      <Search />
      <Chat />
    </div>
  );
}

export default App;

export const ScreenLayout: FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapseSidebar, setCollapseSidebar] = useState(true);

  const handleCollapseSidebar = useCallback(() => {
    setCollapseSidebar((prev) => !prev);
  }, []);

  return (
    <>
      <Header setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} collapseSidebar={collapseSidebar} setSidebarOpen={setSidebarOpen} handleCollapseSidebar={handleCollapseSidebar} />
        <main
          className={cn(
            // TODO max-lg:mb-20 need for Iphone. add check by phone or browser
            'relative flex flex-1 flex-col max-lg:pb-20 overflow-hidden bg-gray-g1 transition-all duration-300 ease-in-out',
            collapseSidebar ? 'lg:ml-64' : 'lg:ml-[78px]'
          )}
        >
          <ContentOverlayLoading>
            <Outlet />
          </ContentOverlayLoading>
        </main>
      </div>
    </>
  );
};
