import { FC } from 'react';
import { EmptyIcon } from 'assets/icons';

export const NoCampaign: FC = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <EmptyIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">Company does not exist</h1>
    </div>
  );
};
