import { FC, forwardRef } from 'react';
import InputRange, { InputRangeClassNames, Range } from 'react-input-range';

import 'react-input-range/lib/css/index.css';
import './styles.css';

interface IRangeSliderProps {
  value: number | Range;
  minValue?: number;
  maxValue?: number;
  onChange?: (value: number | Range) => void;
  classNames?: Partial<InputRangeClassNames>;
  [prop: string]: any;
}

export const RangeSlider: FC<IRangeSliderProps> = forwardRef(({ classNames, value, minValue = 0, maxValue = 100, onChange = () => {}, ...rest }, ref: any) => {
  const _cns = {
    activeTrack: '',
    disabledInputRange: '',
    inputRange: '',
    labelContainer: '',
    maxLabel: '',
    minLabel: '',
    slider: '',
    sliderContainer: '',
    track: '',
    valueLabel: '',
    ...classNames,
  };

  return (
    <InputRange
      ref={ref}
      // classNames={_cns} // TODO now rewrite all styles. need update each _cns field with needed classes
      minValue={minValue}
      maxValue={maxValue}
      // formatLabel={(value, type) => {
      //   return type === 'value'
      //     ? value > 0 && value < 100
      //       ? `${value}`
      //       : ''
      //     : `${value}`
      // }}
      value={value}
      onChange={({ min, max }: any) =>
        onChange({
          min: min >= minValue ? min : minValue,
          max: max <= maxValue ? max : maxValue,
        })
      }
      {...rest}
    />
  );
});
