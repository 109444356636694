export const colors = {
  gray: {
    g1: '#f7f7f7',
    g2: '#d9d9d9',
    g3: '#4D4D50',
    gm: '#9F9E9E',
    gb: '#414141',
    gd: '#575759',
  },
  green: {
    g1: '#23C664',
    g2: '#7EE1E8',
    light: '#D8F8E3',
    light_2: '#D8F6F8',
  },
  blue: {
    b1: '#4319EE',
    light: '#ECE8FD',
    blight: '#D8F6F8',
  },
  red: {
    r1: '#F42E33',
  },
  yellow: {
    y1: '#FFC839',
    light: '#FFEEC4',
  },
  violet: {
    v1: '#7C3CD8',
  },
  pink: {
    p1: '#F03984',
    light: '#FEEBF3',
  },
  white: {
    default: '#ffffff',
  },
  black: {
    default: '#000000',
    b1: '#1e1e1e',
  },
};
