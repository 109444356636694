import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { hideModal, selectIsOpen, selectLoading, selectParams, selectType, startLoading, stopLoading } from './reducer';
import { CloseIcon } from 'assets/icons';

import { CreateCampaign } from './modals/CreateCampaign';
import { AddInfluencer } from './modals/influencers/AddInfluencer';
import { SaveChannelList } from './modals/influencers/SaveChannelList';
import { AddSearchToCampaign } from './modals/influencers/AddSearchToCampaign';
import { EditSearchInfluencer } from './modals/influencers/EditSearchInfluencer';
import { SendToReview } from './modals/selected/SendToReview';
import { SendUpdates } from './modals/selected/SendUpdates';
import { OutreachEdit } from './modals/outreach/OutreachEdit';
import { EngagementEdit } from './modals/engagement/EngagementEdit';
import { ManagementEdit } from './modals/management/ManagementEdit';
import { AdminAddInfluencer } from './modals/admin/AddInfluencers';
import { AdminAddNewManager } from './modals/admin/AddNewManager';
import { AdminAddNewUser } from './modals/admin/AddNewUser';
import { AddToCampaign } from './modals/influencerProfile/AddToCampaign';
import { AddToSearch } from './modals/influencerProfile/AddToSearch';
import { ChangePassword } from './modals/profile/ChangePassword';
import { DeleteConfirmation } from './modals/DeleteConfirmation';
import { EditSelectedChannel } from './modals/selected/EditSelectedChannel';
import { DeleteSelected } from './modals/selected/DeleteSelected';
import { OpenSelected } from './modals/selected/OpenSelected';
import { EditInfluencerDescription } from './modals/influencers/EditInfluencerDescription';
import AddClient from './modals/AddClient';
import { AddAgency } from './modals/admin/AddAgency';

export const ModalTypes = {
  CreateCampaign: 'CreateCampaign',
  AddInfluencer: 'AddInfluencer',
  SaveChannelList: 'SaveChannelList',
  AddSearchToCampaign: 'AddSearchToCampaign',
  EditSearchInfluencer: 'EditSearchInfluencer',
  SendToReview: 'SendToReview',
  SendUpdates: 'SendUpdates',
  OutreachEdit: 'OutreachEdit',
  EngagementEdit: 'EngagementEdit',
  ManagementEdit: 'ManagementEdit',
  AdminAddInfluencer: 'AdminAddInfluencer',
  AdminAddNewManager: 'AdminAddNewManager',
  AdminAddNewUser: 'AdminAddNewUser',
  AddToCampaign: 'AddToCampaign',
  AddToSearch: 'AddToSearch',
  ChangePassword: 'ChangePassword',
  DeleteConfirmation: 'DeleteConfirmation',
  DeleteSelected: 'DeleteSelected',
  OpenSelected: 'OpenSelected',
  EditSelectedChannel: 'EditSelectedChannel',
  EditInfluencerDescription: 'EditInfluencerDescription',
  AddClient: 'AddClient',
  AddAgency: 'AddAgency',
};

export const ModalOptions = {
  // [ModalTypes.CreateCampaign]: {}, // Example
};

export const MODALS = {
  [ModalTypes.CreateCampaign]: (props: any) => <CreateCampaign {...props} />,
  [ModalTypes.AddInfluencer]: (props: any) => <AddInfluencer {...props} />,
  [ModalTypes.SaveChannelList]: (props: any) => <SaveChannelList {...props} />,
  [ModalTypes.AddSearchToCampaign]: (props: any) => <AddSearchToCampaign {...props} />,
  [ModalTypes.EditSearchInfluencer]: (props: any) => <EditSearchInfluencer {...props} />,
  [ModalTypes.SendToReview]: (props: any) => <SendToReview {...props} />,
  [ModalTypes.SendUpdates]: (props: any) => <SendUpdates {...props} />,
  [ModalTypes.OutreachEdit]: (props: any) => <OutreachEdit {...props} />,
  [ModalTypes.EngagementEdit]: (props: any) => <EngagementEdit {...props} />,
  [ModalTypes.ManagementEdit]: (props: any) => <ManagementEdit {...props} />,
  [ModalTypes.AdminAddInfluencer]: (props: any) => <AdminAddInfluencer {...props} />,
  [ModalTypes.AdminAddNewManager]: (props: any) => <AdminAddNewManager {...props} />,
  [ModalTypes.AdminAddNewUser]: (props: any) => <AdminAddNewUser {...props} />,
  [ModalTypes.AddToCampaign]: (props: any) => <AddToCampaign {...props} />,
  [ModalTypes.AddToSearch]: (props: any) => <AddToSearch {...props} />,
  [ModalTypes.ChangePassword]: (props: any) => <ChangePassword {...props} />,
  [ModalTypes.DeleteConfirmation]: (props: any) => <DeleteConfirmation {...props} />,
  [ModalTypes.DeleteSelected]: (props: any) => <DeleteSelected {...props} />,
  [ModalTypes.OpenSelected]: (props: any) => <OpenSelected {...props} />,
  [ModalTypes.EditSelectedChannel]: (props: any) => <EditSelectedChannel {...props} />,
  [ModalTypes.EditInfluencerDescription]: (props: any) => <EditInfluencerDescription {...props} />,
  [ModalTypes.AddClient]: (props: any) => <AddClient {...props} />,
  [ModalTypes.AddAgency]: (props: any) => <AddAgency {...props} />,
};

export const Modal: FC = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(selectType);
  const isOpen = useAppSelector(selectIsOpen);
  const params = useAppSelector(selectParams);
  const loading = useAppSelector(selectLoading);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-y-auto"
        style={{ zIndex: 10000 }}
        // initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={() => dispatch(hideModal())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/40 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="inline-block align-bottom bg-white rounded text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                {type
                  ? MODALS[type]({
                      isOpen,
                      loading,
                      params,
                      startLoading: (_name: string) => dispatch(startLoading(_name)),
                      stopLoading: () => dispatch(stopLoading()),
                      hideModal: () => dispatch(hideModal()),
                    })
                  : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface IModalHeaderProps {
  title?: string;
  subtitle?: string;
  close: () => void;
}

export const ModalHeader: FC<IModalHeaderProps> = ({ title = 'Unknown title', subtitle = 'Unknown subtitle', close = () => {} }) => {
  return (
    <div className="p-6 sm:p-8">
      <div className="relative flex items-start justify-center space-x-3">
        <Dialog.Title className="text-2xl leading-[42px] font-semibold text-black-b1">{title}</Dialog.Title>
        <div className="absolute -top-2 -right-2 z-10 flex h-7 items-center">
          <button type="button" className="text-black-b1 focus:outline-none" onClick={close}>
            <span className="sr-only">Close panel</span>
            <CloseIcon aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
