import { colors } from 'variables';

export const channelsLimit = 25;

// Config for sm platforms channel data
export const platformConfig: any = {
  youtube: {
    channel_name: {
      id: 'channel_name',
      name: 'Channel Name',
      role: 'main',
    },
    channel_description: {
      id: 'channel_description',
      name: 'Channel Description',
      role: 'additional',
    },
    channel_creation_date: {
      id: 'channel_creation_date',
      name: 'Channel Creation Date',
      role: 'additional',
    },
    channel_url: {
      id: 'channel_url',
      name: 'Channel URL',
      role: 'main',
    },
    subscribers: {
      id: 'subscribers',
      name: 'Subscribers',
      role: 'main',
    },
    total_videos: {
      id: 'total_videos',
      name: 'Total Videos',
      role: 'additional',
    },
    total_views: {
      id: 'total_views',
      name: 'Total Views',
      role: 'additional',
    },
    recent_videos_60: {
      id: 'recent_videos_60',
      name: 'Recent Videos # (last 60 days)',
      role: 'additional',
    },
    recent_videos_30: {
      id: 'recent_videos_30',
      name: 'Recent Videos # (last 30 days)',
      role: 'additional',
    },
    median_views_60: {
      id: 'median_views_60',
      name: 'Median Views (last 60 days)',
      role: 'additional',
    },
    median_views_30: {
      id: 'median_views_30',
      name: 'Median Views (last 30 days)',
      role: 'additional',
    },
    median_views_20: {
      id: 'median_views_20',
      name: 'Median Views (last 20 videos)',
      role: 'main',
    },
    mean_views_60: {
      id: 'mean_views_60',
      name: 'Mean Views (last 60 days)',
      role: 'additional',
    },
    mean_views_30: {
      id: 'mean_views_30',
      name: 'Mean Views (last 30 days)',
      role: 'additional',
    },
    mean_views_20: {
      id: 'mean_views_20',
      name: 'Mean Views (last 20 videos)',
      role: 'additional',
    },
    true_reach_60: {
      id: 'true_reach_60',
      name: 'True Reach (last 60 days)',
      role: 'additional',
    },
    true_reach_30: {
      id: 'true_reach_30',
      name: 'True Reach (last 30 days)',
      role: 'additional',
    },
    true_reach_20: {
      id: 'true_reach_20',
      name: 'True Reach (last 20 videos)',
      role: 'main',
    },
    frequency_60: {
      id: 'frequency_60',
      name: 'Frequency (last 60 days)',
      role: 'additional',
    },
    frequency_30: {
      id: 'frequency_30',
      name: 'Frequency (last 30 days)',
      role: 'main',
    },
    engagement_score: {
      id: 'engagement_score',
      name: 'Engagement Score (last 20 videos)',
      role: 'main',
    },
    average_likes: {
      id: 'average_likes',
      name: 'Average Likes (based on last 20 videos)',
      role: 'main',
    },
    average_comments: {
      id: 'average_comments',
      name: 'Average Comments (based on last 20 videos)',
      role: 'main',
    },
    avg_video_duration: {
      id: 'avg_video_duration',
      name: 'Average Video Length',
      role: 'main',
    },
    avg_video_month: {
      id: 'avg_video_month',
      name: 'Average Video/Month (based on last 30 days)',
      role: 'additional',
    },
    sentiment_score: {
      id: 'sentiment_score',
      name: 'Sentiment Score (based on last 20 videos)',
      role: 'additional',
    },
    short_views: {
      id: 'short_views',
      name: 'Avg Views Shorts',
      role: 'main',
    },
    short_comments: {
      id: 'short_comments',
      name: 'Short Comments',
      role: 'additional',
    },
    short_likes: {
      id: 'short_likes',
      name: 'Short Likes',
      role: 'additional',
    },
    short_count: {
      id: 'short_count',
      name: 'Short Count',
      role: 'additional',
    },
    short_true_reach: {
      id: 'short_true_reach',
      name: 'Short True Reach',
      role: 'additional',
    },
    short_eng_score: {
      id: 'short_eng_score',
      name: 'Short Engagement Score',
      role: 'additional',
    },
    live_views: {
      id: 'live_views',
      name: 'Avg Live Views',
      role: 'additional',
    },
    live_comments: {
      id: 'live_comments',
      name: 'Live Comments',
      role: 'additional',
    },
    live_likes: {
      id: 'live_likes',
      name: 'Live Likes',
      role: 'additional',
    },
    live_count: {
      id: 'live_count',
      name: 'Live Count',
      role: 'additional',
    },
    live_true_reach: {
      id: 'live_true_reach',
      name: 'Live True Reach',
      role: 'additional',
    },
    live_eng_score: {
      id: 'live_eng_score',
      name: 'Live Engagement Score',
      role: 'additional',
    },
    video_views: {
      id: 'video_views',
      name: 'Avg Views Video',
      role: 'additional',
    },
    video_comments: {
      id: 'video_comments',
      name: 'Video Comments',
      role: 'additional',
    },
    video_likes: {
      id: 'video_likes',
      name: 'Video Likes',
      role: 'additional',
    },
    video_count: {
      id: 'video_count',
      name: 'Video Count',
      role: 'additional',
    },
    video_true_reach: {
      id: 'video_true_reach',
      name: 'Video True Reach',
      role: 'additional',
    },
    video_eng_score: {
      id: 'video_eng_score',
      name: 'Video Engagement Score',
      role: 'additional',
    },
    language: {
      id: 'language',
      name: 'Language',
      role: 'main',
    },
    country: {
      id: 'country',
      name: 'Country',
      role: 'main',
    },
    categories: {
      id: 'categories',
      name: 'Categories',
      role: 'additional',
    },
    top_tags: {
      id: 'top_tags',
      name: 'Top 20 Tags',
      role: 'additional',
    },
    youtube_games: {
      id: 'youtube_games',
      name: 'Recently Played Games (last 20 videos)',
      role: 'main',
    },
    youtube_games_longterm: {
      id: 'youtube_games_longterm',
      name: 'Games Played (last 12 months)',
      role: 'additional',
    },
    genres: {
      id: 'genres',
      name: 'Genres',
      role: 'additional',
    },
    themes: {
      id: 'themes',
      name: 'Themes',
      role: 'additional',
    },
    agency_id: {
      id: 'agency_id',
      name: 'Agency',
      role: 'main',
    },
    direct_email: {
      id: 'direct_email',
      name: 'Direct Email',
      role: 'main',
    },
  },
  twitch: {
    channel_name: {
      id: 'channel_name',
      name: 'Channel Name',
      role: 'main',
    },
    channel_description: {
      id: 'channel_description',
      name: 'Channel Description',
      role: 'additional',
    },
    channel_url: {
      id: 'channel_url',
      name: 'Channel URL',
      role: 'main',
    },
    followers: {
      id: 'followers',
      name: 'Followers',
      role: 'main',
    },
    last_seen: {
      id: 'last_seen',
      name: 'Last Seen',
      role: 'additional',
    },
    // total_views: {
    //   id: 'total_views',
    //   name: 'Total Views',
    //   role: 'additional',
    // },
    days_week_30: {
      id: 'days_week_30',
      name: 'Active Days/Week (last 30 days)',
      role: 'main',
    },
    aaccv_20: {
      id: 'aaccv_20',
      name: 'Average CCV (last 20 streams)',
      role: 'main',
    },
    average_peak_20: {
      id: 'average_peak_20',
      name: 'Average Peak Viewers (last 20 streams)',
      role: 'main',
    },
    median_accv_60: {
      id: 'median_accv_60',
      name: 'Median CCV (last 60 days)',
      role: 'main',
    },
    median_accv_30: {
      id: 'median_accv_30',
      name: 'Median CCV (last 30 days)',
      role: 'main',
    },
    median_accv_20: {
      id: 'median_accv_20',
      name: 'Median CCV (last 20 streams)',
      role: 'main',
    },
    median_peak_60: {
      id: 'median_peak_60',
      name: 'Median Peak Viewers (last 60 streams)',
      role: 'main',
    },
    median_peak_30: {
      id: 'median_peak_30',
      name: 'Median Peak Viewers (last 30 streams)',
      role: 'main',
    },
    median_peak_20: {
      id: 'median_peak_20',
      name: 'Median Peak Viewers (last 20 streams)',
      role: 'main',
    },
    average_stream_duration_20: {
      id: 'average_stream_duration_20',
      name: 'Average Stream Duration (last 20 streams)',
      role: 'main',
    },
    avg_streams_month: {
      id: 'avg_streams_month',
      name: 'Average Streams/Month (last 30 days)',
      role: 'additional',
    },
    hours_live_30: {
      id: 'hours_live_30',
      name: 'Hours Live (last 30 days)',
      role: 'additional',
    },
    viewer_hours_30: {
      id: 'viewer_hours_30',
      name: 'Viewer Hours (last 30 days)',
      role: 'additional',
    },
    // mean_views_30: {
    //   id: 'mean_views_30',
    //   name: 'Follower Change (last 30 days)',
    //   role: 'additional',
    // },
    daily_broadcast_30: {
      id: 'daily_broadcast_30',
      name: 'Daily Broadcast Time (last 30 days)',
      role: 'additional',
    },
    language: {
      id: 'language',
      name: 'Language',
      role: 'main',
    },
    country: {
      id: 'country',
      name: 'Country',
      role: 'main',
    },
    games_streamed: {
      id: 'games_streamed',
      name: 'Recently Streamed Games Number (last 20 streams)',
      role: 'main',
    },
    recently_streamed_games: {
      id: 'recently_streamed_games',
      name: 'Recently Streamed Games (last 20 streams)',
      role: 'main',
    },
    games_longterm: {
      id: 'games_longterm',
      name: 'Games Streamed (last 12 months)',
      role: 'additional',
    },
    genres: {
      id: 'genres',
      name: 'Genres',
      role: 'additional',
    },
    themes: {
      id: 'themes',
      name: 'Themes',
      role: 'additional',
    },
    agency_id: {
      id: 'agency_id',
      name: 'Agency',
      role: 'main',
    },
    direct_email: {
      id: 'direct_email',
      name: 'Direct Email',
      role: 'main',
    },
  },
};

// Config for sm platforms selected channel data
export const platformSelectedConfig: any = {
  youtube: {
    ...platformConfig.youtube,
    approval: {
      id: 'approval',
      name: 'Approval',
      role: 'main',
    },
    outreach_status: {
      id: 'outreach_status',
      name: 'Outreach Status',
      role: 'main',
    },
    content_format: {
      id: 'content_format',
      name: 'Content Format',
      role: 'main',
    },
    theme: {
      id: 'theme',
      name: 'Theme',
      role: 'main',
    },
    internal_price: {
      id: 'internal_price',
      name: 'Internal Price',
      role: 'main',
    },
    internal_cpm: {
      id: 'internal_cpm',
      name: 'Internal CPM',
      role: 'main',
    },
    price: {
      id: 'price',
      name: 'Client Price',
      role: 'main',
    },
    cpm: {
      id: 'cpm',
      name: 'Client CPM',
      role: 'main',
    },
    comments: {
      id: 'comments',
      name: 'Comments',
      role: 'main',
    },
  },
  twitch: {
    ...platformConfig.twitch,
    approval: {
      id: 'approval',
      name: 'Approval',
      role: 'main',
    },
    outreach_status: {
      id: 'outreach_status',
      name: 'Outreach Status',
      role: 'main',
    },
    content_format: {
      id: 'content_format',
      name: 'Content Format',
      role: 'main',
    },
    theme: {
      id: 'theme',
      name: 'Theme',
      role: 'main',
    },
    internal_price: {
      id: 'internal_price',
      name: 'Internal Price',
      role: 'main',
    },
    internal_cpm: {
      id: 'internal_cpm',
      name: 'Internal CPVH',
      role: 'main',
    },
    price: {
      id: 'price',
      name: 'Client Price',
      role: 'main',
    },
    cpm: {
      id: 'cpm',
      name: 'Client CPVH',
      role: 'main',
    },
    comments: {
      id: 'comments',
      name: 'Comments',
      role: 'main',
    },
  },
};

export const engagementConfig: any = {
  channel_name: {
    id: 'channel_name',
    name: 'Name',
    role: 'main',
  },
  channel_url: {
    id: 'channel_url',
    name: 'Channel URL',
    role: 'main',
  },
  contract_status: {
    id: 'contract_status',
    name: 'Contract Status',
    role: 'main',
  },
  approval: {
    id: 'approval',
    name: 'Approval',
    role: 'main',
  },
  outreach_status: {
    id: 'outreach_status',
    name: 'Outreach Status',
    role: 'main',
  },
  content_format: {
    id: 'content_format',
    name: 'Content Format',
    role: 'main',
  },
  theme: {
    id: 'theme',
    name: 'Theme',
    role: 'main',
  },
  internal_price: {
    id: 'internal_price',
    name: 'Internal Price',
    role: 'main',
  },
  internal_cpm: {
    id: 'internal_cpm',
    name: 'Internal CPM',
    role: 'main',
  },
  internal_cpvh: {
    id: 'internal_cpvh',
    name: 'Internal CPVH',
    role: 'main',
  },
  price: {
    id: 'price',
    name: 'Price',
    role: 'main',
  },
  cpm: {
    id: 'cpm',
    name: 'CPM',
    role: 'main',
  },
  cpvh: {
    id: 'cpvh',
    name: 'CPVH',
    role: 'main',
  },
  subscribers: {
    id: 'subscribers',
    name: 'Subscribers',
    role: 'main',
  },
  average_views: {
    id: 'average_views',
    name: 'Avg Views',
    role: 'main',
  },
  country: {
    id: 'country',
    name: 'Country',
    role: 'main',
  },
  agency_id: {
    id: 'agency_id',
    name: 'Agency',
    role: 'main',
  },
  direct_email: {
    id: 'direct_email',
    name: 'Direct Email',
    role: 'main',
  },
  sm_platform: {
    id: 'sm_platform',
    name: 'SM Platform',
    role: 'main',
  },
};

export const campaignManagementConfig: any = {
  channel_name: {
    id: 'channel_name',
    name: 'Name',
    role: 'main',
  },
  channel_url: {
    id: 'channel_url',
    name: 'Channel URL',
    role: 'main',
  },
  published: {
    id: 'published',
    name: 'Publish',
    role: 'main',
  },
  campaign_status: {
    id: 'campaign_status',
    name: 'Campaign Status',
    role: 'main',
  },
  contract_status: {
    id: 'contract_status',
    name: 'Contract Status',
    role: 'main',
  },
  price: {
    id: 'price',
    name: 'Price',
    role: 'main',
  },
  cpm: {
    id: 'cpm',
    name: 'CPM',
    role: 'main',
  },
  cpvh: {
    id: 'cpvh',
    name: 'CPVH',
    role: 'main',
  },
  subscribers: {
    id: 'subscribers',
    name: 'Subscribers',
    role: 'main',
  },
  average_views: {
    id: 'average_views',
    name: 'Avg Views',
    role: 'main',
  },
  tracking_link: {
    id: 'tracking_link',
    name: 'Tracking link',
    role: 'main',
  },
  content_format: {
    id: 'content_format',
    name: 'Content Format',
    role: 'main',
  },
  theme: {
    id: 'theme',
    name: 'Theme',
    role: 'main',
  },
  giveaway_code: {
    id: 'giveaway_code',
    name: 'Giveaway Code',
    role: 'main',
  },
  assets_status: {
    id: 'assets_status',
    name: 'Assets Status',
    role: 'main',
  },
  approval_management: {
    id: 'approval_management',
    name: 'Approval',
    role: 'main',
  },
  draft_due_date: {
    id: 'draft_due_date',
    name: 'Draft due date',
    role: 'main',
  },
  publication_date: {
    id: 'publication_date',
    name: 'Publication Date',
    role: 'main',
  },
  video_draft: {
    id: 'video_draft',
    name: 'Video Draft',
    role: 'main',
  },
  video_final: {
    id: 'video_final',
    name: 'Video Final',
    role: 'main',
  },
  sm_platform: {
    id: 'sm_platform',
    name: 'SM Platform',
    role: 'main',
  },
  agency_id: {
    id: 'agency_id',
    name: 'Agency',
    role: 'main',
  },
  direct_email: {
    id: 'direct_email',
    name: 'Direct Email',
    role: 'main',
  },
  comments: {
    id: 'comments',
    name: 'Comments',
    role: 'main',
  },
};

export const influencerConfig: any = {
  full_name: {
    id: 'full_name',
    name: 'Username',
    role: 'main',
  },
  first_name: {
    id: 'first_name',
    name: 'First Name',
    role: 'main',
  },
  last_name: {
    id: 'last_name',
    name: 'Last Name',
    role: 'main',
  },
  city: {
    id: 'city',
    name: 'City',
    role: 'main',
  },
  gender: {
    id: 'gender',
    name: 'Gender',
    role: 'main',
  },
  platform: {
    id: 'platform',
    name: 'Platform',
    role: 'main',
  },
  genre: {
    id: 'genre',
    name: 'Genre',
    role: 'main',
  },
  main_game: {
    id: 'main_game',
    name: 'Main Games',
    role: 'main',
  },
  youtube_url: {
    id: 'youtube_url',
    name: 'YouTube URL',
    role: 'main',
  },
  twitch_url: {
    id: 'twitch_url',
    name: 'Twitch URL',
    role: 'main',
  },
  twitter_url: {
    id: 'twitter_url',
    name: 'Twitter URL',
    role: 'main',
  },
  instagram_url: {
    id: 'instagram_url',
    name: 'Instagram URL',
    role: 'main',
  },
  tiktok_url: {
    id: 'tiktok_url',
    name: 'TikTok URL',
    role: 'main',
  },
  email: {
    id: 'email',
    name: 'Email',
    role: 'main',
  },
  discord: {
    id: 'discord',
    name: 'Discord',
    role: 'main',
  },
  whatsapp: {
    id: 'whatsapp',
    name: 'WhatsApp',
    role: 'main',
  },
  cell: {
    id: 'cell',
    name: 'Cell',
    role: 'main',
  },
  created_at: {
    id: 'created_at',
    name: 'Creation Date',
    role: 'main',
  },
  language: {
    id: 'language',
    name: 'Language',
    role: 'main',
  },
  country: {
    id: 'country',
    name: 'Country',
    role: 'main',
  },
};

export const approvalSelectedStatuses: any[] = [
  {
    value: 'not_reviewed',
    label: 'Not reviewed',
    default: true,
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'interested',
    label: 'Intrested',
    default: true,
    bg: 'bg-yellow-light',
    color: colors.yellow.light,
  },
  {
    value: 'no',
    label: 'No',
    bg: 'bg-pink-pl',
    color: colors.pink.light,
  },
  {
    value: 'maybe',
    label: 'Maybe',
    bg: 'bg-blue-blight',
    color: colors.blue.blight,
  },
  {
    value: 'add_to_campaign',
    label: 'Add to Campaign',
    bg: 'bg-green-gl',
    color: colors.green.light,
  },
];

export const approvalManagementStatuses: any[] = [
  {
    value: 'yes',
    label: 'Yes',
    bg: 'bg-green-gl',
    color: colors.green.light,
  },
  {
    value: 'no',
    label: 'No',
    default: true,
    bg: 'bg-pink-pl',
    color: colors.pink.light,
  },
  {
    value: 'in_review',
    label: 'In review',
    bg: 'bg-blue-bl',
    color: colors.blue.light,
  },
];

export const contentFormatStatuses: any[] = [
  {
    value: 'dedicated',
    label: 'YouTube Dedicated',
    bg: 'bg-pink-pl',
    color: colors.pink.light,
  },
  {
    value: 'short',
    label: 'YouTube Short',
    bg: 'bg-blue-bl',
    color: colors.blue.light,
  },
  {
    value: 'integration',
    label: 'YouTube Integration',
    default: true,
    bg: 'bg-green-gl',
    color: colors.green.light,
  },
  {
    value: 'live_stream',
    label: 'YouTube Live',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'custom',
    label: 'YouTube Custom',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: '1hr_twitch_stream',
    label: '1hr Twitch Stream',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: '2hr_twitch_stream',
    label: '2hr Twitch Stream',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'twitch_integration',
    label: 'Twitch Integration',
    bg: 'bg-green-gl',
    color: colors.green.light,
  },
  {
    value: 'twitch_custom',
    label: 'Twitch Custom',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'dedicated_live_stream',
    label: 'Dedicated Live Stream',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
];

export const contractStatuses: any[] = [
  {
    value: 'sent_for_review',
    label: 'Sent for review',
    color: colors.blue.light,
    bg: 'bg-blue-bl',
  },
  {
    value: 'negotiation',
    label: 'Negotiation',
    default: true,
    color: colors.pink.light,
    bg: 'bg-pink-pl',
  },
  {
    value: 'ready_to_sign',
    label: 'Ready to sign',
    color: colors.yellow.light,
    bg: 'bg-yellow-yl',
  },
  {
    value: 'sent_for_signature',
    label: 'Sent for signature',
    color: colors.yellow.light,
    bg: 'bg-yellow-yl',
  },
  {
    value: 'executed',
    label: 'Executed',
    color: colors.green.light,
    bg: 'bg-green-gl',
  },
  {
    value: 'renewed',
    label: 'Renewed',
    bg: 'bg-blue-bl',
    color: colors.blue.light,
  },
  {
    value: 'terminated',
    label: 'Terminated',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
];

export const outreachStatuses: any[] = [
  {
    value: 'not_started',
    label: 'Not started',
    default: true,
    bg: 'bg-green-gl',
    color: colors.green.light,
  },
  {
    value: 'attempting_to_reach',
    label: 'Attempting to reach',
    bg: 'bg-pink-pl',
    color: colors.pink.light,
  },
  {
    value: 'replied',
    label: 'Replied',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'negotiation',
    label: 'Negotiation',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'successful_engagement',
    label: 'Successful Engagement',
    bg: 'bg-green-gl',
    color: colors.green.light,
  },
  {
    value: 'unsuccessful_rejected',
    label: 'Unsuccessful Rejected',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'unsuccessful_noreply',
    label: 'Unsuccessful Noreply',
    bg: 'bg-blue-bl',
    color: colors.blue.light,
  },
  {
    value: 'bounce',
    label: 'Bounce',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'send_failure',
    label: 'Send Failure',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'paused',
    label: 'Paused',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'canceled',
    label: 'Canceled',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
];

export const publishStatuses = (publish: boolean) => {
  if (!publish) return { label: 'Publish', class: 'bg-blue-b1' };
  else return { label: 'Published', class: 'bg-green-g1' };
};

export const campaignStatuses: any[] = [
  {
    value: 'not_started',
    label: 'Not started',
    default: true,
    color: colors.blue.light,
    bg: 'bg-blue-bl',
  },
  {
    value: 'content_recording',
    label: 'Content Recording',
    color: colors.pink.light,
    bg: 'bg-pink-pl',
  },
  {
    value: 'pending_approval',
    label: 'Pending Approval',
    color: colors.yellow.light,
    bg: 'bg-yellow-yl',
  },
  {
    value: 'edits_required',
    label: 'Edits Required',
    color: colors.blue.light,
    bg: 'bg-blue-bl',
  },
  // {
  //   value: 'in_review',
  //   label: 'In review',
  //   bg: 'bg-blue-bl',
  //   color: colors.blue.light,
  // },
  {
    value: 'approved',
    label: 'Approved',
    color: colors.green.light,
    bg: 'bg-green-gl',
  },
  {
    value: 'live',
    label: 'Live',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'completed',
    label: 'Completed',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'paused',
    label: 'Paused',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
  {
    value: 'canceled',
    label: 'Canceled',
    bg: 'bg-gray-g1',
    color: colors.gray.g1,
  },
];

export const assetsStatuses: any[] = [
  {
    value: 'campaign_brief_sent',
    label: 'Campaign Brief Sent',
    default: true,
    color: colors.blue.light,
    bg: 'bg-blue-bl',
  },
  {
    value: 'account_boosted',
    label: 'Account Boosted',
    color: colors.pink.light,
    bg: 'bg-pink-pl',
  },
  {
    value: 'assets_shared',
    label: 'Assets Shared',
    color: colors.yellow.light,
    bg: 'bg-yellow-yl',
  },
  {
    value: 'tracking_link_sent',
    label: 'Tracking Link Sent',
    color: colors.green.light,
    bg: 'bg-green-gl',
  },
  {
    value: 'not_started',
    label: 'Not Started',
    color: colors.gray.g1,
    bg: 'bg-gray-g1',
  },
];

// For show where the company is located
export const tags: any = {
  engagement: {
    label: 'E',
    full_label: 'Engagement',
    color: 'text-orange-400',
    bg: 'bg-orange-100',
  },
  management: {
    label: 'M',
    full_label: 'Management',
    color: 'text-blue-400',
    bg: 'bg-blue-100',
  },
  report: {
    label: 'R',
    full_label: 'Report',
    color: 'text-green-400',
    bg: 'bg-green-100',
  },
};

export const genres: any[] = [
  'Action/Adventure',
  'Augmented Reality (AR)',
  'Battle Royale',
  "Beat 'em Up",
  'Card and Board Games',
  'Casual',
  'Educational',
  'Fighting',
  'First-person Shooter (FPS)',
  'Horror',
  'Indie',
  'Massively Multiplayer Online (MMO)',
  'Multiplayer Online Battle Arena (MOBA)',
  'Open World',
  'Party Games',
  'Platformer',
  'Puzzle',
  'Puzzle-Platformer',
  'Racing',
  'Real-time Strategy (RTS)',
  'Roguelike/Roguelite',
  'Role-playing (RPG)',
  'Sandbox',
  'Simulation',
  'Sports',
  'Stealth',
  'Strategy',
  'Survival',
  'Tactical',
  'Tower Defense',
  'Turn-based Strategy (TBS)',
  'Virtual Reality (VR)',
];

export const platforms: any[] = ['PC', 'Stream', 'Playstation', 'Xbox', 'Nintendo Switch ', 'iOS', 'Android', 'Apple Arcade'];

// Advanced filter has_platform field
export const hasPlatform: any[] = ['Twitch', 'Youtube', 'Instagram', 'Tiktok', 'Twitter'];

// CLIENT PAGES

export const platformClientConfig: any = {
  youtube: {
    channel_name: {
      id: 'channel_name',
      name: 'Channel Name',
      role: 'main',
    },
    channel_description: {
      id: 'channel_description',
      name: 'Channel Description',
      role: 'additional',
    },
    channel_creation_date: {
      id: 'channel_creation_date',
      name: 'Channel Creation Date',
      role: 'additional',
    },
    channel_url: {
      id: 'channel_url',
      name: 'Channel URL',
      role: 'main',
    },
    approval: {
      id: 'approval',
      name: 'Approval',
      role: 'main',
    },
    outreach_status: {
      id: 'outreach_status',
      name: 'Outreach Status',
      role: 'main',
    },
    content_format: {
      id: 'content_format',
      name: 'Content Format',
      role: 'main',
    },
    theme: {
      id: 'theme',
      name: 'Theme',
      role: 'main',
    },
    subscribers: {
      id: 'subscribers',
      name: 'Subscribers',
      role: 'main',
    },
    total_videos: {
      id: 'total_videos',
      name: 'Total Videos',
      role: 'additional',
    },
    total_views: {
      id: 'total_views',
      name: 'Total Views',
      role: 'additional',
    },
    recent_videos_60: {
      id: 'recent_videos_60',
      name: 'Recent Videos # (last 60 days)',
      role: 'additional',
    },
    recent_videos_30: {
      id: 'recent_videos_30',
      name: 'Recent Videos # (last 30 days)',
      role: 'additional',
    },
    median_views_60: {
      id: 'median_views_60',
      name: 'Median Views (last 60 days)',
      role: 'additional',
    },
    median_views_30: {
      id: 'median_views_30',
      name: 'Median Views (last 30 days)',
      role: 'additional',
    },
    median_views_20: {
      id: 'median_views_20',
      name: 'Median Views (last 20 videos)',
      role: 'main',
    },
    mean_views_60: {
      id: 'mean_views_60',
      name: 'Mean Views (last 60 days)',
      role: 'additional',
    },
    mean_views_30: {
      id: 'mean_views_30',
      name: 'Mean Views (last 30 days)',
      role: 'additional',
    },
    mean_views_20: {
      id: 'mean_views_20',
      name: 'Mean Views (last 20 videos)',
      role: 'additional',
    },
    true_reach_60: {
      id: 'true_reach_60',
      name: 'True Reach (last 60 days)',
      role: 'additional',
    },
    true_reach_30: {
      id: 'true_reach_30',
      name: 'True Reach (last 30 days)',
      role: 'additional',
    },
    true_reach_20: {
      id: 'true_reach_20',
      name: 'True Reach (last 20 videos)',
      role: 'main',
    },
    frequency_60: {
      id: 'frequency_60',
      name: 'Frequency (last 60 days)',
      role: 'additional',
    },
    frequency_30: {
      id: 'frequency_30',
      name: 'Frequency (last 30 days)',
      role: 'main',
    },
    engagement_score: {
      id: 'engagement_score',
      name: 'Engagement Score (last 20 videos)',
      role: 'main',
    },
    average_likes: {
      id: 'average_likes',
      name: 'Average Likes (based on last 20 videos)',
      role: 'main',
    },
    average_comments: {
      id: 'average_comments',
      name: 'Average Comments (based on last 20 videos)',
      role: 'main',
    },
    avg_video_duration: {
      id: 'avg_video_duration',
      name: 'Average Video Length',
      role: 'main',
    },
    avg_video_month: {
      id: 'avg_video_month',
      name: 'Average Video/Month (based on last 30 days)',
      role: 'additional',
    },
    sentiment_score: {
      id: 'sentiment_score',
      name: 'Sentiment Score (based on last 20 videos)',
      role: 'additional',
    },
    short_views: {
      id: 'short_views',
      name: 'Avg Views Shorts',
      role: 'main',
    },
    short_comments: {
      id: 'short_comments',
      name: 'Short Comments',
      role: 'additional',
    },
    short_likes: {
      id: 'short_likes',
      name: 'Short Likes',
      role: 'additional',
    },
    short_count: {
      id: 'short_count',
      name: 'Short Count',
      role: 'additional',
    },
    short_true_reach: {
      id: 'short_true_reach',
      name: 'Short True Reach',
      role: 'additional',
    },
    short_eng_score: {
      id: 'short_eng_score',
      name: 'Short Engagement Score',
      role: 'additional',
    },
    live_views: {
      id: 'live_views',
      name: 'Avg Live Views',
      role: 'additional',
    },
    live_comments: {
      id: 'live_comments',
      name: 'Live Comments',
      role: 'additional',
    },
    live_likes: {
      id: 'live_likes',
      name: 'Live Likes',
      role: 'additional',
    },
    live_count: {
      id: 'live_count',
      name: 'Live Count',
      role: 'additional',
    },
    live_true_reach: {
      id: 'live_true_reach',
      name: 'Live True Reach',
      role: 'additional',
    },
    live_eng_score: {
      id: 'live_eng_score',
      name: 'Live Engagement Score',
      role: 'additional',
    },
    video_views: {
      id: 'video_views',
      name: 'Avg Views Video',
      role: 'additional',
    },
    video_comments: {
      id: 'video_comments',
      name: 'Video Comments',
      role: 'additional',
    },
    video_likes: {
      id: 'video_likes',
      name: 'Video Likes',
      role: 'additional',
    },
    video_count: {
      id: 'video_count',
      name: 'Video Count',
      role: 'additional',
    },
    video_true_reach: {
      id: 'video_true_reach',
      name: 'Video True Reach',
      role: 'additional',
    },
    video_eng_score: {
      id: 'video_eng_score',
      name: 'Video Engagement Score',
      role: 'additional',
    },
    language: {
      id: 'language',
      name: 'Language',
      role: 'main',
    },
    country: {
      id: 'country',
      name: 'Country',
      role: 'main',
    },
    topics: {
      id: 'topics',
      name: 'Topics',
      role: 'additional',
    },
    categories: {
      id: 'categories',
      name: 'Categories',
      role: 'additional',
    },
    keywords: {
      id: 'keywords',
      name: 'Keywords',
      role: 'additional',
    },
    // top_tags_100: {
    //   id: 'top_tags_100',
    //   name: 'Top 100 Tags',
    //   role: 'additional',
    // },
    top_tags: {
      id: 'top_tags',
      name: 'Top 20 Tags',
      role: 'additional',
    },
    price: {
      id: 'price',
      name: 'Price',
      role: 'main',
    },
    cpm: {
      id: 'cpm',
      name: 'CPM',
      role: 'main',
    },
    youtube_games: {
      id: 'youtube_games',
      name: 'Recently Played Games (last 20 videos)',
      role: 'main',
    },
    youtube_games_longterm: {
      id: 'youtube_games_longterm',
      name: 'Games Played (last 12 months)',
      role: 'additional',
    },
    comments: {
      id: 'comments',
      name: 'Comments',
      role: 'main',
    },
  },
  twitch: {
    channel_name: {
      id: 'channel_name',
      name: 'Channel Name',
      role: 'main',
    },
    channel_description: {
      id: 'channel_description',
      name: 'Channel Description',
      role: 'additional',
    },
    channel_url: {
      id: 'channel_url',
      name: 'Channel URL',
      role: 'main',
    },
    approval: {
      id: 'approval',
      name: 'Approval',
      role: 'main',
    },
    outreach_status: {
      id: 'outreach_status',
      name: 'Outreach Status',
      role: 'main',
    },
    content_format: {
      id: 'content_format',
      name: 'Content Format',
      role: 'main',
    },
    theme: {
      id: 'theme',
      name: 'Theme',
      role: 'main',
    },
    followers: {
      id: 'followers',
      name: 'Followers',
      role: 'main',
    },
    last_seen: {
      id: 'last_seen',
      name: 'Last Seen',
      role: 'additional',
    },
    total_views: {
      id: 'total_views',
      name: 'Total Views',
      role: 'additional',
    },
    days_week_30: {
      id: 'days_week_30',
      name: 'Active Days/Week (last 30 days)',
      role: 'main',
    },
    aaccv_20: {
      id: 'aaccv_20',
      name: 'Average CCV (last 20 streams)',
      role: 'main',
    },
    average_peak_20: {
      id: 'average_peak_20',
      name: 'Average Peak Viewers (last 20 streams)',
      role: 'main',
    },
    median_accv_60: {
      id: 'median_accv_60',
      name: 'Median CCV (last 60 days)',
      role: 'main',
    },
    median_accv_30: {
      id: 'median_accv_30',
      name: 'Median CCV (last 30 days)',
      role: 'main',
    },
    median_accv_20: {
      id: 'median_accv_20',
      name: 'Median CCV (last 20 streams)',
      role: 'main',
    },
    median_peak_60: {
      id: 'median_peak_60',
      name: 'Median Peak Viewers (last 60 streams)',
      role: 'main',
    },
    median_peak_30: {
      id: 'median_peak_30',
      name: 'Median Peak Viewers (last 30 streams)',
      role: 'main',
    },
    median_peak_20: {
      id: 'median_peak_20',
      name: 'Median Peak Viewers (last 20 streams)',
      role: 'main',
    },
    average_stream_duration_20: {
      id: 'average_stream_duration_20',
      name: 'Average Stream Duration (last 20 streams)',
      role: 'main',
    },
    avg_streams_month: {
      id: 'avg_streams_month',
      name: 'Average Streams/Month (last 30 days)',
      role: 'additional',
    },
    hours_live_30: {
      id: 'hours_live_30',
      name: 'Hours Live (last 30 days)',
      role: 'additional',
    },
    viewer_hours_30: {
      id: 'viewer_hours_30',
      name: 'Viewer Hours (last 30 days)',
      role: 'additional',
    },
    // change: {
    //   id: 'change',
    //   name: 'Follower Change (last 30 days)',
    //   role: 'additional',
    // },
    daily_broadcast_30: {
      id: 'daily_broadcast_30',
      name: 'Daily Broadcast Time (last 30 days)',
      role: 'additional',
    },
    language: {
      id: 'language',
      name: 'Language',
      role: 'main',
    },
    price: {
      id: 'price',
      name: 'Price',
      role: 'main',
    },
    cpm: {
      id: 'cpm',
      name: 'CPVH',
      role: 'main',
    },
    recently_streamed_games: {
      id: 'recently_streamed_games',
      name: 'Recently Streamed Games (last 20 streams)',
      role: 'main',
    },
    games_longterm: {
      id: 'games_longterm',
      name: 'Games Streamed (last 12 months)',
      role: 'additional',
    },
    comments: {
      id: 'comments',
      name: 'Comments',
      role: 'main',
    },
  },
};

export const agencyConfig: any = {
  agency_name: {
    id: 'agency_name',
    name: 'Agency Name',
    role: 'main',
  },
  agency_website: {
    id: 'agency_website',
    name: 'Agency Website',
    role: 'main',
  },
  agency_country: {
    id: 'agency_country',
    name: 'Agency Country',
    role: 'main',
  },
  agency_type: {
    id: 'agency_type',
    name: 'Agency Type',
    role: 'main',
  },
  agency_focus: {
    id: 'agency_focus',
    name: 'Agency Focus',
    role: 'main',
  },
};
