import { FC } from 'react';
import cn from 'classnames';
import { Menu } from './index';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon } from 'assets/icons';
import { colors } from 'variables';

interface IActionMenuProps {
  items: any[];
  name?: string;
  className?: string;
  itemsClass?: string;
  optionClass?: string;
}

export const ActionMenu: FC<IActionMenuProps> = ({ name = 'Action', className = '', itemsClass = '', optionClass = '', items = [] }) => {
  return (
    <Menu
      className={cn('flex items-center justify-center', className)}
      itemsClass={cn('!origin-top-right !left-auto top-[2.7rem] right-0 min-w-[155px]', itemsClass)}
      optionClass={cn('!px-4', optionClass)}
      items={items}
    >
      {({ open }) => (
        <button
          type="button"
          className={cn(
            'inline-flex items-center space-x-3 h-[42px] rounded-[4px] border border-transparent shadow-sm pl-3 pr-2 text-sm leading-6 font-medium focus:outline-none focus:ring-0 focus:ring-offset-0',
            open ? 'text-white bg-blue-b1' : 'text-blue-b1 bg-white'
          )}
        >
          <p>{name}</p>
          {open ? (
            <ArrowUpDropdownSolidIcon className="ml-2" pathFill={colors.white.default} aria-hidden="true" />
          ) : (
            <ArrowDownDropdownSolidIcon className="ml-2" pathFill={colors.blue.b1} aria-hidden="true" />
          )}
        </button>
      )}
    </Menu>
  );
};
