import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface IBreadcrumbsProps {
  paths: any[];
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ paths = [] }) => {
  return (
    <div className="flex items-center space-x-8 mb-5">
      <nav className="flex overflow-x-auto sm:overflow-x-hidden" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-1">
          {paths.map((page, i) => {
            const last = i === paths.length - 1;
            return (
              <li key={page.name} className="flex items-center text-gray-gm hover:text-gray-gm">
                {i !== 0 ? '/' : ''}
                {last ? (
                  <p className="ml-1 text-sm text-black-b1 whitespace-nowrap">{page.name}</p>
                ) : (
                  <Link to={page.to} className={cn('text-sm whitespace-nowrap', i === 0 ? '' : 'ml-1')} aria-current={page.current ? 'page' : undefined}>
                    {page.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
};
