import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import cn from 'classnames';
import { useAppSelector } from 'hooks/store';
import { ValidationTypes } from 'utils/validation';
import { ClipLoader, Form, Input, Label, RSelect, RSelectStyles } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { selectSavedChannelList } from 'pages/Influencers/reducer';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
    height: '48px !important',
  }),
};

interface ISaveChannelListProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const SaveChannelList: FC<ISaveChannelListProps> = ({ params, hideModal }) => {
  const savedChannelList = useAppSelector(selectSavedChannelList);

  const defaultValues: any = useMemo(
    () => ({
      name: '',
      list_id: '',
    }),
    []
  );

  const savedChannelListOptions = useMemo(
    () =>
      savedChannelList.map((d: any) => ({
        value: d.list_id,
        label: d.list_name,
      })),
    [savedChannelList]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title="Add to saved list" close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.SaveChannelList} // TODO update validation when one field required
        onSubmit={async (values) => {
          console.log('SUMBIT', values);
          return params.handleSave(values.name ? { list_name: values.name } : { list_id: values.list_id }).then(() => hideModal());
        }}
      >
        {({ control, register, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="list_id" label="Select saved list" error={errors.list_id} />
              <div className="mt-0.5">
                <Controller
                  name="list_id"
                  control={control}
                  render={({ field }: any) => (
                    <RSelect
                      {...field}
                      styles={selectStyles}
                      placeholder="Not selected"
                      options={savedChannelListOptions}
                      error={errors.list_id}
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={savedChannelListOptions.find((option) => option.value === field.value) || null}
                      onChange={(option: any) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="name" label="Create new one" error={errors.name} />
              <div className="mt-0.5">
                <Input id="name" type="text" placeholder="Enter name" error={errors.name} {...register('name')} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                <p>Save</p>
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
