import { FC } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useQueryLocation } from 'hooks/useQueryLocation';
import { CampaignsTable } from '../components/CampaignsTable';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { SimpleSearch } from 'components/SimpleSearch';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  createCampaignThunk,
  fetchCampaignsThunk,
  selectCampaigns,
  selectCampaignsCount,
  selectCampaignsLoading,
  selectCampaignsOrderBy,
  selectCampaignsOrderDir,
} from '../reducer';
import { NoCampaigns } from '../components/NoCampaigns';

const CampaignsTab: FC = () => {
  const query = useQueryLocation();
  const searchPage = query.get('page');

  const dispatch = useAppDispatch();
  const campaigns = useAppSelector(selectCampaigns);
  const campaingsOrderBy = useAppSelector(selectCampaignsOrderBy);
  const campaingsOrderDir = useAppSelector(selectCampaignsOrderDir);
  const campaignsCount = useAppSelector(selectCampaignsCount);
  const campaignsLoading = useAppSelector(selectCampaignsLoading);

  const page = searchPage == null ? 1 : +searchPage;

  useLegacyEffect(() => {
    dispatch(
      fetchCampaignsThunk({
        page,
        order_by: campaingsOrderBy,
        order_dir: campaingsOrderDir,
      })
    );
  }, [page, campaingsOrderBy, campaingsOrderDir]);

  return (
    <>
      <SimpleSearch
        placeholder="Search by campaign name"
        size="lg"
        iconPlace="right"
        onChange={(value: string) => {
          dispatch(
            fetchCampaignsThunk({
              page,
              order_by: campaingsOrderBy,
              order_dir: campaingsOrderDir,
              search: value,
            })
          );
        }}
      />

      <div className="flex flex-1 flex-col p-6 bg-gray-g1 overflow-hidden">
        {campaignsCount ? (
          <>
            <div className="flex items-center justify-between">
              <h1 className="text-2xl leading-[42px] font-semibold">Campaigns ({campaignsCount})</h1>
              <div className="flex items-center space-x-4">
                {/* <Menu
                  className="flex items-center justify-center"
                  itemsClass="top-[2.7rem] !left-0" // min-w-[154px]
                  optionClass="!px-4"
                  items={[
                    {
                      label: 'Excel',
                      onClick: () => {},
                    },
                    {
                      label: 'CSV',
                      onClick: () => {},
                    },
                  ]}
                >
                  {({ open }) => (
                    <button
                      type="button"
                      className={cn(
                        'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0',
                        open ? 'bg-blue-b1' : 'bg-white'
                      )}
                    >
                      <ExportIcon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                    </button>
                  )}
                </Menu> */}
                <button
                  type="button"
                  className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-0"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch(
                      showModal({
                        type: ModalTypes.CreateCampaign,
                        params: {
                          handleSave: async (data: any) => await dispatch(createCampaignThunk(data)),
                        },
                      })
                    );
                  }}
                >
                  <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                  <p className="mr-1.5">Create new campaign</p>
                </button>
              </div>
            </div>

            <CampaignsTable campaigns={campaigns} />
          </>
        ) : !campaignsCount && !campaignsLoading.is ? (
          <NoCampaigns />
        ) : null}
      </div>
    </>
  );
};

export default CampaignsTab;
