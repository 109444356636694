import { FC, forwardRef } from 'react';
import { StylesConfig } from 'react-select';
import { default as RSAsyncSelect } from 'react-select/async';
import { colors } from 'variables';

const filterColors = (options: any[], inputValue: string) => {
  return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
};

const styles: StylesConfig<any, false> = {
  control: (styles, { isFocused, selectProps: { error } }: any) => ({
    ...styles,
    minHeight: '48px',
    backgroundColor: 'white',
    fontSize: '12px',
    color: colors.black.b1,
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    boxShadow: 'none',
    '&:hover': {
      borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    },
  }),
  valueContainer: (styles) => ({ ...styles, padding: '0 12px' }),
  input: (styles) => ({
    ...styles,
    '& > input': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  singleValue: (styles) => ({ ...styles, marginLeft: 0, marginRight: 0 }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.gray.gm,
    marginLeft: 0,
    marginRight: 0,
  }),
  option: (styles) => ({
    ...styles,
    padding: '6px 16px',
    fontSize: '14px',
    color: colors.black.b1,
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: '10px 0' }),
};

interface IAsyncSelectProps {
  options: any[]; // temp/ after API - remove
  onChange?: (value: any) => void;
  name?: string;
  placeholder?: string;
  required?: boolean;
  [prop: string]: any;
}

export const AsyncSelect: FC<IAsyncSelectProps> = forwardRef(
  ({ options, onChange, name = 'async-select', placeholder = 'Enter', required = false, ...rest }, ref: any) => {
    const promiseOptions = (inputValue: string) => {
      return onChange
        ? onChange(inputValue)
        : new Promise<any[]>((resolve) => {
            setTimeout(() => {
              resolve(filterColors(options, inputValue));
            }, 500);
          });
    };

    return (
      <RSAsyncSelect
        ref={ref}
        styles={styles}
        // name={name}
        // required={required}
        // classNames={} // 5.7+ version
        className="w-full"
        cacheOptions
        isClearable={false}
        menuPlacement="auto"
        placeholder={placeholder}
        // defaultValue={formState.search}
        // isMulti
        {...rest}
        defaultOptions
        loadOptions={promiseOptions}
        // options={colourOptions}
        // onChange={}
        components={{
          // ...animatedComponents, // width: auto not working with animation
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    );
  }
);
