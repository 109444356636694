import { FC, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'hooks/store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ClipLoader, Form, Input, Label } from 'shared';
import { ValidationTypes } from 'utils/validation';
import { updatePasswordViaResetLinkThunk } from 'pages/Profile/reducer';
import { EyeCloseIcon, EyeOpenIcon } from 'assets/icons';

import logo from 'assets/logo/Cloutboost_Horizontal_logo_(Warm Gradient)@4x_(1).png';
import gamersImage from 'assets/logo/Combined_All_gamers.jpg';

export const ResetPassword: FC = () => {
  const { resetLinkCode } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isShow, setIsShow] = useState<any>({
    password: false,
    c_password: false,
  });

  let isCorrectFieldsText = useRef(true); // For submit error text when invalid fields

  const defaultValues: { password: string; c_password: string } = useMemo(
    () => ({
      password: '',
      c_password: '',
    }),
    []
  );

  const handleShowPassword = (name: string) => setIsShow((prev: any) => ({ ...prev, [name]: !prev[name] }));

  return (
    <>
      <div className="relative min-h-full flex lg:grid lg:grid-cols-2 sm:p-6">
        <Link to="/login" className="absolute top-4 left-4 sm:top-6 sm:left-6 flex items-center">
          <img className="w-[187px]" src={logo} alt="Cloutboost" />
        </Link>
        <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-[295px]">
            <div>
              <h1 className="mt-6 text-2xl leading-[42px] font-semibold tracking-tight text-black">Enter your new password.</h1>
            </div>

            <div className="mt-7 lg:w-[280px]">
              <Form
                defaultValues={defaultValues}
                validationType={ValidationTypes.resetPassword}
                onSubmit={async (values) => {
                  return await dispatch(
                    updatePasswordViaResetLinkThunk({
                      new_password: values.password,
                      reset_link_code: resetLinkCode || '',
                    })
                  ).then((res: any) => {
                    if (res.payload === 'Ok') {
                      navigate('/login', {
                        replace: true,
                        state: { fromResetPassword: true },
                      });
                    }
                  });
                }}
              >
                {({ register, formState: { errors, isSubmitting, isValid } }) => {
                  if (isSubmitting) isCorrectFieldsText.current = isValid;
                  return (
                    <>
                      <div>
                        <Label htmlFor="password" label="New password" error={errors.password} />
                        <div className="relative mt-0.5">
                          <Input
                            id="password"
                            type={isShow.password ? 'text' : 'password'}
                            placeholder="Enter password"
                            error={errors.password}
                            {...register('password')}
                          />
                          <span className="absolute top-3 right-3 text-black-b1 cursor-pointer" onClick={() => handleShowPassword('password')}>
                            {isShow.password ? <EyeOpenIcon /> : <EyeCloseIcon />}
                          </span>
                        </div>
                      </div>

                      <div className="mt-3">
                        <Label htmlFor="c_password" label="Repeat password" error={errors.c_password} />
                        <div className="relative mt-0.5">
                          <Input
                            id="c_password"
                            type={isShow.c_password ? 'text' : 'password'}
                            placeholder="Enter password"
                            error={errors.c_password}
                            {...register('c_password')}
                          />
                          <span className="absolute top-3 right-3 text-black-b1 cursor-pointer" onClick={() => handleShowPassword('c_password')}>
                            {isShow.c_password ? <EyeOpenIcon /> : <EyeCloseIcon />}
                          </span>
                        </div>
                      </div>

                      <div className="relative pt-[38px]">
                        {!isCorrectFieldsText.current ? (
                          <div className="absolute top-2.5 left-0 text-[10px] leading-6 text-red-r1">Passwords must match. Try again.</div>
                        ) : null}

                        <button
                          type="submit"
                          className={cn(
                            'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                            { disabled: isSubmitting }
                          )}
                          disabled={isSubmitting}
                        >
                          <p> Set new password</p>
                          <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                        </button>
                      </div>

                      <div className="mt-8 flex items-center">
                        <p className="text-sm leading-6 font-semibold text-black">
                          Back to{' '}
                          <Link to="/login" className="text-sm leading-6 font-semibold text-blue-b1 hover:text-blue-b1">
                            Log In
                          </Link>
                        </p>
                      </div>
                    </>
                  );
                }}
              </Form>
            </div>
          </div>
        </div>
        <div className="relative hidden flex-1 lg:block">
          <img className="absolute h-full w-full rounded-[30px] object-cover" src={gamersImage} alt="" />
        </div>
      </div>
    </>
  );
};
