import 'whatwg-fetch';

import { SERVER_API } from 'app/constants';
import User from 'app/User';
// import { toast } from 'shared'
import { toast } from 'react-toastify';

export const makeRequest = async ({ path, method = 'GET', params = {}, contentType = 'application/json', formData = false, exportData = false }: any) => {
  const options: any = {
    method: method,
    body: params,
    headers: {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache, must-revalidate',
    },
    mode: 'cors',
    timeout: 5,
  };
  const isParams = Object.keys(params).length;
  if (method === 'GET' || (!isParams && method === 'DELETE')) {
    delete options.body;
    delete options.headers['Content-Type'];
  }
  if (formData) delete options.headers['Content-Type'];

  const token = User.isAuth();
  if (token) options.headers['Authorization'] = `Bearer ${token}`;

  let response = await fetch(path.indexOf('http') === 0 ? path : SERVER_API + path, options)
    .then((response: any) => {
      if (response.status === 500) {
        return Promise.reject({
          status: 500,
          message: 'Something went wrong. Contact us for more information.',
        });
      }

      if (!response.ok) {
        // throw Error(response.statusText);
        return Promise.reject({ status: response.status });
        // return response.json().then((res) => {
        //   console.log('response !ok', res)
        //   // return Promise.reject({ ...res, status: response.status })
        // })
        // return response.json().then((res) => {
        //   console.log('response !ok', res)
        //   // throw { ...res, status: response.status }
        // })
      }

      if (exportData) return response.blob();

      // check if content type non json object => return plain text
      if (response.headers.get('content-type') && response.headers.get('content-type').indexOf('application/json') === -1) {
        return response.text();
      }

      return response.json();
    })
    .then((data) => {
      // if (success) return success(data, successMessage)
      // if (successMessage) return { data, success: successMessage }
      return data;
    })
    .catch((error) => {
      if (error?.status === 500) {
        toast('Something went wrong. Contact us for more information.', {
          type: 'error',
        });
        throw error;
      }

      if (error?.status === 404) {
        toast(error?.message || 'Query Not Found', {
          type: 'error',
        });

        throw error;
      }

      if (error?.status === 403) {
        toast(error?.message || 'Sorry, you have insufficient permissions', {
          type: 'error',
        });

        throw error;
      }

      if (error?.status === 401) {
        toast(error?.message || 'Invalid email or password', {
          type: 'error',
        });
        throw error;
      }

      throw error;
    });

  return response;
};
