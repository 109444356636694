import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { default as PhoneInput2 } from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import './styles.css';

interface IInputProps {
  className?: string;
  [propName: string]: any;
}

export const PhoneInput: FC<IInputProps> = forwardRef(({ error, className = '', ...rest }, ref: any) => {
  return (
    <>
      <PhoneInput2
        country={'us'}
        {...rest}
        inputClass={cn(
          'block !w-full !h-12 !pl-[4.75rem] !appearance-none !text-xs !leading-6 !font-normal !text-black-b1 !placeholder-gray-gm !rounded-[4px] !border  focus:!ring-0 focus:!outline-none',
          // className,
          error ? '!border-red-r1 focus:!border-red-r1' : '!border-gray-g2 focus:!border-blue-b1'
        )}
        dropdownClass="QWE !border-gray-g2 focus:!border-blue-b1"
        aria-invalid={error ? 'true' : 'false'}
      />
    </>
  );
});
