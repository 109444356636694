import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Tabs } from 'shared';
import { Details } from './tabs/Details';
import { Videos } from './tabs/Videos';
import { SponsoredVideos } from './tabs/SponsoredVideos';
import { fetchInfluencerThunk, fetchSavedChannelListWithoutLoadingThunk, influencersActions, selectSavedChannelList } from 'pages/Influencers/reducer';
import { ROLE } from 'app/RequiredRole';
import { fetchCampaignThunk } from 'pages/Campaign/reducer';

interface IInfluencerProps {
  role: ROLE;
}

export const Influencer: FC<IInfluencerProps> = ({ role }) => {
  const params = useParams();
  const { state } = useLocation(); // TODO add campaignName from reducer state, not location.state
  const savedChannelList = useAppSelector(selectSavedChannelList);

  const dispatch = useAppDispatch();

  const influencerName = state?.influencerName || params?.influencerId || '';

  useLegacyEffect(() => {
    if (params?.influencerId) dispatch(fetchInfluencerThunk(params.influencerId));
  }, [params?.influencerId]);

  // The following piece of code invokes a spam of API calls.
  // I've commented it because of it.
  // Saved lists seem to work fine even without it,
  // So I don't know why it is needed at all.
  //
  // Need for add to saved list modal
  // useLegacyEffect(() => {
  //   if (role !== ROLE.user && !savedChannelList.length)
  //     dispatch(fetchSavedChannelListWithoutLoadingThunk())
  // }, [role, savedChannelList])

  useLegacyEffect(() => {
    return () => {
      dispatch(influencersActions.resetInfluencer());
    };
  }, []);

  useLegacyEffect(() => {
    if (params?.campaignId) dispatch(fetchCampaignThunk(params.campaignId));
  }, [params?.campaignId]);

  return (
    // <Tabs
    //   classList="pt-9 justify-end"
    //   tabs={[
    //     {
    //       query: 'details',
    //       title: 'Details',
    //       panelClass: '!p-0',
    //       content: <Details role={role} />,
    //     },
    //     {
    //       query: 'videos',
    //       title: 'Videos',
    //       content: <Videos influencerName={influencerName} />,
    //     },
    //     {
    //       query: 'sponsored_videos',
    //       title: 'Sponsored videos',
    //       content: <SponsoredVideos influencerName={influencerName} />,
    //     },
    //   ]}
    // />
    <Details role={role} />
  );
};
