import { FC, useMemo, useState } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch } from 'hooks/store';
import { StylesConfig } from 'react-select';
import { Controller } from 'react-hook-form';
import cn from 'classnames';
import { ValidationTypes } from 'utils/validation';
import { fetchCampaignsAllThunk } from 'pages/Campaigns/reducer';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { ClipLoader, Form, Label, RSelect, RSelectStyles } from 'shared';

import 'pages/Influencers/tabs/styles.css';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

interface IAddToCampaignProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AddToCampaign: FC<IAddToCampaignProps> = ({ params, hideModal }) => {
  const dispatch = useAppDispatch();

  const [campaigns, setCampaigns] = useState<any[]>([]);

  const defaultValues: any = useMemo(
    () => ({
      campaign_id: '',
    }),
    []
  );

  const campaignOptions = useMemo(
    () =>
      campaigns.map((d: any) => ({
        value: d.campaign_id,
        label: d.campaign_name,
      })),
    [campaigns]
  );

  useLegacyEffect(() => {
    dispatch(fetchCampaignsAllThunk({ page_size: 'all' }))
      .unwrap()
      .then((res) => {
        if (res.campaigns) setCampaigns(res.campaigns);
      });
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={`Add to campaign`} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AddToCampaign}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params.handleSave(values).then(() => hideModal());
        }}
      >
        {({ control, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="campaign_id" label="Select Campaign" error={errors.campaign_id} />
              <div className="mt-0.5">
                <Controller
                  name="campaign_id"
                  control={control}
                  render={({ field }: any) => (
                    <RSelect
                      {...field}
                      styles={selectStyles}
                      placeholder="Campaign name"
                      options={campaignOptions}
                      error={errors.campaign_id}
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={campaignOptions.find((option: any) => option.value === field.value) || null}
                      onChange={(option: any) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="!mt-8 grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                <p>Save</p>
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
