import { FC, useCallback, useMemo, useRef } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick';
import moment from 'moment';
import cn from 'classnames';
import { Form } from 'shared';
import { createMessageChatThunk, fetchChatThunk, hideChat, selectChatData, selectIsOpen, selectParams } from './reducer';
import { ChatEmpty } from './ChatEmpty';
import { ChatSkeleton } from './ChatSkeleton';
import { Avatar, ProfileAvatar } from 'components/Avatar';
import { ArrowUpLong14x18Icon, CloseIcon, ResetIcon } from 'assets/icons';
import { campaignActions } from 'pages/Campaign/reducer';
import { campaignClientActions } from 'pages-client/Campaign/reducer';

export const Chat: FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectIsOpen);
  const params = useAppSelector(selectParams);
  const chatData = useAppSelector(selectChatData);

  const chatRef = useRef<any>(null); // For outside click detect
  const resetFormRef = useRef<any>(() => null); // For reset function from react-hook-form
  const textareaRef = useRef<any>(null); // For dynamic textarea height
  const messagesRef = useRef<any>(null); // For auto scroll bottom
  useDetectOutsideClick(chatRef, () => {
    if (params.__from === 'manager')
      dispatch(
        campaignActions.updateChannelsComments({
          selected_id: params.selected_id,
          data: chatData || [],
        })
      );
    if (params.__from === 'client')
      dispatch(
        campaignClientActions.updateChannelsComments({
          selected_id: params.selected_id,
          data: chatData || [],
        })
      );
    dispatch(hideChat());
  });

  const defaultValues: any = useMemo(
    () => ({
      message: '',
    }),
    []
  );

  const scrollToBottom = () => {
    if (messagesRef.current != null) messagesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleFetchChat = useCallback(() => {
    dispatch(
      fetchChatThunk({
        campaign_id: params.campaign_id,
        selected_id: params.selected_id,
      })
    )
      .unwrap()
      .then(() => scrollToBottom());
  }, [params.campaign_id, params.selected_id]);

  useLegacyEffect(() => {
    handleFetchChat();
    // const refresh = () => {
    //   console.log('REFRESH')
    //   getChatApi(params.selected_id)
    // }
    // window.addEventListener('beforeunload', refresh)
    // return () => {
    //   window.removeEventListener('beforeunload', refresh)
    // }
  }, [handleFetchChat]);

  if (!isOpen) return null;

  return (
    // width=422px
    <div ref={chatRef} className="fixed z-40 top-[72px] right-0 w-[26.375rem] h-[calc(100vh-72px)] flex flex-col border border-gray-g2 bg-white">
      <div className="relative px-6 py-4 border-b border-gray-g2">
        <p className="text-base font-semibold leading-6 text-black-b1">Comments</p>
        <p className="text-sm leading-6 text-black-b1">{params.channel_name}</p>
        <ResetIcon className="absolute right-14 top-4 cursor-pointer" pathFill="#414141" onClick={() => handleFetchChat()} />
        <CloseIcon className="absolute right-5 top-4 cursor-pointer" onClick={() => dispatch(hideChat())} />
      </div>
      {!chatData ? (
        <ChatSkeleton />
      ) : chatData.length ? (
        <div className="flex-1 px-6 py-4 overflow-y-auto">
          <ul role="list" className="last-of-type:pb-0">
            {chatData.map((chatItem: any) => (
              <li key={chatItem.message_id} className="pb-6 last-of-type:pb-0">
                <div className="flex space-x-3">
                  <div className="">
                    <Avatar
                      src={chatItem.avatar_url}
                      initials={chatItem.full_name}
                      imageClass="w-[34px] h-[34px] aspect-h-0 rounded-full"
                      initialsClass="w-[34px] h-[34px] rounded-full"
                    />
                  </div>
                  <div className="flex-1 space-y-1.5">
                    <div className="mt-1 flex items-center justify-between">
                      <p className="text-sm font-semibold leading-6 text-black-b1">{chatItem.full_name || 'Unknown'}</p>
                      <p className="text-xs leading-6 text-gray-gm">{moment(chatItem.timestamp).fromNow()}</p>
                    </div>
                    <p className="whitespace-pre-line text-xs leading-6 text-black-b1">{chatItem.message}</p>
                  </div>
                </div>
              </li>
            ))}
            <div ref={messagesRef} />
          </ul>
        </div>
      ) : (
        <ChatEmpty />
      )}
      <div className="flex items-end px-6 py-6 space-x-3">
        <div className="flex-shrink-0 mb-[15px]">
          <ProfileAvatar imageClass="w-[34px] rounded-full" initialsClass="w-[34px] h-[34px] rounded-full" />
        </div>
        <Form
          className={cn('relative flex-1', !chatData ? 'pointer-events-none' : '')}
          defaultValues={defaultValues}
          onSubmit={(values) => {
            console.log('SUMBIT', values);
            return dispatch(
              createMessageChatThunk({
                campaign_id: params.campaign_id,
                selected_id: params.selected_id,
                data: {
                  message: values.message,
                },
              })
            )
              .unwrap()
              .then(() => {
                resetFormRef.current();
                scrollToBottom();
              });
          }}
        >
          {({ register, reset, watch, handleSubmit, onSubmit, formState: { isSubmitting } }) => {
            resetFormRef.current = reset;

            const { ref, ...rest } = register('message', { required: true });

            const message = watch('message');

            // Need for dynamic textarea height
            if (textareaRef.current) {
              // We need to reset the height momentarily to get the correct scrollHeight for the textarea
              textareaRef.current.style.height = '0px';
              const scrollHeight = textareaRef.current.scrollHeight + 2;

              // We then set the height directly, outside of the render loop
              // Trying to set this with state or a ref will product an incorrect value.
              textareaRef.current.style.height = scrollHeight + 'px';
              textareaRef.current.style.minHeight = '4rem';

              const _height = (textareaRef.current.style.height || '').replace('px', '');
              if (_height > 64) textareaRef.current.style.paddingTop = '0.75rem';
              else textareaRef.current.style.paddingTop = '19px';
            }

            return (
              <>
                <textarea
                  id="message"
                  name="message"
                  type="text"
                  autoComplete="message"
                  rows={1}
                  tabIndex={-1}
                  placeholder="Add comment, @ to mention..."
                  className="block w-full max-h-28 p-3 pt-[19px] pr-14 resize-none appearance-none text-xs leading-6 font-normal bg-gray-g1 text-black placeholder-gray-gm rounded-[4px] border border-gray-g1 focus:border-blue-b1 focus:ring-0 focus:outline-none"
                  {...rest}
                  ref={(e) => {
                    ref(e);
                    textareaRef.current = e;
                  }}
                  onKeyDown={(e: any) => {
                    // TODO check key code for macOS
                    if ((e.key === 'Enter' || e.keyCode == 13) && e.shiftKey == false) {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <button
                  type="submit"
                  className={cn(
                    'absolute bottom-[11px] right-[11px] cursor-pointer flex items-center justify-center w-[42px] h-[42px] rounded-full bg-blue-b1',
                    !chatData || isSubmitting || !message ? 'disabled !bg-gray-g2' : ''
                  )}
                  disabled={!chatData || isSubmitting || !message}
                >
                  <ArrowUpLong14x18Icon />
                </button>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
};
