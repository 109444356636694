import { ChangeEvent, FC, useState } from 'react';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { AsyncSelectMulti } from 'shared';

import 'components/Search/searches/stylesCampaigns.css';

interface ISendToReviewProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

const emailsOptions: any[] = [
  { value: 'mladzhun@gmail.com', label: 'mladzhun@gmail.com' },
  { value: 'dkdlls@gmail.com', label: 'dkdlls@gmail.com' },
  { value: 'xcbfgh@gmail.com', label: 'xcbfgh@gmail.com' },
];

export const SendToReview: FC<ISendToReviewProps> = ({ hideModal }) => {
  const [formState, setFormState] = useState<any>({
    emails: [],
  });

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => setFormState((prev: any) => ({ ...prev, [name]: value }));

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={'Send to client for review'} close={hideModal} />
      <form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        onSubmit={() => {
          console.log('SUMBIT', formState);
          hideModal();
        }}
      >
        <div>
          <label htmlFor="name" className="block text-xs font-normal leading-6 text-black">
            Client email address
          </label>
          <div className="mt-0.5">
            <AsyncSelectMulti
              options={emailsOptions}
              required={true}
              openMenuOnClick={false}
              isClearable={false}
              placeholder="Enter email address"
              // options={colourOptions}
              // onChange={(props: any) => handleChange('search', props)}
              components={{
                // ...animatedComponents, // width: auto not working with animation
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8">
          <button
            type="button"
            className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
            onClick={hideModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex w-full h-12 items-center justify-center text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};
