import { FC } from 'react';
import { ChatEmptyIcon } from 'assets/icons';

export const ChatEmpty: FC = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <ChatEmptyIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">No messages</h1>
      <p className="mt-2 text-center text-sm leading-6 text-black-b1">Start a conversation to see messages here</p>
    </div>
  );
};
