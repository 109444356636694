import { FC } from 'react';
import { Tabs } from 'shared';
import { ManageInfluencers } from './tabs/ManageInfluencers';
import { PeopleManagement } from './tabs/PeopleManagement';
import { ManageAgencies } from './tabs/ManageAgencies';

export const Admin: FC = () => {
  return (
    <>
      <Tabs
        classRoot="pt-6"
        tabs={[
          {
            query: 'manage_influencers',
            title: 'Manage influencers',
            content: <ManageInfluencers />,
            panelClass: '!p-0',
          },
          {
            query: 'people_management',
            title: 'People management',
            content: <PeopleManagement />,
            panelClass: '!p-0',
          },
          {
            query: 'manage_agencies',
            title: 'Manage agencies',
            content: <ManageAgencies />,
            panelClass: '!p-0',
          },
        ]}
      />
    </>
  );
};
