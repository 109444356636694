import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/store';
import { Form, Input, Label } from 'shared';
import { ValidationTypes } from 'utils/validation';
import { fetchSignInThunk, fetchOAuthThunk } from './reducer';
import { googleLoginUrl, facebookLoginUrl, getOAuthData } from './oauth';
import { ISignInRequest } from './types'; // TODO move to services api
import { EyeCloseIcon, EyeOpenIcon, FacebookIcon, GmailIcon } from 'assets/icons';

import logo from 'assets/logo/Cloutboost_Horizontal_logo_(Warm Gradient)@4x_(1).png';
import gamersImage from 'assets/logo/Combined_All_gamers.jpg';

export const SignIn: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isShow, setIsShow] = useState<boolean>(false);

  const ignoreRef = useRef(false);
  let isCorrectFieldsText = useRef(true); // For submit error text when invalid fields

  const defaultValues: ISignInRequest = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    []
  );

  const isFromResetPassword = location.state?.fromResetPassword;

  // TODO: need to fix multiple call
  useEffect(() => {
    if (location.search && !ignoreRef.current) {
      const oauthData = getOAuthData(location.search);
      if (oauthData.code) {
        dispatch(fetchOAuthThunk(oauthData))
          .unwrap()
          .then(() => {
            ignoreRef.current = false;
            navigate(location.state?.path || '/', { replace: true });
          })
          .catch((err: any) => {
            ignoreRef.current = false;
            // toast({
            //   status: 'error',
            //   title: 'Error',
            //   description: 'Oh no, there was an error!',
            //   isClosable: true,
            // })
          });
      }
    }
    return () => {
      ignoreRef.current = true;
    };
  }, [location.search]);

  return (
    <>
      <div className="relative min-h-full flex lg:grid lg:grid-cols-2 sm:p-6">
        <Link to="/login" className="absolute top-4 left-4 sm:top-6 sm:left-6 flex items-center">
          <img className="w-[187px]" src={logo} alt="Cloutboost" />
        </Link>
        <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {isFromResetPassword ? (
            <div className="mx-auto w-full max-w-sm lg:w-[295px]">
              <h1 className="mt-6 text-2xl leading-[42px] font-semibold tracking-tight text-black">Log in to Cloutboost</h1>
              <p className="mt-3 text-[10px] leading-6 text-green-g1">Your password has been updated.</p>

              <div className="mt-3 lg:w-[280px]">
                <div className="mt-2">
                  <Form
                    defaultValues={defaultValues}
                    validationType={ValidationTypes.login}
                    onSubmit={(values) => {
                      dispatch(fetchSignInThunk(values))
                        .unwrap()
                        .then(() => {
                          navigate(location.state?.path || '/', {
                            replace: true,
                          });
                        })
                        .catch((err: any) => {
                          // toast({
                          //   status: 'error',
                          //   title: 'Error',
                          //   description: 'Oh no, there was an error!',
                          //   isClosable: true,
                          // })
                        });
                    }}
                  >
                    {({ register, formState: { errors, isSubmitting, isValid } }) => {
                      if (isSubmitting) isCorrectFieldsText.current = isValid;
                      return (
                        <>
                          <div>
                            <Label htmlFor="email" label="Email address" error={errors.email} />
                            <div className="mt-0.5">
                              <Input id="email" type="text" placeholder="Email" error={errors.email} {...register('email')} />
                            </div>
                          </div>

                          <div className="mt-3">
                            <Label htmlFor="password" label="Password" error={errors.password} />
                            <div className="relative mt-0.5">
                              <Input
                                id="password"
                                type={isShow ? 'text' : 'password'}
                                placeholder="Password"
                                error={errors.password}
                                {...register('password')}
                              />
                              <span className="absolute top-3 right-3 text-black-b1 cursor-pointer" onClick={() => setIsShow((prev) => !prev)}>
                                {isShow ? <EyeOpenIcon /> : <EyeCloseIcon />}
                              </span>
                            </div>
                          </div>

                          <div className="relative pt-10">
                            {!isCorrectFieldsText.current ? (
                              <div className="absolute top-2.5 left-0 text-[10px] leading-6 text-red-r1">Incorrect user ID or password. Try again.</div>
                            ) : null}

                            <button
                              type="submit"
                              className="flex w-full h-12 items-center justify-center uppercase text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0"
                            >
                              Log in
                            </button>
                          </div>
                        </>
                      );
                    }}
                  </Form>
                </div>
              </div>
            </div>
          ) : (
            <div className="mx-auto w-full max-w-sm lg:w-[295px]">
              <div>
                <h1 className="mt-6 text-2xl leading-[42px] font-semibold tracking-tight text-black">Welcome to Cloutboost</h1>
                <p className="mt-3 text-sm font-normal leading-6 text-black">To access your account, please log in</p>
              </div>

              <div className="mt-7 lg:w-[280px]">
                <a
                  href={googleLoginUrl}
                  className="inline-flex w-full h-12 p-3 items-center rounded-[4px] border border-gray-g1 bg-gray-g1 text-sm text-pink-500 shadow-sm hover:bg-gray-100 cursor-pointer"
                >
                  <span className="sr-only">Continue with Google</span>
                  <GmailIcon />
                  <p className="ml-3 text-xs font-normal leading-6 text-black">Continue with Google</p>
                </a>

                <a
                  // href=""
                  href={facebookLoginUrl}
                  className="mt-3 inline-flex w-full h-12 p-3 items-center rounded-[4px] border border-gray-g1 bg-gray-g1 text-sm text-pink-500 shadow-sm hover:bg-gray-100 cursor-pointer"
                  // aria-disabled={true}
                >
                  <span className="sr-only">Continue with Facebook</span>
                  <FacebookIcon />
                  <p className="ml-3 text-xs font-normal leading-6 text-black">Continue with Facebook</p>
                </a>

                <div className="relative mt-5">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex items-center justify-center">
                    <span className="bg-white pb-1 px-3 text-xs leading-6 font-normal text-black">or</span>
                  </div>
                </div>

                <div className="mt-2">
                  <Form
                    defaultValues={defaultValues}
                    validationType={ValidationTypes.login}
                    onSubmit={(values) => {
                      dispatch(fetchSignInThunk(values))
                        .unwrap()
                        .then(() => {
                          navigate(location.state?.path || '/', {
                            replace: true,
                          });
                        })
                        .catch((err: any) => {
                          // toast({
                          //   status: 'error',
                          //   title: 'Error',
                          //   description: 'Oh no, there was an error!',
                          //   isClosable: true,
                          // })
                        });
                    }}
                  >
                    {({ register, formState: { errors, isSubmitting, isValid } }) => {
                      if (isSubmitting) isCorrectFieldsText.current = isValid;
                      return (
                        <>
                          <div>
                            <Label htmlFor="email" label="Email address" error={errors.email} />
                            <div className="mt-0.5">
                              <Input id="email" type="text" placeholder="Email" error={errors.email} {...register('email')} />
                            </div>
                          </div>

                          <div className="mt-3">
                            <Label htmlFor="password" label="Password" error={errors.password} />
                            <div className="relative mt-0.5">
                              <Input
                                id="password"
                                type={isShow ? 'text' : 'password'}
                                placeholder="Password"
                                error={errors.password}
                                {...register('password')}
                              />
                              <span className="absolute top-3 right-3 text-black-b1 cursor-pointer" onClick={() => setIsShow((prev) => !prev)}>
                                {isShow ? <EyeOpenIcon /> : <EyeCloseIcon />}
                              </span>
                            </div>
                          </div>

                          <div className="mt-3 flex items-center justify-end">
                            <Link to="/forgot-password" className="text-[10px] font-normal text-blue-b1 hover:text-blue-b1">
                              Forgot your password?
                            </Link>
                          </div>

                          <div className="relative pt-10">
                            {!isCorrectFieldsText.current ? (
                              <div className="absolute top-2.5 left-0 text-[10px] leading-6 text-red-r1">Incorrect user ID or password. Try again.</div>
                            ) : null}

                            <button
                              type="submit"
                              className="flex w-full h-12 items-center justify-center uppercase text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0"
                            >
                              Log in
                            </button>
                          </div>
                        </>
                      );
                    }}
                  </Form>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="relative hidden flex-1 lg:block">
          <img className="absolute h-full w-full rounded-[30px] object-cover" src={gamersImage} alt="" />
        </div>

        {/* For fixed position when scrolling page */}

        {/* <div className="fixed top-6 right-6 bottom-6 hidden w-[calc(50%-24px)] h-[calc(100vh-48px)] lg:block">
          <img
            className="h-full w-full rounded-[30px] object-cover"
            src={gamersImage}
            alt=""
          />
        </div> */}
      </div>
    </>
  );
};
