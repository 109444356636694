import { useMemo } from 'react';
import { useAppSelector } from './store';
import { selectCommon } from 'app/reducer';
import { countries } from 'utils/countries';
import { orderBy } from 'lodash';

export const useCountries = () => {
  const common = useAppSelector(selectCommon);

  return useMemo(() => {
    if (common.countries) {
      return orderBy(
        common.countries.map(
          (d: any) =>
            countries.find((dd: any) => d === dd['alpha-2']) || {
              name: d,
              'alpha-2': d,
            }
        ),
        'name'
      );
    }
    return [];
  }, [common.countries]);
};

export const useCountriesSelectOptions = () => {
  const countries = useCountries();

  return useMemo(() => countries.map((d: any) => ({ value: d['alpha-2'], label: d.name })), [countries]);
};

export const useCountriesAsObject = () => {
  const countries = useCountries();

  return useMemo(
    () =>
      countries.reduce(
        (a: any, c: any) => ({
          ...a,
          [c['alpha-2']]: c,
        }),
        {}
      ),
    [countries]
  );
};
