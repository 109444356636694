import { useMemo } from 'react';
import { useAppSelector } from './store';
import { selectCommon } from 'app/reducer';
import { languages } from 'utils/languages';
import { orderBy } from 'lodash';

export const useLanguages = () => {
  const common = useAppSelector(selectCommon);

  return useMemo(() => {
    if (common.languages) {
      return orderBy(
        common.languages.map(
          (d: any) =>
            languages.find((dd: any) => d === dd.alpha2) || {
              English: d,
              alpha2: d,
            }
        ),
        'English'
      );
    }
    return [];
  }, [common.languages]);
};

export const useLanguagesSelectOptions = () => {
  const languages = useLanguages();

  return useMemo(() => languages.map((d: any) => ({ value: d.alpha2, label: d.English })), [languages]);
};

export const useLanguagesAsObject = () => {
  const languages = useLanguages();

  return useMemo(
    () =>
      languages.reduce(
        (a: any, c: any) => ({
          ...a,
          [c.alpha2]: c,
        }),
        {}
      ),
    [languages]
  );
};
