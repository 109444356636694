import { FC, forwardRef, useMemo, memo, useRef, useImperativeHandle } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useTable, useRowSelect, useBlockLayout, useSortBy, useResizeColumns } from 'react-table';
import { useVirtual } from 'react-virtual';
import moment from 'moment';
import { isEqual, orderBy } from 'lodash';
import cn from 'classnames';
import { colors } from 'variables';
import {
  dateSort,
  formatNumber,
  getCpm,
  getCpmOtherDenominator,
  getTooltip,
  getTotalForCPM,
  numberSort,
  publicationDateFormat,
  replaceSpaceToUnderscore,
  statusSort,
  localeSort,
  filterCampaignChannels,
} from 'utils/helper';
import { Breadcrumbs, HSelect, MenuActionWithFloat, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import {
  approvalManagementStatuses,
  assetsStatuses,
  campaignManagementConfig,
  campaignStatuses,
  contentFormatStatuses,
  contractStatuses,
  publishStatuses,
} from 'utils/common';
import { PopupDescription } from 'components/PopupDescription';
import { FormatSelect } from '../components/FormatSelect';
import { smPlatformConfig } from './Engagement';
import {
  campaignActions,
  deleteInManagementThunk,
  fetchCampaignManagementDataThunk,
  selectCampaign,
  selectCampaignManagementData,
  selectCampaignManagementSettingsFilters,
  selectCampaignManagementSettingsSearchValue,
  selectCampaignManagementSettingsTableFilter,
  selectCampaignManagementSettingsTableFilterHiddenColumns,
  selectCampaignManagementSettingsTableFilterResizing,
  selectCampaignManagementSettingsTableFilterSortBy,
  selectCampaignSavedManagementIds,
  updateCampaignManagementDataThunk,
} from '../reducer';
import { selectRole } from 'pages/Auth/reducer';
import { selectAgencies } from 'pages/Admin/reducer';
import { showChat } from 'components/Chat/reducer';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { SimpleSearch } from 'components/SimpleSearch';
import { ChatTableView } from 'components/Chat/ChatTableView';
import { ExportMenu } from 'components/ExportMenu';
import { ManagementEmpty } from '../empties/ManagementEmpty';
import { IndeterminateCheckbox, getTotalForColumn } from 'pages/Influencers/tabs/InfluencersTab';
import { checkContentFormat, engagementColumnsForContentFormat, getColumnFieldByContentFormat, getTotalForContentFormat } from './Selected';
import { CampaignManagementSettings } from 'components/Settings';
import { Bars3Icon, LinkIcon, BarChartSolidIcon, DotsIcon } from 'assets/icons';

import './styles.css';

const tableColumns = ({ dispatch, campaignId, agencies }: any) => [
  {
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <div className="flex items-center space-x-2 truncate">
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
        <TableHeaderTruncate name={campaignManagementConfig.channel_name.name} />
      </div>
    ),
    accessor: 'channel_name',
    Cell: ({ row, value }: any) => {
      return (
        <div className="flex items-center space-x-2 overflow-hidden">
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
          <TableCellTruncate
            linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
            value={value}
          />
          <a href={row.original.channel_url} target="_blank" rel="noopener">
            <LinkIcon />
          </a>
        </div>
      );
    },
    Footer: 'Total',
    width: 220,
    sortType: localeSort,
  },
  {
    Header: campaignManagementConfig.sm_platform.name,
    accessor: 'sm_platform',
    Cell: ({ value }: any) => <div className="flex justify-center items-center">{smPlatformConfig[value]}</div>,
    width: 130,
    className: 'justify-center',
  },
  {
    Header: campaignManagementConfig.published.name,
    accessor: 'published',
    Cell: ({ row, value }: any) => {
      const status = publishStatuses(value);
      return (
        <div className="flex">
          <div
            className={`px-2 text-white text-[10px] h-6 rounded-[4px] cursor-pointer ${status.class} ${value ? 'pointer-events-none' : ''} ${
              row.original.approval_management !== 'yes' && !value ? 'pointer-events-none opacity-75' : ''
            }`}
            onClick={() => {
              if (!value)
                dispatch(
                  updateCampaignManagementDataThunk({
                    campaign_id: campaignId || '',
                    selected_id: row.original.selected_id,
                    data: {
                      published: true,
                    },
                  })
                );
            }}
          >
            {status.label}
          </div>
        </div>
      );
    },
    width: 130,
  },
  {
    Header: campaignManagementConfig.approval_management.name,
    accessor: 'approval_management',
    Cell: ({ row, value }: any) => (
      <FormatSelect
        value={value}
        options={approvalManagementStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignManagementDataThunk({
                campaign_id: campaignId || '',
                selected_id: row.original.selected_id,
                data: {
                  approval_management: value,
                },
              })
            );
        }}
      />
    ),
    width: 130,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, approvalManagementStatuses),
  },
  {
    Header: campaignManagementConfig.campaign_status.name,
    accessor: 'campaign_status',
    Cell: ({ row, value }: any) => (
      <FormatSelect
        value={value}
        options={campaignStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignManagementDataThunk({
                campaign_id: campaignId || '',
                selected_id: row.original.selected_id,
                data: {
                  campaign_status: value,
                },
              })
            );
        }}
      />
    ),
    width: 180,
    minWidth: 180,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, campaignStatuses),
  },
  {
    Header: campaignManagementConfig.contract_status.name,
    accessor: 'contract_status',
    Cell: ({ value }: any) => <FormatSelect isDisabled={true} noDropdownIndicator={true} value={value} options={contractStatuses} onChange={() => null} />,
    width: 170,
    minWidth: 170,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contractStatuses),
  },
  {
    Header: campaignManagementConfig.price.name,
    accessor: 'price',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: campaignManagementConfig.cpm.name,
    accessor: 'cpm',
    Cell: ({ row }: any) => (
      <TableCellTruncate
        value={formatNumber(
          row.original.sm_platform === 'youtube' ? getCpm(row.original.price, getCpmOtherDenominator(row.original), row.original.sm_platform) : null,
          '$0,0.[00]',
          'float'
        )}
      />
    ),
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price', 'youtube'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: campaignManagementConfig.cpvh.name,
    accessor: campaignManagementConfig.cpvh.id,
    Cell: ({ row }: any) => (
      <TableCellTruncate
        value={formatNumber(
          row.original.sm_platform === 'twitch' ? getCpm(row.original.price, getCpmOtherDenominator(row.original), row.original.sm_platform) : null,
          '$0,0.[00]',
          'float'
        )}
      />
    ),
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price', 'twitch'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: campaignManagementConfig.subscribers.name,
    accessor: 'subscribers',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'subscribers'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: campaignManagementConfig.average_views.name,
    accessor: 'average_views',
    Cell: (info: any) => (
      <TableCellTruncate value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_views : info.value, '0,0')} />
    ),
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'average_views'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: campaignManagementConfig.tracking_link.name,
    accessor: 'tracking_link',
    Cell: ({ value }: any) => (
      <TableCellTruncate
        value={
          <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
            {value}
          </a>
        }
      />
    ),
    width: 130,
  },
  {
    Header: campaignManagementConfig.content_format.name,
    accessor: 'content_format',
    Cell: ({ row, value }: any) => (
      <FormatSelect
        value={value}
        options={contentFormatStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignManagementDataThunk({
                campaign_id: campaignId || '',
                selected_id: row.original.selected_id,
                data: {
                  content_format: value,
                },
              })
            );
        }}
      />
    ),
    width: 180,
    minWidth: 180,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contentFormatStatuses),
  },
  {
    Header: campaignManagementConfig.theme.name,
    accessor: campaignManagementConfig.theme.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
  },
  {
    Header: campaignManagementConfig.giveaway_code.name,
    accessor: 'giveaway_code',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
  },
  {
    Header: campaignManagementConfig.assets_status.name,
    accessor: 'assets_status',
    Cell: ({ row, value }: any) => (
      <FormatSelect
        value={value}
        options={assetsStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignManagementDataThunk({
                campaign_id: campaignId || '',
                selected_id: row.original.selected_id,
                data: {
                  assets_status: value,
                },
              })
            );
        }}
      />
    ),
    width: 180,
    minWidth: 180,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, assetsStatuses),
  },
  {
    Header: campaignManagementConfig.draft_due_date.name,
    accessor: 'draft_due_date',
    Cell: ({ value }: any) => <TableCellTruncate value={value ? moment(value).format('MMM DD-YY') : ''} />,
    width: 130,
    sortType: dateSort,
  },
  {
    Header: campaignManagementConfig.publication_date.name,
    accessor: 'publication_date',
    Cell: ({ row, value }: any) => <TableCellTruncate value={value ? publicationDateFormat(value) : ''} />,
    width: 190,
    sortType: dateSort,
  },
  {
    Header: campaignManagementConfig.video_draft.name,
    accessor: 'video_draft',
    Cell: ({ value }: any) => (
      <TableCellTruncate
        value={
          <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
            {value}
          </a>
        }
      />
    ),
    width: 130,
  },
  {
    Header: campaignManagementConfig.video_final.name,
    accessor: 'video_final',
    Cell: ({ row, value }: any) =>
      row.original.sm_platform === 'youtube' ? (
        <TableCellTruncate
          value={
            <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
              {value}
            </a>
          }
        />
      ) : (
        <TableCellTruncate value={value} />
      ),
    width: 130,
  },
  {
    Header: campaignManagementConfig.direct_email.name,
    accessor: 'direct_email',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 220,
  },
  {
    Header: campaignManagementConfig.agency_id.name,
    accessor: 'agency_id',
    Cell: ({ value }: any) => <TableCellTruncate value={agencies?.[value]?.agency_name || ''} />,
    width: 130,
  },
  {
    Header: campaignManagementConfig.comments.name,
    accessor: 'comments',
    Cell: (row: any) => <ChatTableView row={row} from="manager" />,
    width: 130,
    className: 'justify-center',
    sortType: numberSort,
  },
];

export const makeColumnHiddenCheckboxes = (props: any): any[] => {
  const arr: any[] = [];

  tableColumns(props).forEach((d: any) => {
    if (campaignManagementConfig[d.accessor].role === 'main')
      arr.push({
        id: campaignManagementConfig[d.accessor].id,
        label: campaignManagementConfig[d.accessor].name,
        active: true,
      });
  });

  return arr;
};

interface IManagementProps {}

export const Management: FC<IManagementProps> = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign);
  const managementData = useAppSelector(selectCampaignManagementData);
  const savedManagementIds = useAppSelector(selectCampaignSavedManagementIds);
  const searchValue = useAppSelector(selectCampaignManagementSettingsSearchValue);
  const tableFilters = useAppSelector(selectCampaignManagementSettingsTableFilter);
  const hiddenColumns = useAppSelector(selectCampaignManagementSettingsTableFilterHiddenColumns);
  const filters = useAppSelector(selectCampaignManagementSettingsFilters);

  const agencies = useAppSelector(selectAgencies);

  const agenciesPrepare = useMemo(() => {
    let agencyObj: any = {};

    if (agencies.length) {
      agencies.forEach((agency: any) => {
        agencyObj[agency.agency_id] = { ...agency };
      });
    }

    return agencyObj;
  }, [agencies]);

  const tableRef = useRef<any>(null);

  const handleChangeSelect = (props: any) =>
    dispatch(
      campaignActions.setManagementSettings({
        tableFilters: {
          ...tableFilters,
          hiddenColumns: props,
        },
      })
    );

  const isEmpty = Boolean(!managementData.length);

  const columns = useMemo(() => {
    if (campaignId)
      return (
        [
          ...tableColumns({ dispatch, campaignId, agencies: agenciesPrepare }),
          {
            Header: '',
            accessor: 'action',
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            disabledResizing: true,
            disableSortBy: true,
            className: 'flex py-0 bg-gradient-to-l from-white via-white to-transparent group-hover:sticky group-hover:z-10 group-hover:right-0',
            Cell: ({ row }: any) => (
              <MenuActionWithFloat
                className="flex"
                optionClass="!px-2.5"
                items={[
                  {
                    label: 'Edit',
                    onClick: () => {
                      dispatch(
                        showModal({
                          type: ModalTypes.ManagementEdit,
                          params: {
                            influencer: row.original,
                            handleSave: async (data: any) =>
                              await dispatch(
                                updateCampaignManagementDataThunk({
                                  campaign_id: campaignId || '',
                                  selected_id: row.original.selected_id,
                                  data,
                                  thunk: { noLoading: true },
                                })
                              ),
                          },
                        })
                      );
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: () => {
                      dispatch(
                        showModal({
                          type: ModalTypes.DeleteConfirmation,
                          params: {
                            name: row.values.channel_name,
                            handleDelete: async () =>
                              await dispatch(
                                deleteInManagementThunk({
                                  selected_id: row.original.selected_id,
                                  campaign_id: row.original.campaign_id,
                                })
                              ),
                          },
                        })
                      );
                    },
                  },
                  {
                    label: 'Comment',
                    onClick: () => {
                      dispatch(showChat(row.original));
                    },
                  },
                ]}
              >
                {({ open }) => (
                  <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                    <DotsIcon aria-hidden="true" />
                  </span>
                )}
              </MenuActionWithFloat>
            ),
          },
        ].filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || []
      );

    return [];
  }, [campaignId, hiddenColumns, agenciesPrepare]);

  const hiddenColumnsOptions = useMemo(
    () =>
      tableColumns({ dispatch, campaignId, agencies: agenciesPrepare }).map((d: any) => ({
        id: d.accessor,
        name: campaignManagementConfig[d.accessor].name,
      })),
    [campaignId, agenciesPrepare]
  );

  const managementDataSorted = useMemo(() => orderBy(managementData, [(d) => d.channel_name || '', (d) => d.selected_id], ['asc', 'asc']), [managementData]);

  const managementDataFiltered = useMemo(() => filterCampaignChannels(managementDataSorted, { filters }), [managementDataSorted, filters]);

  const managementDataSearched = useMemo(
    () => managementDataFiltered.filter((d: any) => (d.channel_name || '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1),
    [searchValue, managementDataFiltered]
  );

  const exportColumns = useMemo(() => {
    const columns = tableColumns({
      dispatch,
      campaignId,
      agencies: agenciesPrepare,
    })
      .filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action'))
      .map((d: any) => ({
        key: campaignManagementConfig[d.accessor].id,
        header: campaignManagementConfig[d.accessor].name,
      }));

    columns.splice(1, 0, {
      key: 'channel_url',
      header: campaignManagementConfig['channel_url'].name,
    });
    return columns;
  }, [campaignId, hiddenColumns, agenciesPrepare]);

  const exportData = useMemo(() => {
    return managementDataSearched
      .filter((d: any) => (savedManagementIds.length ? savedManagementIds.includes(d.selected_id) : true))
      .map((d: any) => {
        const obj: any = {};
        exportColumns.forEach((dd: any) => {
          obj[dd.key] =
            Object.keys(engagementColumnsForContentFormat).includes(dd.key) && checkContentFormat(d.content_format)
              ? getColumnFieldByContentFormat[d.content_format]?.[dd.key]
                ? d[getColumnFieldByContentFormat[d.content_format][dd.key]] || 0
                : 0
              : d[dd.key];
        });
        return obj;
      });
  }, [managementDataSearched, exportColumns, savedManagementIds]);

  const handleApply = (value: any) => {
    dispatch(
      campaignActions.setManagementSettings({
        filters: value,
      })
    );
  };

  useLegacyEffect(() => {
    if (campaignId) dispatch(fetchCampaignManagementDataThunk(campaignId));
  }, [campaignId]);

  // Initialize hiddenColumns
  useLegacyEffect(() => {
    if (!hiddenColumns)
      dispatch(
        campaignActions.setManagementSettings({
          tableFilters: {
            ...tableFilters,
            hiddenColumns: makeColumnHiddenCheckboxes({
              dispatch,
              campaignId,
              agencies: agenciesPrepare,
            }),
          },
        })
      );
  }, [hiddenColumns, agenciesPrepare]);

  const role = useAppSelector(selectRole);

  return (
    <>
      <SimpleSearch
        className="py-4"
        placeholder="Search by campaign management"
        defaultValue={searchValue}
        onChange={(value: string) =>
          dispatch(
            campaignActions.setManagementSettings({
              searchValue: value,
            })
          )
        }
      />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">Campaign Management ({managementDataSearched.length})</h1>
          {isEmpty ? null : (
            <div className="flex items-center space-x-4">
              <button
                type="button"
                className="inline-flex items-center !ml-3 h-[42px] space-x-1 rounded-[4px] border border-transparent px-2.5 text-sm leading-6 font-semibold text-blue-b1 focus:outline-none focus:ring-0 focus:ring-blue-b1 focus:ring-offset-0"
                onClick={() => {
                  navigate(`/campaigns/${campaignId}?tab=report`);
                }}
              >
                <BarChartSolidIcon className="mr-1 mt-1" pathFill={colors.blue.b1} aria-hidden="true" />
                <p>Generate report</p>
              </button>
              <ExportMenu data={exportData} columns={exportColumns} filename={`${replaceSpaceToUnderscore(campaign.campaign_name)}_management`} />
              <HSelect
                menuClass="w-44 !origin-top-right !left-auto right-0"
                selected={hiddenColumns}
                options={hiddenColumnsOptions}
                onChange={(props: any) => handleChangeSelect(props)}
              >
                {({ open }) => (
                  <button
                    type="button"
                    className={cn(
                      'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                      open ? 'bg-blue-b1' : 'bg-white'
                    )}
                  >
                    <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                  </button>
                )}
              </HSelect>
            </div>
          )}
        </div>
        {/* TODO update breadcrumbs with data and routes */}
        <Breadcrumbs
          paths={[
            { name: `Campaigns`, to: `/campaigns` },
            {
              name: `${campaign.campaign_name}`,
              to: `/campaigns/${campaign.campaign_id}`,
            },
            {
              name: `Campaign Management`,
              to: '',
            },
          ]}
        />

        {isEmpty ? (
          <ManagementEmpty />
        ) : (
          <>
            {/* <CampaignManagementSettings
              campaign={campaign}
              searchChannels={{ filters }}
              role={role}
              handleSubmit={handleApply}
            /> */}

            <div className="flex flex-col overflow-hidden">
              <div className="flex flex-col min-w-full align-middle overflow-hidden">
                <Table ref={tableRef} columns={columns} data={managementDataSearched} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Table = memo(
  forwardRef(function Table({ columns, data }: any, ref: any) {
    const dispatch = useAppDispatch();
    const savedManagementIds = useAppSelector(selectCampaignSavedManagementIds);
    const tableFilters = useAppSelector(selectCampaignManagementSettingsTableFilter);
    const tfSortBy = useAppSelector(selectCampaignManagementSettingsTableFilterSortBy);
    const tfResizing = useAppSelector(selectCampaignManagementSettingsTableFilterResizing);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    const selectedRows = useMemo(() => {
      const ids: any = {};
      data.forEach((d: any, i: number) => {
        if (savedManagementIds.includes(d.selected_id)) ids[i] = true;
      });
      return ids;
    }, [data]); // savedManagementIds

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      toggleAllRowsSelected,
      // @ts-ignore
      state: { selectedRowIds, sortBy, columnResizing },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { selectedRowIds: selectedRows, sortBy: tfSortBy },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      useSortBy,
      useRowSelect,
      useResizeColumns,
      useBlockLayout
    );

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedRows: () => toggleAllRowsSelected(false),
      }),
      []
    );

    useLegacyEffect(() => {
      const ids: string[] = Object.keys(selectedRowIds).map((d: any) => data[d].selected_id);
      dispatch(campaignActions.setSavedManagementIds(ids));
    }, [selectedRowIds]);

    // TODO make better update tableFilters. May be use the getSortByToggleProps hook to extend its functionality
    useLegacyEffect(() => {
      dispatch(
        campaignActions.setManagementSettings({
          tableFilters: {
            ...tableFilters,
            sortBy,
          },
        })
      );
    }, [sortBy]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          campaignActions.setManagementSettings({
            tableFilters: {
              ...tableFilters,
              resizing: columnResizing.columnWidths,
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  // TODO shadow replace drop-shadow-d1
                  className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20"
                >
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          cell.column.id !== 'action' &&
                            cell.column.id !== 'published' &&
                            cell.column.id !== 'campaign_status' &&
                            cell.column.id !== 'assets_status' &&
                            cell.column.id !== 'approval_management' &&
                            cell.column.id !== 'content_format'
                            ? 'truncate'
                            : '',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }),
  (prev, next) => isEqual(prev, next)
);
