import { FC, useMemo } from 'react';
import cn from 'classnames';
import { ValidationTypes } from 'utils/validation';
import { Checkbox, ClipLoader, Form, Input, Label } from 'shared';
import { ModalHeader } from '../../index';
import { ModalState } from 'components/Modal/reducer';
import { RoleView } from 'pages/Admin/tabs/PeopleManagementTable';

interface IAdminAddNewManagerProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AdminAddNewManager: FC<IAdminAddNewManagerProps> = ({ params, hideModal }) => {
  const isUpdate = Boolean(params?.user?.user_id);

  const defaultValues: any = useMemo(
    () => ({
      full_name: params?.user?.full_name || '',
      email: params?.user?.email || '',
      role: params?.user?.role || 'manager',
    }),
    [params?.user]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isUpdate ? 'Update manager' : 'Add new manager'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AdminAddNewManager}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params
            .handleSave(
              // TODO may be need remove because this handle on server
              Object.keys(values).reduce((acc: any, cur: any) => {
                if (values[cur] != null) {
                  acc[cur] = values[cur];
                }

                return acc;
              }, {})
            )
            .then(() => hideModal());
        }}
      >
        {({ register, watch, setValue, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="full_name" label="Manager name" error={errors.full_name} />
              <div className="mt-0.5">
                <Input id="full_name" type="text" placeholder="Manager name" error={errors.full_name} {...register('full_name')} />
              </div>
            </div>
            <div>
              <Label htmlFor="email" label="Email" error={errors.email} />
              <div className="mt-0.5">
                <Input id="email" type="email" placeholder="Email address" error={errors.email} {...register('email')} />
              </div>
            </div>
            <div>
              <Label htmlFor="role" label="Role" />
              <div className="flex items-center space-x-4 mt-3">
                <Checkbox
                  // checked={watch('role').includes('admin')}
                  checked={watch('role') === 'admin'}
                  onChange={() =>
                    // setValue('role', xor(watch('role'), ['admin']))
                    setValue('role', 'admin')
                  }
                />
                <RoleView role="admin" />
              </div>
              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'manager'} onChange={() => setValue('role', 'manager')} />
                <RoleView role="manager" />
              </div>
              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'campaign_manager'} onChange={() => setValue('role', 'campaign_manager')} />
                <RoleView role="campaign_manager" />
              </div>
              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'influencer_manager'} onChange={() => setValue('role', 'influencer_manager')} />
                <RoleView role="influencer_manager" />
              </div>
              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'commenter'} onChange={() => setValue('role', 'commenter')} />
                <RoleView role="commenter" />
              </div>
              <div className="flex items-center space-x-4 mt-5">
                <Checkbox checked={watch('role') === 'viewer'} onChange={() => setValue('role', 'viewer')} />
                <RoleView role="viewer" />
              </div>
            </div>
            <div className="!mt-8 grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                {isUpdate ? <p>Save</p> : <p>Create</p>}
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
