import { useIsAuth } from 'hooks/useIsAuth';
import { Navigate } from 'react-router-dom';

export const RedirectAuth = ({ children }: { children: JSX.Element }) => {
  let isAuth = useIsAuth();

  if (isAuth) {
    return <Navigate to="/" replace />;
  }

  return children;
};
