import { makeRequest } from './http';

export const getUsersApi = async (search?: string) => {
  const response = await makeRequest({
    path: `/users${search ? '?search=' + search : ''}`,
    method: 'GET',
  });
  return response;
};

export const createUserApi = async (data: ICreateUser) => {
  const response = await makeRequest({
    path: `/users`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateUserApi = async ({ user_id, data }: IUpdateUser) => {
  const response = await makeRequest({
    path: `/users/${user_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const uploadUserAvatarApi = async (formData: any) => {
  const response = await makeRequest({
    path: `/profile/upload_avatar`,
    method: 'POST',
    params: formData,
    formData: true,
  });
  return response;
};

export const deleteUserAvatarApi = async (url: string) => {
  const response = await makeRequest({
    path: `/profile/avatar/${url}`,
    method: 'DELETE',
  });
  return response;
};

export const updateProfileApi = async (data: IUpdateProfile) => {
  const response = await makeRequest({
    path: `/profile`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const deleteUserApi = async (user_id: string) => {
  const response = await makeRequest({
    path: `/users/${user_id}`,
    method: 'DELETE',
  });
  return response;
};

export const getUsersAutocompleteApi = async (search: string = '') => {
  const response = await makeRequest({
    path: `/users/autocomplete?search=${search}`,
    method: 'GET',
  });
  return response;
};

export const requestResetLinkApi = async (data: IRequestResetLink) => {
  const response = await makeRequest({
    path: `/users/request-reset-link`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updatePasswordProfileApi = async (data: IUpdatePasswordProfile) => {
  const response = await makeRequest({
    path: `/users/change-password-profile`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updatePasswordViaResetLinkApi = async (data: IUpdatePasswordViaResetLink) => {
  const response = await makeRequest({
    path: `/users/change-password`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

// CLIENTS
// TODO move to new /clients forder

export interface IListClients {
  query?: string;
}

export const getClientsApi = async (data: IListClients) => {
  const params = new URLSearchParams({ query: data.query ?? '' }).toString();

  return await makeRequest({
    path: `/clients?${params}`,
    method: 'GET',
  });
};

export interface ICreateClient {
  client_name: string;
}

export const createClientApi = async (data: ICreateClient) => {
  return await makeRequest({
    path: '/clients',
    method: 'POST',
    params: JSON.stringify(data),
  });
};

export const updateClientApi = async (client_id: number, data: ICreateClient) => {
  return await makeRequest({
    path: `/clients/${client_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
};

export const deleteClientApi = async (client_id: number) => {
  return await makeRequest({
    path: `/clients/${client_id}`,
    method: 'DELETE',
  });
};

// TYPES

export type Roles = 'admin' | 'user';

export interface ICreateUser {
  email: string;
  role: Roles;
  full_name: string;
  client_id?: string | number;
}

export interface IUpdateUser {
  user_id: string;
  data: Partial<ICreateUser>;
}

export interface IUpdateProfile {
  full_name: string;
}

export interface IRequestResetLink {
  email: string;
}

export interface IUpdatePasswordProfile {
  old_password: string;
  new_password: string;
}

export interface IUpdatePasswordViaResetLink {
  reset_link_code: string;
  new_password: string;
}
