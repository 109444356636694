import { FC } from 'react';
import { useAppDispatch } from 'hooks/store';
import { colors } from 'variables';
import { showChat } from './reducer';
import { CommentIcon } from 'assets/icons';

interface IChatTableViewProps {
  row: any;
  from: string;
}

export const ChatTableView: FC<IChatTableViewProps> = ({ row, from = 'manager' }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex items-center justify-center">
      {row.value ? (
        <div className="relative">
          <CommentIcon
            className="cursor-pointer"
            pathFill={colors.blue.b1}
            aria-hidden="true"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();

              dispatch(showChat({ ...row.row.original, __from: from }));
            }}
          />
          <span className="absolute -top-2 left-5 inline-flex items-center rounded-full px-1 py-0 text-xs font-medium text-blue-b1 bg-blue-b1/10">
            {row.value}
          </span>
        </div>
      ) : (
        <CommentIcon
          className="cursor-pointer"
          aria-hidden="true"
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();

            dispatch(showChat({ ...row.row.original, __from: from }));
          }}
        />
      )}
    </div>
  );
};
