import { FC, memo, useCallback, useState } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { components, DropdownIndicatorProps, MenuListProps, OptionProps, StylesConfig } from 'react-select';
import { colors } from 'variables';
import { RSelect, RSelectStyles } from 'shared';
import { getStatus } from '../tabs/Selected';
import { ArrowDownSmallIcon, ArrowUpSmallIcon } from 'assets/icons';
import { isEqual } from 'lodash';

const formatStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  container: (styles, props) => ({
    ...(RSelectStyles?.container ? RSelectStyles?.container(styles, props) : {}),
    width: 'fit-content',
  }),
  control: (styles, props: any) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    backgroundColor: props.getValue()[0]?.color || colors.gray.g1,
    color: props.getValue()[0]?.textColor || colors.black.b1,
    fontSize: '10px',
    lineHeight: '12px',
    minHeight: '24px !important',
    height: '24px !important',
    borderWidth: 0,
    boxShadow: 'none',
    opacity: props.selectProps.noDropdownIndicator ? 1 : props.selectProps.isDisabled ? 0.75 : 1,
  }),
  singleValue: (styles, props) => ({
    ...(RSelectStyles?.singleValue ? RSelectStyles?.singleValue(styles, props) : {}),
    color: props.getValue()[0]?.textColor || colors.black.b1,
  }),
  option: (styles, props) => ({
    ...(RSelectStyles?.option ? RSelectStyles?.option(styles, props) : {}),
    color: props.getValue()[0]?.textColor || colors.black.b1,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  dropdownIndicator: (styles, props) => ({
    ...(RSelectStyles?.dropdownIndicator ? RSelectStyles?.dropdownIndicator(styles, props) : {}),
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (styles, props) => ({
    ...(RSelectStyles?.menu ? RSelectStyles?.menu(styles, props) : {}),
    marginTop: '2px',
    marginBottom: '0px',
    width: 'fit-content',
  }),
  menuList: (styles, props) => ({
    ...(RSelectStyles?.menuList ? RSelectStyles?.menuList(styles, props) : {}),
    padding: '8px 0',
    maxHeight: '150px',
  }),
  valueContainer: (styles, props: any) => ({
    ...(RSelectStyles?.valueContainer ? RSelectStyles?.valueContainer(styles, props) : {}),
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: props.selectProps.noDropdownIndicator ? '0.5rem' : 0,
    paddingLeft: '0.5rem',
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
};

const FormatOption = ({ children, ...props }: OptionProps<any, false>) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div
        className="flex items-center"
        style={{
          width: 'fit-content',
          borderRadius: '4px',
          padding: '0 10px',
          backgroundColor: data?.color || colors.gray.g1,
          minHeight: '24px',
          height: '24px',
          fontSize: '10px',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </div>
    </components.Option>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props} className="!pr-0">
      {menuIsOpen ? (
        <ArrowUpSmallIcon pathFill={props.getValue()[0]?.textColor || colors.black.b1} />
      ) : (
        <ArrowDownSmallIcon pathFill={props.getValue()[0]?.textColor || colors.black.b1} />
      )}
    </components.DropdownIndicator>
  );
};

const MenuList = (props: MenuListProps<any, false>) => {
  return (
    <components.MenuList {...props} className="menulist-sroll-id">
      {props.children}
    </components.MenuList>
  );
};

interface IFormatSelect {
  value: string;
  options: any[];
  onChange: (value: string) => void;
  isDisabled?: boolean;
  noDropdownIndicator?: boolean;
}

export const FormatSelect: FC<IFormatSelect> = memo(
  ({ value: _value = '', options = [], isDisabled = false, noDropdownIndicator = false, onChange }) => {
    const [value, setValue] = useState<string>(() => _value || getStatus('', options).value);

    const handleChange = useCallback(
      (option: any) => {
        setValue(option.value);
        if (onChange) onChange(option.value);
      },
      [onChange]
    );

    useLegacyEffect(() => {
      setValue(_value || getStatus('', options).value);
    }, [_value, options]);

    return (
      <RSelect
        styles={formatStyles}
        placeholder="Not Selected"
        options={options}
        isClearable={false}
        isSearchable={false}
        isDisabled={isDisabled}
        noDropdownIndicator={noDropdownIndicator}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          Option: FormatOption,
          DropdownIndicator: !noDropdownIndicator ? DropdownIndicator : () => null,
          MenuList,
        }}
        value={options.find((option) => option.value === value) || null}
        onChange={handleChange}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        menuShouldScrollIntoView={false}
        closeMenuOnScroll={(e: any) => !e.target.classList.contains('menulist-sroll-id')}
      />
    );
  },
  (prev, next) => isEqual(prev, next)
);
