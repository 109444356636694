import { FC, useState, Fragment, ReactNode, memo, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { colors } from 'variables';
import { Checkbox } from 'shared/checkbox';
import { ArrowDownDropdownSolidIcon, ArrowUpDropdownSolidIcon } from 'assets/icons';
import { isEqual } from 'lodash';

interface IHSelect {
  options: any[];
  onChange: (props: any) => void;
  selected?: any[];
  children?: ReactNode | ((props: any) => ReactNode);
  defaultTitle?: string;
  rootClass?: string;
  menuClass?: string;
  menuListClass?: string;
  menuListOptionClass?: string;
  optionClass?: string;
}

// Headless Select

export const HSelect: FC<IHSelect> = memo(
  ({
    children,
    selected = [],
    options,
    defaultTitle = 'Select...',
    rootClass = '',
    menuClass = '',
    menuListClass = '',
    menuListOptionClass = '',
    optionClass = '',
    onChange,
  }) => {
    const [selectedOption, setSelectedOption] = useState(() => selected); // options

    useEffect(() => {
      setSelectedOption(selected);
    }, [selected]);

    return (
      <Listbox
        as="div"
        className={cn('relative inline-block text-left', rootClass)}
        multiple
        by="id"
        value={selectedOption}
        onChange={(props: any) => {
          setSelectedOption(props);
          onChange(props);
        }}
      >
        {({ open }) => (
          <>
            {/* Render a `Fragment` instead of a `button` */}
            <Listbox.Button as={Fragment}>
              {children ? (
                typeof children === 'function' ? (
                  children({ open })
                ) : (
                  children
                )
              ) : (
                <button
                  type="button"
                  className={cn(
                    'flex items-center justify-between w-44 h-12 pl-4 appearance-none text-sm leading-6 font-semibold bg-gray-g1 rounded-[4px] border-2 border-gray-g1 p-3 focus:border-blue-b1 focus:outline-none focus:ring-blue-b1',
                    open ? 'text-blue-b1' : 'text-black'
                  )}
                >
                  {defaultTitle} {selectedOption.length ? `(${selectedOption.length})` : ''}
                  {open ? <ArrowUpDropdownSolidIcon className="ml-2" pathFill={colors.blue.b1} /> : <ArrowDownDropdownSolidIcon className="ml-2" />}
                </button>
              )}
            </Listbox.Button>

            <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options
                static
                className={cn(
                  'origin-top-left absolute z-40 left-0 max-h-[17rem] mt-1 rounded-[4px] border border-gray-g1 shadow-s1 overflow-y-auto bg-white ring-0 focus:outline-none',
                  menuClass
                )}
              >
                <div className={cn('py-2.5', menuListClass)}>
                  {options.map((option) => (
                    <Listbox.Option key={option.id} value={option} className={cn('ring-0 focus:outline-none', menuListOptionClass)}>
                      {({ selected }) =>
                        option.Option ? (
                          option.Option({ selected })
                        ) : (
                          <div
                            className={cn(
                              'whitespace-nowrap px-4 py-1.5 space-x-2 flex items-center cursor-pointer text-black-b1 hover:bg-gray-g1',
                              optionClass
                            )}
                          >
                            <Checkbox checked={selected} onChange={() => {}} />
                            {option.Name ? option.Name() : <p className="text-sm leading-6">{option.name}</p>}
                          </div>
                        )
                      }
                    </Listbox.Option>
                  ))}
                </div>
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    );
  },
  (prev, next) => isEqual(prev.selected, next.selected) && isEqual(prev.options, next.options) && isEqual(prev.onChange, next.onChange)
);
