import { FC, forwardRef } from 'react';
import cn from 'classnames';

interface ITextareaProps {
  rows?: number;
  className?: string;
  [propName: string]: any;
}

export const Textarea: FC<ITextareaProps> = forwardRef(({ error, className = '', rows = 3, ...rest }, ref: any) => {
  return (
    <textarea
      ref={ref}
      rows={rows}
      tabIndex={-1}
      {...rest}
      className={cn(
        'block w-full text-xs leading-6 font-normal text-black-b1 placeholder-gray-gm rounded border p-3 focus:ring-0 focus:outline-none',
        rest.disabled ? 'bg-gray-g1 text-gray-gm border-gray-g2' : '',
        className,
        error ? 'border-red-r1 focus:border-red-r1' : 'border-gray-g2 focus:border-blue-b1'
      )}
      aria-invalid={error ? 'true' : 'false'}
    />
  );
});
