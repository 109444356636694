import { FC } from 'react';
import { EmptyIcon } from 'assets/icons';

export const SelectedEmpty: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <EmptyIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">No selected influencers yet</h1>
      {/* <p className="mt-2 text-center text-sm leading-6 text-black-b1">
        Go to the Influencers page and select the most
        <br /> suitable options
      </p> */}
    </div>
  );
};
