import { createAsyncThunk } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { fetchLogoutThunk } from 'pages/Auth/reducer';
import { toast } from 'react-toastify';
import {
  IRequestResetLink,
  IUpdatePasswordProfile,
  IUpdatePasswordViaResetLink,
  IUpdateProfile,
  deleteUserAvatarApi,
  requestResetLinkApi,
  updatePasswordProfileApi,
  updatePasswordViaResetLinkApi,
  updateProfileApi,
  uploadUserAvatarApi,
} from 'services';

export const requestResetLinkThunk = createAsyncThunk('request_reset_link_thunk', async (data: IRequestResetLink, { dispatch }: any) => {
  const response = await dispatch(requestResetLinkOnlyThunk(data));
  if (requestResetLinkOnlyThunk.rejected.match(response) || response.payload !== 'Ok')
    toast('Failed to send link', {
      type: 'error',
    });
  return response.payload;
});

export const requestResetLinkOnlyThunk = createAsyncThunk('request_reset_link_only_thunk', async (data: IRequestResetLink) => {
  const response: string = await requestResetLinkApi(data);
  return response;
});

// Call from Profile
export const updatePasswordProfileThunk = createAsyncThunk('update_password_profile_thunk', async (data: IUpdatePasswordProfile) => {
  const response: string = await updatePasswordProfileApi(data);
  if (response === 'Ok')
    toast('Password updated successfully', {
      type: 'success',
    });
  else {
    toast('Failed to update password', {
      type: 'error',
    });
    throw new Error();
  }
  return response;
});

// Call from /reset-password
export const updatePasswordViaResetLinkThunk = createAsyncThunk(
  'update_password_via_reset_link_thunk',
  async (data: IUpdatePasswordViaResetLink, { dispatch }: any) => {
    const response = await dispatch(updatePasswordViaResetLinkOnlyThunk(data));

    if (updatePasswordViaResetLinkOnlyThunk.fulfilled.match(response) && response.payload === 'Ok') {
      await dispatch(fetchLogoutThunk());
    }

    if (updatePasswordViaResetLinkOnlyThunk.rejected.match(response) || response.payload !== 'Ok')
      toast('Failed to update password', {
        type: 'error',
      });

    return response.payload;
  }
);

// Call from /reset-password
export const updatePasswordViaResetLinkOnlyThunk = createAsyncThunk('update_password_via_reset_link_only_thunk', async (data: IUpdatePasswordViaResetLink) => {
  const response: string = await updatePasswordViaResetLinkApi(data);
  return response;
});

// PROFILE

export const updateUserInfoThunk = createAsyncThunk('update_user_info_thunk', async (data: IUpdateProfile, { dispatch }: any) => {
  const response = await dispatch(updateUserInfoOnlyThunk(data));

  if (updateUserInfoOnlyThunk.fulfilled.match(response))
    toast('Profile information updated successfully', {
      type: 'success',
    });

  if (updateUserInfoOnlyThunk.rejected.match(response))
    toast('Failed to update profile information', {
      type: 'error',
    });

  return response.payload;
});

export const updateUserInfoOnlyThunk = createAsyncThunk('update_user_info_only_thunk', async (data: IUpdateProfile) => {
  const response: any[] = await updateProfileApi(data);

  if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();
  return response;
});

export const uploadUserAvatarThunk = createAsyncThunk('upload_user_avatar_thunk', async (file: File) => {
  let formData = new FormData();
  formData.append('file', file);

  const response: any = await uploadUserAvatarApi(formData);

  if (!response.avatar_url) throw new Error();

  return response.avatar_url;
});

export const deleteUserAvatarThunk = createAsyncThunk('delete_user_avatar_thunk', async (url: string) => {
  const response: any = await deleteUserAvatarApi(url);

  if (response !== 'Ok') throw new Error();

  return response;
});
