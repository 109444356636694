import { FC } from 'react';
import { useAppSelector } from 'hooks/store';
import { useQueryLocation } from 'hooks/useQueryLocation';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { colors } from 'variables';
import { selectCampaignsPagesCountClient } from '../reducer';
import { ArrowLeftIcon, ArrowRightIcon } from 'assets/icons';

export const Pagination: FC = () => {
  const query = useQueryLocation();
  const searchPage = query.get('page');

  const pagesCount = useAppSelector(selectCampaignsPagesCountClient);

  const page = searchPage == null ? 1 : +searchPage;

  if (pagesCount < 2) return null;

  return (
    <div className="flex items-center justify-between px-4 py-3 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
        <nav className="isolate inline-flex space-x-3" aria-label="Pagination">
          {page > 1 ? (
            <Link to={page - 1 === 1 ? '' : `?page=${page - 1}`} className="relative inline-flex items-center text-sm font-medium text-gray-gb focus:z-20">
              <span className="sr-only">Previous</span>
              <ArrowLeftIcon aria-hidden="true" />
            </Link>
          ) : (
            <div className="relative inline-flex items-center text-sm font-medium text-gray-gm focus:z-20">
              <span className="sr-only">Previous</span>
              <ArrowLeftIcon aria-hidden="true" pathFill={colors.gray.gm} />
            </div>
          )}
          <div className="space-x-1 flex items-center">
            {Array.from(Array(pagesCount).keys()).map((d: any) => {
              const _d = d + 1;
              return (
                <Link
                  to={_d === 1 ? '' : `?page=${_d}`}
                  className={cn(
                    'relative inline-flex items-center justify-center w-6 h-6 rounded-[4px] text-xs focus:z-20',
                    page === _d ? 'z-10 text-white bg-blue-b1' : 'text-black-b1'
                  )}
                >
                  {_d}
                </Link>
              );
            })}
          </div>
          {page < pagesCount ? (
            <Link to={`?page=${page + 1}`} className="relative inline-flex items-center text-sm font-medium text-gray-gb focus:z-20">
              <span className="sr-only">Next</span>
              <ArrowRightIcon aria-hidden="true" />
            </Link>
          ) : (
            <div className="relative inline-flex items-center text-sm font-medium text-gray-gm focus:z-20">
              <span className="sr-only">Next</span>
              <ArrowRightIcon aria-hidden="true" pathFill={colors.gray.gm} />
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};
