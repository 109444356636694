import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import { ValidationTypes } from 'utils/validation';
import { Link } from 'react-router-dom';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { Form, Label, RSelect, RSelectStyles } from 'shared';

import 'pages/Influencers/tabs/styles.css';

const campaignsOptions: any[] = [
  { value: 'campaign_1', label: 'Campaign 1' },
  { value: 'campaign_2', label: 'Campaign 2' },
  { value: 'campaign_3', label: 'Campaign 3' },
];

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
    height: '48px !important',
  }),
};

interface IAddSearchToCampaignProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AddSearchToCampaign: FC<IAddSearchToCampaignProps> = ({ params, hideModal }) => {
  const defaultValues: any = useMemo(
    () => ({
      name: '',
    }),
    []
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={`Add ${params.name} search to campaign`} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AddSearchToCampaign}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          hideModal();
        }}
      >
        {({ control, formState: { errors } }) => (
          <>
            <div>
              <Label htmlFor="name" label="Select Campaign" error={errors.name} />
              <div className="mt-0.5">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }: any) => (
                    <RSelect
                      {...field}
                      styles={selectStyles}
                      placeholder="Campaign name"
                      options={campaignsOptions}
                      error={errors.name}
                      components={{
                        ClearIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      value={campaignsOptions.find((option) => option.value === field.value) || null}
                      onChange={(option: any) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="">
              {/* TODO use navigate. hidemodal and navigate to /campaigns */}
              <p className="text-sm leading-6 font-normal text-black">
                Or go to{' '}
                <Link to="" className="disabled text-blue-b1 hover:underline">
                  Campaign
                </Link>{' '}
                page to create new one
              </p>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
