import { makeRequest } from './http';

export const getAgenciesFullApi = async () => {
  return await makeRequest({
    path: `/agencies?full=true`,
    method: 'GET',
  });
};

export const saveAgencyApi = async (data: ICreateAgencies) => {
  return await makeRequest({
    path: `/agencies`,
    method: 'POST',
    params: JSON.stringify({ data: [data] }),
  });
};

export const updateAgencyApi = async ({ agency_id, data }: IUpdateAgency) => {
  return await makeRequest({
    path: `/agencies/${agency_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
};

export const deleteAgencyApi = async (agency_id: string) => {
  return await makeRequest({
    path: `/agencies/${agency_id}`,
    method: 'DELETE',
  });
};

export interface ICreateAgencies {
  agency_name: string;
  agency_website: string;
  agency_country: string;
  agency_type: string;
  agency_focus: string;
}

export interface IUpdateAgency {
  agency_id: string;
  data: ICreateAgencies;
}
