import { useMemo } from 'react';
import { platformSelectOptions } from 'pages/Influencers/tabs/InfluencersTab';

export const useSMPlatforms = (campaign: any) => {
  return useMemo(() => {
    if (campaign.sm_platform) {
      const sm_platform = campaign.sm_platform.split(',');
      if (sm_platform.length) {
        const platformConfig: any = {};
        sm_platform.forEach((d: any) => {
          platformConfig[d] = platformSelectOptions[d];
        });
        return platformConfig;
      }
    }
    return platformSelectOptions; // TODO if not have sm_platform need to add global check in each tab
  }, [campaign?.sm_platform]);
};
