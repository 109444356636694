import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { deleteAgencyApi, getAgenciesFullApi, ICreateAgencies, IUpdateAgency, saveAgencyApi, updateAgencyApi } from '../../services/agencies';
import { isArray } from 'lodash';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

interface IUsers {
  loading: Loading;
  fullAgencies: any[];
  search: string;
}

const initialState: IUsers = {
  loading: defaultLoading,
  fullAgencies: [],
  search: '',
};

const slice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    setSearchAgencies: (state, { payload }: PayloadAction<string>) => {
      state.search = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAgenciesFullThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchAgenciesFullThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH AGENCIES', payload);

        state.fullAgencies = payload;
        state.loading.is = false;
      })
      .addCase(createAgencyThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(createAgencyThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED CREATE AGENCY', payload);

        if (isArray(payload) && payload.length) {
          state.fullAgencies.push(payload[0]);
        }
        state.loading.is = false;
      })
      .addCase(updateAgencyThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(updateAgencyThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE AGENCY', payload);

        if (isArray(payload) && payload.length) {
          const idx = state.fullAgencies.findIndex((d: any) => d.agency_id === payload[0].agency_id);
          if (idx !== -1) state.fullAgencies.splice(idx, 1, payload[0]);
        }
        state.loading.is = false;
      })
      .addCase(deleteAgencyThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(deleteAgencyThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE AGENCY', payload);
        state.fullAgencies = state.fullAgencies.filter((d: any) => d.agency_id !== payload);
        state.loading.is = false;
      });
  },
});

export const usersActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

// AGENCIES
export const fetchAgenciesFullThunk = createAsyncThunk('fetch_agencies_full_thunk', async (args) => {
  const response: any[] = await getAgenciesFullApi();
  return typeof response === 'string' ? [] : response;
});

export const createAgencyThunk = createAsyncThunk('create_agency_thunk', async (data: ICreateAgencies) => {
  const response: any = await saveAgencyApi(data);
  return response;
});

export const updateAgencyThunk = createAsyncThunk('update_agency_thunk', async ({ agency_id, data }: IUpdateAgency) => {
  const response: any[] = await updateAgencyApi({ agency_id, data });
  return response;
});

export const deleteAgencyThunk = createAsyncThunk('delete_agency_thunk', async (id: string) => {
  await deleteAgencyApi(id);
  return id;
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.agencies;

export const selectAgenciesAdmin = createSelector(selectState, (state) => state.fullAgencies);

export const selectAgenciesLoading = createSelector(selectState, (state) => state.loading);

export const selectAgenciesLoadingToHeader = createSelector(selectAgenciesLoading, (loading) => Boolean(loading.is && !loading.id));
