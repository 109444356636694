import { FC, useState, useRef, useCallback, useMemo } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { debounce } from 'lodash';
import cn from 'classnames';
import { Input } from 'shared';
import { SearchIcon } from 'assets/icons';

type Size = 'md' | 'lg';

interface ISimpleSearchProps {
  className?: string;
  placeholder?: string;
  size?: Size;
  iconPlace?: 'left' | 'right';
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const SimpleSearch: FC<ISimpleSearchProps> = ({
  className = '',
  placeholder = 'Search',
  size = 'md',
  iconPlace = 'left',
  defaultValue = '',
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);

  const inputRef = useRef<any>(null);

  const searchIcon = useMemo(
    () => (
      <div className={cn('absolute z-40 flex', { md: 'top-2.5', lg: 'top-4' }[size], { left: 'left-3', right: 'right-3' }[iconPlace])}>
        <SearchIcon className={cn('cursor-pointer')} onClick={() => inputRef.current?.focus()} />
      </div>
    ),
    [size, iconPlace]
  );

  const handleChange = (e: any) => {
    const value = e.target.value;
    setValue(value);
    debouncedUpdate(value);
  };

  const debouncedUpdate = debounce((value: string) => {
    if (onChange) onChange(value);
  }, 250);

  useLegacyEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, []);

  return (
    <div className={cn('flex flex-col flex-shrink-0 p-6 bg-white', className)}>
      <div className="flex flex-1 justify-between">
        <div className="flex flex-1">
          <form className="relative flex w-full md:ml-0" onSubmit={(e: any) => e.preventDefault()}>
            {searchIcon}
            <Input
              ref={inputRef}
              id="campaign_name"
              type="text"
              placeholder={placeholder}
              className={cn(
                '!px-5 !text-base bg-gray-g1 border-gray-g1 focus:border-blue-b1',
                {
                  md: '!h-[42px]',
                  lg: '!h-[54px]',
                }[size],
                {
                  left: '!pl-12',
                  right: '!pr-12',
                }[iconPlace]
              )}
              value={value}
              onChange={handleChange}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
