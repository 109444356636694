import { FC, useMemo } from 'react';
import cn from 'classnames';
import { ValidationTypes } from 'utils/validation';
import { ClipLoader, Form, Input, Label } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';

interface IEditSearchInfluencerProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const EditSearchInfluencer: FC<IEditSearchInfluencerProps> = ({ params, hideModal }) => {
  const isUpdate = params?.name;

  const defaultValues: any = useMemo(
    () => ({
      name: params?.name || '',
    }),
    [params]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isUpdate ? `Edit ${params?.name || ''}` : 'Create new list'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.EditSearchInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          return params[isUpdate ? 'handleSave' : 'handleCreate'](values.name).then(() => hideModal());
        }}
      >
        {({ register, formState: { errors, isSubmitting } }) => (
          <>
            <div>
              <Label htmlFor="name" label={isUpdate ? 'Edit list name' : 'Create list name'} error={errors.name} />
              <div className="mt-0.5">
                <Input id="name" type="text" placeholder="Enter name" error={errors.name} {...register('name')} />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={cn(
                  'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                  { disabled: isSubmitting }
                )}
                disabled={isSubmitting}
              >
                <p>{isUpdate ? 'Save' : 'Create'}</p>
                <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
