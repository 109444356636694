import { FC, useRef } from 'react';
import { default as RDatePicker } from 'react-datepicker';
import cn from 'classnames';
import { colors } from 'variables';
import { ArrowLeftIcon, ArrowRightIcon, CalendarIcon } from 'assets/icons';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

interface IDatePickerProps {
  selected: Date | null | undefined;
  onChange: (
    date: Date | null
    // event: SyntheticEvent<any, Event> | undefined
  ) => void;
  error?: boolean;
  placeholder?: string;
  showTimeSelect?: boolean;
  dateFormat?: string;
}

export const DatePicker: FC<IDatePickerProps> = ({
  selected = null,
  error,
  placeholder = '',
  showTimeSelect = false,
  dateFormat = 'dd MMM yyyy',
  onChange = () => null,
}) => {
  const inputRef = useRef<any>(null);

  const customHeader = ({ monthDate, decreaseMonth, increaseMonth }: any) => (
    <div>
      <button
        type="button"
        aria-label="Previous Month"
        className={'mt-0.5 react-datepicker__navigation react-datepicker__navigation--previous'}
        onClick={decreaseMonth}
      >
        <ArrowLeftIcon pathFill={colors.black.b1} />
      </button>
      <span className="react-datepicker__current-month">
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      <button
        type="button"
        aria-label="Next Month"
        className={'mt-0.5 react-datepicker__navigation react-datepicker__navigation--next'}
        onClick={increaseMonth}
      >
        <ArrowRightIcon pathFill={colors.black.b1} />
      </button>
    </div>
  );

  return (
    <>
      <RDatePicker
        ref={inputRef}
        className={cn(
          'block w-full h-12 appearance-none !text-xs !leading-6 font-normal text-black-b1 placeholder-gray-gm rounded-[4px] border p-3 focus:ring-0 focus:outline-none',
          error ? 'border-red-r1 focus:border-red-r1' : 'border-gray-g2 focus:border-blue-b1'
        )}
        selected={selected}
        onChange={onChange}
        timeIntervals={30}
        timeCaption="Time"
        dateFormat={dateFormat}
        placeholderText={placeholder}
        showTimeSelect={showTimeSelect}
        renderCustomHeader={customHeader}
      />
      <CalendarIcon
        className="absolute top-3.5 right-3 w-5 h-5 cursor-pointer"
        aria-hidden="true"
        onClick={() => {
          if (inputRef?.current?.setFocus) inputRef.current.setFocus();
        }}
      />
    </>
  );
};
