import { FC } from 'react';
import { EmptyReportIcon } from 'assets/icons';

export const ReportEmpty: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <EmptyReportIcon />
      <h1 className="text-2xl leading-[42px] font-semibold text-black-b1">Report is not generated yet</h1>
      <p className="mt-2 text-center text-sm leading-6 text-black-b1">
        The manager will generate a report soon and <br /> you will be notified.
      </p>
    </div>
  );
};
