import { FC, useMemo, useState } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cn from 'classnames';
import { getAgenciesApi } from 'services';
import { Form, Input, Label, PhoneInput, RSelect, SelectCheckboxStyles, SelectCheckbox, RSelectStyles, DropdownIndicator, ClipLoader, Checkbox } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { useGenreSelectOptions } from 'hooks/useGenre';
import { usePlatformSelectOptions } from 'hooks/usePlatform';
import { useMainGameSelectOptions } from 'hooks/useMainGame';
import { useLanguagesSelectOptions } from 'hooks/useLanguages';
import { useCountriesSelectOptions } from 'hooks/useCountries';

const selectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

const selectMultiStyles: StylesConfig<any, true> = {
  ...SelectCheckboxStyles,
  control: (styles, props) => ({
    ...(SelectCheckboxStyles?.control ? SelectCheckboxStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
  }),
};

const genderOptions: any[] = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

interface IAddInfluencerProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AddInfluencer: FC<IAddInfluencerProps> = ({ params, hideModal }) => {
  const [agencyOptions, setAgencyOptions] = useState<any[]>([]); // TODO may be useAgencies but this array not have agency_id
  const genreOptions = useGenreSelectOptions();
  const platformOptions = usePlatformSelectOptions();
  const mainGameOptions = useMainGameSelectOptions();
  const languageOptions = useLanguagesSelectOptions();
  const countriesOptions = useCountriesSelectOptions();

  const isUpdate = Boolean(params?.influencer?.influencer_id);

  const defaultValues: any = useMemo(
    () => ({
      first_name: params?.influencer?.first_name || '',
      last_name: params?.influencer?.last_name || '',
      email: params?.influencer?.email || '',
      gender: params?.influencer?.gender || '',
      language: params?.influencer?.language || '',
      country: params?.influencer?.country || '',
      city: params?.influencer?.city || '',
      platform: params?.influencer?.platform != null ? params.influencer.platform.split(',') : [],
      genre: params?.influencer?.genre != null ? params.influencer.genre.split(',') : [],
      main_game: params?.influencer?.main_game != null ? params.influencer.main_game.split(',') : [],
      youtube_url: params?.influencer?.youtube_url || '',
      twitch_url: params?.influencer?.twitch_url || '',
      instagram_url: params?.influencer?.instagram_url || '',
      tiktok_url: params?.influencer?.tiktok_url || '',
      agency_id: params?.influencer?.agency_id || '',
      discord: params?.influencer?.discord || '',
      cell: params?.influencer?.cell || '',
      // TODO now 'whatsapp' have string type. need boolean
      whatsapp: params?.influencer?.whatsapp !== null ? params?.influencer?.whatsapp === 'true' : false,
    }),
    [params?.influencer]
  );

  useLegacyEffect(() => {
    getAgenciesApi().then((res) => setAgencyOptions(res.map((d: any) => ({ value: d.agency_id, label: d.agency_name }))));
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isUpdate ? 'Update influencer' : 'Add influencer'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        // validationType={ValidationTypes.AdminAddInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          const _values = {
            ...values,
            platform: values.platform.join(','),
            genre: values.genre.join(','),
            main_game: values.main_game.join(','),
          };

          if (params.noPlatform) delete _values.platform;

          return params.handleSave(_values).then(() => hideModal());
        }}
      >
        {({ register, control, watch, setValue, formState }) => {
          const { errors, isSubmitting } = formState;
          return (
            <>
              <div>
                <Label htmlFor="first_name" label="First name" error={errors.first_name} />
                <div className="mt-0.5">
                  <Input id="first_name" type="text" placeholder="First name" error={errors.first_name} {...register('first_name')} />
                </div>
              </div>
              <div>
                <Label htmlFor="last_name" label="Last name" error={errors.last_name} />
                <div className="mt-0.5">
                  <Input id="last_name" type="text" placeholder="Last name" error={errors.last_name} {...register('last_name')} />
                </div>
              </div>
              <div>
                <Label htmlFor="email" label="Personal email" error={errors.email} />
                <div className="mt-0.5">
                  <Input id="email" type="email" placeholder="Email address" error={errors.email} {...register('email')} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <Label htmlFor="gender" label="Gender" error={errors.gender} />
                  <div className="mt-0.5">
                    <Controller
                      name="gender"
                      control={control}
                      render={(props: any) => {
                        const { field } = props;
                        return (
                          <RSelect
                            {...field}
                            styles={selectStyles}
                            placeholder="Not Selected"
                            options={genderOptions}
                            error={errors.gender}
                            components={{
                              ClearIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            value={genderOptions.find((option) => option.value === field.value) || null}
                            onChange={(option: any) => {
                              field.onChange(option.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="language" label="Language" error={errors.language} />
                  <div className="mt-0.5">
                    <Controller
                      name="language"
                      control={control}
                      render={(props: any) => {
                        const { field } = props;
                        return (
                          <RSelect
                            {...field}
                            styles={selectStyles}
                            placeholder="Not Selected"
                            options={languageOptions}
                            error={errors.language}
                            components={{
                              ClearIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            value={languageOptions.find((option) => option.value === field.value) || null}
                            onChange={(option: any) => {
                              field.onChange(option.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <Label htmlFor="country" label="Country" error={errors.country} />
                  <div className="mt-0.5">
                    <Controller
                      name="country"
                      control={control}
                      render={(props: any) => {
                        const { field } = props;
                        return (
                          <RSelect
                            {...field}
                            styles={selectStyles}
                            placeholder="Not Selected"
                            options={countriesOptions}
                            error={errors.country}
                            components={{
                              ClearIndicator: () => null,
                              IndicatorSeparator: () => null,
                            }}
                            value={countriesOptions.find((option) => option.value === field.value) || null}
                            onChange={(option: any) => {
                              field.onChange(option.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="city" label="City" error={errors.city} />
                  <div className="mt-0.5">
                    <Input id="city" type="text" placeholder="City" error={errors.city} {...register('city')} />
                  </div>
                </div>
              </div>
              {!params.noPlatform ? (
                <div>
                  <Label htmlFor="platform" label="Platform" error={errors.platform} />
                  <div className="mt-0.5">
                    <Controller
                      name="platform"
                      control={control}
                      render={({ field }: any) => (
                        <SelectCheckbox
                          {...field}
                          styles={selectMultiStyles}
                          placeholder="Not Selected"
                          options={platformOptions}
                          error={errors.platform}
                          defaultValue={platformOptions.filter((option: any) => field.value.includes(option.value)) || null}
                          value={platformOptions.filter((option: any) => field.value.includes(option.value)) || null}
                          onChange={(option: any) => {
                            field.onChange(option.map((d: any) => d.value));
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <Label htmlFor="genre" label="Genre" error={errors.genre} />
                <div className="mt-0.5">
                  <Controller
                    name="genre"
                    control={control}
                    render={({ field }: any) => (
                      <SelectCheckbox
                        {...field}
                        styles={selectMultiStyles}
                        placeholder="Not Selected"
                        options={genreOptions}
                        error={errors.genre}
                        defaultValue={genreOptions.filter((option: any) => field.value.includes(option.value)) || null}
                        value={genreOptions.filter((option: any) => field.value.includes(option.value)) || null}
                        onChange={(option: any) => {
                          field.onChange(option.map((d: any) => d.value));
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="main_game" label="Main game" error={errors.main_game} />
                <div className="mt-0.5">
                  <Controller
                    name="main_game"
                    control={control}
                    render={({ field }: any) => (
                      <SelectCheckbox
                        {...field}
                        styles={selectMultiStyles}
                        placeholder="Not Selected"
                        options={mainGameOptions}
                        error={errors.main_game}
                        defaultValue={mainGameOptions.filter((option: any) => field.value.includes(option.value)) || null}
                        value={mainGameOptions.filter((option: any) => field.value.includes(option.value)) || null}
                        onChange={(option: any) => {
                          field.onChange(option.map((d: any) => d.value));
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="youtube_url" label="You Tube Channel URL" error={errors.youtube_url} />
                <div className="mt-0.5">
                  <Input id="youtube_url" type="text" placeholder="Place link" error={errors.youtube_url} {...register('youtube_url')} />
                </div>
              </div>
              <div>
                <Label htmlFor="twitch_url" label="Twitch channel URL" error={errors.twitch_url} />
                <div className="mt-0.5">
                  <Input id="twitch_url" type="text" placeholder="Place link" error={errors.twitch_url} {...register('twitch_url')} />
                </div>
              </div>
              <div>
                <Label htmlFor="instagram_url" label="Instagram URL" error={errors.instagram_url} />
                <div className="mt-0.5">
                  <Input id="instagram_url" type="text" placeholder="Place link" error={errors.instagram_url} {...register('instagram_url')} />
                </div>
              </div>
              <div>
                <Label htmlFor="tiktok_url" label="TikTok URL" error={errors.tiktok_url} />
                <div className="mt-0.5">
                  <Input id="tiktok_url" type="text" placeholder="Place link" error={errors.tiktok_url} {...register('tiktok_url')} />
                </div>
              </div>
              <div>
                <Label htmlFor="agency_id" label="Agency name" error={errors.agency_id} />
                <div className="mt-0.5">
                  <Controller
                    name="agency_id"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <CreatableSelect
                          {...field}
                          styles={selectStyles}
                          placeholder="Not Selected"
                          options={agencyOptions}
                          error={errors.agency_id}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            DropdownIndicator,
                          }}
                          value={agencyOptions.find((option: any) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="discord" label="Discord" error={errors.discord} />
                <div className="mt-0.5">
                  <Input id="discord" type="text" placeholder="Discord" error={errors.discord} {...register('discord')} />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-x-6">
                <div>
                  <Label htmlFor="cell" label="Cell" error={errors.cell} />
                  <div className="mt-0.5">
                    <Controller
                      name="cell"
                      control={control}
                      render={({ field: { ref, ...field } }: any) => <PhoneInput {...field} inputProps={{ ref }} id="cell" />}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-center space-x-3 mt-[38px]">
                    <Checkbox checked={watch('whatsapp')} onChange={(e: any) => setValue('whatsapp', e.target.checked)} />
                    <p className="text-sm leading-6 font-normal text-black">Using WhatsApp</p>
                  </div>
                </div>
              </div>
              <div className="!my-8">
                <p className="text-sm leading-6 font-normal text-black">
                  Didn't fing influencer?{' '}
                  <Link to="/" className="text-blue-b1 hover:underline">
                    Go to data base
                  </Link>{' '}
                  to add new one.
                </p>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <button
                  type="button"
                  className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  {isUpdate ? <p>Save</p> : <p>Create</p>}
                  <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                </button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
