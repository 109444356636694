import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { IOAuthRequest, ISignInRequest } from './types';
import User from 'app/User';
import { login, logout, oauth } from 'services';
import { ROLE } from 'app/RequiredRole';
import { deleteUserAvatarThunk, updateUserInfoOnlyThunk, uploadUserAvatarThunk } from 'pages/Profile/reducer';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

interface IUser {
  token: string;
  full_name: string;
  email: string;
  role: ROLE;
  [prop: string]: string;
}

interface AuthState {
  user: IUser | null;
  loading: Loading;
}

const initialState: AuthState = { user: User.info, loading: defaultLoading };

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSignInThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED SIGN IN', payload);
        state.user = payload;
      })

      .addCase(fetchOAuthThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED OAuth', payload);
        state.user = payload;
      })

      .addCase(updateUserInfoOnlyThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE USER INFO', payload);
        User.updateUserInfo(payload[0]);
        state.user = { ...state.user, ...payload[0] };
      })

      .addCase(uploadUserAvatarThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(uploadUserAvatarThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPLOAD USER AVATAR', payload);
        User.updateUserInfo({ avatar_url: payload });
        if (state.user !== null) state.user = { ...state.user, avatar_url: payload };
        state.loading.is = false;
      })
      .addCase(uploadUserAvatarThunk.rejected, (state) => {
        state.loading.is = false;
      })

      .addCase(deleteUserAvatarThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(deleteUserAvatarThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE USER AVATAR', payload);
        User.updateUserInfo({ avatar_url: '' });
        if (state.user !== null) state.user = { ...state.user, avatar_url: '' };
        state.loading.is = false;
      })
      .addCase(deleteUserAvatarThunk.rejected, (state) => {
        state.loading.is = false;
      })

      .addCase(fetchLogoutThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED logout', payload);
        return { ...initialState, user: null };
      });
  },
});

// export const { setCredentials } = slice.actions

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchSignInThunk = createAsyncThunk('signin_thunk', async (user: ISignInRequest) => {
  const response = await login(user);

  User.login(response);
  return response;
});

export const fetchOAuthThunk = createAsyncThunk('oauth_thunk', async (user: IOAuthRequest) => {
  const response = await oauth(user);

  User.login(response);
  return response;
});

export const fetchLogoutThunk = createAsyncThunk('logout_thunk', async () => {
  await logout();

  User.logout();
  return;
});

export const selectState = (state: RootState) => state.auth;

export const selectUser = createSelector(selectState, (state) => state.user);

export const selectToken = createSelector(selectState, (state) => state?.user?.token);

export const selectRole = createSelector(selectState, (state) => state?.user?.role || ROLE.user);

const avatarUrl = '';

export const selectAvatarUrl = createSelector(selectState, (state) => state?.user?.avatar_url || avatarUrl);

export const selectAuthLoading = createSelector(selectState, (state) => state.loading);

export const selectAuthLoadingToHeader = createSelector(selectAuthLoading, (loading) => Boolean(loading.is && !loading.id));
