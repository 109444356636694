import { FC } from 'react';

export const SkeletonCampaignClient: FC = () => {
  return (
    <div className="flex flex-1 p-6 bg-gray-g1">
      <div className="animate-pulse flex flex-1 flex-col overflow-hidden">
        <div className="flex space-x-6">
          <div className="w-32 h-8 rounded-[4px] bg-[#e2e2e2]"></div>
          <div className="w-32 h-8 rounded-[4px] bg-[#e2e2e2]"></div>
          <div className="w-32 h-8 rounded-[4px] bg-[#e2e2e2]"></div>
        </div>
        <div className="mt-5 w-full h-[42px] rounded-[4px] bg-[#e2e2e2]"></div>
        <div className="flex items-center justify-between mt-8">
          <div className="w-52 h-12 rounded-[4px] bg-[#e2e2e2]"></div>
          <div className="flex space-x-4">
            <div className="w-12 h-12 rounded-[4px] bg-[#e2e2e2]"></div>
            <div className="w-40 h-12 rounded-[4px] bg-[#e2e2e2]"></div>
          </div>
        </div>
        <div className="flex items-center mt-2 space-x-2">
          <div className="w-32 h-5 rounded-[4px] bg-[#e2e2e2]"></div>
          <div className="w-32 h-5 rounded-[4px] bg-[#e2e2e2]"></div>
        </div>
        <div className="mt-6 w-full h-[90px] rounded-[4px] bg-[#e2e2e2]"></div>
        <div className="mt-6 flex-1 rounded-[4px] bg-[#e2e2e2]"></div>
      </div>
    </div>
  );
};
