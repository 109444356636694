import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { isArray, orderBy } from 'lodash';
import { getCommonApi } from 'services';
import { hasPlatform } from 'utils/common';

interface IApp {
  common: any;
}

const initialState: IApp = {
  common: {},
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // COMMON
      .addCase(fetchCommonThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH COMMON', payload);
        const { countries, languages, main_game, genre, platform, categories, agencies, has_platform } = payload[0];

        state.common = {
          ...payload[0],
          countries: isArray(countries) ? countries.filter(Boolean) : [],
          languages: isArray(languages) ? languages.filter((d: any) => d && !['do', 'iw', 'yu', 'zx'].includes(d)) : [],
          main_game: isArray(main_game) ? orderBy(main_game.filter(Boolean)) : [],
          genre: isArray(genre) ? orderBy(genre.filter(Boolean)) : [],
          platform: isArray(platform) ? orderBy(platform.filter(Boolean)) : [],
          categories: isArray(categories) ? orderBy(categories.filter(Boolean)) : [],
          agencies: isArray(agencies) ? orderBy(agencies.filter(Boolean)) : [],
          has_platform: isArray(has_platform) ? orderBy(has_platform.filter(Boolean)) : hasPlatform,
        };
      });
  },
});

// export const { setCredentials } = slice.actions

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchCommonThunk = createAsyncThunk('fetch_common_thunk', async () => {
  const response: any[] = await getCommonApi();

  if (!isArray(response) || (isArray(response) && !response.length)) throw new Error();
  return response;
});

//
// ----------------- SELECTORS ----------------- //
//

export const selectState = (state: RootState) => state.app;

export const selectCommon = createSelector(selectState, (state) => state.common);

export const selectCommonAgencies = createSelector(selectCommon, (common) => common.agencies);

export const selectCommonMainGame = createSelector(selectCommon, (common) => common.main_game);

export const selectCommonGenres = createSelector(selectCommon, (common) => common.genres);

export const selectCommonThemes = createSelector(selectCommon, (common) => common.themes);

export const selectCommonPlatform = createSelector(selectCommon, (common) => common.platform);

export const selectCommonCategories = createSelector(selectCommon, (common) => common.categories);

export const selectCommonHasPlatform = createSelector(selectCommon, (common) => common.has_platform);
