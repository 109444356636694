import { FC, Fragment, ReactNode, useRef } from 'react';
import cn from 'classnames';
import { Menu as HMenu } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import { detectOverflow } from '@floating-ui/react';
import { isEqual } from 'lodash';

type MenuItemsType = {
  label: string;
  onClick: (event: any) => void;
  iconComponent?: any;
  disabled?: boolean;
};

interface MenuProps {
  children: ReactNode | ((props: any) => ReactNode);
  items: MenuItemsType[];
  className?: string;
  itemsClass?: string;
  optionClass?: string;
}

export const MenuActionWithFloat: FC<MenuProps> = ({ children, items, className, itemsClass = '', optionClass = '' }) => {
  const initialRef = useRef(null); // need for detectOverflow initial value
  const menuPropsRef = useRef<any>(null); // props from menu: open, close() for detectOverflow

  const middleware = ({ referenceEl, floatingEl }: any) => [
    {
      name: 'middleware',
      async fn(state: any) {
        const overflow: any = await detectOverflow(state);
        if (initialRef.current != null && !isEqual(initialRef.current, overflow)) {
          initialRef.current = null;
          menuPropsRef.current.close();
        } else if (menuPropsRef.current.open && initialRef.current == null) initialRef.current = overflow;
        return {};
      },
    },
  ];

  return (
    <HMenu
      as="div"
      className={cn('relative inline-block text-left', className)}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {({ open, close }) => {
        if (!open) initialRef.current = null;
        menuPropsRef.current = { open, close };
        return (
          <>
            <Float
              middleware={middleware}
              offset={1}
              portal
              placement="bottom-end"
              flip
              strategy="fixed"
              transform={false}
              floatingAs={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              tailwindcssOriginClass
            >
              <HMenu.Button as="div" className="flex items-center focus:outline-none focus:ring-0">
                <span className="sr-only">Open menu</span>
                {typeof children === 'function' ? children({ open }) : children}
              </HMenu.Button>
              <HMenu.Items
                static
                className={cn(
                  'rounded-sm border border-gray-g1 shadow-s1 bg-white ring-0 focus:outline-none', //  origin-top-left absolute z-50 -left-2
                  itemsClass
                )}
              >
                <div className="py-1">
                  {items.map((item: MenuItemsType) => (
                    <HMenu.Item key={item.label} disabled={item.disabled}>
                      <div
                        onClick={(e: any) => item.onClick(e)}
                        className={cn(
                          'whitespace-nowrap px-1.5 py-1.5 space-x-2 flex items-center cursor-pointer text-black-b1 hover:bg-gray-g1',
                          item.disabled ? 'disabled' : '',
                          optionClass
                        )}
                      >
                        {item.iconComponent ? <item.iconComponent className="w-4 h-4" aria-hidden="true" /> : null}
                        <p className="text-sm">{item.label}</p>
                      </div>
                    </HMenu.Item>
                  ))}
                </div>
              </HMenu.Items>
            </Float>
          </>
        );
      }}
    </HMenu>
  );
};
