import { FC, useMemo, useState } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useQueryLocation } from 'hooks/useQueryLocation';
import { CampaignsTable } from './components/CampaignsTable';
import { SimpleSearch } from 'components/SimpleSearch';
import {
  fetchCampaignsClientThunk,
  selectCampaignsClient,
  selectCampaignsClientLoading,
  selectCampaignsCountClient,
  selectCampaignsOrderByClient,
  selectCampaignsOrderDirClient,
} from './reducer';
import { NoCampaigns } from './components/NoCampaigns';
// import { SkeletonCampaignsClient } from './Skeleton'

export const CampaignsClient: FC = () => {
  const query = useQueryLocation();
  const searchPage = query.get('page');

  const dispatch = useAppDispatch();
  const campaigns = useAppSelector(selectCampaignsClient);
  const campaingsOrderBy = useAppSelector(selectCampaignsOrderByClient);
  const campaingsOrderDir = useAppSelector(selectCampaignsOrderDirClient);
  const campaignsCount = useAppSelector(selectCampaignsCountClient);
  const campaignsLoading = useAppSelector(selectCampaignsClientLoading);

  const page = searchPage == null ? 1 : +searchPage;

  useLegacyEffect(() => {
    dispatch(
      fetchCampaignsClientThunk({
        page,
        order_by: campaingsOrderBy,
        order_dir: campaingsOrderDir,
      })
    );
    // dispatch(fetchCampaignsClientThunk({ thunk: { noLoading: true } }))
  }, [page, campaingsOrderBy, campaingsOrderDir]);

  // if (campaigns === undefined) return <SkeletonCampaignsClient />

  return (
    <>
      <SimpleSearch
        placeholder="Search by campaign name"
        size="lg"
        iconPlace="right"
        onChange={(value: string) => {
          dispatch(
            fetchCampaignsClientThunk({
              page,
              order_by: campaingsOrderBy,
              order_dir: campaingsOrderDir,
              search: value,
            })
          );
        }}
      />

      <div className="flex flex-1 flex-col p-6 bg-gray-g1 lg:overflow-hidden">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">Campaigns ({campaignsCount})</h1>
        </div>
        {campaigns === undefined ? null : campaigns.length ? (
          <CampaignsTable campaigns={campaigns} />
        ) : !campaignsCount && !campaignsLoading.is ? (
          <NoCampaigns />
        ) : null}
      </div>
    </>
  );
};
