import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

export interface LoadingType {
  is: boolean;
  id: string;
}

export interface ModalState {
  type: string | undefined;
  isOpen: boolean;
  config: any;
  loading: LoadingType;
  params: any;
}

const initialState: ModalState = {
  isOpen: false,
  type: undefined,
  config: {},
  loading: { is: false, id: '' },
  params: {},
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state: ModalState, { payload }: PayloadAction<Partial<ModalState>>) => {
      state.isOpen = true;
      state.type = payload.type;
      state.config = payload.config;
      state.params = payload.params;
    },
    hideModal: (state: ModalState) => {
      return { ...initialState };
    },
    startLoading: (state: ModalState, { payload }: PayloadAction<string>) => {
      state.loading = { is: true, id: payload };
    },
    stopLoading: (state: ModalState) => {
      state.loading = { is: false, id: '' };
    },
  },
});

export const { showModal, hideModal, startLoading, stopLoading } = slice.actions;

export default slice.reducer;

//
// ------------------ SELECTORS ------------------ //
//

export const selectState = (state: RootState) => state.modal;

export const selectIsOpen = createSelector(selectState, (state) => state.isOpen);

export const selectType = createSelector(selectState, (state) => state.type);

export const selectParams = createSelector(selectState, (state) => state.params);

export const selectLoading = createSelector(selectState, (state) => state.loading);
