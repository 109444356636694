import { FC } from 'react';
import { components, MultiValueRemoveProps, StylesConfig } from 'react-select';
import { default as RSAsyncSelect } from 'react-select/async';
import { colors } from 'variables';
import { CloseIcon } from 'assets/icons';

const filterColors = (options: any[], inputValue: string) => {
  return options.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
};

export const AsyncSelectMultiStyles: StylesConfig<any, true> = {
  control: (styles, { isFocused, selectProps: { error } }: any) => ({
    ...styles,
    minHeight: '48px',
    backgroundColor: 'white',
    fontSize: '12px',
    color: colors.black.b1,
    borderRadius: '4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    boxShadow: 'none',
    '&:hover': {
      borderColor: error ? colors.red.r1 : isFocused ? colors.blue.b1 : colors.gray.g2,
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 12px',
    fontSize: '12px',
    color: colors.black.b1,
  }),
  input: (styles) => ({
    ...styles,
    '& > input': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  singleValue: (styles) => ({ ...styles, marginLeft: 0, marginRight: 0 }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.gray.gm,
    fontSize: '12px',
  }),
  option: (styles) => ({
    ...styles,
    padding: '6px 16px',
    fontSize: '14px',
    color: colors.black.b1,
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 1000,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: '10px 0' }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: colors.blue.light,
    borderRadius: '4px',
    padding: '0 10px',
    // margin: '4px 8px 4px 0',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '12px',
    color: colors.blue.b1,
    '&:hover': {
      backgroundColor: colors.blue.light,
    },
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: colors.blue.b1,
    '&:hover': {
      backgroundColor: colors.blue.light,
      color: colors.blue.b1,
    },
  }),
};

export const MultiValueRemove = (props: MultiValueRemoveProps<any>) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon className="w-4 h-4" pathFill={colors.blue.b1} />
    </components.MultiValueRemove>
  );
};

interface IAsyncSelectMultiProps {
  options: any[]; // temp/ after API - remove
  loadOptions?: (value: any) => void;
  onChange?: (value: any) => void;
  name?: string;
  placeholder?: string;
  required?: boolean;
  [prop: string]: any;
}

export const AsyncSelectMulti: FC<IAsyncSelectMultiProps> = ({
  options,
  loadOptions = () => {},
  name = 'async-select',
  placeholder = 'Enter',
  required = false,
  ...rest
}) => {
  // const promiseOptions = (inputValue: string) => {
  //   return onChange
  //     ? onChange(inputValue)
  //     : new Promise<any[]>((resolve) => {
  //         setTimeout(() => {
  //           resolve(filterColors(options, inputValue))
  //         }, 500)
  //       })
  // }

  return (
    <RSAsyncSelect
      styles={{ ...AsyncSelectMultiStyles, ...(rest.styles || {}) }}
      name={name}
      required={required}
      // classNames={} // 5.7+ version
      className="w-full"
      cacheOptions
      isClearable={false}
      menuPlacement="auto"
      placeholder={placeholder}
      // defaultValue={formState.search}
      isMulti
      defaultOptions
      // loadOptions={promiseOptions}
      loadOptions={loadOptions}
      {...rest}
      // options={colourOptions}
      // onChange={}
      components={{
        // ...animatedComponents, // width: auto not working with animation
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MultiValueRemove,
        ...(rest.components || {}),
      }}
    />
  );
};
