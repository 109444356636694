import { FC, useMemo } from 'react';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { AsyncSelect, Form, Input, Label } from 'shared';
import { ValidationTypes } from 'utils/validation';
import { Controller } from 'react-hook-form';

interface IAdminAddInfluencerProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const AdminAddInfluencer: FC<IAdminAddInfluencerProps> = ({ params, hideModal }) => {
  const isUpdate = Boolean(params?.id);

  const defaultValues: any = useMemo(
    () => ({
      name: params?.name || '',
      url: params?.url || '',
    }),
    [params]
  );

  const selectOptions = useMemo(
    () => [
      { value: 'campaign_0', label: 'Campaing name 0' },
      { value: 'campaign_1', label: 'Camapign name 1' },
      { value: 'campaign_2', label: 'Campaign name 2' },
      { value: 'campaign_3', label: 'Campaign name 3' },
      { value: 'campaign_4', label: 'Campaign name 4' },
    ],
    []
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title={isUpdate ? 'Update campaign' : 'Add influencer'} close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        validationType={ValidationTypes.AdminAddInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          hideModal();
        }}
      >
        {({ register, control, formState: { errors } }) => (
          <>
            <div>
              <Label htmlFor="name" label="Influencers Name" error={errors.name} />
              <div className="mt-0.5">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }: any) => (
                    <AsyncSelect
                      {...field}
                      inputRef={field.ref}
                      error={errors.name}
                      placeholder="Enter name"
                      options={selectOptions}
                      value={selectOptions.find((option) => option.value === field.value)}
                      onChange={(option: any) => {
                        field.onChange(option.value);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="url" label="URL" error={errors.url} />
              <div className="mt-0.5">
                <Input id="url" type="text" placeholder="Place link!" error={errors.url} {...register('url')} />
              </div>
            </div>
            <div className="!mt-8 grid grid-cols-2 gap-8">
              <button
                type="button"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
                onClick={hideModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="flex w-full h-12 items-center justify-center text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
