import { FC, useState, KeyboardEventHandler, useMemo } from 'react';
import { useAppSelector } from 'hooks/store';
import CreatableSelect from 'react-select/creatable';
import Select, {
  ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  InputActionMeta,
  StylesConfig,
  MultiValueGenericProps,
} from 'react-select';
import { Controller } from 'react-hook-form';
import { isArray, isEqual, xor } from 'lodash';
import cn from 'classnames';
import { colors } from 'variables';
import {
  Form,
  Label,
  MultiValueRemove,
  Popover,
  RangeSlider,
  SelectCheckbox,
  Switch,
  ValueContainerWithSearch,
  settingsSelectCheckboxMultiWithSearchStyles,
} from 'shared';
import {
  ArrowDownDropdownSolidIcon,
  ArrowDownSmallIcon,
  ArrowUpDropdownSolidIcon,
  ArrowUpSmallIcon,
  CloseIcon,
  FilterIcon,
  SearchIcon,
  RecentIcon,
} from 'assets/icons';
import { Disclosure } from '@headlessui/react';
import { useGenreSelectOptions } from 'hooks/useGenre';
import { usePlatformSelectOptions } from 'hooks/usePlatform';
import { useCategoriesSelectOptions } from 'hooks/useCategories';
import { useHasPlatformSelectOptions } from 'hooks/useHasPlatform';
import { selectAgencies } from 'pages/Admin/reducer';
import { TagsSettings } from './Settings';
import { useThemeSelectOptions } from 'hooks/useThemes';

export const SearchStyles: StylesConfig<any, true> = {
  container: (styles) => ({ ...styles, flex: '1 1', overflow: 'hidden' }),
  control: (styles, { isFocused, getValue }) => ({
    ...styles,
    minHeight: '54px',
    padding: `0 ${getValue()?.length ? '18px' : '52px'} 0 18px`,
    backgroundColor: colors.gray.g1,
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.gray.gm,
    borderRadius: '0 4px 4px 0',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isFocused ? colors.blue.b1 : colors.gray.g1,
    boxShadow: 'none',
    '&:hover': {
      borderColor: isFocused ? colors.blue.b1 : colors.gray.g1,
    },
  }),
  input: (styles) => ({
    ...styles,
    '& > input': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.gray.gm,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
  }),
  clearIndicator: (styles, { selectProps: { isAdvances } }: any) => ({
    ...styles,
    marginRight: isAdvances ? '2.3rem' : '-0.5rem',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  indicatorsContainer: (styles, { selectProps: { size } }: any) => ({
    ...styles,
    alignItems: 'flex-start',
    marginTop: { sm: 0, md: '1px', lg: '7px' }[size as Size],
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: colors.blue.light,
    borderRadius: '4px',
    padding: '0 10px',
    margin: '4px 8px 4px 0',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: colors.blue.b1,
    '&:hover': {
      backgroundColor: colors.blue.light,
    },
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    '&:hover': {
      backgroundColor: colors.blue.light,
      color: colors.blue.b1,
    },
  }),
};

const Control = ({ children, ...props }: ControlProps<any, true>) => {
  return (
    <components.Control {...props}>
      <SearchIcon className="mr-5" />
      {children}
    </components.Control>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon className="cursor-pointer" />
    </components.ClearIndicator>
  );
};

const MultiValueContainerWithMode = (searchMode: string) => (props: MultiValueGenericProps<any, true>) => {
  const isLast = props.data.value === props.selectProps.value.at(-1)?.value;

  return (
    <>
      <components.MultiValueContainer {...props} />

      {searchMode && !isLast && <div className="uppercase mx-2">{searchMode}</div>}
    </>
  );
};

const createOption = (label: string) => ({
  label,
  value: label,
});

export const SearchSelectStyles: StylesConfig<any, false> = {
  container: (styles) => ({ ...styles, position: 'relative' }),
  control: (styles) => ({
    ...styles,
    width: '12rem',
    minHeight: '54px',
    height: '100%',
    padding: '0 6px 0 18px',
    backgroundColor: colors.gray.g1,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.black.b1,
    borderRadius: '4px 0 0 4px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.gray.g1,
    },
  }),
  input: (styles) => ({
    ...styles,
    '& > input': {
      boxShadow: 'none !important',
      outline: 'none !important',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.gray.gm,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, props) => ({
    ...styles,
    display: 'flex',
    padding: '6px 20px',
    fontSize: '14px',
    color: colors.black.b1,
    backgroundColor: props.isSelected ? colors.gray.g1 : colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 1000,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: '10px 0' }),
};

export const SearchModeSelectStyles: StylesConfig<any, false> = {
  ...SearchSelectStyles,

  control: (styles, props) => ({
    ...(SearchSelectStyles?.control ? SearchSelectStyles.control(styles, props) : {}),
    width: '7rem',
  }),
};

const SearchSelectDropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  const { menuIsOpen } = props.selectProps;
  return <components.DropdownIndicator {...props}>{menuIsOpen ? <ArrowUpDropdownSolidIcon /> : <ArrowDownDropdownSolidIcon />}</components.DropdownIndicator>;
};

const indicatorSeparatorStyle: any = {
  position: 'absolute',
  zIndex: 1000,
  top: 14,
  right: 0,
  bottom: 14,
  alignSelf: 'stretch',
  backgroundColor: colors.gray.gm,
  width: 1,
};

const SearchSelectIndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<any, false>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const searchOptions: any[] = [
  {
    value: 'name',
    label: 'Influencer name',
    search_label: 'influencer name',
    default: true,
  },
  { value: 'game', label: 'Game', search_label: 'game' },
  { value: 'keywords', label: 'Keywords', search_label: 'keywords' },
];

const searchModeOptions: any[] = [
  { value: 'or', label: 'OR', default: true },
  { value: 'and', label: 'AND' },
];

export type Size = 'sm' | 'md' | 'lg';

interface IHeaderSearchWithSettingsProps {
  value?: string[];
  searchSettings?: any;
  advancesFilters?: any;
  className?: string;
  noAdvances?: boolean;
  size?: Size;
  isDisabled?: boolean;
  platform?: string;
  onChange?: (values: string[], searchBy: string, searchMode: string, from?: string) => void;
  onSubmit?: (props: any) => void;
  [prop: string]: any;
}

export const HeaderSearchWithSettings: FC<IHeaderSearchWithSettingsProps> = ({
  className = '',
  value: _value = [],
  searchSettings = {},
  advancesFilters = {},
  noAdvances,
  size = 'md',
  isDisabled = false,
  onChange = () => null,
  onSubmit = () => null,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<any[]>(() => _value.map((d: string) => createOption(d)));

  const [searchBy, setSearchBy] = useState<any>(
    () => searchOptions.filter((d: any) => (searchSettings?.searchBy ? d.value === searchSettings.searchBy : d.default))?.[0] || null
  );

  const [searchMode, setSearchMode] = useState<any>(
    () => searchModeOptions.filter((sm: any) => (searchSettings?.searchMode ? sm.value === searchSettings.searchMode : sm.default))?.[0] || null
  );

  const isAdvances = !noAdvances;

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if ((!inputValue || value.some((d: any) => d.value === inputValue)) && (event.key === 'Enter' || event.key === 'Tab')) {
      event.preventDefault();
      return;
    }
    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        event.preventDefault();

        const newOption = createOption(inputValue);
        setValue((prev) => [...prev, newOption]);
        setInputValue('');
        onChange(
          [...value, newOption].map((d: any) => d.value),
          searchBy?.value,
          searchMode?.value
        );
      }
    }
  };

  // Method move as params to Search View
  // const handleChangeSearchOptions = useCallback(
  //   (options: any[]) => setSearchOptions(options),
  //   []
  // )

  return (
    <div className={cn('flex flex-col flex-shrink-0 p-6 bg-white', className)}>
      <div className="flex flex-1 justify-between">
        <div className="flex flex-1">
          <div className="relative flex w-full md:ml-0">
            <form
              className="w-full flex"
              onSubmit={(e: any) => {
                e.preventDefault();
                console.log('SUBMIT');
              }}
            >
              <Select
                styles={{
                  ...SearchSelectStyles,
                  ...(rest.searchSelectStyles || {}),
                }}
                isDisabled={isDisabled}
                name="searchBy"
                options={searchOptions}
                isSearchable={false}
                isClearable={false}
                components={{
                  DropdownIndicator: SearchSelectDropdownIndicator,
                  IndicatorSeparator: SearchSelectIndicatorSeparator,
                }}
                value={searchBy}
                onChange={(newValue: any) => {
                  setSearchBy(newValue);
                  onChange(
                    [...value].map((d: any) => d.value),
                    newValue.value,
                    newValue.value === 'name' ? 'or' : searchMode?.value,
                    'searchBy'
                  );
                }}
              />

              {searchBy?.value !== 'name' && (
                <Select
                  styles={{
                    ...SearchModeSelectStyles,
                    ...(rest.searchModeSelectStyles || {}),
                  }}
                  isDisabled={isDisabled}
                  name="searchMode"
                  options={searchModeOptions}
                  isSearchable={false}
                  isClearable={false}
                  components={{
                    DropdownIndicator: SearchSelectDropdownIndicator,
                    IndicatorSeparator: SearchSelectIndicatorSeparator,
                  }}
                  value={searchMode}
                  onChange={(newValue: any) => {
                    setSearchMode(newValue);
                    onChange(
                      [...value].map((sm: any) => sm.value),
                      searchBy?.value,
                      newValue.value,
                      'searchMode'
                    );
                  }}
                />
              )}

              <CreatableSelect
                styles={{ ...SearchStyles, ...(rest.styles || {}) }}
                {...{ isAdvances, size }}
                components={{
                  // ...animatedComponents, // width: auto not working with animation
                  Control,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  ClearIndicator,
                  MultiValueRemove,
                  Menu: () => null,
                  MultiValueContainer: MultiValueContainerWithMode(searchBy?.value === 'name' ? null : searchMode?.value),
                }}
                placeholder={`Search by ${searchBy?.search_label || ''}`}
                isDisabled={isDisabled}
                isMulti
                isClearable
                menuIsOpen={false}
                value={value}
                inputValue={inputValue}
                onChange={(newValue: any) => {
                  setValue(newValue);
                  onChange(
                    newValue.map((d: any) => d.value),
                    searchBy?.value,
                    searchMode?.value
                  );
                }}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
              />
              {/* <Select
                  className="w-full"
                  styles={searchStyles}
                  closeMenuOnSelect={false}
                  components={{
                    // ...animatedComponents, // width: auto not working with animation
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    ClearIndicator,
                    MultiValueRemove,
                    Menu: () => null,
                  }}
                  placeholder="Search by influencer name/keyword/game name"
                  defaultValue={searchOptions}
                  isMulti
                  options={searchOptions}
                  value={searchOptions}
                  onChange={(props: any) => handleChangeSearchOptions(props)}
                  onMenuOpen={() =>
                    dispatch(
                      showSearch({
                        type: SearchTypes.Campaigns,
                        params: {
                          options: searchOptions,
                          changeOptions: handleChangeSearchOptions,
                        },
                      })
                    )
                  }
                /> */}
            </form>

            <div
              className={cn(
                'absolute right-5 z-40 flex',
                { disabled: isDisabled },
                {
                  sm: 'top-4',
                  md: 'top-2.5',
                  lg: 'top-4',
                }[size]
              )}
            >
              {isAdvances ? (
                <Popover
                  panelClass="!origin-top-right !left-auto right-0 h-[calc(100vh-12rem)] p-[34px] px-[17px] !shadow-s7" // TODO make height auto without calc
                  content={(props: any) => (
                    <AdvancesSearch {...props} advancesFilters={advancesFilters} onSubmit={onSubmit} platform={rest.platform} searchBy={searchBy.value} />
                  )}
                >
                  {({ open }) => {
                    const keys = Object.keys(advancesFilters).length;
                    return (
                      <span className="relative">
                        <FilterIcon className="cursor-pointer" pathFill={open || keys ? colors.blue.b1 : colors.black.b1} />
                        {keys ? (
                          <span className="absolute -top-2 left-5 inline-flex items-center rounded-full px-1 py-0 text-xs font-medium text-blue-b1 bg-blue-b1/10">
                            {keys}
                          </span>
                        ) : null}
                      </span>
                    );
                  }}
                </Popover>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultSliderValue: any = {
  '0-720': { min: 0, max: 720 },
  '0-100': { min: 0, max: 100 },
  '0-60': { min: 0, max: 60 },
};

const getSliderValue = (field: string) => {
  switch (field) {
    case 'recent_videos_30':
    case 'yt_shorts_ratio':
    case 'avg_videos_month':
      return defaultSliderValue['0-100'];
    case 'avg_video_length':
      return defaultSliderValue['0-720'];
    // case 'yt_shorts_ratio':
    // case 'avg_videos_month':
    //   return defaultSliderValue['0-60']
    default:
      return defaultSliderValue['0-100'];
  }
};

export const AdvancesSearch: FC<any> = ({ close, advancesFilters, onSubmit, platform, searchBy }) => {
  const agencies = useAppSelector(selectAgencies);
  const genres = useGenreSelectOptions();
  const themes = useThemeSelectOptions();
  const platforms = usePlatformSelectOptions();
  const categories = useCategoriesSelectOptions();
  const hasPlatform = useHasPlatformSelectOptions();

  const defaultValues: any = useMemo(
    () => ({
      agency_id: advancesFilters?.agency_id || [],
      platform: advancesFilters?.platform || [],
      categories: advancesFilters?.categories || [],
      genres: advancesFilters?.genres || [],
      themes: advancesFilters?.themes || [],
      has_platform: advancesFilters?.has_platform || [],
      recently_played_games: advancesFilters?.recently_played_games || false,
      has_email: advancesFilters?.has_email || false,
      is_active: advancesFilters?.is_active || false,
      gender: advancesFilters?.gender || [],
      recent_videos_30: advancesFilters?.recent_videos_30
        ? {
            min: Math.round(advancesFilters?.recent_videos_30[0]) || getSliderValue('recent_videos_30').min,
            max: Math.round(advancesFilters?.recent_videos_30[1]) || getSliderValue('recent_videos_30').max,
          }
        : getSliderValue('recent_videos_30'),
      avg_video_length: advancesFilters?.avg_video_length
        ? {
            min: Math.round(advancesFilters?.avg_video_length[0]) || getSliderValue('avg_video_length').min,
            max: Math.round(advancesFilters?.avg_video_length[1]) || getSliderValue('avg_video_length').max,
          }
        : getSliderValue('avg_video_length'),
      yt_shorts_ratio: advancesFilters?.yt_shorts_ratio
        ? {
            min: Math.round(advancesFilters?.yt_shorts_ratio[0]) || getSliderValue('yt_shorts_ratio').min,
            max: Math.round(advancesFilters?.yt_shorts_ratio[1]) || getSliderValue('yt_shorts_ratio').max,
          }
        : getSliderValue('yt_shorts_ratio'),
      avg_videos_month: advancesFilters?.avg_videos_month
        ? {
            min: Math.round(advancesFilters?.avg_videos_month[0]) || getSliderValue('avg_videos_month').min,
            max: Math.round(advancesFilters?.avg_videos_month[1]) || getSliderValue('avg_videos_month').max,
          }
        : getSliderValue('avg_videos_month'),
      median_views_30: advancesFilters?.median_views_30 || [],
      median_views_60: advancesFilters?.median_views_60 || [],
    }),
    [advancesFilters]
  );

  const agencyOptions = useMemo(() => agencies.map((d: any) => ({ value: d.agency_id, label: d.agency_name })), [agencies]);

  const settingsConfig: any[] = useMemo(
    () => [
      {
        name: 'agency_id',
        label: 'Agency',
        options: agencyOptions,
      },
      // {
      //   name: 'platform',
      //   label: 'Gaming platform',
      //   options: platforms,
      // },
      {
        name: 'categories',
        label: 'Categories',
        options: categories,
      },
      {
        name: 'genres',
        label: 'Gaming genre',
        options: genres,
      },
      {
        name: 'themes',
        label: 'Gaming theme',
        options: themes,
      },
      {
        name: 'has_platform',
        label: 'Has Platform',
        options: hasPlatform,
      },
    ],
    [agencyOptions, platforms, categories, genres, themes, hasPlatform]
  );

  const onInputChange = (inputValue: string, { action, prevInputValue }: InputActionMeta) => {
    if (action === 'input-change') return inputValue;
    if (action === 'menu-close') return '';

    return prevInputValue;
  };

  return (
    <Form
      className="flex flex-col h-full overflow-hidden"
      defaultValues={defaultValues}
      // validationType={ValidationTypes.AdminAddInfluencer}
      onSubmit={(values: any) => {
        console.log('SUMBIT', values);
        const result: any = {};
        Object.keys(defaultValues).forEach((key: string) => {
          if (values[key] && ((isArray(values[key]) && values[key].length) || (Object.keys(values[key]).length && !isEqual(values[key], getSliderValue(key)))))
            result[key] =
              key === 'recent_videos_30' || key === 'avg_video_length' || key === 'yt_shorts_ratio' || key === 'avg_videos_month'
                ? [values[key].min, values[key].max]
                : values[key];

          if (typeof values[key] === 'boolean' && values[key]) result[key] = values[key];
        });

        onSubmit(result);
        close();
      }}
    >
      {({ control, watch, setValue, setError, clearErrors, reset, resetField, formState: { errors } }) => (
        <>
          <div className="flex items-center justify-between mb-5 px-[17px]">
            <p className=" whitespace-nowrap text-base leading-6 font-semibold text-black">Advances search</p>
            <div
              className="cursor-pointer text-sm leading-6 font-semibold text-blue-b1"
              onClick={() => {
                reset();
                onSubmit({});
                close();
              }}
            >
              Reset
            </div>
          </div>
          <div className="h-full px-[17px] space-y-4 overflow-y-auto">
            <div className="w-[18.75rem] space-y-4">
              {settingsConfig.map((d: any) => (
                <div key={d.name}>
                  <Label
                    htmlFor={d.name}
                    label={d.label}
                    // label={
                    //   <span className="flex items-center">
                    //     <p className='mr-1'>{d.label} </p>
                    //     {watch(d.name) && watch(d.name).length ? (
                    //       <>
                    //         <p>(</p>
                    //         <p className="text-blue-b1">
                    //           {watch(d.name).length}
                    //         </p>
                    //         <p>)</p>
                    //       </>
                    //     ) : null}
                    //   </span>
                    // }
                    error={errors[d.name]}
                  />
                  <Controller
                    name={d.name}
                    control={control}
                    render={({ field }: any) => (
                      <SelectCheckbox
                        {...field}
                        styles={settingsSelectCheckboxMultiWithSearchStyles}
                        placeholder="Select"
                        options={d.options}
                        error={errors[field.name]}
                        isSearchable={true}
                        isClearable={true}
                        onInputChange={onInputChange}
                        defaultValue={d.options.filter((option: any) => field.value.includes(option.value)) || null}
                        value={d.options.filter((option: any) => field.value.includes(option.value)) || null}
                        onChange={(option: any, actionMeta: any) => {
                          // Clear
                          if (actionMeta.action === 'clear') field.onChange([]);
                          else field.onChange(option.map((d: any) => d.value));
                        }}
                        components={{
                          ValueContainer: ValueContainerWithSearch,
                          MultiValue: () => null,
                          MultiValueLabel: () => null,
                          MultiValueRemove: () => null,
                        }}
                      />
                    )}
                  />
                </div>
              ))}
            </div>
            <div className="w-[18.75rem] space-y-4">
              {/* <p className="whitespace-nowrap text-sm leading-6 font-semibold text-black">
                Gender
              </p>
              <div className="grid grid-cols-2 gap-[1.125rem]">
                <div className="flex items-center justify-between h-[2.75rem] px-3 rounded-[4px] text-sm leading-6 text-black-b1 bg-gray-g1">
                  <p>Male</p>
                  <Switch
                    checked={watch('gender').includes('male')}
                    onChange={() =>
                      setValue('gender', xor(watch('gender'), ['male']))
                    }
                  />
                  {/ * <Controller
                    name="gender"
                    control={control}
                    render={({ field }: any) => {
                      console.log('field', field.value)
                      return (
                        <Switch
                          {...field}
                          checked={field.value === 'male'}
                          onChange={(checked: boolean) =>
                            field.onChange(checked ? 'male' : '')
                          }
                        />
                      )
                    }}
                  /> * /}
                </div>
                <div className="flex items-center justify-between h-[2.75rem] px-3 rounded-[4px] text-sm leading-6 text-black-b1 bg-gray-g1">
                  <p>Female</p>
                  <Switch
                    checked={watch('gender').includes('female')}
                    onChange={() =>
                      setValue('gender', xor(watch('gender'), ['female']))
                    }
                  />
                </div>
              </div> */}

              <p className="whitespace-nowrap text-sm leading-6 font-semibold text-black">Channels</p>

              {searchBy === 'game' && (
                <div className="flex p-3 rounded-[4px] text-black-b1 bg-gray-g1">
                  <div className="flex-none mr-2">
                    <RecentIcon />
                  </div>

                  <div className="grow">
                    <div className="flex items-center justify-between text-sm leading-6">
                      <p>Recently played games</p>

                      <Controller
                        name="recently_played_games"
                        control={control}
                        render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(checked: boolean) => field.onChange(checked)} />}
                      />
                    </div>

                    <div className="mt-1 text-xs text-gray-gd leading-4">Show only channels who played selected games during last 20 videos/streams</div>
                  </div>
                </div>
              )}

              <div className="flex items-center justify-between h-[2.75rem] px-3 rounded-[4px] text-sm leading-6 text-black-b1 bg-gray-g1">
                <p>Active for the last 60 days</p>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }: any) => {
                    return <Switch {...field} checked={field.value} onChange={(checked: boolean) => field.onChange(checked)} />;
                  }}
                />
              </div>
              <div className="flex items-center justify-between h-[2.75rem] px-3 rounded-[4px] text-sm leading-6 text-black-b1 bg-gray-g1">
                <p>Has emails</p>
                <Controller
                  name="has_email"
                  control={control}
                  render={({ field }: any) => {
                    return <Switch {...field} checked={field.value} onChange={(checked: boolean) => field.onChange(checked)} />;
                  }}
                />
              </div>

              <div className="p-4 rounded-[4px] bg-gray-g1">
                <div className="flex items-center justify-between">
                  <p className="text-sm leading-6 font-semibold text-black">Recent videos</p>
                  <p
                    className="cursor-pointer text-sm leading-6 font-semibold text-blue-b1"
                    onClick={() =>
                      resetField('recent_videos_30', {
                        defaultValue: getSliderValue('recent_videos_30'),
                      })
                    }
                  >
                    Clear
                  </p>
                </div>
                <div id="advances-search" className="relative px-1 pt-10 w-full">
                  <Controller
                    name="recent_videos_30"
                    control={control}
                    render={({ field }: any) => (
                      <RangeSlider {...field} minValue={getSliderValue('recent_videos_30').min} maxValue={getSliderValue('recent_videos_30').max} />
                    )}
                  />
                </div>
              </div>

              <div className="p-4 rounded-[4px] bg-gray-g1">
                <div className="flex items-center justify-between">
                  <p className="text-sm leading-6 font-semibold text-black">Avg. video length (minutes)</p>
                  <p
                    className="cursor-pointer text-sm leading-6 font-semibold text-blue-b1"
                    onClick={() =>
                      resetField('avg_video_length', {
                        defaultValue: getSliderValue('avg_video_length'),
                      })
                    }
                  >
                    Clear
                  </p>
                </div>
                <div id="advances-search" className="relative px-1 pt-10 w-full">
                  <Controller
                    name="avg_video_length"
                    control={control}
                    render={({ field }: any) => (
                      <RangeSlider {...field} minValue={getSliderValue('avg_video_length').min} maxValue={getSliderValue('avg_video_length').max} />
                    )}
                  />
                </div>
              </div>

              {platform === 'youtube' && (
                <div className="p-4 rounded-[4px] bg-gray-g1">
                  <div className="flex items-center justify-between">
                    <p className="text-sm leading-6 font-semibold text-black">YT shorts ratio (%)</p>
                    <p
                      className="cursor-pointer text-sm leading-6 font-semibold text-blue-b1"
                      onClick={() =>
                        resetField('yt_shorts_ratio', {
                          defaultValue: getSliderValue('yt_shorts_ratio'),
                        })
                      }
                    >
                      Clear
                    </p>
                  </div>
                  <div id="advances-search" className="relative px-1 pt-10 w-full">
                    <Controller
                      name="yt_shorts_ratio"
                      control={control}
                      render={({ field }: any) => (
                        <RangeSlider {...field} minValue={getSliderValue('yt_shorts_ratio').min} maxValue={getSliderValue('yt_shorts_ratio').max} />
                      )}
                    />
                  </div>
                </div>
              )}

              <div className="p-4 rounded-[4px] bg-gray-g1">
                <div className="flex items-center justify-between">
                  <p className="text-sm leading-6 font-semibold text-black">Avg. videos/month</p>
                  <p
                    className="cursor-pointer text-sm leading-6 font-semibold text-blue-b1"
                    onClick={() =>
                      resetField('avg_videos_month', {
                        defaultValue: getSliderValue('avg_videos_month'),
                      })
                    }
                  >
                    Clear
                  </p>
                </div>
                <div id="advances-search" className="relative px-1 pt-10 w-full">
                  <Controller
                    name="avg_videos_month"
                    control={control}
                    render={({ field }: any) => (
                      <RangeSlider {...field} minValue={getSliderValue('avg_videos_month').min} maxValue={getSliderValue('avg_videos_month').max} />
                    )}
                  />
                </div>
              </div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={cn(
                        'w-full flex justify-between p-4 rounded-[4px] bg-gray-g1 focus:outline-none',
                        open ? 'rounded-bl-none rounded-br-none pb-2.5' : ''
                      )}
                    >
                      <p className="text-sm leading-6 font-semibold text-black">Median views (last 30 days)</p>
                      {open ? <ArrowUpSmallIcon /> : <ArrowDownSmallIcon />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="!mt-0 p-4 pt-0 rounded-bl-[4px] rounded-br-[4px] bg-gray-g1">
                      <Controller
                        name="median_views_30"
                        control={control}
                        render={({ field }: any) => (
                          <TagsSettings
                            {...field}
                            className="!p-0"
                            valueClass="!text-gray-gm"
                            isTags={false}
                            config={{ type: '00' }}
                            setError={setError}
                            clearErrors={clearErrors}
                          />
                        )}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={cn(
                        'w-full flex justify-between p-4 rounded-[4px] bg-gray-g1 focus:outline-none',
                        open ? 'rounded-bl-none rounded-br-none pb-2.5' : ''
                      )}
                    >
                      <p className="text-sm leading-6 font-semibold text-black">Median views (last 60 days)</p>
                      {open ? <ArrowUpSmallIcon /> : <ArrowDownSmallIcon />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="!mt-0 p-4 pt-0 rounded-bl-[4px] rounded-br-[4px] bg-gray-g1">
                      <Controller
                        name="median_views_60"
                        control={control}
                        render={({ field }: any) => (
                          <TagsSettings
                            {...field}
                            className="!p-0"
                            valueClass="!text-gray-gm"
                            isTags={false}
                            config={{ type: '00' }}
                            setError={setError}
                            clearErrors={clearErrors}
                          />
                        )}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              {/* <div className="p-4 rounded-[4px] bg-gray-g1">
                <p className="text-sm leading-6 font-semibold text-black">
                  Sentiment score
                </p>
                <div
                  id="advances-search"
                  className="relative px-1 pt-10 w-full"
                >
                  <Controller
                    name="sentiment_score"
                    control={control}
                    render={({ field }: any) => <RangeSlider {...field} />}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div className="mt-3 px-[17px] grid grid-cols-2 gap-4">
            <button
              type="button"
              className="flex w-full h-[42px] items-center uppercase justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex w-full h-[42px] items-center uppercase justify-center text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none"
            >
              Apply Search
            </button>
          </div>
        </>
      )}
    </Form>
  );
};
