import { ChangeEventHandler, FC, useRef, useState } from 'react';
import { SERVER_API } from 'app/constants';
import { selectAvatarUrl, selectUser } from 'pages/Auth/reducer';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useUserInitials } from 'hooks/useUserInitials';
import cn from 'classnames';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { CloseIcon, DeleteIcon, HICheckIcon, UploadPhotoIcon } from 'assets/icons';
import { deleteUserAvatarThunk, uploadUserAvatarThunk } from 'pages/Profile/reducer';
import { toast } from 'shared';

interface IAvatarProps {
  src?: string | null;
  initials?: string | null;
  imageClass?: string;
  initialsClass?: string;
}

export const Avatar: FC<IAvatarProps> = ({ src = undefined, initials = '', imageClass = 'w-11 rounded-[4px]', initialsClass }) => {
  return src != null && src ? <AvatarImage className={imageClass} src={src} /> : <AvatarInitials className={initialsClass} initials={initials} />;
};

export const ProfileAvatar: FC<IAvatarProps> = (props) => {
  const user = useAppSelector(selectUser);
  const avatarUrl = useAppSelector(selectAvatarUrl);

  return <Avatar {...props} src={avatarUrl} initials={user?.full_name || ''} />;
};

interface IAvatarImageProps {
  src: string;
  className?: string;
}

export const AvatarImage: FC<IAvatarImageProps> = ({ src = '', className = 'w-11 rounded-[4px]' }) => {
  return (
    <div className={cn('aspect-h-1 aspect-w-1 overflow-hidden', className)}>
      <img
        src={SERVER_API + '/profile/avatar/' + src}
        alt=""
        className="h-full w-full object-cover object-center invisible"
        onLoad={(e: any) => (e.target.style.visibility = 'visible')}
        onError={(event: any) => {
          setTimeout(() => {
            event.target.src = SERVER_API + '/profile/avatar/' + src;
          }, 1000);
          // event.onerror = null // For not call onError event
        }}
      />
    </div>
  );
};

interface IAvatarInitialsProps {
  initials?: string | null;
  className?: string;
}

export const AvatarInitials: FC<IAvatarInitialsProps> = ({ initials = '', className = 'h-11 w-11 rounded-[4px]' }) => {
  const _initials = initials || 'Unknown';
  const userInitials = useUserInitials(_initials);

  return (
    <span className={cn('inline-flex items-center justify-center text-sm font-medium text-blue-b1 bg-blue-bl', className)} title={_initials}>
      <span>{userInitials}</span>
    </span>
  );
};

// this transforms file to base64
const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || '');
    reader.onerror = (error) => reject(Error('Image preview is not available'));
  });
};

export const AvatarManage: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const avatarUrl = useAppSelector(selectAvatarUrl);

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(avatarUrl);

  const imageRef = useRef<HTMLInputElement>(null);

  const isNewImage = imageUrl.includes('base64');
  const url = isNewImage ? imageUrl : imageUrl ? SERVER_API + '/profile/avatar/' + imageUrl : '';

  const onChangeImage: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      file2Base64(file).then((base64) => {
        setImageFile(file);
        setImageUrl(base64);
      });
    }
  };

  useLegacyEffect(() => {
    if (avatarUrl !== imageUrl) {
      if (imageRef.current !== null && imageRef.current.value) imageRef.current.value = '';
      setImageFile(null);
      setImageUrl(avatarUrl);
    }
  }, [avatarUrl]);

  return (
    <>
      {url ? (
        <div className={cn('aspect-h-1 aspect-w-1 overflow-hidden w-28 rounded-full')}>
          <img
            src={url}
            alt=""
            className="h-full w-full object-cover object-center invisible"
            onLoad={(e: any) => (e.target.style.visibility = 'visible')}
            onError={(event: any) => {
              setTimeout(() => {
                event.target.src = url;
              }, 1000);
              // event.onerror = null // For not call onError event
            }}
          />
        </div>
      ) : (
        <AvatarInitials className="w-28 h-28 rounded-full text-3xl font-medium" initials={user?.full_name || ''} />
      )}
      <button
        type="button"
        className="absolute bottom-0 right-0 z-20 cursor-pointer inline-flex items-center rounded-full border border-transparent bg-blue-b1 p-1 text-white shadow-sm hover:bg-blue-b1 focus:outline-none focus:ring-0"
        onClick={() => imageRef.current?.click()}
      >
        <input ref={imageRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={onChangeImage} />
        <UploadPhotoIcon aria-hidden="true" />
      </button>
      {isNewImage ? (
        <>
          <button
            type="button"
            className="absolute bottom-0 -right-10 inline-flex items-center rounded-full border border-transparent bg-gray-gm p-1 text-white shadow-sm hover:bg-gray-gm focus:outline-none focus:ring-0"
            onClick={() => {
              if (imageRef.current !== null && imageRef.current.value) imageRef.current.value = '';
              setImageFile(null);
              setImageUrl(avatarUrl);
            }}
          >
            <CloseIcon aria-hidden="true" width="20" height="20" pathFill="#ffffff" />
          </button>
          <button
            type="button"
            className="absolute bottom-0 -right-20 inline-flex items-center rounded-full border border-transparent bg-green-g1 p-1 text-white shadow-sm hover:bg-green-g1 focus:outline-none focus:ring-0"
            onClick={() => {
              if (imageFile != null)
                dispatch(uploadUserAvatarThunk(imageFile))
                  .unwrap()
                  .then(() =>
                    toast('Profile image updated successfully', {
                      type: 'success',
                    })
                  )
                  // TODO make one toast if worked toast with status = 500
                  .catch(() =>
                    toast('Failed to update profile image', {
                      type: 'error',
                    })
                  );
            }}
          >
            <HICheckIcon className="w-5 h-5" />
          </button>
        </>
      ) : imageUrl ? (
        <button
          type="button"
          className="absolute bottom-0 -right-10 inline-flex items-center rounded-full border border-transparent bg-gray-gm p-1 text-white shadow-sm hover:bg-gray-gm focus:outline-none focus:ring-0"
          onClick={() =>
            dispatch(deleteUserAvatarThunk(avatarUrl))
              .unwrap()
              .then(() =>
                toast('Profile image deleted successfully', {
                  type: 'success',
                })
              )
              // TODO make one toast if worked toast with status = 500
              .catch(() =>
                toast('Failed to delete profile image', {
                  type: 'error',
                })
              )
          }
        >
          <DeleteIcon aria-hidden="true" width={20} height={20} pathFill="#ffffff" />
        </button>
      ) : null}
    </>
  );
};
