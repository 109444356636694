import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { cloneDeep, isArray } from 'lodash';
import {
  createUserApi,
  deleteUserApi,
  getAgenciesApi,
  getClientsApi,
  getInfluencersV2Api,
  getUsersApi,
  ICreateUser,
  IListClients,
  ISearchChannels,
  IUpdateUser,
  SearchChannelsResponse,
  updateUserApi,
} from 'services';
import { toast } from 'shared';
import { channelsLimit } from 'utils/common';

type Loading = { is: boolean; id: string };

const defaultLoading: Loading = { is: false, id: '' };

const defaultTableFilters: any = {};

interface ISearchInfluencersAdminProps extends ISearchChannels {
  search?: any;
  tableFilters?: any;
}

interface IUsers {
  influencers: any[];
  users: any[];
  clients: any[];
  total: any;
  searchInfluencers: ISearchInfluencersAdminProps;
  selectedInfluencersRows: any[];
  loading: Loading;

  agencies: any[];
}

const initialState: IUsers = {
  influencers: [],
  users: [],
  clients: [],
  total: {},
  searchInfluencers: {
    order_by: '',
    order_dir: '',
    tableFilters: defaultTableFilters,
  },
  selectedInfluencersRows: [],
  loading: defaultLoading,

  agencies: [], // TODO move to another place
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearchInfluencers: (state, { payload }: PayloadAction<Partial<ISearchInfluencersAdminProps>>) => {
      state.searchInfluencers = { ...state.searchInfluencers, ...payload };
    },
    setInfluencersRows: (state, { payload }: PayloadAction<any[]>) => {
      state.selectedInfluencersRows = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsersThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchUsersThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH USERS', payload);

        state.users = payload;
        state.loading.is = false;
      })
      .addCase(fetchUsersThunk.rejected, (state) => {
        console.log('REJECTED FETCH USERS');

        state.loading.is = false;
      })

      .addCase(createUserThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED CREATE USER', payload);

        state.users.push(payload[0]);
      })

      .addCase(updateUserThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED UPDATE USER', payload);

        const idx = state.users.findIndex((d: any) => d.user_id === payload[0].user_id);
        if (idx !== -1) state.users.splice(idx, 1, payload[0]);
      })

      .addCase(deleteUserThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED DELETE USER', payload);

        state.users = state.users.filter((d: any) => d.user_id !== payload);
      })

      .addCase(fetchClientsThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH CLIENTS', payload);

        state.clients = payload;
      })

      .addCase(fetchAgenciesThunk.fulfilled, (state, { payload }) => {
        console.log('FULFILLED FETCH AGENCIES', payload);

        state.agencies = payload;
      })

      .addCase(fetchInfluencersAdminThunk.pending, (state) => {
        state.loading.is = true;
      })
      .addCase(fetchInfluencersAdminThunk.fulfilled, (state, { payload, meta }) => {
        console.log('FULFILLED FETCH INFLUENCERS ADMIN', payload);

        if (payload.data && isArray(payload.data) && payload.total) {
          state.influencers = meta.arg?.isScrollFetch ? [...state.influencers, ...payload.data] : payload.data;
          state.total = payload.total;
        }
        state.loading.is = false;
      })
      .addCase(fetchInfluencersAdminThunk.rejected, (state) => {
        console.log('REJECTED FETCH INFLUENCERS ADMIN');

        state.loading.is = false;
      });
  },
});

export const usersActions = slice.actions;

export default slice.reducer;

//
// ----------------- ASYNC LOGIC ----------------- //
//

export const fetchUsersThunk = createAsyncThunk('fetch_users_thunk', async (search: string | void) => {
  const response: any[] = await getUsersApi(search || '');
  return typeof response === 'string' ? [] : response;
});

export const createUserThunk = createAsyncThunk('create_user_thunk', async (data: ICreateUser) => {
  const response: any = await createUserApi(data);

  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to create new user', {
      type: 'error',
    });
    throw new Error();
  } else {
    toast('New user created successfully', { type: 'success' });
  }

  return response;
});

export const updateUserThunk = createAsyncThunk('update_user_thunk', async ({ user_id, data }: IUpdateUser) => {
  const response: any[] = await updateUserApi({ user_id, data });

  if (!isArray(response) || (isArray(response) && !response.length)) {
    toast('Failed to update user', {
      type: 'error',
    });
    throw new Error();
  } else {
    toast('New user updated successfully', { type: 'success' });
  }

  return response;
});

export const deleteUserThunk = createAsyncThunk('delete_user_thunk', async (user_id: string) => {
  const response = await deleteUserApi(user_id);

  if (typeof response === 'string' && response === 'Success!') {
    toast('User deleted successfully', { type: 'success' });
  } else {
    toast('Failed to delete user', { type: 'error' });
    throw new Error();
  }

  return user_id;
});

export const fetchInfluencersAdminThunk = createAsyncThunk(
  'fetch_influencers_admin_thunk',
  async (args: (ISearchInfluencersAdminProps & { isScrollFetch?: boolean }) | void, { getState }: any) => {
    const { searchInfluencers } = getState().users;

    const search = cloneDeep({ ...searchInfluencers, ...args });
    const keys = Object.keys(search);
    const payloadObj: any = {};

    if (keys.length) {
      payloadObj.limit = channelsLimit;

      if (search.search) payloadObj.search = search.search;
      if (search.order_by) payloadObj.order_by = search.order_by;
      if (search.order_dir) payloadObj.order_dir = search.order_dir;
      if (search.offset) payloadObj.offset = search.offset;
    }

    const response: SearchChannelsResponse = await getInfluencersV2Api(payloadObj);
    return typeof response === 'string' ? { data: [], total: {} } : response;
  }
);

// CLIENTS

export const fetchClientsThunk = createAsyncThunk('fetch_clients_thunk', async (args?: IListClients) => {
  const response: any[] = await getClientsApi(args ?? {});
  return typeof response === 'string' ? [] : response;
});

// AGENCIES

export const fetchAgenciesThunk = createAsyncThunk('fetch_agencies_thunk', async (args) => {
  const response: any[] = await getAgenciesApi();
  return typeof response === 'string' ? [] : response;
});

// ------------- SELECTORS --------------- //

export const selectState = (state: RootState) => state.users;

export const selectInfluencersAdmin = createSelector(selectState, (state) => state.influencers);

export const selectUsers = createSelector(selectState, (state) => state.users);

export const selectClients = createSelector(selectState, (state) => state.clients);

export const selectAgencies = createSelector(selectState, (state) => state.agencies);

export const selectTotalAdmin = createSelector(selectState, (state) => state.total);

export const selectSearchInfluencersAdmin = createSelector(selectState, (state) => state.searchInfluencers);

export const selectInfluencersSelectedRowsAdmin = createSelector(selectState, (state) => state.selectedInfluencersRows);

export const selectSearchTableFilters = createSelector(selectSearchInfluencersAdmin, (search) => search?.tableFilters || defaultTableFilters);

const defaultResizing = {};

export const selectSearchTableFiltersResizing = createSelector(selectSearchTableFilters, (tableFilters) => tableFilters?.resizing || defaultResizing);

export const selectUsersLoading = createSelector(selectState, (state) => state.loading);

export const selectUsersLoadingToHeader = createSelector(selectUsersLoading, (loading) => Boolean(loading.is && !loading.id));
