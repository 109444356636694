import { FC, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'hooks/store';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { ClipLoader, Form, Input, Label } from 'shared';
import { ValidationTypes } from 'utils/validation';
import { requestResetLinkThunk } from 'pages/Profile/reducer';

import logo from 'assets/logo/Cloutboost_Horizontal_logo_(Warm Gradient)@4x_(1).png';
import gamersImage from 'assets/logo/Combined_All_gamers.jpg';

export const ForgotPassword: FC = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  let isCorrectFieldsText = useRef(true); // For submit error text when invalid fields

  const defaultValues: { email: string } = useMemo(
    () => ({
      email: state?.email || '',
    }),
    [state?.email]
  );
  return (
    <>
      <div className="relative min-h-full flex lg:grid lg:grid-cols-2 sm:p-6">
        <Link to="/login" className="absolute top-4 left-4 sm:top-6 sm:left-6 flex items-center">
          <img className="w-[187px]" src={logo} alt="Cloutboost" />
        </Link>
        <div className="flex flex-1 flex-col justify-center px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <Form
            defaultValues={defaultValues}
            validationType={ValidationTypes.forgotPassword}
            onSubmit={async (values) => {
              return await dispatch(requestResetLinkThunk(values)).then((res: any) => {
                if (res.payload === 'Ok') setIsSubmit(true);
              });
            }}
          >
            {({ register, watch, formState: { errors, isSubmitting, isValid } }) => {
              if (isSubmitting) isCorrectFieldsText.current = isValid;
              return (
                <div className="mx-auto w-full max-w-sm lg:w-[295px]">
                  <div>
                    <h1 className="mt-6 text-2xl leading-[42px] font-semibold tracking-tight text-black">
                      {isSubmit ? 'Check your email' : 'Forgot your password?'}
                    </h1>
                    <p className="mt-3 pr-5 text-sm font-normal leading-6 text-black">
                      {isSubmit ? 'We sent a password reset link to' : "Enter your email address below and we'll get you back on track."}
                    </p>
                    {isSubmit ? <p className="pr-5 text-sm font-semibold leading-6 text-black">{watch('email')}</p> : null}
                  </div>

                  {!isSubmit ? (
                    <div className="mt-7 lg:w-[280px]">
                      <div>
                        <Label htmlFor="email" label="Email address" error={errors.email} />
                        <div className="mt-0.5">
                          <Input id="email" type="email" placeholder="Email" error={errors.email} {...register('email')} />
                        </div>
                      </div>

                      <div className="relative pt-[38px]">
                        {!isCorrectFieldsText.current ? (
                          <div className="absolute top-2.5 left-0 text-[10px] leading-6 text-red-r1">Incorrect email address. Try again.</div>
                        ) : null}

                        <button
                          type="submit"
                          className={cn(
                            'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                            { disabled: isSubmitting }
                          )}
                          disabled={isSubmitting}
                        >
                          <p>Request reset link</p>
                          <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                        </button>
                      </div>
                    </div>
                  ) : null}

                  {isSubmit ? (
                    <div className="mt-10 space-x-1.5 flex items-center">
                      <p className="text-sm leading-6 font-normal text-black">Didn't receive the email?</p>
                      <p
                        className="cursor-pointer text-sm leading-6 font-semibold text-blue-b1 hover:text-blue-b1"
                        onClick={() => dispatch(requestResetLinkThunk({ email: watch('email') }))}
                      >
                        Resend
                      </p>
                    </div>
                  ) : null}

                  <div className="mt-4 flex items-center">
                    <p className="text-sm leading-6 font-semibold text-black">
                      Back to{' '}
                      <Link to={state?.from ? state.from : '/login'} className="text-sm leading-6 font-semibold text-blue-b1 hover:text-blue-b1">
                        {state?.from ? 'profile' : 'Log In'}
                      </Link>
                    </p>
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
        <div className="relative hidden flex-1 lg:block">
          <img className="absolute h-full w-full rounded-[30px] object-cover" src={gamersImage} alt="" />
        </div>
      </div>
    </>
  );
};
