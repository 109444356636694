import { FC, useState, useEffect, useMemo, useRef, memo, useImperativeHandle, forwardRef } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useTable, useRowSelect, useResizeColumns, useFlexLayout } from 'react-table';
import { useVirtual } from 'react-virtual';
import { isEqual } from 'lodash';
import cn from 'classnames';
import { agencyConfig } from 'utils/common';
import { formatNumber } from 'utils/helper';
import { MenuActionWithFloat, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { SimpleSearch } from 'components/SimpleSearch';
import { PopupDescription } from 'components/PopupDescription';
import { DotsIcon, ExportIcon } from 'assets/icons';
import { PlusIcon } from '@heroicons/react/24/outline';
import { selectInfluencersSelectedRowsAdmin, selectSearchInfluencersAdmin } from '../reducer';
import {
  createAgencyThunk,
  deleteAgencyThunk,
  fetchAgenciesFullThunk,
  selectAgenciesAdmin,
  selectAgenciesLoadingToHeader,
  updateAgencyThunk,
  usersActions,
} from '../agenciesReducer';
import { getCountriesMap } from '../../../utils/countries';
import { ExportMenu } from '../../../components/ExportMenu';

export const ManageAgencies: FC = () => {
  const dispatch = useAppDispatch();
  useLegacyEffect(() => {
    dispatch(fetchAgenciesFullThunk());
  }, []);

  const countriesMap = getCountriesMap();

  const agencies = useAppSelector(selectAgenciesAdmin);
  const [filteredAgencies, setFilteredAgencies] = useState<{}[]>([]);

  useEffect(() => {
    setFilteredAgencies(agencies);
  }, [agencies]);

  const columns = useMemo(
    () => [
      {
        Header: agencyConfig.agency_name.name,
        accessor: 'agency_name',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: '80',
      },
      {
        Header: agencyConfig.agency_website.name,
        accessor: 'agency_website',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={
              <a href={value} className="text-blue-b1 visited:text-purple-600" target="_blank" rel="noopener">
                {value}
              </a>
            }
          />
        ),
      },
      {
        Header: agencyConfig.agency_country.name,
        accessor: 'agency_country',
        Cell: ({ value }: any) => (
          <TableCellTruncate
            value={value
              .split(',')
              .map((country: string) => countriesMap.get(country) || '')
              .join(', ')}
          />
        ),
      },
      {
        Header: agencyConfig.agency_type.name,
        accessor: 'agency_type',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
      },
      {
        Header: agencyConfig.agency_focus.name,
        accessor: 'agency_focus',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
      },
      {
        Header: '',
        accessor: 'action',
        width: 50,
        minWidth: 50,
        maxWidth: 50,
        disabledResizing: true,
        disableSortBy: true,
        className: 'flex py-0 bg-gradient-to-l from-white via-white to-transparent group-hover:sticky group-hover:z-10 group-hover:right-0',
        Cell: (row: any) => (
          <MenuActionWithFloat
            className="flex"
            optionClass="!px-2.5"
            items={[
              {
                label: 'Edit',
                onClick: () => {
                  dispatch(
                    showModal({
                      type: ModalTypes.AddAgency,
                      params: {
                        noPlatform: true,
                        agency: row.row.original,
                        handleSave: async (data: any) => {
                          await dispatch(
                            updateAgencyThunk({
                              agency_id: row.row.original.agency_id,
                              data,
                            })
                          );
                        },
                      },
                    })
                  );
                },
              },
              {
                label: 'Delete',
                onClick: () =>
                  dispatch(
                    showModal({
                      type: ModalTypes.DeleteConfirmation,
                      params: {
                        name: row.row.original.agency_name,
                        handleDelete: async () => await dispatch(deleteAgencyThunk(row.row.original.agency_id)),
                      },
                    })
                  ),
              },
            ]}
          >
            {({ open }) => (
              <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                <DotsIcon aria-hidden="true" />
              </span>
            )}
          </MenuActionWithFloat>
        ),
      },
    ],
    []
  );

  const exportColumns = columns.map((column) => ({
    key: column.accessor,
    header: column.Header,
  }));

  const handleSearch = (search: string = '') => {
    const filtered = search === '' ? agencies : agencies.filter((agency) => agency.agency_name.toLowerCase().includes(search.toLocaleLowerCase()));
    setFilteredAgencies(filtered);
  };

  return (
    <>
      <SimpleSearch className="py-4" placeholder="Search by name" onChange={handleSearch} />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl leading-[42px] font-semibold">{`Manage agencies (${formatNumber(
            filteredAgencies?.length ? parseFloat((filteredAgencies.length || 0).toString()) : 0
          )})`}</h1>
          <div className="flex items-center space-x-4">
            <ExportMenu data={filteredAgencies} columns={exportColumns} filename={`Agencies`} />
            <button
              type="button"
              className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-2 focus:ring-blue-b1 focus:ring-offset-2"
              onClick={() =>
                dispatch(
                  showModal({
                    type: ModalTypes.AddAgency,
                    params: {
                      handleSave: async (data: any) => await dispatch(createAgencyThunk(data)),
                    },
                  })
                )
              }
            >
              <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              <p className="mr-1.5">ADD AGENCY</p>
            </button>
          </div>
        </div>

        <div className="mt-4 flex flex-col overflow-hidden">
          <div className="flex flex-col min-w-full align-middle overflow-hidden">
            <Table columns={columns} data={filteredAgencies} />
          </div>
        </div>
      </div>
    </>
  );
};

const Table: FC<any> = memo(
  forwardRef(({ columns, data }, ref: any) => {
    const searchInfluencers = useAppSelector(selectSearchInfluencersAdmin);
    const selectedRows = useAppSelector(selectInfluencersSelectedRowsAdmin);
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
      }),
      []
    );

    const _selectedRows = useMemo(() => {
      const ids: any = {};
      data.forEach((d: any, i: number) => {
        if (selectedRows.some((s: any) => s.id === d.influencer_id)) ids[i] = true;
      });
      return ids;
    }, [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useRowSelect,
      useResizeColumns,
      useFlexLayout
    );

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table w-full">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps()}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      searchInfluencers.order_by === column.id ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort
                          column={column}
                          isSorted={searchInfluencers.order_by === column.id}
                          isSortedDesc={searchInfluencers.order_dir === 'desc'}
                        />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }),
  (prev, next) => isEqual(prev, next)
);
