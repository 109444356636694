import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { cloneDeep, isEqual } from 'lodash';
import cn from 'classnames';
import { ValidationTypes } from 'utils/validation';
import { ClipLoader, Form, Input, Label } from 'shared';
import { IUpdatePasswordProfile } from 'services';
import { updatePasswordProfileThunk, updateUserInfoThunk } from './reducer';
import { selectUser } from 'pages/Auth/reducer';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { AvatarManage } from 'components/Avatar';

export const Profile: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  // TODO add field for image
  const defaultValues: {
    full_name: string;
    email: string;
  } = useMemo(
    () => ({
      full_name: user?.full_name || 'Unknown',
      email: user?.email || '',
    }),
    [user]
  );

  return (
    <>
      <div className="flex flex-1 flex-col p-6 bg-gray-g1">
        <div className="flex items-center">
          <h1 className="text-2xl leading-[42px] font-semibold">My profile</h1>
        </div>
        <div className="mt-8 grid grid-cols-3">
          <div className="col-span-1 bg-white rounded-[4px] p-10">
            <Form
              className="flex flex-col"
              defaultValues={defaultValues}
              validationType={ValidationTypes.Profile}
              onSubmit={async (values) => {
                console.log('SUBMIT', values);
                if (!isEqual(defaultValues, values)) {
                  const valuesCopy = cloneDeep(values);
                  delete valuesCopy.email;
                  return await dispatch(updateUserInfoThunk(valuesCopy));
                }
              }}
            >
              {({ register, formState: { errors, isSubmitting } }) => (
                <>
                  <div className="relative mx-auto">
                    <AvatarManage />
                  </div>
                  <div className="mt-6">
                    <Label htmlFor="full_name" label="Full name" error={errors.full_name} />
                    <div className="mt-0.5">
                      <Input id="full_name" type="text" placeholder="John Doe" error={errors.full_name} {...register('full_name')} />
                    </div>
                  </div>

                  <div className="mt-3">
                    <Label htmlFor="email" label="Email address" error={errors.email} />
                    <div className="mt-0.5">
                      <Input id="email" type="email" className="pointer-events-none" readOnly placeholder="Email" error={errors.email} {...register('email')} />
                    </div>
                  </div>

                  <div className="mt-6 text-center">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-[4px] border border-transparent px-2.5 text-sm leading-6 font-medium text-blue-b1 focus:outline-none focus:ring-0"
                      onClick={() =>
                        dispatch(
                          showModal({
                            type: ModalTypes.ChangePassword,
                            params: {
                              handleSave: async (data: IUpdatePasswordProfile) => await dispatch(updatePasswordProfileThunk(data)),
                            },
                          })
                        )
                      }
                    >
                      Change password
                    </button>
                  </div>

                  <div className="mt-6">
                    <button
                      type="submit"
                      className={cn(
                        'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                        { disabled: isSubmitting }
                      )}
                      disabled={isSubmitting}
                    >
                      <p>Save changes</p>
                      <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                    </button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
